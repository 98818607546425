import React from 'react';
import PropTypes from "prop-types";
import Styled from "styled-components";

const Container = Styled.div`
  progress {
    margin-right: 8px;
    height: 10px;
  }

  progress[value] {
    width: ${props => props.width};

    -webkit-appearance: none;
    appearance: none;
  }

  progress[value]::-webkit-progress-bar {
    height: 10px;
    border-radius: 20px;
    background-color: #eee;
  }  

  progress[value]::-webkit-progress-value {
    height: 10px;
    border-radius: 20px;
    background-color: ${props => props.color};
  }
`;

const ProgressBar = React.forwardRef(({ value, max, color, width, className }, ref) => {
  return (
    <Container color={color} width={width}>
      <progress value={value} max={max} />
      <span>{(value / max) * 100}%</span>
    </Container>
  );
});

ProgressBar.displayName = 'ProgressBar';

ProgressBar.defaultProps = {
  className: '',
  max: 100,
  color: "lightBlue",
  width: "250px"
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string
};

export default ProgressBar;