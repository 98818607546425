import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import PSIcon from '../../PSIcon';

const TableContractsUser = ({ listItem }) => {
  return (
    <table className="container-table-contracts-user">
      <thead>
        <tr>
          <th className="table-contracts-user-header-field">Nombre contrato</th>
          <th className="table-contracts-user-header-field">Tipo</th>
          <th className="table-contracts-user-header-field">Fecha realizado</th>
          <th className="table-contracts-user-header-field">Acción</th>
        </tr>
      </thead>
      <tbody>
        {listItem.map((item, index) => {
          return (
            <tr key={index}>
              <td className="table-contracts-user-item contract-name-item d-flex">
                <PSIcon type={item.icon} />
                <div>
                  <span>{item.contractName}</span>
                </div>
              </td>
              <td className="table-contracts-user-item type-item">{item.type}</td>
              <td className="table-contracts-user-item">{item.executedDate}</td>
              <td className="table-contracts-user-item action-item">
                <div className="d-flex">
                  <PSIcon type="download2" />
                  <PSIcon type="more" />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableContractsUser;

TableContractsUser.displayName = 'TableTaskHistory';

TableContractsUser.defaultProps = {
  className: '',
  listItem: [],
};

TableContractsUser.propTypes = {
  className: PropTypes.string,
  listItem: PropTypes.array,
};
