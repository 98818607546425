import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';

const ActivityComment = ({ username, date, comment }) => {
  return (
    <div className="activity-comment_container">
      <div className="activity-comment-profile-image">
        <PSIcon type="user" />
      </div>
      <div className="activity-comment-body">
        <div className="body-title">
          <p className="comment-user">{username}</p>
          <span className="comment-date d-flex">{date}</span>
        </div>
        <p className="comment-paragraph">{comment}</p>
        <div className="activity-comment-footer">
          <a>Editar</a>
          <a>Eliminar</a>
        </div>
      </div>
    </div>
  );
};
export default ActivityComment;

ActivityComment.displayName = 'ActivityComment';

ActivityComment.defaultProps = {
  username: 'Daniel Montanchez',
  date: '20/04/2020',
  comment:
    'Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Pellentesque sit amet nibh ac turpis blandit efficitur.',
};

ActivityComment.propTypes = {
  username: PropTypes.string,
  date: PropTypes.string,
  comment: PropTypes.string,
};
