export class RolModel {
  constructor(props) {
    this.creationDate =   props['CreationDate'];
    this.creationUser =   props['CreationUser'];
    this.id =  props['Id'];
    this.menuOptions =  props['MenuOptions'];
    this.name =  props['Name'];
    this.value =  props['Name']; // todo for the moment
    this.label =  props['Name'];
    this.sort =  props['Sort'];
    this.updateDate =  props['UpdateDate'];
  }

}