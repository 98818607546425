import React from 'react';
import './index.scss';
import { ContractsTree, ContainerCardContract, ItemFile } from '../../molecules';
import { pdfItemFile } from '../../molecules/itemFile/Constants';
import { Search } from '../../atoms';

export default function contractContracts({ date, files }) {
  return (
    <div className="container-contract">
      <div>
        <ContractsTree />
      </div>
      <div>
        <ContainerCardContract>
          <div>
            <div>
              <h3>Contratos 1</h3>
            </div>
            <div className="container-files">
              <span>Creado {date} | Archivos</span>
            </div>
            <div className="search-bar">
              <div className="container-search">
                <Search placeholder="Buscar documento" onSearch={() => console.log('search')} />
              </div>
              <div>
                <span>Ver:</span>
              </div>
            </div>
            <div className="container-file">
              {files.map((item) => {
                return (
                  <ItemFile
                    key={item}
                    icon={pdfItemFile.icon}
                    fileName={pdfItemFile.fileName}
                    uploadDate={pdfItemFile.uploadDate}
                  />
                );
              })}
            </div>
          </div>
        </ContainerCardContract>
      </div>
    </div>
  );
}
