import React, { useState, useEffect } from 'react';
import './index.scss';
import {ListFAQ, ListProducts} from "../../organisms";

const listFaq = [
  {question:"¿Qué es Renta Total Protecta?", answer:'ddd'},
  {question:"¿Podría tomar más de una póliza del Seguro Renta Total Protecta?", answer:'ddd'},
  {question:"¿Por cuánto tiempo podríamos tomar una Renta Total Protecta?", answer:'ddd'},
  {question:"¿Esta póliza la puede pagar mi empleador o algún familiar para mí?", answer:'ddd'},
  {question:"¿Qué es Renta Total Protecta?", answer:'ddd'},
]

export default function FrequentQuestion({listProducts}) {
  return (
    <div className="frequent-question">
      <div className="frequent-question__title">
        Preguntas frecuentes
      </div>
      <ListProducts listProducts={listProducts} className="list-products-faq"/>
      <ListFAQ listFaq={listFaq}/>
    </div>
  );
}
