import {doGet, doPost} from './Api';

export default class RequestCompany {
  static async responseGetRequestAprovee(type) {
    const response = await doGet(`${process.env.REACT_APP_GET_REQUEST_APROVEE}list`);
    return response.data.data.filter(r => r.process_status.toLowerCase() === type);
  }


  static async sendRequestToAprovee(params, status) {
    const response = await doPost(`${process.env.REACT_APP_GET_REQUEST_APROVEE }enrollments/${status}` ,params);
    return response.data;
  }

  static async getFileRUC(urlPath) {
    const response = await doGet(`${process.env.REACT_APP_GET_REQUEST_APROVEE}enrollments/${urlPath}`);
    return response.data.data;
    }
}
