import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/index';

import { OPTION_SEGMENT } from '../../../../scripts/Constants';

import PSTabs from '../../PSTabs';
import { ButtonColor } from '../../atoms/button/Button';
import { Button, SelectCustom } from '../../atoms';
import PSIcon from '../../PSIcon';
import DropzoneCustom from '../../molecules/dropZoneCustom/dropZoneCustom';

import './index.scss';

const NameTab = ({ name, icon }) => {
  return (
    <div className="d-flex icon-container">
      <PSIcon type={icon} />
      <div className="title-tab">
        <h4>{name}</h4>
      </div>
    </div>
  );
};

const tabsCreateMngPromotions = [
  {
    id: 0,
    name: <NameTab name="Imágenes" icon="laptopActivated" />,
    className: '',
    selected: true,
  },
  // { id: 1, name: <NameTab name="Mobile" icon="mobileDisabled" />, className: '', selected: false },
];

const tabsCreateMngPromotions2 = [
  { id: 0, name: 'Opciones', className: '', selected: true },
  // { id: 1, name: 'Descripción', className: '', selected: false },
];

const CreateManagementPromotions = React.forwardRef(
  ({ onSave, onChangePromotion, newPromotion, onClose, typeSelected, loadingSave }, ref) => {
    const [tabsCreateManagementPromotions, setTabsCreateManagementPromotions] = useState([
      ...tabsCreateMngPromotions,
    ]);
    const [tabsCreateManagementPromotions2, setTabsCreateManagementPromotions2] = useState([
      ...tabsCreateMngPromotions2,
    ]);
    const today = moment(Date.now()).format('YYYY-MM-DD');

    const isCompleted = () => {
      let completedField = false;

      if (
        newPromotion.file &&
        newPromotion.name &&
        newPromotion.startDate &&
        newPromotion.endDate
      ) {
        completedField = true;
      }

      if (typeSelected === 3) {
        if (newPromotion.products && newPromotion.products.length > 0) {
          completedField = completedField && true;
        } else {
          completedField = completedField && false;
        }
      }
      return completedField;
    };

    const onSelectTabCMP = (indexChange) => {
      if (indexChange >= 0) {
        tabsCreateManagementPromotions.forEach((item, index) => {
          if (index === indexChange) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
        setTabsCreateManagementPromotions([...tabsCreateManagementPromotions]);
      }
    };

    const onSelectTabCMP2 = (indexChange2) => {
      if (indexChange2 >= 0) {
        tabsCreateManagementPromotions2.forEach((item, index) => {
          if (index === indexChange2) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
        setTabsCreateManagementPromotions2([...tabsCreateManagementPromotions2]);
      }
    };

    return (
      <div className="create-management-promotion" ref={ref}>
        <div className="body__validation">
          <div className="body__row">
            <div className="management-promotion-body-container">
              <h2 className="lc-title2">Subir archivos</h2>
              <PSTabs
                className="tabs-newsletter"
                options={tabsCreateManagementPromotions}
                onSelecTab={onSelectTabCMP}
                hasFilters={false}
                hasButtonSubmit={false}
              />
              <div className="drop-area-container">
                <DropzoneCustom
                  onChangeFile={(file) => {
                    onChangePromotion('file', file);
                  }}
                  acceptMultiple={typeSelected === 1}
                />
              </div>
              <PSTabs
                className="tabs-newsletter"
                options={tabsCreateManagementPromotions2}
                onSelecTab={onSelectTabCMP2}
                hasFilters={false}
                hasButtonSubmit={false}
              />
              <div className="options-container">
                {typeSelected === 3 && (
                  <div>
                    <h6>Segmento</h6>
                    <SelectCustom
                      className="category-dropdown"
                      placeholder=""
                      onChange={(itemSelected) => onChangePromotion('products', itemSelected)}
                      isMulti
                      options={OPTION_SEGMENT}
                    />
                  </div>
                )}

                <div>
                  <label htmlFor="name">Nombre*</label>
                  <input id="name" onChange={(e) => onChangePromotion('name', e.target.value)} />
                </div>
                <div>
                  <label htmlFor="startDate">Fecha de Inicio:*</label>
                  <input
                    id="startDate"
                    type="date"
                    min={today}
                    max="2050-12-31"
                    onChange={(e) => onChangePromotion('startDate', e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="endDate">Fecha de Fin:*</label>
                  <input
                    id="endDate"
                    type="date"
                    min={newPromotion.startDate ? newPromotion.startDate : today}
                    max="2050-12-31"
                    onChange={(e) => onChangePromotion('endDate', e.target.value)}
                  />
                </div>
              </div>
              <div className="options-container mb-2">
                <div className="w-100">
                  <label htmlFor="start">Enlace:</label>
                  <input
                    style={{ width: '80%' }}
                    type="text"
                    onChange={(e) => onChangePromotion('link', e.target.value)}
                  />
                </div>
              </div>
              <div className="btns-container">
                <Button
                  type={ButtonColor.blue}
                  onClick={onSave}
                  disabled={!isCompleted()}
                  loading={loadingSave}
                >
                  Guardar
                </Button>
                <Button
                  className="ml-1 btn-close"
                  type={ButtonColor.orangeOutline}
                  onClick={onClose}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

CreateManagementPromotions.displayName = 'CreateManagementPromotions';

CreateManagementPromotions.defaultProps = {
  surveyCreate: {},
  onHandleCreateSurvey: () => {},
  onClose: () => {},
};

CreateManagementPromotions.propTypes = {
  surveyCreate: PropTypes.object,
  onHandleCreateSurvey: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateManagementPromotions;
