import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSIcon from './PSIcon';
import PSDownloadFile from './PSDownloadFile';
import { typeResquest as typeRequest, typeResquest } from '../shared/constants/Constants';
import { formatDateTime } from '../../scripts/Util';

const StyledPSCard = styled.div`
  width: 100%;
  min-height: 154px;
  background: #fff;
  box-shadow: 3px 3px 10px #004eaf12;
  border-radius: 7px;
  opacity: 1;
  display: flex;
  .section-lef {
    width: 50%;
    display: flex;
    .container-icon {
      margin: 1rem;
      width: 40%;
      .circle {
        width: 83px;
        height: 83px;
        background: #555454 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        .icon {
          padding: 0.4rem;
        }
      }
      .icon-detail {
        margin: 0.7rem 0.45rem;
        cursor: pointer;
      }
    }
    .container-info {
      margin: 1rem 0;
      width: 60%;
      color: #002655;
      .downloadFile {
        cursor: pointer;
        border: 0;
        .container-download-file {
          margin: 0;
        }
      }
      .info-name {
        font-size: 15px;
        letter-spacing: 0px;
      }
      .info-general {
        font-size: 12px;
        margin: 1rem 0;
        opacity: 0.7;
      }
      .info-general-new {
        margin: 0.5rem 0 !important;
      }
      .info-date {
        font-size: 12px;
      }
    }
  }
  .section-right {
    width: 50%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    .container-pill {
      margin-bottom: 4px;
      justify-content: flex-end;
      display: flex;
      .pill {
        background: #d049c7;
        border-radius: 10px;
        color: #fff;
        font-size: 12px;
        width: 82px;
        height: 23px;
        text-align: center;
        padding-top: 5px;
      }
    }

    .container-wait-time {
      display: flex;
      justify-content: flex-end;
      color: #002655;
      opacity: 0.7;
      font-size: 12px;
      .time-info {
        padding: 0 4px;
      }
    }
    .info-accepted {
      font-size: 12px;
      margin: 0 1rem;
    }
    .attend-request {
      display: flex;
      margin: auto 0;
      justify-content: center;
      .margin-15 {
        margin-right: 15%;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .info-rejected {
      display: flex;
      justify-content: center;
      margin: auto 0;

      .icon-download {
        cursor: pointer;
        margin-right: 15%;
        svg {
          width: 35px;
          height: 36px;
        }
      }
      .icon-delete {
        svg {
          width: 29px;
          height: 35px;
        }
      }
    }
  }

  .less-opacity {
    opacity: 0.5;
  }
`;

const PSCard = React.forwardRef(
  (
    {
      className,
      onClick,
      showDetail,
      type,
      request,
      acceptRequest,
      rejectRequest,
      downloadFile,
      isReadOnly,
      ...props
    },
    ref
  ) => {
    const dateString = formatDateTime(request.date);
    const updateDateString = formatDateTime(request.lastUpdate);
    return (
      <StyledPSCard {...props} ref={ref} className={className}>
        <div className="section-lef">
          <div className="container-icon">
            <div className="circle">
              <PSIcon type="companyWhite" className="icon" />
            </div>
            <PSIcon type="detail" className="icon-detail" onClick={() => showDetail(request)} />
            <div />
          </div>
          <div className="container-info ">
            <div className="info-name">
              <label>{request.legalName}</label>
            </div>
            <div className={`info-general ${type === typeRequest.NEW ? ' info-general-new' : ''}`}>
              <div>
                <label>{request.rucNumber}</label>
              </div>
              <div>
                <label>
                  {`${request.contactName} ${request.contactLastName} ${request.contactLastName2}`}{' '}
                </label>
              </div>
              <div className="less-opacity">
                <label>{`${request.contactRole}`}</label>
              </div>
            </div>
            {type === typeResquest.NEW && (
              <PSDownloadFile
                nameFile="ArchivoRUC.pdf"
                className="downloadFile"
                onClick={() => downloadFile(request)}
              />
            )}

            <div className="info-date">
              <div>
                <label>Fecha de solicitud</label>
              </div>
              <div className="less-opacity">
                <label>{dateString}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="section-right">
          {type === typeResquest.NEW && (
            <div className="container-pill">
              <div className="pill">
                <label>Espera</label>
              </div>
            </div>
          )}
          {/* <div className="container-wait-time"> */}
          {/*  <PSIcon type="time" /> */}
          {/*  <div className="time-info"> */}
          {/*    <label>{info.waitTime}</label> */}
          {/*  </div> */}
          {/* </div> */}
          {(type === typeResquest.ACCEPTED || type === typeResquest.REJECTED) && (
            <div className="ma-1">
              <PSDownloadFile nameFile="ArchivoRUC.pdf" onClick={() => downloadFile(request)} />
            </div>
          )}

          {type === typeResquest.NEW && !isReadOnly && (
            <div className="attend-request">
              <PSIcon type="acceptChanged" className="margin-15" onClick={() => acceptRequest()} />
              <PSIcon type="deny" onClick={() => rejectRequest()} />
            </div>
          )}

          {(type === typeResquest.ACCEPTED || type === typeResquest.REJECTED) && (
            <div className="info-accepted">
              <div className="less-opacity">
                <label>{type === typeResquest.ACCEPTED ? 'Aceptada por' : 'Rechazado por'}</label>
              </div>
              <div>
                <label>{request.aproveeOwner}</label>
              </div>
              <div>
                <label>{updateDateString}</label>
              </div>
            </div>
          )}
        </div>
      </StyledPSCard>
    );
  }
);

PSCard.displayName = 'PSCard';

PSCard.defaultProps = {
  onClick: () => { },
  className: null,
  info: {},
  type: typeResquest.NEW,
  request: {},
  acceptRequest: () => { },
  rejectRequest: () => { },
  downloadFile: () => { },
  showDetail: () => { },
};

PSCard.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  info: PropTypes.object,
  type: PropTypes.string,
  request: PropTypes.object,
  acceptRequest: PropTypes.func,
  rejectRequest: PropTypes.func,
  downloadFile: PropTypes.func,
  showDetail: PropTypes.func,
};

export default PSCard;
