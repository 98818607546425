import React from 'react';
import './index.scss';
import { ContainerCardContract } from '../../molecules';

const index = () => {
  return (
    <ContainerCardContract>
      <span className="comment-title">Observación</span>
      <p className="comment-paragraph">
        Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Pellentesque sit amet nibh ac
        turpis blandit efficitur.
      </p>
    </ContainerCardContract>
  );
};

export default index;
