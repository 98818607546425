import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { Products } from '../../components/templates';

export const listProducts = [
  { name: 'Renta Vitalicias', icon: 'prdRentasVitalicias' , branchId:1},
  { name: 'SOAT', icon: 'prdSoat' , branchId:2},
  { name: 'Desgravamen', icon: 'prdDesgravamen',branchId:3 },
  { name: 'Renta Total', icon: 'prdRentaTotal',branchId:4 },
  { name: 'Ahorro Total', icon: 'prdAhorroTotal',branchId:5 },
  { name: 'SCTR', icon: 'prdSCTR' ,branchId:6},
  { name: 'Accidentes Personales', icon: 'prdAccidentesP',branchId:7 },
  { name: 'Vida Ley', icon: 'prdVidaLey',branchId:8 },
  { name: 'Vida Grupo', icon: 'prdVidaGrupo',branchId:9 },
];

export default function ProductsPage(props) {
  const [productSelected, setProductSelected] = useState(null); // definir

  useEffect(() => {

  }, []);

  return (
    <Products
      listProducts={listProducts}
      productSelected={productSelected}
      selectProduct={(product) => setProductSelected(product)}
    />
  );
}
