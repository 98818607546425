import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const Switch = React.forwardRef(({ className,isDisabled, id, onHandle, isChecked, ...props}, ref) =>{
  const [checked, setChecked] = useState(isChecked);
  const onHandleChecked = () => {
    setChecked(!checked);
    onHandle(!checked);
  };

  return (
    <div ref={ref} className={`container-switch  ${className}`}>
      <input
        className="react-switch-checkbox"
        id={id}
        disabled={isDisabled}
        type="checkbox"
        checked={checked}
        onChange={() => onHandleChecked()}
        {...props}
      />
      <label
        className="react-switch-label"
        htmlFor={id}
        style={{ background: checked && '#4caf50' }}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  )
});

Switch.displayName = 'Switch';

Switch.defaultProps = {
  className: '',
  onClick: () => {},
  id: -1,
  onHandle : () => {},
  isChecked: false,
  isDisabled: false,
};

Switch.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.number,
  onHandle: PropTypes.func,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default Switch;
