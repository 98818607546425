import {
  transformStringDateToMount,
  currencyInRange,
  listSegmentById,
} from '../../../scripts/Util';

export class PromotionModel {
  constructor(props) {
    this.creationDate = props.CreationDate;
    this.creationUser = props.CreationUser;
    this.date = props.Date;
    this.default = props.Default;
    this.id = props.Id;
    this.image = props.Image;
    this.name = props.Name;
    this.sort = props.Sort;
    this.type = props.Type;
    this.status = props.Status;
    this.startDate = props.StartDate;
    this.endDate = props.EndDate;
    this.rangeVigency = currencyInRange(props.StartDate, props.EndDate);
    this.link = props.Link;
    this.updateDate = props.UpdateDate;
    this.month = props.StartDate ? transformStringDateToMount(props.StartDate) : '';
    this.products = props.Products;
    this.productsName = listSegmentById(props.Products);
  }
}
