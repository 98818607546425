import React, { useState, useEffect } from 'react';
import { DropzoneNewsLetter} from './../../molecules';
import './index.scss';
import {NewsLetterTable} from "../index";
import PSLoading from "../../PSLoading";

const data = [
  {
    order : 1,
    img:"img",
    name:"nombre Imgaen",
    date:"20/01/2021",
    favorite: true,
  },
  {
    order : 2,
    img:"img2",
    name:"nombre Imgaen2",
    date:"22/01/2021",
    favorite: true,
  },
  {
    order : 2,
    img:"img",
    name:"nombre Imgen3",
    date:"24/01/2021",
    favorite: false,
  }
]

export default function NewSletterBanner({showPreviewItem,listCategory, loading, selectCategory, categorySelected, selectFileWeb, selectFileResponsive}) {
  return(
      <div className="newsletter-banner">
        <div className="container-newsLetter">
          <DropzoneNewsLetter title="Imagen Web" selectFile={selectFileWeb}/>
          <DropzoneNewsLetter title="Imagen Responsive" selectFile={selectFileResponsive}/>
        </div>
        <div className="newsletter-banner-section-table">
          <div>
            {
              loading ?
                <PSLoading color='orange'/>:
                <NewsLetterTable data={listCategory} showPreviewItem={showPreviewItem} selectItem={selectCategory} itemSelected ={categorySelected} />
            }

          </div>
        </div>
      </div>
  )
}
