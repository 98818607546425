import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import PSIcon from '../../PSIcon';
import { showErrorToast } from '../../../shared/constants/Constants';
import { MAX_SIZE_UPLOAD_FILE } from '../../../../scripts/Constants';

export const ButtonIconColor = {
  blue: 'ButtonIcon-blue',
  orange: 'ButtonIcon-orange',
  white: 'ButtonIcon-white',
  red: 'ButtonIcon-red',
  green: 'ButtonIcon-green',
};

const ButtonFile = React.forwardRef(({ type, className, onClick, name, icon, selectFile }, ref)=>{

  const [fileLoaded, setFileLoaded] = useState(null);
  const onDrop = (e, file) => {
    if (file.length > 0) {
      const { size } = file[0].file;
      if (size < MAX_SIZE_UPLOAD_FILE) {
        setFileLoaded(file[0].file);
        selectFile(file[0].file);
      } else {
        showErrorToast('El peso máximo del archivo es 10 Megas');
      }
    }
  }

  return(
    <div ref={ref} className={`container-ButtonIcon  ${type} ${className}`}>
      <button onClick={onClick}>
        <Dropzone maxSize={'2048'} accept=".pdf" multiple={false} onDragEnter={(e)=>{console.log("valores",e)}}
                  onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section className="section-drop-zone">
              <div {...getRootProps()} className="container-button-drop">
                <input {...getInputProps()} />
                <span>{fileLoaded ? fileLoaded.name : name}</span>
                <div>
                  <PSIcon type={icon} />
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </button>
    </div>
  );
});

ButtonFile.displayName = 'ButtonIcon';

ButtonFile.defaultProps = {
  className: '',
  onClick: () => {},
  selectFile:() => {},
  type: ButtonIconColor.blue,
  icon: 'block',
  name: '',
};

ButtonFile.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  selectFile: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
};

export default ButtonFile;
