import React from 'react';
import Chart from 'react-apexcharts';
import './index.scss';
import PropTypes from 'prop-types';

/* eslint-disable react/prefer-stateless-function */
const StatisticalChart = React.forwardRef(
  ({ series, type, xaxisCategories, title, colors, yarisMax, tickAmount, className }, ref) => {
    const options = {
      title: {
        text: title,
        align: 'left',
        margin: 10,
        offsetX: 15,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          fontFamily: 'Gotham-Book',
          color: '#4D4F5C',
        },
      },
      chart: {
        id: 'point-users',
        height: type === 'area' ? '50%' : '100%',
        width: '100%',
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '12px',
        fontFamily: 'Gotham-Book',
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: colors,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        offsetX: 15,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 3,
        colors: '#fff',
        strokeColor: colors,
        strokeWidth: 3,
        strokeOpacity: 1,
        fillOpacity: 2,
        shape: 'circle',
        offsetX: 0,
        offsetY: 0,
        hover: {
          size: 4,
          sizeOffset: 1,
        },
      },
      xaxis: {
        type: 'category',
        categories: xaxisCategories,
        lines: {
          show: true,
        },
        labels: {
          maxWidth: 50,
          show: true,
          style: {
            fontSize: '10px',
            colors: '#A9A9A9',
          },
          offsetX: 0,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          height: 1,
          color: '#EAF0F4',
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        max: yarisMax,
        tickAmount,
        opposite: false,
        decimalsInFloat: false,
        lines: {
          show: true,
        },
        labels: {
          align: 'right',
          style: {
            color: '#A9A9A9',
            fontSize: '10px',
            cssClass: 'apexcharts-yaxis-label',
          },
          formatter(val, index) {
            return `${val} pts`;
          },
        },
        axisTicks: {
          show: false,
        },
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: type==='area' ?colors :'#fff',
        width: 2.5,
        dashArray: 0,
      },
      grid: {
        show: true,
      },
      fill: {
        colors: colors,
        lines: {
          show: true,
        },
        gradient: {
          shadeIntensity: 0.8,
          opacityFrom: 0.4,
          opacityTo: 0.2,
        },
        pattern: {
          style: 'verticalLines',
          width: 6,
          height: 6,
          strokeWidth: 2,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          columnWidth: '30%',
          barHeight: '30%',
        },
      },
    };

    return (
      <div className={`statistical-chart-component ${className}`}>
        <Chart options={options} series={series} type={type} />
      </div>
    );
  }
);

StatisticalChart.displayName = 'StatisticalChart';

StatisticalChart.propTypes = {
  className: '',
  series: [],
  type: 'area',
  xaxisCategories: [],
  title: '',
  colors: [],
  yarisMax: 100,
  tickAmount: 3,
};

StatisticalChart.propTypes = {
  className: PropTypes.string,
  series: PropTypes.array,
  type: PropTypes.string,
  xaxisCategories: PropTypes.array,
  title: PropTypes.string,
  colors: PropTypes.array,
  yarisMax: PropTypes.number,
  tickAmount: PropTypes.number,
};

export default StatisticalChart;
