import React from 'react'
import PropTypes from 'prop-types';
import './index.scss';

const Search = ({ placeholder, onSearch }) => {
    return (
        <div className="option-filters">
            <input
                type="text"
                placeholder={placeholder}
                onChange={(e) => onSearch(e.target.value || '')}
            />
        </div>
    )
}

export default Search

Search.displayName = 'Search';

Search.defaultProps = {
    placeholder: '',
};

Search.propTypes = {
    placeholder: PropTypes.string,
};
