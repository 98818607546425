import React, { useState } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { pathServer } from '../../shared/constants/Constants';

import { ModalConfirm } from '../../components/organisms';
import { Routes } from '../../../routes';

export default function HomePage() {
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();

  const onGoNet = () => {
    setShowModal(false);
  };

  const onGoDocument = () => {
    history.push(Routes.CONTRACT.HOME);
    setShowModal(false);
  };

  return (
    <div className="home-page">
       <div className="img-container">
        <h1>Bienvenido</h1>
        <img src={`${pathServer.PATH_IMG}default.png`} alt="" className="img-default" />
      </div>
      {
        process.env.REACT_APP_CONTRACT_MANAGER ==='true' &&
        <ModalConfirm
          title="¡Bienvenido al Backoffice!"
          question="Elige a dónde quieres acceder"
          buttonCancel="Gestor de Contratos"
          buttonAccept="Net Privada"
          openModal={showModal}
          onCancel={onGoDocument}
          onAccept={onGoNet}
        />
      }
    </div>
  );
}
