export class ItemRequest {
  constructor(props) {
    this.status = props['ESTADO'];
    this.birthdate = props['birthdate'];
    this.businessEmail = props['business_email'];
    this.businessPhone = props['business_phone_number'];
    this.contactAnexo = props['contact_anexo'];
    this.contactDocument = props['contact_document'];
    this.contactEmail = props['contact_email'];
    this.contactLastName = props['contact_last_name'];
    this.contactLastName2 = props['contact_last_name2'];
    this.contactName = props['contact_name'];
    this.contactPhone = props['contact_phone'];
    this.contactRole = props['contact_role'];
    this.date = props['date'];
    this.rucNumber = props['documentID'];
    this.familyName = props['family_name'];
    this.givenName = props['given_name'];
    this.id = props['id'];
    this.indPdp = props['ind_pdp'];
    this.legalName = props['legal_name'];
    this.middleName = props['middle_name'];
    this.processStatus = props['process_status'];
    this.rucFile = props['ruc_file_path'];
    this.sort = props['sort'];
    this.mainReasson = props['observations'];
    this.secondaryReasson = props['comments'];
    this.aproveeOwner = props['update_by'];
    this.lastUpdate=props['last_update'];
  }
}
