import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import './index.scss';
import PSLanding from '../../components/PSLanding';
import PSButton from '../../components/PSButton';
import PSTextInput from '../../components/PSTextInput';
import { loadPermission, loginUser, logout } from '../../redux/ducks/user-loginDuck';
import PSError from '../../components/PSError';
import { Routes } from '../../../routes';

export default function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { errorLogin } = useSelector((state) => state.user);
  const [loading, setLoading] = useState();

  const login = async (user, password) => {
    setLoading(true);
    await dispatch(loginUser(user, password));
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      user: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      user: Yup.string().required('Usuario  es requerido'),
      password: Yup.string()
        .min(8, 'Necesita mínimo 6 caracteres')
        .required('La contraseña es requerida'),
    }),

    onSubmit(form) {
      const { user, password } = form;
      login(user, password);
    },
  });

  useEffect(() => {
    if (errorLogin === null) {
      history.push(Routes.HOME);
    } else if (errorLogin === 'Credenciales Incorrectas') {
      setTimeout(() => {
        dispatch(logout());
      }, 5000);
    }
  }, [errorLogin]);

  return (
    <PSLanding>
      <div className="login-container">
        <div className="login-body">
          <div>
            <div className="login-header-title">
              <label>¡Bienvenido!</label>
            </div>
            <form className="login-form" onSubmit={formik.handleSubmit} noValidate>
              <div className="form-container">
                <PSTextInput
                  type="text"
                  placeholder="Ingrese usuario"
                  {...formik.getFieldProps('user')}
                />
                <PSError>{formik.touched.user && formik.errors.user}</PSError>
                <PSTextInput
                  placeholder="********"
                  type="password"
                  {...formik.getFieldProps('password')}
                />
                <PSError>{formik.touched.password && formik.errors.password}</PSError>
                <PSError>{errorLogin && errorLogin}</PSError>
                <div className="login-submit">
                  <Link to={Routes.FORGOT_PASSWORD} className="login-footer-forgot">
                    Olvidé mi contraseña
                  </Link>

                  <PSButton type="submit" loading={loading}>
                    Ingresar
                  </PSButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </PSLanding>
  );
}
