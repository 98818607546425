import React, { useState, useEffect } from 'react';
import './index.scss';
import PSTabs from '../../PSTabs';
import { Routes } from '../../../../routes';
import { useHistory } from 'react-router-dom';
import { NewSletterCategory, NewSletterBanner} from './../../organisms';
import PSModal from "../../PSModal";
import {ContainerPreviewBanner} from "../../molecules";
import ManagerNewsLatter from "../../../shared/services/ManagerNewsLatter";
import {getStructToGetUrlFile} from "../../../../scripts/Util";
import {showErrorToast, showSuccessToast} from "../../../shared/constants/Constants";

const tabsNewSletter = [
  { id: 0, name: 'Banner', className: '', selected: true },
  { id: 1, name: 'Categoría', className: '', selected: false },
];

export default function NewSletterGeneral(props) {

  const history = useHistory();
  const [showPreview, setShowPreview] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentCategory,setCurrentCategory] = useState(null);
  const [currentImgWeb, setCurrentImgWeb] = useState(null);
  const [currentImgResponsive, setCurrentImgResponsive] = useState(null);


  const loadCategory = async () => {
    try{
      setLoading(true);
      const response = await ManagerNewsLatter.responseGetListCategory();
      if(response.statusCode === 200) {
        const  data = response.data;
        await loadImgCategory(data);
        setLoading(false);
      }else{
        setLoading(false);
      }
    }catch (error){
      setLoading(false);
    }
  }


  const loadImgCategory = async (list) => {
    try{
      const listCategory = list;
      setLoading(true);
      for (const category of listCategory) {
        const responseImage = await ManagerNewsLatter.responseGetImages(category.webBannerS3UrlImage);
        category.img=responseImage
      }
      setListCategory([...listCategory] );
      setLoading(false);
    }catch (error){

      console.log("error", error)
    }

  };

  const onClickShowPreview =(item)=>{
    setCurrentCategory({ ...item });
    setShowPreview(true)
  }

  const onSubmitChanges = async ()=>{
    if(currentCategory){
      if(currentImgWeb || currentImgResponsive){
        try{
          setLoading(true);
          if(currentImgWeb){
            const structToGetUrlImg = getStructToGetUrlFile(currentCategory.idContenido, currentImgWeb.name);
            currentCategory.webBannerS3UrlImage = `/newsletter/get/${structToGetUrlImg}`;

            const responseUrlImg = await ManagerNewsLatter.getUrlDocuments(structToGetUrlImg);

            if(responseUrlImg.code ===200){
              await ManagerNewsLatter.uploadFile(
                responseUrlImg.data,
                currentImgWeb,
                currentImgWeb.type
              );
            }
          }

          if(currentImgResponsive){
            const structToGetUrlImgResponsive = getStructToGetUrlFile(currentCategory.idContenido, currentImgResponsive.name);
            currentCategory.responsiveBannerS3UrlImage = `/newsletter/get/${structToGetUrlImgResponsive}`;
            const responseUrlImgReponsive = await ManagerNewsLatter.getUrlDocuments(
              structToGetUrlImgResponsive
            );

            if (responseUrlImgReponsive.code === 200) {
              await ManagerNewsLatter.uploadFile(
                responseUrlImgReponsive.data,
                currentImgResponsive,
                currentImgResponsive.type
              );
            }
          }

          const responseUpdate = await ManagerNewsLatter.responseUpdateCategory(currentCategory);
          if (responseUpdate.statusCode === 200) {
            showSuccessToast('Categoría actualizado');
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }else{
        showErrorToast('Debe adjuntar nueva imagen para actualizar categoría');
      }

    } else{
      showErrorToast('Seleccione una categoría');
    }
  };

  useEffect(()=>{
    loadCategory();
  },[]);

  return <div className="newsletter-general">
      <div className="newsletter-general__header">
        <h2 className="color-blue extra-bold">Newsletter</h2>
        <PSTabs className="tabs-newsletter" options={tabsNewSletter} onSelecTab={(index) => {
          index === 0  ?  history.push(Routes.NEWSLETTER_GENERAL) :  history.push(Routes.NEWSLETTER_CATEGORY);
        }} hasFilters={false} hasButtonSubmit onSubmit={onSubmitChanges}/>
      </div>
      <div className="newsletter-general__body">
        <NewSletterBanner
          showPreviewItem={(item)=>onClickShowPreview(item)}
          listCategory={listCategory}
          loading={loading}
          selectCategory={(item)=>setCurrentCategory(item)}
          categorySelected={currentCategory}
          selectFileWeb = {(img)=>setCurrentImgWeb(img)}
          selectFileResponsive ={(imgResponsive)=>setCurrentImgResponsive(imgResponsive)}
        />
        {showPreview && (
          <PSModal
            open={showPreview}
            onClose={() => setShowPreview(false)}
            classNames={{
              overlay: 'customOverlay',
              modal: 'customModalPreview',
            }}
          >
            <ContainerPreviewBanner category={currentCategory}/>
          </PSModal>
        )}
      </div>
  </div>
}
