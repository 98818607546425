import moment from 'moment-timezone';
import { Icons, MONTH, OPTION_SEGMENT_BY_ID } from './Constants';
import {
  DATE_TIME_FORMAT,
  DOMAIN_READ_ONLY,
  LINE_BREAK_CODE,
  listOfProducts,
  services,
} from '../app/shared/constants/Constants';
import 'moment/locale/es';

export function getIcon(type) {
  if (Icons[type] !== undefined) {
    return Icons[type].url;
  }
  return Icons.default.url;
}

export function getFirstLetter(str) {
  if (str.toString) {
    return str.toString().substr(1);
  }
  return null;
}

export function filterOptionsMenu() {
  const options = [
    {
      id: 212,
      description: 'Gestionar Menú',
      url: '/management/menu',
      show: process.env.REACT_APP_PERMISSION_CONFIGURATION_GENERAL_MENU,
    },
    {
      id: 213,
      description: 'Gestionar Banner',
      url: '/management/promotions',
      show: process.env.REACT_APP_PERMISSION_CONFIGURATION_GENERAL_BANNER,
    },
    {
      id: 214,
      description: 'Gestionar Preguntas Frecuentes',
      url: '/frequent-question',
      show: process.env.REACT_APP_PERMISSION_CONFIGURATION_GENERAL_FAQ,
    },
  ];
  return options.filter((op) => op.show === 'true');
}
export function isOnlyReaderToDomain(mail) {
  const dominio = mail.split('@');
  let isReadOnly = false;
  if (dominio.length > 1) {
    if (dominio[1] === DOMAIN_READ_ONLY) {
      isReadOnly = true;
    }
  }
  return isReadOnly;
}

export function removeDays(date, days) {
  date = new Date(date);
  date.setDate(date.getDate() - days);
  return date;
}

export function findByAtribute(list = [], property, valueDefaul) {
  let value = valueDefaul || '-';
  const index = list.findIndex((value) => {
    return value.Name === property;
  });

  if (index !== -1) {
    value = list[index].Value;
  }
  return value;
}

export function removeElementFromArray(index, arr) {
  const position = arr.findIndex((a, i) => i === index);
  if (position > -1) {
    arr.splice(position, 1);
  }
  return arr;
}

export function getTypeDocument(document) {
  let typeDocument = 'DNI';

  switch (document) {
    case 'l':
      typeDocument = 'DNI';
      break;
    case 'r':
      typeDocument = 'RUC';
      break;
    case 'e':
      typeDocument = 'Carnet de Extranjería';
      break;
    case 'g':
      typeDocument = 'Pasaporte';
      break;
    default:
      typeDocument = 'DNI';
  }
  return typeDocument;
}

export const downloadUrl = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function sortByAttribute(list, value, atribute) {
  const escapedValue = escapeRegExp(value);
  const regex = new RegExp(escapedValue, 'i');
  return list.filter((item) => item?.[atribute] && regex.test(item?.[atribute]));
}

export const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export function formatDate(date, format = 'DD/MM/YYYY') {
  let dateFormat = '';
  if (date) {
    dateFormat = moment(date).format(format);
  }
  return dateFormat;
}

export function formatDateTime(date, format = DATE_TIME_FORMAT) {
  let dateFormat = '';
  if (date) {
    dateFormat = moment.unix(date).format(format);
  }
  return dateFormat;
}

export function swapMonthsDay(value) {
  value = value.split('/');
  return new Date(value[2], parseInt(value[1], 10) - 1, value[0]);
}

export function currencyInRange(initDate, endDate) {
  if (!initDate || !endDate) return false;
  const initArray = initDate?.split('-');
  const endArray = endDate?.split('-');

  const init = new Date(initArray[0], parseInt(initArray[1], 10) - 1, initArray[2]);
  const end = new Date(endArray[0], parseInt(endArray[1], 10) - 1, endArray[2], 23, 59, 59);

  const currencyDate = new Date();

  return currencyDate >= init && currencyDate <= end;
}

export function getPdp(value) {
  let valueReturn = 'Si';

  if (value === 'false') {
    valueReturn = 'No';
  }
  return valueReturn;
}
export function getorigin(value) {
  const VALUE_RECEIVE = value;
  const VALUE_ORIGIN = {
    NPBACKOF: 'Backoffice',
    netprivapubmaq: 'NetPrivada ',
    appmovilpubmaq: 'App Net Privada',
  };
  const VALUE_DEFAULT = null;
  const VALUE_RETURN = VALUE_ORIGIN[VALUE_RECEIVE] || VALUE_DEFAULT;
  return VALUE_RETURN;
}

export function isEmpty(value) {
  return value === '';
}

export function inspectParagraph(paragraphs) {
  if (paragraphs.length > 0) {
    return paragraphs.map((p) => {
      if (p.hasOwnProperty('paragraph') && p.paragraph.includes(LINE_BREAK_CODE)) {
        p.paragraph = p.paragraph.replace(/\n/g, '<br>');
      }
      return p;
    });
  }
  return paragraphs;
}
export function inspectParagraphInverse(paragraphs) {
  if (paragraphs.length > 0) {
    return paragraphs.map((p) => {
      if (p.hasOwnProperty('paragraph') && p.paragraph.includes('<br>')) {
        p.paragraph = p.paragraph.replaceAll('<br>', LINE_BREAK_CODE);
      }
      return p;
    });
  }
  return paragraphs;
}

export function sortArrayByDates(arr, field = 'date') {
  const arrayForSort = [...arr];
  arrayForSort.sort((a, b) => {
    return new Date(b[field]) - new Date(a[field]);
  });
  return arrayForSort;
}

export function isEmptyArray(arr) {
  return arr.length === 0;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function sortAction(arrFunction, field, list) {
  const { action } = arrFunction.find((obj) => obj[field]) || {};
  if (action) {
    return action();
  }
  return list;
}

export function sortList(listRequest) {
  listRequest.sort((a, b) => {
    return moment(b.date).diff(moment(a.date));
  });
  return listRequest;
}

export function capitalize(text) {
  const wordsArray = text.toLowerCase().split(' ');
  const capsArray = wordsArray
    .filter((word) => word.trim() !== '')
    .map((word) => {
      return word[0].toUpperCase() + word.slice(1);
    });
  return capsArray.join(' ');
}

export function getMessageField(field) {
  let message = '';
  switch (field) {
    case 'principalTitle':
      message = 'Título Principal';
      break;
    case 's3UrlImage':
      message = 'Imagen del Boletín';
      break;
    case 'urlLink':
      message = 'Archivo';
      break;
    default:
      message = 'Título Principal';
  }
  return message;
}

export function isRequired(atributes = [], data = {}) {
  if (atributes) {
    const message = [];
    atributes.forEach((a) => {
      if (data.hasOwnProperty(a) && data[a] === '') {
        const field = getMessageField(a);
        message.push(`${field} es obligatorio ` + '    ');
      } else if (a === 'urlLink') {
        const { buttons } = data;
        if (isEmpty(buttons[0].link)) {
          message.push(`    El enlace es obligatorio   `);
        }
      }
    });
    return message;
  }
  return null;
}

export function getStructToGetUrlFile(categoryId, fileName) {
  const date = new Date();
  return btoa(
    `${categoryId}#${date.getFullYear()}-${date.getMonth() + 1}#${date.getTime()}${fileName}`
  );
}

export function transformDatetoUnix(date) {
  if (date) {
    return moment(date).format('X');
  }
  return null;
}

export function transformUnixToDate(unixTime) {
  if (unixTime) {
    const date = new Date(unixTime);
    return date.toLocaleDateString('es-PE');
  }
  return null;
}

export function chunkArray(myArray, chunkSize) {
  let index;
  const newArray = [];
  const arrayLength = myArray.length;
  const tempArray = [];
  for (const item of myArray) {
    newArray.push(item);
  }
  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = newArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }
  return tempArray;
}

export function transformUnixToMount(unixTime) {
  if (unixTime) {
    const date = new Date(unixTime);
    return MONTH[date.getMonth()];
  }
  return null;
}
export function transformStringDateToMount(stringDate) {
  const date = moment(stringDate);
  return MONTH[date.month() || 0];
}

export function removeDuplicates(originalArray, prop) {
  const newArray = [];
  const lookupObject = {};

  for (const i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (const i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

export function removeElement(value, array) {
  return array.filter((ar) => {
    return ar.branchId !== value;
  });
}

export function generateArrayIterable(count) {
  return Array.from({ length: count }, (_, i) => i + 1);
}

export function setProducts(products) {
  let prod = products.map((p) => {
    return { ...p, ...services[p.menuId] };
  });

  prod = prod.filter((ar) => {
    return ar.icon !== undefined;
  });
  prod = removeDuplicates(prod, 'menuId');
  prod = removeElement(72, prod);
  return prod;
}

export function capitalizeSentence(s) {
  return s.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase();
  });
}

export function getMes(numberMonth) {
  let month = '';
  switch (numberMonth) {
    case '01':
      month = 'Enero';
      break;
    case '02':
      month = 'Febrero';
      break;
    case '03':
      month = 'Marzo';
      break;
    case '04':
      month = 'Abril';
      break;
    case '05':
      month = 'Mayo';
      break;
    case '06':
      month = 'Junio';
      break;
    case '07':
      month = 'Julio';
      break;
    case '08':
      month = 'Agosto';
      break;
    case '09':
      month = 'Setiembre';
      break;
    case '10':
      month = 'Octubre';
      break;
    case '11':
      month = 'Noviembre';
      break;
    case '12':
      month = 'Diciembre';
      break;
    default:
      month = '-';
  }
  return month;
}

export function getCurrentYear() {
  const date = new Date();
  return date.getFullYear();
}

export function getLastThreeYear() {
  const listYear = [];
  const year = getCurrentYear();
  listYear.push(
    { value: year, label: year },
    { value: year - 1, label: year - 1 },
    { value: year - 2, label: year - 2 }
  );
  return listYear;
}

export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function fixStructBase64(base64Data) {
  let base64Clear = '';
  const base64Split = base64Data.split(',');

  if (base64Split.length > 0) {
    base64Clear = base64Split[1];
    const lastCharacter = '"';
    if (base64Split[1].substring(base64Split[1].length - 1) === lastCharacter) {
      base64Clear = base64Split[1].substring(0, base64Split[1].length - 1);
    }
  }
  return base64Clear;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getPaginatedList(list, pageSelected, MAX_PER_PAGE) {
  const startIndex = (pageSelected - 1) * MAX_PER_PAGE;
  const endIndex = pageSelected * MAX_PER_PAGE;
  return list.slice(startIndex, endIndex);
}

export function formatRoleToSelect(roles) {
  const rolesList = roles.map((r) => {
    return { label: r.label, value: r.sort };
  });
  rolesList.unshift({ value: 0, label: 'Seleccionar todos' });
  return rolesList;
}

export function formatUserToSelect(users) {
  const userList = users.map((r) => {
    return { label: r.name, value: r.sort };
  });
  userList.unshift({ value: 0, label: 'Seleccionar todos' });
  return userList;
}

export function includesArray(a, b) {
  if (a.length >= b.length) {
    return a.some((r) => b.indexOf(r) >= 0);
  }
  return b.some((r) => a.indexOf(r) >= 0);
}

export function removeArrayFromArray(arr1, arr2) {
  return arr1.filter((el) => !arr2.includes(el));
}

export function maxLengthCheck(evt) {
  const theEvent = evt || window.event;
  let key;
  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}
export function numericInputValidation(evt) {
  const theEvent = evt || window.event;
  let key;
  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function getValueDocument(typeDocument) {
  let value;
  switch (typeDocument.toUpperCase()) {
    case 'L':
      value = 'DNI';
      break;
    case 'R':
      value = 'RUC';
      break;
    case 'E':
      value = 'CE';
      break;
    case 'G':
      value = 'PPT';
      break;
    default:
      value = 'DNI';
      break;
  }
  return value;
}

export const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;

export const Timer = {
  waitingApi: 2000,
};

export function validateOnlyNumbers(evt) {
  const theEvent = evt || window.event;
  let key;
  // Handle paste
  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]/;
  return regex.test(key);
}

export function formatCodeInput(evt) {
  const theEvent = evt || window.event;
  const validate = validateOnlyNumbers(evt);
  if (validate) {
    const index = theEvent.target.value.lastIndexOf('-');
    const test = theEvent.target.value.substr(index + 1);
    if (test.length === 1 && index !== 5) {
      theEvent.target.value += '-';
    }
    return theEvent;
  }
  theEvent.preventDefault();
  return false;
}

export function removeDashInput(value) {
  const letter = /-/g;
  return value.replace(letter, '');
}

export function listSegmentById(products) {
  if (products) {
    return products.map((px) => OPTION_SEGMENT_BY_ID[parseInt(px)]);
  }
  return '';
}

export function channelSurvey(idChannel) {
  let typeChannel;
  switch (idChannel) {
    case '15':
      typeChannel = 'Web';
      break;
    case '25':
      typeChannel = 'Mobile';
      break;
    case '35':
      typeChannel = 'App';
      break;
    default:
      typeChannel = 'Web';
  }
  return typeChannel;
}
