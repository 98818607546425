import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const ContainerCardContract = React.forwardRef(({ className, children }, ref) => {
  return (
    <div ref={ref} className={`container-cardContract ${className}`}>
      {children}
    </div>
  );
});

ContainerCardContract.displayName = 'ContainerCardContract';

ContainerCardContract.defaultProps = {
  className: '',
};

ContainerCardContract.propTypes = {
  className: PropTypes.string,
};

export default ContainerCardContract;
