import React from 'react';
import './index.scss';
import { CSVLink } from 'react-csv';
import PSDynamicTable from '../../PSDynamicTable';
import { Stats, ContainerCard, HeaderSection, StatisticalChart, Paginator } from '../../molecules';
import PSIcon from '../../PSIcon';
import { HEADERS_FIELDS_CSV_SURVEYS, NAME_FILE_SURVEY } from '../../../shared/constants/Constants';

export default function SurveySatisfactionDashboard({
  headerTable,
  dataTable,
  colors,
  colors2,
  loading,
  xaxisCategory1,
  xaxisCategory2,
  series1,
  series2,
  stats,
  onHandleBack,
  onReload,
  maxValueGraphic,
  countPaginate,
  actualPage,
  selectActualPage,
  listUserSurvey,
}) {
  return (
    <div className="container-survey-satisfactionDashboard">
      <HeaderSection title="Encuesta Satisfacción" hasBack onHandleBack={onHandleBack} />
      <section className="container-date d-flex">
        {/* <div className="date d-flex"> */}
        {/* <PSIcon type="smallCalendar" className="date-icon"/> */}
        {/* <span>2 Noviembre, 2020 - 2 Diciembre, 2020</span> */}
        {/* </div> */}
        <div className="actions d-flex">
          <a className="d-flex">
            <span>
              <CSVLink
                onClick={() =>
                  setTimeout(() => {
                    alert('Su archivo se ha descargado con éxito');
                  }, 1000)
                }
                headers={HEADERS_FIELDS_CSV_SURVEYS}
                data={listUserSurvey}
                filename={NAME_FILE_SURVEY}
              >
                {' '}
                Descargar{' '}
              </CSVLink>
            </span>

            {/* <PSIcon type="arrowDown" className="actions-icon"/> */}
          </a>
        </div>
      </section>
      <section className="container-stats">
        <Stats stats={stats} />
      </section>
      <section className="container-graphics">
        <StatisticalChart
          className="higth-chart"
          title="General"
          series={series1}
          type="area"
          colors={colors}
          xaxisCategories={xaxisCategory1}
          yarisMax={15}
          tickAmount={3}
        />
        <StatisticalChart
          className="bar-chart"
          title="Encuestados"
          series={series2}
          type="bar"
          colors={colors2}
          xaxisCategories={xaxisCategory2}
          yarisMax={maxValueGraphic + 100}
          tickAmount={4}
        />
      </section>
      <ContainerCard>
        <div className="header-card">
          <div className="title">
            <span>Usuarios</span>
          </div>
          <div className="refresh">
            <PSIcon type="refresh" className="icon-refresh" onClick={onReload} />
            <span>Refrescar</span>
          </div>
        </div>
        <PSDynamicTable
          headers={headerTable}
          items={dataTable}
          hasActions={false}
          hasDates={false}
          loading={loading}
          labelPrincipalHeader="Usuario"
        />
        {countPaginate > 1 && (
          <Paginator
            pageCount={countPaginate}
            actualPage={actualPage}
            onPageChange={(page) => selectActualPage(page.selected + 1)}
          />
        )}

        {/* <div className="show"> */}
        {/* <a>Show more</a> */}
        {/* </div> */}
      </ContainerCard>
    </div>
  );
}
