export const RequestType = {
  one: 'type-one',
  two: 'type-two',
  tree: 'type-tree',
};

export const RequestDefault = {
  state: 'Pendiente',
  code: 'CON-100',
  name: 'Nombre del contrato',
  type: 'Tipo de contrato',
  date: '16 Mar 2021',
  numberOfAttachments: 3,
  numberOfMessages: 12,
};
