const validBenefeciary = (list = [], beneficary) => {
    let isBenefeciary = list.find((l) => l.receiver.document.number === beneficary.nroDoc);
    return isBenefeciary || false
};

const filterTickets = (list = [], beneficary) => {
    const DOCUMENT_SIZE = 8
    if (!beneficary || !beneficary.nroDoc) {
        return [];
    }
    const filteredTickets = list.filter((l) => {
        //TODO: Eliminated blanck space
        let docNumber = String(l.receiver.document.number).trim(); 

        //TODO:If document had fewee than 8 digits , there would be a 0 before of document 
        let formattedDocNumber = docNumber.length < DOCUMENT_SIZE
            ? docNumber.padStart(DOCUMENT_SIZE, '0')
            : docNumber;
        return formattedDocNumber === beneficary.nroDoc;
    });

    return filteredTickets;
}

export { validBenefeciary, filterTickets }