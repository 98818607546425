import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export const BoletaRowClass = {
  orange: 'row-orange',
  orange2: 'row-orange-2',
  blue: 'row-blue',
  white: 'row-white',
  gray1: 'row-grey',
  gray2: 'row-grey-2',
  hidden: 'row-hidden',
}

const FileBoletaRow = React.forwardRef(({ type, className, isBold, name }, ref) => (
  <div className={`app-boleta-row ${type} ${className}`} ref={ref}>
    <label htmlFor="" style={{ fontWeight: isBold ? '700' : '500' }}>
      {name}{' '}
    </label>
  </div>
));

FileBoletaRow.displayName = 'FileBoletaRow';

FileBoletaRow.defaultProps = {
  isBold: false,
  className: '',
};

FileBoletaRow.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isBold: PropTypes.boolean,
  className: PropTypes.string,
};

export default FileBoletaRow;
