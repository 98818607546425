import React from 'react';
import ContractContainer from '../../../components/containers/ContractPage';
import { ContractorCollaborator } from '../../../components/templates';

export default function CommunityPageContractor() {
  const listFiles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <ContractContainer
      title="Comunidad"
      description="Conoce a todos los colaboradores que tienes a tu  cargo"
      hasFilters
    >
      <ContractorCollaborator date="22 Mar 2021" files={listFiles} />
    </ContractContainer>
  );
}
