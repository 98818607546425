import React from 'react';
import PropTypes from 'prop-types';

import { pathServer } from '../../../shared/constants/Constants';
import PSIcon from '../../PSIcon';

import './index.scss';

const ItemHistoryPromotions = React.forwardRef(
  ({ className, index, data, onselect, ...props }, ref) => {
    return (
      <tr
        key={index}
        ref={ref}
        className={`container-itemHistoryPromotions ${className}`}
        onClick={onselect}
      >
        <td className="container-content">
          <div className="d-flex">
            <img src={`${pathServer.PATH_IMG}calendar/${index + 1}.svg`} className="img-month" />
            <div>
              <span className="margin-text">{data ? data.name : ''}</span>
            </div>
          </div>
        </td>
        {data?.products && (
          <td>
            <span>
              {data && data.productsName ? data.productsName.toString().replace(/,/g, ', ') : ''}
            </span>
          </td>
        )}
        <td>
          <span>{data && data.status ? data.status : ''}</span>
        </td>
        <td className="container-content">
          <div className="d-flex">
            <PSIcon type="smallCalendar" />
            <span className="margin-text">{data ? data.date : ''}</span>
          </div>
        </td>
        <td className="container-content">
          <div className="d-flex">
            <PSIcon type="time" />
            <span className="margin-text">
              {data.startDate && data.endDate
                ? `${data.startDate.replaceAll('-', '.')} - ${data.endDate.replaceAll('-', '.')}`
                : '-'}
            </span>
          </div>
        </td>
      </tr>
    );
  }
);

ItemHistoryPromotions.displayName = 'ItemHistoryPromotions';

ItemHistoryPromotions.defaultProps = {
  className: '',
  index: 1,
};

ItemHistoryPromotions.propTypes = {
  className: PropTypes.string,
  index: PropTypes.integer,
};

export default ItemHistoryPromotions;
