import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { CollaboratorsInfo } from './Constants';
import { CollaboratorCard } from '../../molecules';

const MyCollaborators = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <div className={`my-collaborators_container ${className}`} ref={ref}>
      <div className="collaborators_container">
        {CollaboratorsInfo.map((ci, index) => {
          return <CollaboratorCard key={index} collaborator={ci} {...props} />;
        })}
      </div>
    </div>
  );
});

MyCollaborators.displayName = 'MyCollaborators';

MyCollaborators.defaultProps = {
  className: '',
};

MyCollaborators.propTypes = {
  className: PropTypes.string,
};

export default MyCollaborators;
