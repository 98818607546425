import React from 'react';
import RoutesPage from './app/RoutesPage';
import './assets/styles/base.scss';
import { ToastContainer } from 'react-toastify';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'https://rentas-api.core.prd.protectasecuritycloud.pe/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-api-key': 'da2-j7e5axjtwzewhdbfnpf4q6wmme',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

function App() {
  return (
    <div>
      <ApolloProvider client={client}>
        <RoutesPage />
      </ApolloProvider>
      <ToastContainer limit={1} />
    </div>
  );
}

export default App;
