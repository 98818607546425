import React from 'react';
import ContractContainer from '../../../components/containers/ContractPage';
import { ContractRequest } from '../../../components/templates';

export default function RequestPageContractor() {
  const listResponsible = [
    {
      name: 'Daniel Montanchez',
      job: 'Nombre del cargo',
    },
    {
      name: 'Daniel Montanchez',
      job: 'Nombre del cargo',
    },
  ];
  const files = [1, 2];
  const comments = [1, 2, 3, 4];
  return (
    <ContractContainer title="Solicitudes">
      <ContractRequest listResponsible={listResponsible} files={files} comments={comments} />
    </ContractContainer>
  );
}
