import React, { useEffect, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSDynamicTable from '../../PSDynamicTable';
import { HeaderSection } from '../../molecules';
import { Button, SelectCustom } from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';
import PSIcon from '../../PSIcon';
import { PermissionList } from '../../organisms';
import PSModal from '../../PSModal';
import { CreateUserBackoffice } from '../index';
import { formatRoleToSelect, formatUserToSelect } from '../../../../scripts/Util';
import PSPaginator from '../../PSPaginator';

const MAX_PER_PAGE = 8;
const defaultFilters = {
  value: null,
  attribute: null,
};
const ConfigurationRole = React.forwardRef(
  (
    {
      headerTable,
      listUser,
      listRoles,
      loadingUsers,
      className,
      userCreate,
      onHandleValuesUser,
      onSaveUser,
      onUpdatePermission,
      loadingCreateUser,
      loadingSubmit,
      setShowModalCreateUser,
      showModalCreateUser,
      onSubmitPermission,
      blockUser,
      unblockUser,
      deleteUser,
      textConfirmModal,
    },
    ref
  ) => {
    const [viewUsed, setViewUsed] = useState(null);
    const [filters, setFilters] = useState(defaultFilters);
    const [pageSelected, setPageSelected] = useState(1);
    const [filterUsers, setFilterUsers] = useState([]);
    const listPaginate = filterUsers.slice(
      (pageSelected - 1) * MAX_PER_PAGE,
      pageSelected * MAX_PER_PAGE
    );

    const onFilterData = (evt, attribute) => {
      const { value } = evt;
      if (value) {
        setFilters({ value, attribute });
        let filter;
        if (attribute === 'roles') {
          filter = listUser.filter((l) => l.roles.includes(value));
        } else {
          filter = listUser.filter((l) => l.sort === value);
        }
        setFilterUsers(filter);
        setPageSelected(1);
      } else {
        setFilters(defaultFilters);
        setFilterUsers([...listUser]);
      }
    };

    useEffect(() => {
      if (viewUsed === 2) setFilters(defaultFilters);
    }, [viewUsed]);
    useEffect(() => {
      if (listUser) {
        let filter = listUser;
        const { value, attribute } = filters;
        if (attribute) {
          filter =
            attribute === 'roles'
              ? filter.filter((l) => l.roles.includes(value))
              : filter.filter((l) => l.sort === value);
        }
        setFilterUsers(filter);
      }
    }, [listUser]);

    const renderView = () => {
      switch (viewUsed) {
        case 1:
          return (
            <div className="container-role">
              <HeaderSection
                title="Roles y Permisos"
                buttonHeader={
                  <Button
                    type={ButtonColor.blue}
                    onClick={() => setViewUsed(2)}
                    loading={loadingSubmit}
                  >
                    Configurar Permisos
                  </Button>
                }
              />
              <div className="configuration-filters">
                <PSIcon
                  className="my-auto"
                  type="userCreate"
                  onClick={() => setShowModalCreateUser(true)}
                />
                <SelectCustom
                  isSearchable
                  className="select-user"
                  placeholder="Ver todos"
                  options={formatUserToSelect(listUser)}
                  onChange={(e) => onFilterData(e, 'email')}
                />
                <SelectCustom
                  className="select-role"
                  placeholder="Todos Roles"
                  options={formatRoleToSelect(listRoles)}
                  onChange={(e) => onFilterData(e, 'roles')}
                />
                {/* <Button icon="userDelete" className="user-create" type={ButtonColor.redOutline}> */}
                {/*  Ver Inactivos */}
                {/* </Button> */}
              </div>
              <div className="configuration-body">
                <PSDynamicTable
                  hasActions
                  showMainHeader={false}
                  headers={headerTable}
                  items={listPaginate}
                  loading={loadingUsers}
                  blockUser={blockUser}
                  unblockUser={unblockUser}
                  deleteUser={deleteUser}
                  textConfirmModal={textConfirmModal}
                />
              </div>
              {!loadingUsers && (
                <div className="configuration-footer">
                  <PSPaginator
                    maxPerPage={MAX_PER_PAGE}
                    countList={filterUsers.length}
                    onSelectPage={(pageSel) => setPageSelected(pageSel)}
                    pageSelected={pageSelected}
                  />
                </div>
              )}
            </div>
          );
        case 2:
          return (
            <PermissionList
              onHandleCheck={(data) => onUpdatePermission(data)}
              changeView={(roleSelected) => {
                onSubmitPermission(roleSelected);
                if (!loadingSubmit) {
                  setViewUsed(1);
                }
              }}
              listRoles={listRoles}
              loadingSubmit={loadingSubmit}
            />
          );
      }
      return true;
    };

    useEffect(() => {
      setViewUsed(1);
    }, []);

    return (
      <div className={`${className} configuration-role `} ref={ref}>
        {renderView()}

        <PSModal
          open={showModalCreateUser}
          onClose={() => setShowModalCreateUser(false)}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModalPreview',
          }}
        >
          <CreateUserBackoffice
            userCreate={userCreate}
            onHandleInput={onHandleValuesUser}
            onSaveUser={onSaveUser}
            loadingCreateUser={loadingCreateUser}
            listRoles={listRoles}
          />
        </PSModal>
      </div>
    );
  }
);

ConfigurationRole.displayName = 'PSConfigurationRole';

ConfigurationRole.defaultProps = {
  headerTable: [],
  listUser: [],
  listRoles: [],
  loadingUsers: false,
  className: '',
  onUpdatePermission: () => {},
  loadingCreateUser: false,
  loadingSubmit: false,
  onSubmitPermission: () => {},
};

ConfigurationRole.propTypes = {
  headerTable: PropTypes.array,
  listUser: PropTypes.array,
  listRoles: PropTypes.array,
  loadingUsers: PropTypes.bool,
  className: PropTypes.string,
  onUpdatePermission: PropTypes.func,
  loadingCreateUser: PropTypes.bool,
  onSubmitPermission: PropTypes.func,
  loadingSubmit: PropTypes.bool,
};

export default ConfigurationRole;
