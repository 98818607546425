import { getMes } from '../../../scripts/Util';

export class Ticket {
  constructor(props) {
    this.id = props.id;
    this.beneficiaryName = `${props.beneficary.firstName} ${
      props.beneficary.middleName ? props.beneficary.middleName : ''
    } ${props.beneficary.lastName} ${props.beneficary.secondLastName}`;
    this.description = getMes(props.period.substring(4, 6));
    this.period = props.period;
    this.month = props.period.substring(4, 6);
    this.year = props.period.substring(0, 4);
    this.beneficiary = props.beneficary;
    this.receiver = props.receiver;
    this.policy = props.policy;
    this.type = props.type;
  }
}
