import React, {useEffect} from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Button } from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';
import {pathServer} from "../../../shared/constants/Constants";

const ContainerPreviewNewsLetter = React.forwardRef(({ className, boletin, imageUrlBoletin, onClickDownload,buttonMagazine }, ref) => {
  const openExternalLink = () => {
    const link = document.createElement('a');
      link.href = boletin.buttons[0].link;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
  return (
      <div ref={ref} className={`container-preview-newsletter ${className}`}>
        <div className="preview-newsletter-header">
          <div className="header-title-preview">Vista preliminar</div>
          <div className="header-title-newsletter">{boletin.principalTitle ? boletin.principalTitle: 'Boletin' }</div>
          {/*<p>Recuerda, debes guardar tus cambios para tener las funcionalidades de la previsualizacion correcta</p>*/}

        </div>
        <div className="preview-newsletter-body">
          <div className="body-section-img">
            <div className="container-img">
              <img src={imageUrlBoletin || `${pathServer.PATH_IMG}defaultNewsletter.jpeg`} />
            </div>
          </div>

          <div className="body-section-info">
            <div>
              <div className="section-info-title">
                <div>{boletin.principalTitle}</div>
                <div>{boletin.shortDescription}</div>
              </div>
              {
                boletin.content.map(item=>{
                return(
                  <div className="section-info-description">
                    <div>{item.title}</div>
                    <div>{item.description}</div>
                  </div>
                )
                })
              }
              {
                boletin.paragraphs.map(item=>{
                return(
                  <div className="section-info-paragraphs">
                    <div>{item.paragraph}</div>
                  </div>
              )
              })
              }
              {boletin.buttons.map((item) => {
                return(
                  <div className="section-info-button">
                    <Button
                      onClick={
                        item.link.includes('/newsletter') ? onClickDownload : openExternalLink
                      }
                      type={ButtonColor.orange}
                    >
                      {buttonMagazine.title || item.title}
                    </Button>
                  </div>
                )
                })
              }
            </div>
          </div>
        </div>
      </div>
  );
});

ContainerPreviewNewsLetter.displayName = 'ContainerPreviewNewsLetter';

ContainerPreviewNewsLetter.defaultProps = {
  className: '',
  boletin: {},
  imageUrlBoletin:'',
  onClickDownload : () => {},
  buttonMagazine: {title: null, link : null},
};

ContainerPreviewNewsLetter.propTypes = {
  className: PropTypes.string,
  boletin: PropTypes.object,
  imageUrlBoletin: PropTypes.string,
  onClickDownload: PropTypes.func,
  buttonMagazine: PropTypes.obj,
};

export default ContainerPreviewNewsLetter;
