import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import {ContainerCard} from "../index";
import PSIcon from "../../PSIcon";

const TYPE_WEB='web';

const PreviewImgPromotions = React.forwardRef(({ className,type,img, ...props}, ref) =>{

  return (
    <div ref={ref} className={`container-previewImgPromotions ${className}`}>
      <div className="preview-title"><span>{`Vista Previa ${type ===TYPE_WEB? 'Web': 'Mobile'}`}</span></div>
      <ContainerCard>
        <img src={img}/>
      </ContainerCard>
      <div className="preview-info">

        <div className="preview-info-icon">
          <PSIcon type={type === TYPE_WEB ? "monitor": "mobile"}/>
          <span> {type === TYPE_WEB ? "14.5 mb": "12.5 mb"}</span>
        </div>
        <div className="preview-info-text"><span>Tamaño</span></div>
      </div>
    </div>
  )

});

PreviewImgPromotions.displayName = 'PreviewImgPromotions';

PreviewImgPromotions.defaultProps = {
  className: '',
  type: TYPE_WEB,
};

PreviewImgPromotions.propTypes = {
  onChange: PropTypes.func,
  type:  PropTypes.string,

};

export default PreviewImgPromotions;
