import React, { useState, useEffect } from 'react';
import './index.scss';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import { MAX_SIZE_UPLOAD_IMG } from '../../../../scripts/Constants';
import { showErrorToast } from '../../../shared/constants/Constants';
import { isEmpty } from '../../../../scripts/Util';

export default function DropzoneContract({
  selectFile,
  title,
  imgEdit,
  isEdit,
  messageWarning,
  messageDimensiones,
  messageDimensiones2,
}) {
  const [fileLoaded, setFileLoaded] = useState(null);
  const [image, setImage] = useState(null);
  const onDrop = (e, file) => {
    if (file.length > 0) {
      const { size } = file[0].file;
      if (size < MAX_SIZE_UPLOAD_IMG) {
        const imageUrl = URL.createObjectURL(file[0].file);
        setFileLoaded(file[0].file);
        selectFile(file[0].file);
        setImage(imageUrl);
      } else {
        showErrorToast('El peso máximo del archivo es 248kb');
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(imgEdit) && isEdit && imgEdit) {
      setImage(imgEdit);
    }
  }, [imgEdit]);

  return (
    <div className="dropzone-contract">
      {title && <div>{title}</div>}
      <Dropzone
        maxSize="2480"
        accept=".jpeg,.jpg,.png"
        multiple={false}
        onDragEnter={(e) => {
          console.log('valores', e);
        }}
        onDrop={(e, file) => onDrop(e, file)}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="section-drop-zone">
            <div className="container-info-drop-zone">
              {image ? (
                <img src={image} alt="" width={250} />
              ) : (
                <div className="icon_container">
                  <PSIcon type="blueUpload" />
                </div>
              )}
              <div>
                {' '}
                {fileLoaded ? fileLoaded.name : <p>Suelta archivos para adjuntar, o cargar</p>}
              </div>
            </div>
            <div {...getRootProps()} className="container-button-drop">
              <input {...getInputProps()} />
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

DropzoneContract.defaultProps = {
  selectFile: () => {},
  imgEdit: null,
  isEdit: false,
  messageWarning: 'El peso máximo del archivo es 248kb',
  messageDimensiones: 'Las dimensiones en imagenes verticales son: 280x380',
  messageDimensiones2: 'Las dimensiones en imagenes horizontales son: 400x300',
};

DropzoneContract.propTypes = {
  selectFile: PropTypes.func,
  imgEdit: PropTypes.string,
  isEdit: PropTypes.bool,
  messageWarning: PropTypes.string,
  messageDimensiones: PropTypes.string,
  messageDimensiones2: PropTypes.string,
};
