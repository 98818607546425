import React, { useState } from 'react';
import './index.scss';
import PropTypes from "prop-types";
import {ContainerCard} from "../index";
import {pathServer} from "../../../shared/constants/Constants";

const CardCalendar = React.forwardRef(({className, src, month, onClick ,id}, ref)=>{
  return(
    <ContainerCard ref={ref} className={`container-card-calendar ${className}`} onClick={onClick}>
      <div className="container-card-calendar__elements">
        <img src={`${pathServer.PATH_IMG}calendar/${id}.svg`} alt={id}/>
        <div><span>{month}</span></div>
      </div>
    </ContainerCard>
  );
});

CardCalendar.displayName = 'CardCalendar';

CardCalendar.defaultProps = {
  className: '',
  src: 'jan.png',
  month: 'Enero',
  onClick: ()=>{},
  id: 1,
};

CardCalendar.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  month: PropTypes.string,
  onClick:  PropTypes.function,
  id: PropTypes.integer,
};

export default CardCalendar;
