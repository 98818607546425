import { doPostWhitParameters, doPost } from './Api';
import { STORAGE_TOKEN_APP } from '../constants/Constants';

export default class RecoverPasswordService {
  static async getTokenApp() {
    const data = {
      authentication: {
        applicationId: '21200001',
        authenticationType: '00',
        userData: {
          user: 'HELPDESKNETMAQ',
          password: `${process.env.REACT_APP_PASS_APP}`,
        },
      },
    };

    const response = await doPost(
      `${process.env.REACT_APP_BASE_URL_NET}/security/globalAuthentication/v0/authentication`,
      data
    );

    return response.data;
  }

  static async validateStatus(email) {
    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const data = {
      identityDocument: {
        documentNumber: email,
        documentType: {
          id: 'EMAIL',
        },
      },
    };
    const response = await doPost(
      `${process.env.REACT_APP_BASE_URL_NET}/helpdesk/v0/employees/digital-activation-status`,
      data,
      token
    );
    return response.data;
  }

  static async sendValidationOtp(email) {
    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const data = {
      identityDocument: {
        documentNumber: email,
        documentType: {
          id: 'EMAIL',
        },
      },
    };

    const response = await doPostWhitParameters(
      `${process.env.REACT_APP_BASE_URL_NET}/helpdesk/v0/employees/digital-activation/init`,
      data,
      token,
      { 'validation-type': 10 }
    );
    return response.data;
  }

  static async validateOTPChallenge(payload) {
    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const data = {
      identityDocument: {
        documentNumber: payload.documentId,
        documentType: {
          id: 'EMAIL',
        },
      },
    };

    const response = await doPostWhitParameters(
      `${process.env.REACT_APP_BASE_URL_NET}/helpdesk/v0/employees/digital-activation/check`,
      data,
      token,
      {
        'validation-type': 10,
        'validation-data': payload.code,
      }
    );
    const dataResponse = response.data;

    if (dataResponse.status.id === 204 && dataResponse.status.name === 'LIMIT EXCEEDED') {
      return 'TRIES_EXCEEDED';
    }

    if (dataResponse.status.id === 204 && dataResponse.status.name === 'UNSUCCESS VALIDATION') {
      return 'INVALID_CODE';
    }

    if (dataResponse.status.id === 500) {
      return 'SERVER_ERROR';
    }

    return 'VALID_CODE';
  }

  static async finalizeProcess(payload, code) {
    const token = localStorage.getItem(STORAGE_TOKEN_APP);
    const data = {
      'customer-id': payload.documentId,
      password: payload.password,
    };

    const response = await doPostWhitParameters(
      `${process.env.REACT_APP_BASE_URL_NET}/helpdesk/v0/employees/recover-password`,
      data,
      token,
      {
        'validation-type': 10,
        'validation-data': code,
      }
    );

    return response.data;
  }
}
