import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from "prop-types";
import PSIcon from "../../PSIcon";

export default function NewsLetterTable({ className, data, showPreviewItem, deleteItem, selectFavorite, selectItem, itemSelected }) {

  return(
    <div className={`newsletter-table ${className}`}>
      <table className="container-table">
        <thead>
        <tr className="table-header">
          <th className="table-header-item">Banner</th>
          <th className="table-header-item">Categoría</th>
          <th className="table-header-item">Fecha subida</th>
          {/*<th className="table-header-item">Acción</th>*/}
        </tr>
        </thead>
        <tbody>
        {
          data && data.map(item=>{
            return (
              <tr className={`table-body ${itemSelected && itemSelected.idContenido === item.idContenido  ? 'item-selected': ''}` } onClick={()=>selectItem(item)}>
                <td className="table-body-item item-flex">
                  <div>{item.contentOrder}</div>
                  <div className="container-img"><img src={item.img}/></div>
                </td>
                <td className="table-body-item">{item.principalTitle}</td>
                <td className="table-body-item"><div>{item.dateRegister}</div></td>
                {/*<td className="table-body-item"><div>Lorem ipsum</div></td>*/}
                {/*<td className="table-body-item item-action">*/}
                {/*  <div>*/}
                {/*    <PSIcon type="show" onClick={()=>showPreviewItem(item)}/>*/}
                {/*  </div>*/}
                {/*  <div>*/}
                {/*    {item.favorite?*/}
                {/*      <PSIcon type="heartFavorite" onClick={selectFavorite}/>:*/}
                {/*      <PSIcon type="heart" onClick={selectFavorite}/>}*/}
                {/*  </div>*/}
                {/*  <div>*/}
                {/*    <PSIcon type="delete" onClick={deleteItem}/>*/}
                {/*  </div>*/}
                {/*  <div>*/}
                {/*    <PSIcon type="dragDrop"/>*/}
                {/*  </div>*/}
                {/*</td>*/}
              </tr>
            )
          })
        }
        </tbody>

      </table>

    </div>
  )
}

NewsLetterTable.displayName = 'NewsLetterTable';

NewsLetterTable.defaultProps = {
  className: "",
  data: [],
  showPreviewItem: ()=>{},
  deleteItem:  ()=>{},
  selectFavorite: ()=>{},
  selectItem:()=>{},
};

NewsLetterTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  showPreviewItem : PropTypes.func,
  deleteItem : PropTypes.func,
  selectFavorite: PropTypes.func,
};
