export const CollaboratorInfo = {
  photo: 'profile/profile_photo.jpg',
  name: 'Andrea Espinoza',
  jobTitle: 'Colaborador',
  field2: 'Lorem Ipsum',
};

export const CollaboratorsInfo = [
  {
    photo: 'profile/profile_photo.jpg',
    name: 'Antionette Martinez',
    jobTitle: 'Colaborador',
    boards: 3,
    tasks: 24,
    overdueTasks: 1,
  },
  {
    photo: 'profile/profile_photo.jpg',
    name: 'Antionette Martinez',
    jobTitle: 'Colaborador',
    boards: 3,
    tasks: 24,
    overdueTasks: 1,
  },
  {
    photo: 'profile/profile_photo.jpg',
    name: 'Antionette Martinez',
    jobTitle: 'Colaborador',
    boards: 3,
    tasks: 24,
    overdueTasks: 1,
  },
  {
    photo: 'profile/profile_photo.jpg',
    name: 'Antionette Martinez',
    jobTitle: 'Colaborador',
    boards: 3,
    tasks: 24,
    overdueTasks: 1,
  },
  {
    photo: 'profile/profile_photo.jpg',
    name: 'Antionette Martinez',
    jobTitle: 'Colaborador',
    boards: 3,
    tasks: 24,
    overdueTasks: 1,
  },
  {
    photo: 'profile/profile_photo.jpg',
    name: 'Antionette Martinez',
    jobTitle: 'Colaborador',
    boards: 3,
    tasks: 24,
    overdueTasks: 1,
  },
  {
    photo: 'profile/profile_photo.jpg',
    name: 'Antionette Martinez',
    jobTitle: 'Colaborador',
    boards: 3,
    tasks: 24,
    overdueTasks: 1,
  },
  {
    photo: 'profile/profile_photo.jpg',
    name: 'Antionette Martinez',
    jobTitle: 'Colaborador',
    boards: 3,
    tasks: 24,
    overdueTasks: 1,
  },
];

export const SmallCollaboratorsInfo = [
  {
    photo: 'profile/user_photo.png',
    name: 'Andrea Sofia',
    lastName: 'Gonzales Rodriguez',
    isSelected: true,
  },
  {
    photo: 'profile/user_photo.png',
    name: 'Sandra Gabriela',
    lastName: 'Suarez Gomez',
    isSelected: true,
  },
  {
    photo: 'profile/user_photo.png',
    name: 'Maria Fernanda',
    lastName: 'Tirado Izquierdo',
    isSelected: false,
  },
  {
    photo: 'profile/user_photo.png',
    name: 'Laura Alejandra',
    lastName: 'Valladares Benavente',
    isSelected: false,
  },
  {
    photo: 'profile/user_photo.png',
    name: 'Ana Teresa',
    lastName: 'Hurtado Miller',
    isSelected: false,
  },
  {
    photo: 'profile/user_photo.png',
    name: 'Ariana Stephanie',
    lastName: 'Castro Durango',
    isSelected: false,
  },
  {
    photo: 'profile/user_photo.png',
    name: 'Beatriz Alondra',
    lastName: 'Hermenegildo Zurita',
    isSelected: false,
  },
  {
    photo: 'profile/user_photo.png',
    name: 'Paola Alejandra',
    lastName: 'Murillo Campos',
    isSelected: false,
  },
  {
    photo: 'profile/user_photo.png',
    name: 'Carla Teresa',
    lastName: 'Zavaleta Garcia',
    isSelected: false,
  },
];
