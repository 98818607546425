import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import ItemFile from '../itemFile/ItemFile';
import PSIcon from '../../PSIcon';

const ContractFile = React.forwardRef(({ className, icon, fileName, uploadDate }, ref) => {
  return (
    <div className={`contract-file_container ${className}`} ref={ref}>
      <div className="status-update_container">
        <p>Actualizado - {uploadDate}</p>
      </div>
      <div className="contain_container d-flex">
        <div className="item-file_container">
          <ItemFile icon={icon} />
        </div>
        <div className="file-title_container">
          <p>{fileName}</p>
        </div>
        <div className="download-icon_container">
          <PSIcon type="download2" />
        </div>
      </div>
    </div>
  );
});

ContractFile.displayName = 'ContractFile';

ContractFile.defaultProps = {
  className: '',
  icon: 'default',
  fileName: '',
  uploadDate: '',
};

ContractFile.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  fileName: PropTypes.string,
  uploadDate: PropTypes.string,
};

export default ContractFile;
