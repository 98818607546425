import { Routes } from '../../../../routes';

export const MENU_SIDEBAR = [
  {
    id: 1,
    title: 'Dashboard',
    icon: 'dashboardMenu',
    url: Routes.CONTRACT.HOME,
  },
  {
    id: 2,
    title: 'Solicitudes',
    icon: 'requests',
    url: Routes.CONTRACT.REQUEST,
  },
  {
    id: 3,
    title: 'Contratos',
    icon: 'requests',
    url: Routes.CONTRACT.CONTRACTS,
  },
  {
    id: 4,
    title: 'Comunidad',
    icon: 'community',
    url: Routes.CONTRACT.COMMUNITY,
  },
  {
    id: 5,
    title: 'Plantillas',
    icon: 'requests',
    url: Routes.CONTRACT.TEMPLATES,
  },
];
