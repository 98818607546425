import React from 'react';
import './index.scss';
import PSIcon from '../../PSIcon';
import { Button, Comment, CommentInput, Description } from '../../atoms';
import {
  ContainerCardContract,
  ContractFile,
  ContractReview,
  RequestComment,
} from '../../molecules';
import { AttachedFiles, DetailContract, Step } from '../../organisms';
import { attachedFiles } from '../../organisms/attachedFiles/Constants';
import { pdfItemFile } from '../../molecules/itemFile/Constants';

const TaskDetail = React.forwardRef(() => {
  const comments = [1, 2, 3, 4];
  return (
    <div className="task-detail_container">
      <div className="header d-flex">
        <div className="d-flex">
          <div className="item-container active d-flex">
            <PSIcon type="bookmark" />
            <div>
              <p>Información</p>
            </div>
          </div>
          <div className="item-container d-flex">
            <PSIcon type="calendarContract" />
            <div>
              <p>Historial</p>
            </div>
          </div>
        </div>
        <Button>Enviar tarea</Button>
      </div>
      <div className="body_container d-flex">
        <div className="left-container" style={{ width: '70%' }}>
          <div className="body-left">
            <div className="request-general-information_container title-container">
              <h3>Inf. General de Solicitud</h3>
            </div>
            <Description />
            <AttachedFiles attachedFiles={attachedFiles} />
            <div className="principal-comments_container">
              <ContainerCardContract className="mt-1">
                <div className="title-container">
                  <h3>Comentarios</h3>
                </div>
                <CommentInput />
                <div className="previousComments d-flex">
                  <span>Ver comentarios anteriores</span>
                  <PSIcon type="blueArrowUp" />
                </div>
                <div>
                  {comments.map((item) => {
                    return <RequestComment key={item} />;
                  })}
                </div>
              </ContainerCardContract>
            </div>
          </div>
        </div>
        <div className="right-container" style={{ width: '30%' }}>
          <div className="select_container">
            <select>
              <option value="value">Por hacer</option>
            </select>
          </div>
          <div className="detail-contract_container">
            <DetailContract />
          </div>
          <div className="observation_container mt-1">
            <Comment />
          </div>
        </div>
      </div>
      <Step />
    </div>
  );
});

export default TaskDetail;
