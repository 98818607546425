import React, {useState, useEffect, useMemo} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import PSIcon from '../../PSIcon';
import {ModalConfirm, ModalSuccess, NewSletterSummaryCard} from '../../organisms';
import { Routes } from '../../../../routes';
import PropTypes from 'prop-types';
import { NewSletterBoletin } from '../index';
import {inspectParagraph, inspectParagraphInverse} from "../../../../scripts/Util";

const NewSletterAllBoletin = React.forwardRef(
  (
    {
      listBoletines,
      goBack,
      categorySelected,
      boletinSelected,
      onSelectBoletin,
      onHandleBoletin,
      onDeleteBoletin,
      boletinOriginal,
    },
    ref
  ) => {
    const history = useHistory();
    const [showEdit, setShowEdit] = useState(false);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [boletinToDelete, setBoletinToDelete] = useState(null);
    const showCalendar = false;

    const showBoletin = (boletin) => {
      setShowEdit(true);
      boletin.paragraphs = inspectParagraphInverse(boletin.paragraphs);
      onSelectBoletin(boletin);
    };

    return showEdit ? <NewSletterBoletin  boletinOriginal={boletinOriginal} onHandleBoletin={onHandleBoletin}  category={categorySelected} boletin={boletinSelected} goBack={goBack} isEdit hiddenBoletin={ () => setShowEdit(false)} />  :
      <div className="newsletter-all-boletin" ref={ref}>
        <div className="newsletter-all-boletin__header d-flex">
          <PSIcon
            hasHover
            enabledClick={categorySelected}
            type="arrowBack"
            onClick={() => {
              history.push(`${Routes.NEWSLETTER_CATEGORY}/${categorySelected.idContenido}`);
            }}
          />
          <div className="ml-1 d-flex">
            <h2 className="color-blue lighter">Todos los</h2>
            <h2 className="color-blue bold ml-1">Boletines</h2>
            <h2 className="color-blue ml-1"> | Categoría {categorySelected ? categorySelected.principalTitle : 'En Vivo'}</h2>
          </div>
        </div>
        <div className="newsletter-all-boletin__body mt-1">
          { showCalendar && (
            <div className="container-calender">
              calendario.....
            </div>
          )}
          <div className="container-list">
            <div className="container-list__card">
              {listBoletines.map((boletin) => {
                return(
                  <NewSletterSummaryCard
                    onDeleteBoletin={()=>{setShowModalConfirmation(true); setBoletinToDelete(boletin)}}
                    showBoletin={(boletinSend) => showBoletin(boletinSend)}
                    className ="card"
                    boletin={boletin}
                  />
                )
              })
              }
            </div>
          </div>
      </div>
        {showModalConfirmation && (
          <ModalConfirm openModal={showModalConfirmation} onCancel={() => setShowModalConfirmation(false)} title="Eliminar Boletín"
              onAccept={ () => {
            onDeleteBoletin(boletinToDelete);
            setShowModalConfirmation(false);
          }} question="¿Estás seguro que deseas eliminar el boletín?" onClick={()=>{}}/>
        )}
    </div>

  });

NewSletterAllBoletin.displayName = 'NewSletterAllBoletin';

NewSletterAllBoletin.defaultProps = {
  listBoletines : [],
  categorySelected : null,
  goBack: () => {},
  boletinSelected: null,
  onSelectBoletin: () => {},
  onHandleBoletin: () => {},
  onDeleteBoletin: () => {},
};

NewSletterAllBoletin.propTypes = {
  listBoletines : PropTypes.array,
  categorySelected: PropTypes.object,
  goBack: PropTypes.func,
  boletinSelected: PropTypes.object,
  onSelectBoletin: PropTypes.func,
  onHandleBoletin: PropTypes.func,
  onDeleteBoletin: PropTypes.func,
};

export default NewSletterAllBoletin;
