import React, { useState, useEffect } from 'react';
import './index.scss';
import {ContainerCard} from "../../molecules";
import {ListProducts} from "../../organisms";
import {Button} from "../../atoms";

export default function Products({listProducts, productSelected, selectProduct}) {

  return (
    <div className="container-product">
      <div className="container-product__header">
        <h1 className="title">Productos</h1>
      </div>
      <div className="container-product__body">
        <div>
          <ListProducts listProducts={listProducts} selectProduct={selectProduct}/>
        </div>
        <div>

          <ContainerCard>
            {
              productSelected?
                <>
                  {/*todo foncy : hacer el componente */}
                  <div>Banner - Rentas Vitalicias</div>
                  <div>Selecciona una imagen el cual será banner de la categoría</div>
                  <div>img</div>
                  <Button>Actualizar</Button>
                </>:
                <div>
                  Seleccione uno de los productos
                </div>
            }
          </ContainerCard>
        </div>
      </div>
    </div>
  )
}
