import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './index.scss';
import {FrequentQuestion} from "../../components/templates";

const listProducts = [
  {name:'Renta Vitalicias', icon:'prdRentasVitalicias', branchId:1, menuId:1,},
  {name:'SOAT', icon:'prdSoat', branchId:2, menuId:2,},
  {name:'Desgravamen', icon:'prdDesgravamen', branchId:3, menuId:3 },
  {name:'Renta Total', icon:'prdRentaTotal',branchId:4, menuId:4 },
  {name:'Ahorro Total', icon:'prdAhorroTotal', branchId:5, menuId:5},
  {name:'SCTR', icon:'prdSCTR', branchId:6,  menuId:6},
  {name:'Accidentes Personales', icon:'prdAccidentesP', branchId:7,  menuId:7},
  {name:'Vida Ley', icon:'prdVidaLey', branchId:8,  menuId:8},
  {name:'Vida Grupo', icon:'prdVidaGrupo', branchId :9,  menuId:9},
];

export default function FrequentQuestionPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {

  }, []);

  return (
    <FrequentQuestion listProducts={listProducts}/>
  );
}
