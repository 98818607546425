import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSButton from './PSButton';
import { REASSON_1, REASSON_2, REASSON_3 } from '../shared/constants/Constants';

const StyledPSSolicitud = styled.label`
  color: #002655;
  .modal-header {
    font-size: 24px;
    h4 {
      margin: 2.2rem 0;
      font-weight: 400;
    }
  }

  .modal-reasson {
    label {
      line-break: anywhere;
      display: block;
      margin-bottom: 4px;
    }
    .large {
      margin-bottom: 13px !important;
    }

    b {
      position: absolute;
      width: 78%;
      font-weight: 400;
    }
  }

  textarea {
    border: 1px solid #d6d6d6;
    border-radius: 7px;
    padding: 15px;
    width: 100%;
    color: #002655;
  }

  .modal-footer {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    button {
      cursor: pointer;
      background: #002655;
      width: 200px;
    }
  }

  .modal-body {
    display: block;
    font-size: 12px;
    .modal-reasson {
      padding-bottom: 10px;
    }
  }
`;

const PSSolicitud = React.forwardRef(
  ({ isAccept, onSubmit, request, onChangeAttribute, ...props }, ref) => {
    const message = isAccept ? 'Aceptar' : 'Rechazar';
    return (
      <StyledPSSolicitud {...props} ref={ref}>
        <div className="modal-header">
          {!isAccept ? (
            <h4>
              Rechazar <br />
              <b>Solicitud</b>
            </h4>
          ) : (
            <h4 className="text-center">¿Estás seguro de aceptar la solicitud?</h4>
          )}
        </div>
        {!isAccept && (
          <div className="modal-body">
            <div className="modal-reasson">
              <legend>Seleccione el motivo (obligatorio)</legend>
              <br />
              <label>
                <input
                  onChange={(e) => onChangeAttribute(e, 'radio')}
                  type="radio"
                  name="reasson2"
                  value={REASSON_1}
                />{' '}
                <b>{REASSON_1}</b>
              </label>
              <label className="large">
                <input
                  onChange={(e) => onChangeAttribute(e, 'radio')}
                  type="radio"
                  name="reasson2"
                  value={REASSON_2}
                />{' '}
                <b>{REASSON_2}</b>
              </label>
              <label>
                <input
                  onChange={(e) => onChangeAttribute(e, 'radio')}
                  type="radio"
                  name="reasson2"
                  value={REASSON_3}
                />{' '}
                <b>{REASSON_3}</b>
              </label>
            </div>
            <textarea
              name=""
              id=""
              cols="30"
              rows="6"
              placeholder="Agregue información adicional aquí (opcional)"
              onChange={(e) => onChangeAttribute(e, 'area')}
            />
          </div>
        )}
        <div className="modal-footer">
          <PSButton
            onClick={() => {
              onSubmit(request);
            }}
          >
            {' '}
            {message}{' '}
          </PSButton>
        </div>
      </StyledPSSolicitud>
    );
  }
);

PSSolicitud.displayName = 'PSSolicitud';

PSSolicitud.defaultProps = {
  isAccept: false,
  onSubmit: () => {},
  request: {},
  onChangeAttribute: () => {},
};

PSSolicitud.propTypes = {
  onSubmit: PropTypes.func,
  isAccept: PropTypes.bool,
  request: PropTypes.object,
  onChangeAttribute: PropTypes.func,
};

export default PSSolicitud;
