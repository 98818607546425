import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { ContainerCard } from '../index';
import { RequestDefault, RequestType } from './Constants';
import PSIcon from '../../PSIcon';

const CardRequest = React.forwardRef(({ className, type, requestInfo }, ref) => {
  return (
    <ContainerCard ref={ref} className={`container-card-request ${className} ${type}`}>
      <div className="request-state">
        <span>{requestInfo.state}</span>
      </div>
      <div className="container-content">
        <div>
          <PSIcon type="profile" className="content-profile" />
          <div className="content-text__light">
            <span>Tipo</span>
          </div>
          <div className="content-text__light">
            <span>Fecha</span>
          </div>
        </div>
        <div>
          <div className="content-code">
            <span>{requestInfo.code}</span>
          </div>
          <div className="content-name">
            <span>{requestInfo.name}</span>
          </div>
          <div>
            <div className="content-info">
              <span>{requestInfo.type}</span>
            </div>
            <div className="content-info">
              <span>{requestInfo.date}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container-extraInfo">
        <PSIcon type="attachment" />
        <span>{requestInfo.numberOfAttachments}</span>
        <PSIcon type="message" className="icon-message" />
        <span>{requestInfo.numberOfMessages}</span>
      </div>
    </ContainerCard>
  );
});

CardRequest.displayName = 'CardRequest';

CardRequest.defaultProps = {
  className: '',
  type: RequestType.one,
  requestInfo: RequestDefault,
};

CardRequest.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  requestInfo: PropTypes.object,
};

export default CardRequest;
