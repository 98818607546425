import {
  channelSurvey,
  getTypeDocument,
  transformUnixToDate,
  transformUnixToMount,
} from '../../../scripts/Util';

export class SurveyModel {
  constructor(props) {
    this.name = props.Name;
    this.mail = props.Email ? props.Email.toLowerCase() : 'usuario net';
    this.nroDocument = props.sort ? props.sort.substring(1, props.sort.length) : '-';
    this.date = props.Date ? transformUnixToDate(props.Date) : '';
    this.unixDate = props.Date;
    this.month = props.Date ? transformUnixToMount(props.Date) : '';
    this.channel = channelSurvey(props.Channel);
    this.points = isNaN(props.Ranking) ? '-' : props.Ranking;
    this.typeDocument = props.sort ? getTypeDocument(props.sort.substring(0, 1)) : '-';
  }
}
