import React from 'react';
import PropTypes from 'prop-types';
import PSBox from './PSBox';
import PSLabel from './PSLabel';

const PSLoginSelectValidation = React.forwardRef(({ className, email }, ref) => {
  // const { userValidationInfo } = useSelector((state) => state.auth);

  const fontSize = email.length < 19 ? 30 : email.length < 34 ? 20 : 14;
  return (
    <div ref={ref}>
      <PSBox className={className}>
        <PSLabel className="text-info f-bold">Le enviaremos el código al correo registrado</PSLabel>
        <div>
          <PSLabel className={`mt-1 font-z-${fontSize}`}>{email}</PSLabel>
        </div>
      </PSBox>
    </div>
  );
});

PSLoginSelectValidation.displayName = 'PSLoginSelectValidation';

PSLoginSelectValidation.defaultProps = {
  onChange: () => {},
};

PSLoginSelectValidation.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['phoneNumber', 'email']),
};

export default PSLoginSelectValidation;
