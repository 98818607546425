import moment from 'moment-timezone';
import Auth from '../../../Amplify';
import { FIELD_NEXTTIME_LOGIN, STORAGE_TOKEN_COGNITO } from '../../shared/constants/Constants';
import { isOnlyReaderToDomain } from '../../../scripts/Util';
import RolesAndPermissions from '../../shared/services/RolesAndPermissions';

// CONSTANTES

const dataInicial = {};

const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

const ROLES_PERMISSION_SUCCESS = 'ROLES_PERMISSION_SUCCESS';
const ROLES_PERMISSION_ERROR = 'ROLES_PERMISSION_ERROR';

export const setLoginUser = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export default function loginReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, ...action.payload, errorLogin: null, successLogin: true };
    case LOGIN_ERROR:
      return { ...state, errorLogin: action.payload, successLogin: false };
    case LOGOUT_SUCCESS:
      return { ...dataInicial };
    case ROLES_PERMISSION_SUCCESS:
      return { ...state, ...action.payload };
    case ROLES_PERMISSION_ERROR:
      return { ...state, errorPermission: action.payload };
    default:
      return state;
  }
}

// ACCIONES

export const loginUser = (user, password) => async (dispatch, getState) => {
  try {
    const response = await Auth.signIn(user, password);
    const keyCognitoId = `${response.keyPrefix}.${response.username}.idToken`;
    const idToken = response.storage ? response.storage[keyCognitoId] : null;

    localStorage.setItem(STORAGE_TOKEN_COGNITO, idToken);
    localStorage.setItem(FIELD_NEXTTIME_LOGIN, new Date());
    const isReadOnly = isOnlyReaderToDomain(user);
    const administrator = await RolesAndPermissions.loadAdministrator(response.attributes.email);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { ...response.attributes, isReadOnly },
    });
    dispatch({
      type: ROLES_PERMISSION_SUCCESS,
      payload: { administrator: { ...administrator } },
    });
  } catch ({ message }) {
    dispatch({
      type: LOGIN_ERROR,
      payload: message?.includes('disabled') ? 'Usuario bloqueado' : 'Credenciales Incorrectas',
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {}
};
