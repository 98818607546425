import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { CommentDefault, AttachmentDefault } from './Constants';
import PSIcon from '../../PSIcon';

const Comments = React.forwardRef(({ className, comment, attachment }, ref) => {
  return (
    <div ref={ref} className={`container-comments ${className}`}>
      <div className="container-comments__info">
        <PSIcon type="profile" className="img-comment" />
        <span className="user-comment">{comment.user}</span>
        <span className="date-comment">{comment.date}</span>
      </div>
      <div className="container-comments__description">
        <p>{comment.description}</p>
      </div>
      {attachment && (
        <div className="container-comments__attached">
          <PSIcon type="fileAttached" className="mr-1" />
          <div>
            <div>
              <span>{attachment.name}</span>
            </div>
            <div className="lighter">
              <span>{attachment.size}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

Comments.displayName = 'Comments';

Comments.defaultProps = {
  className: '',
  comment: CommentDefault,
  attachment: AttachmentDefault,
};

Comments.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.object,
  attachment: PropTypes.object,
};

export default Comments;
