import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';

const DropDown = React.forwardRef(
  ({ selectedValue, className, onClick, optionText, options }, ref) => {
    const onHandle = (event) => {
      event.preventDefault();
      if(onClick){
        onClick(event);
      }
   };
  return(
      <div ref={ref} className={`container-select ${className}`}>
        {optionText && <span className="optional-title">{optionText}</span>}
        <PSIcon type="poligono" className="icon-select" />
        <select  name="select-year" id="selectYear" className='select-year' value={selectedValue} onChange={(e)=>onHandle(e)}>
          {options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </div>
  )
});

DropDown.displayName = 'DropDown';

DropDown.defaultProps = {
  className: '',
  onClick: () => {},
  optionText: null,
  value: '',
};

DropDown.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  optionText: PropTypes.string,
  value: PropTypes.string,
};

export default DropDown;
