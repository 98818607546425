import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Button } from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';

const ModalContainer = React.forwardRef(
  ({ mainTitle, onAccept, btnMessage, children, showFooterSection, loadingSubmit }, ref) => {
    return (
      <div className="modal-container" ref={ref}>
        <div className="modal-header">
          <h5> {mainTitle}</h5>
        </div>
        <div className="modal-body">{children}</div>
        {showFooterSection && (
          <div className="modal-footer">
            <div className="button-container">
              <Button type={ButtonColor.blue} onClick={onAccept} loading={loadingSubmit}>
                {btnMessage}
              </Button>
            </div>
          </div>
        )}
      </div>
  );
  }
);

ModalContainer.displayName = 'ModalContainer';

ModalContainer.defaultProps = {
  mainTitle: 'Crear Usuario',
  btnMessage: 'Agregar',
  onAccept: () => {},
  children: null,
  showFooterSection: true,
  loadingSubmit: false
};

ModalContainer.propTypes = {
  mainTitle: PropTypes.string,
  btnMessage: PropTypes.string,
  children: PropTypes.node,
  onAccept: PropTypes.function,
  showFooterSection: PropTypes.bool,
  loadingSubmit: PropTypes.bool,
};

export default ModalContainer;

