import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import styled from 'styled-components';

const StyledPSModalBody = styled.div`
  margin: 0 1rem !important;
  min-width: 350px;
`;

const PSModal = React.forwardRef(
  ({ children, open, center, onClose, classNames, styles, ...props }, ref) => {
    return (
      <Modal
        ref={ref}
        open={open}
        animationDuration={0}
        center={center}
        onClose={onClose}
        classNames={classNames}
        {...props}
      >
        <StyledPSModalBody className="container-children">{children}</StyledPSModalBody>
      </Modal>
    );
  }
);

PSModal.displayName = 'PSModal';

PSModal.defaultProps = {
  open: false,
  center: true,
  classNames: {
    overlay: 'customOverlay',
    modal: 'customModal',
  },
  onClose: () => {},
  styles: {},
};

PSModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  center: PropTypes.bool,
  onClose: PropTypes.func,
  classNames: PropTypes.object,
  styles: PropTypes.object,
};

export default PSModal;
