import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './index.scss';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { NewSletterBoletin } from '../../components/templates';
import { BOLETIN_OBJ } from '../../../scripts/Constants';
import { Routes } from '../../../routes';

export default function Boletin(props) {
  const { id } = useParams();
  const history = useHistory();
  const cleanData = cloneDeep({ ...BOLETIN_OBJ, dateRegister: moment().format('X') });
  const [boletinSelected, setBoletinSelected] = useState({ ...cleanData });
  const boletinOriginal = { ...cleanData };
  boletinSelected.idTipoContenido = id;

  const goBack = () => {
    history.push(`${Routes.NEWSLETTER_CATEGORY}/${id}`);
  };

  const onHandleBoletin = (value, attribute) => {
    boletinSelected[attribute] = value;
    setBoletinSelected({ ...boletinSelected });
  };

  return (
    <NewSletterBoletin boletinOriginal={boletinOriginal} onHandleBoletin={onHandleBoletin}  boletin={boletinSelected} goBack={goBack}  categoryId={id}/>
  );
}
