import React, { useState, useEffect } from 'react';
import './index.scss';
import Dropzone from 'react-dropzone';
import PSIcon from '../../PSIcon';
import PropTypes from 'prop-types';

export default function DropzoneConstancy() {
  const onDrop = () => {
    //todo: This method must be implemented.
  };

  return(
    <div className="dropzone-news-latter dropzone-constancy">
      <Dropzone maxSize={2048} accept=".jpeg,.jpg,.png" multiple={false} onDragEnter={(e)=>{console.log("valores",e)}}
                onDrop={(e,file) => onDrop(e,file)}>
        {({ getRootProps, getInputProps }) => (
          <section className="section-drop-zone">
            <div className="container-info-drop-zone" {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                <PSIcon type="upload"/>
              </div>
              <div className='title'>Subir constancia</div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

DropzoneConstancy.defaultProps = {
  selectFile: () => {},
};

DropzoneConstancy.propTypes = {
  selectFile: PropTypes.func,
};
