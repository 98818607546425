import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

import { getIcon } from '../../scripts/Util';

const StyledPsIcon = styled.div`
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  &:hover {
    cursor: ${(props) => (props.hasHover ? 'pointer' : 'initial')};
  }
`;

const PSIcon = React.forwardRef(
  ({ children, className, type, onClick, enabledClick, ...props }, ref) => (
    <StyledPsIcon {...props} ref={ref} className={`icon ${className}`}>
      <ReactSVG src={getIcon(type)} onClick={onClick}>
        {children}
      </ReactSVG>
    </StyledPsIcon>
  )
);

PSIcon.displayName = 'PSIcon';

PSIcon.defaultProps = {
  onClick: () => {},
  type: 'default',
  className: null,
  hasHover: false,
  enabledClick: true,
};

PSIcon.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.elementType,
  className: PropTypes.string,
  hasHover: PropTypes.bool,
  enabledClick: PropTypes.bool,
};

export default PSIcon;
