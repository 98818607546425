import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { SmallCollaboratorCard } from '../../molecules';
import Button, { ButtonColor } from '../../atoms/button/Button';

const ItemsListBrowser = React.forwardRef(
  (
    { className, title, subtitle, hasBrowser, hasFilesSelector, onSearchByName, list, button },
    ref
  ) => {
    return (
      <div className={`items-list-browser_container ${className}`} ref={ref}>
        <div className="ilb-subtitle-container">{subtitle && <h4>{subtitle}</h4>}</div>
        <div className="ilb-title-container">
          <h3>{title}</h3>
        </div>
        <div className="option-filters">
          {hasBrowser && (
            <input
              type="text"
              placeholder="Buscar colaborador"
              onChange={(e) => onSearchByName(e.target.value || '')}
            />
          )}
        </div>
        {hasFilesSelector && (
          <div className="files-selector">
            <select>
              <option value="value">Contratos 1</option>
            </select>
          </div>
        )}
        <div className="list_container">
          {list.map((ci) => {
            return (
              <SmallCollaboratorCard
                photo={ci.photo}
                name={ci.name}
                lastName={ci.lastName}
                isSelected={ci.isSelected}
              />
            );
          })}
        </div>
        <Button type={ButtonColor.blue}>{button}</Button>
      </div>
    );
  }
);

ItemsListBrowser.displayName = 'ItemsListBrowser';

ItemsListBrowser.defaultProps = {
  className: '',
  title: 'Mis Colaboradores',
  subtitle: '',
  hasSearchInput: false,
  hasFilesSelector: false,
  onSearchByDoc: () => {},
};

ItemsListBrowser.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hasSearchInput: PropTypes.bool,
  hasFilesSelector: PropTypes.bool,
  onSearchByDoc: PropTypes.func,
};

export default ItemsListBrowser;
