import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import { SurveySatisfactionDashboard } from '../../../components/templates';
import SurveyService from '../../../shared/services/SurveyService';
import { SurveyModel } from '../../../shared/model/SurveyModel';
import { Routes } from '../../../../routes';
import { chunkArray } from '../../../../scripts/Util';

const headerTable = [
  { elementProp: 'typeDocument', headerColumn: 'Tipo Doc' },
  { elementProp: 'nroDocument', headerColumn: 'N° Doc' },
  { elementProp: 'date', headerColumn: 'Encuestado' },
  { elementProp: 'channel', headerColumn: 'Canal' },
  { elementProp: 'points', headerColumn: 'Pts' },
];

const ComponentChannel = ({ channel }) => {
  return (
    <div className="item-channel">
      <span className={`circle circle__${channel?.toLowerCase() || 'web'}`} />
      <span className="text">{channel}</span>
    </div>
  );
};

const STATS = [
  { img: 'users', title: '0', description: 'Todos los usuarios' },
  { img: 'satisfaction', title: '0', description: 'Satisfacción ( 6- 10 ) pts' },
  { img: 'dissatisfaction', title: '0', description: 'Insactisfactorio ( 1 - 5 )' },
  { img: 'ignore', title: '0', description: 'Ignorados' },
];

const COLORS = ['#F1075E', '#eac00f', '#199f51'];
const COLORS2 = ['#3599B8', '#01B8AA', '#FD625E'];

const XAXIS_CATEGORY_1 = ['2 Nov', '4 Nov', '11 Nov', '18 Nov', '25 Nov', '2 Dic'];

const XAXIS_CATEGORY_2 = ['Julio'];
const SERIES_2 = [
  {
    name: 'Total',
    data: [120],
  },
  {
    name: 'Realizado',
    data: [80],
  },
  {
    name: 'Ignorado',
    data: [20],
  },
];

const PAGES_PAGINATE = 30;

export default function SurveySatisfactionDashboardPage(props) {
  const history = useHistory();
  const [listUserSurvey, setListUserSurvey] = useState([]);
  const [stats, setStats] = useState([...STATS]);
  const [xaxisCategory2, setXaxisCategory2] = useState([...XAXIS_CATEGORY_2]);
  const [series2, setSeries2] = useState([...SERIES_2]);
  const [dataFormatted, setDataFormatted] = useState({ bad: null, regular: null, good: null });
  const [loading, setLoading] = useState(false);
  const [maxValueGraphic, setMaxValueGraphic] = useState(300);
  const [countPaginate, setCountPaginate] = useState(2);
  const [actualPage, setActualPage] = useState(0);

  const SERIES_1 = [
    {
      name: 'Detractor (0-6)',
      data: [1, 0, 3, 1, 3, 7],
      color: '#F1075E',
    },
    {
      name: 'Pasivos (7-8)',
      data: [5, 2, 5, 2, 5, 11],
      color: '#eac00f',
    },
    {
      name: 'Promotor (9-10)',
      data: [10, 4, 7, 5, 7, 12],
      color: '#199f51',
    },
  ];
  SERIES_1[0].data = dataFormatted.bad ? dataFormatted.bad.map((d) => d.points) : [];
  SERIES_1[1].data = dataFormatted.regular ? dataFormatted.regular.map((d) => d.points) : [];
  SERIES_1[2].data = dataFormatted.good ? dataFormatted.good.map((d) => d.points) : [];

  const loadSurveyInfo = async () => {
    try {
      setLoading(true);
      const response = await SurveyService.getSurveyInfo();
      if (response.statusCode === 200) {
        let survey = response.data.Items.map((item) => {
          return new SurveyModel(item);
        });
        survey = survey.sort((firstItem, secondItem) => {
          return secondItem.unixDate - firstItem.unixDate;
        });

        let surveySort = cloneDeep(survey);
        surveySort = surveySort.sort((firstItem, secondItem) => {
          return firstItem.unixDate - secondItem.unixDate;
        });
        const numberUsers = survey.length;
        setInitialpagination(survey.length);
        const userSatisfaction = survey.filter((item) => {
          return item.points && item.points > 5;
        }).length;
        const userDissatisfaction = survey.filter((item) => {
          return item.points && item.points <= 5;
        }).length;
        const userIgnored = survey.filter((item) => {
          return !item.points;
        }).length;

        const xaxiMonth = [];
        const totalPoint = [];
        const totalPointDone = [];
        const totalPintRejected = [];
        let maxValue = maxValueGraphic;
        forEach(groupBy(survey, 'month'), (value, key) => {
          xaxiMonth.push(key);
          const totalPointByMount = value.length * 10;
          let valueInitial = 0;
          value.filter((item) => !!item.points).forEach((item) => (valueInitial += item.points));
          const totalPointDoneByMount = valueInitial;
          const totalPointRejectedByMount = value.filter((item) => !item.points).length * 10;

          if (maxValue < totalPointByMount) {
            maxValue = totalPointByMount;
          }
          totalPoint.push(totalPointByMount);
          totalPointDone.push(totalPointDoneByMount);
          totalPintRejected.push(totalPointRejectedByMount);
        });

        stats[0].title = numberUsers;
        stats[1].title = userSatisfaction;
        stats[2].title = userDissatisfaction;
        stats[3].title = userIgnored;
        series2[0].data = totalPoint;
        series2[1].data = totalPointDone;
        series2[2].data = totalPintRejected;
        const surveyRender = survey.map((item) => {
          return {
            ...item,
            channelSV: item.channel,
            channel: <ComponentChannel channel={item.channel} />,
          };
        });
        setDataFormatted({
          ...dataFormatted,
          bad: filterData(surveySort, 1),
          regular: filterData(surveySort, 2),
          good: filterData(surveySort, 3),
        });
        setListUserSurvey([...surveyRender]);
        setStats([...stats]);
        setSeries2([...series2]);
        setMaxValueGraphic(maxValue);

        setXaxisCategory2([...xaxiMonth]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const listPaginate = (list) => {
    return list.slice((actualPage - 1) * PAGES_PAGINATE, actualPage * PAGES_PAGINATE);
  };

  const setInitialpagination = (totalPages) => {
    const nroPages = Math.ceil(totalPages / PAGES_PAGINATE);
    setCountPaginate(nroPages);
  };

  const filterData = (list, type = 1) => {
    const groupChunk = chunkArray(list, 5) || list;
    if (groupChunk) {
      return groupChunk.map((g) => {
        let acum = 0;
        let iterate = 0;
        for (let i = 0; i < g.length; i++) {
          switch (type) {
            case 1:
              if (g[i].points > 0 && g[i].points <= 6) {
                acum += g[i].points;
                iterate += 1;
              }
              break;
            case 2:
              if (g[i].points > 6 && g[i].points <= 8) {
                acum += g[i].points;
                iterate += 1;
              }
              break;
            case 3:
              if (g[i].points > 8 && g[i].points <= 10) {
                acum += g[i].points;
                iterate += 1;
              }
              break;
          }
        }
        const points = iterate > 0 ? Math.ceil(acum / iterate) : 0;
        const firstDate = g[0].date || '';
        return { firstDate, points, info: { ...g } };
      });
    }
    return list;
  };

  useEffect(() => {
    loadSurveyInfo();
  }, []);
  return (
    <SurveySatisfactionDashboard
      headerTable={headerTable}
      dataTable={listPaginate(listUserSurvey)}
      listUserSurvey={listUserSurvey}
      colors={COLORS}
      colors2={COLORS2}
      series1={SERIES_1}
      series2={series2}
      maxValueGraphic={maxValueGraphic}
      countPaginate={countPaginate}
      actualPage={actualPage}
      selectActualPage={(page) => setActualPage(page)}
      xaxisCategory1={
        dataFormatted.bad ? dataFormatted.bad.map((d) => d.firstDate) : XAXIS_CATEGORY_1
      }
      xaxisCategory2={xaxisCategory2}
      stats={stats}
      onHandleBack={() => history.push(`${Routes.SURVEY_SATISFACTION}`)}
      onReload={loadSurveyInfo}
      loading={loading}
    />
  );
}
