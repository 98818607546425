import Axios from 'axios';
import * as axios from 'axios/index';
import { STORAGE_TOKEN_COGNITO } from '../../../scripts/Constants';
import { Routes } from '../../../routes';

export const Api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const ApiBusiness = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BUSINESS,
});

export function getServerUrl() {
  if (window.baseHref) {
    return window.baseHref;
  }
  return '';
}

export const signAuth = async () => {
  await localStorage.clear();
  window.location.replace(Routes.LOGIN);
};

export function doGetImagesorFiles(url) {
  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}

export function doGet(url, token, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token || idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}

export function doGetWithParams(url, params = {}, headerParams = null) {
  const token = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `${token}`,
  };

  if (headerParams) {
    headers = { ...headers, ...headerParams };
  }

  return axios({
    url,
    method: 'get',
    headers,
    data: params,
  }).catch((error) => {});
}

export function doGetWithEntity(url, entity, token, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'get',
    headers: {
      entity,
      'Content-Type': 'application/json',
      Authorization: `${token || idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}

export function doPostWithEntity(url, entity, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'post',
    headers: {
      entity,
      'Content-Type': 'application/json',
      Authorization: `${idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {});
}

export function doPutWithEntity(url, entity, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'put',
    headers: {
      entity,
      'Content-Type': 'application/json',
      Authorization: `${idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}

export function doPatch(url, entity, payload = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'patch',
    headers: {
      entity: entity || 'USUARIO',
      'Content-Type': 'application/json',
      Authorization: idTokenCognito,
    },
    data: payload,
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}

export function doGetUnauthorized(url, params = {}) {
  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  }).catch((error) => {});
}

export function doPost(url, params = {}, token = null) {
  const idTokenCognito = token || localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}

export function doPostWhitParameters(url, params = {}, token = null, headersParams) {
  const idTokenCognito = token || localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `${idTokenCognito}`,
  };

  if (headers) {
    headers = { ...headers, ...headersParams };
  }

  return axios({
    url,
    method: 'post',
    headers,
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}

export function doPut(url, params = {}) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';
  return axios({
    url,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${idTokenCognito}`,
    },
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}

export function doDelete(url, params = {}, headersParams) {
  const idTokenCognito = localStorage.getItem(STORAGE_TOKEN_COGNITO) || '';

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `${idTokenCognito}`,
  };

  if (headers) {
    headers = { ...headers, ...headersParams };
  }
  
  return axios({
    url,
    method: 'delete',
    headers,
    data: params,
  }).catch((error) => {
    if (error.response === undefined) {
      // signAuth();
    }
  });
}
