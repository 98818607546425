import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import PSModal from '../../PSModal';
import { ItemsListBrowser } from '../../organisms';
import { SmallCollaboratorsInfo } from '../../organisms/myCollaborators/Constants';

const Collaborators = React.forwardRef(
  ({ className, collaborators, hasAddButton, hasRemoveButton }, ref) => {
    const [viewModal, setViewModal] = useState(false);
    const [filter, setFilter] = useState('');
    const onGetCollaborators = () => {
      return SmallCollaboratorsInfo.filter((item) => {
        return filter ? item.name.search(new RegExp(filter, 'i')) !== -1 : item;
      });
    };
    return (
      <div className={`collaborators_container ${className}`} ref={ref}>
        <div className="collaborators-header_container d-flex">
          <h3>{hasAddButton ? 'Seleccionar colaboradores' : 'Colaboradores'}</h3>
          {hasAddButton ? (
            <PSIcon
              type="orangeAdd"
              onClick={() => {
                setViewModal(true);
              }}
            />
          ) : (
            <div />
          )}
        </div>
        <div className="collaborators-list_container">
          {collaborators.map((item, index) => (
            <div key={index} className="collaborator_container d-flex">
              <div className="info_container d-flex">
                <PSIcon className="profile-icon" type={item.icon} />
                <p>{item.name}</p>
              </div>
              <PSIcon
                type="remove"
                className="remove-icon"
                onClick={() => {
                  setViewModal(true);
                }}
              />
            </div>
          ))}
        </div>
        <PSModal
          onClose={() => {
            setViewModal(false);
          }}
          open={viewModal}
          center
          classNames="customModal"
        >
          <ItemsListBrowser
            hasBrowser
            button="Agregar"
            title="Colaboradores"
            subtitle="Seleccionar"
            onSearchByName={(value) => {
              setFilter(value);
            }}
            list={onGetCollaborators()}
            className="ilb-psmodal_container"
          />
        </PSModal>
      </div>
    );
  }
);

Collaborators.displayName = 'Collaborators';

Collaborators.defaultProps = {
  className: '',
  collaborators: [],
  hasAddButton: true,
};

Collaborators.propTypes = {
  className: PropTypes.string,
  collaborators: PropTypes.array,
  hasAddButton: PropTypes.bool,
};

export default Collaborators;
