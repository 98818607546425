import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import {ContainerCard} from "../index";
import {listRequestsDefault} from "./Constants";

const AccordionContract = React.forwardRef(({ className, listRequests }, ref) => {

  const [collapse, setCollapse] = useState(-1);

  const onCollapse = (index) =>{
    if(index === collapse){
      setCollapse(-1);
    }else{
      setCollapse(index);
    }
  };


  return (
    <ContainerCard ref={ref} className={`container-accordion ${className}`}>
      <div><h3>Resumen</h3></div>
      <div>
        {
          listRequests.map(( request,index ) =>{
            return(
              <div key ={request.id}>
                <div className="container-name">
                  <div className="d-flex">
                    <div className="circle"/>
                    <span>{request.name}</span>
                  </div>
                  <PSIcon type= {`${collapse!==index? "iconDownRed": "iconBackRed"}`}  onClick={()=>onCollapse(index)}/>
                </div>
                <div className={`container-options ${collapse!==index ? "d-none":""}`}>
                  {
                    request.options.map(item=>{
                      return(
                        <div className="options" key ={request.id}>
                          <PSIcon type="profile"/>
                          <div className="ml-0">
                            <div><span>{item.name}</span></div>
                            <div className="date"><span>{item.date}</span></div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              )
          })
        }
      </div>
    </ContainerCard>
  );
});

AccordionContract.displayName = 'AccordionContract';

AccordionContract.defaultProps = {
  className: '',
  listRequests:listRequestsDefault,

};

AccordionContract.propTypes = {
  className: PropTypes.string,

};

export default AccordionContract;
