import React from 'react';
import './index.scss';
import ContractContainer from '../../containers/ContractPage';
import Button from '../../atoms/button/Button';
import { pathServer } from '../../../shared/constants/Constants';
import CardRequest from '../../molecules/CardRequest/CardRequest';
import { RequestType } from '../../molecules/CardRequest/Constants';
import { CommentInput, Comments, ContractDetail } from '../../molecules';
import { DetailContract } from '../../organisms';

export default function contractorCollaboratorDetail({ ...props }) {
  const { collaborator } = props; // aca tienes la info del collaborador seleccionado
  return (
    <ContractContainer title="Colaborador Asignado" hasBack {...props}>
      <div className="contractor-collaborator-detail d-flex">
        <img src={`${pathServer.PATH_IMG}${collaborator.photo}`} />
        <div className="info_container">
          <div>
            <span>Colaborador</span>
          </div>
          <p> {collaborator.name}</p>
          <p>{collaborator.lastName}</p>
        </div>
      </div>
      <div className="buttons-container d-flex">
        <Button className="disabled-button">Por hacer</Button>
        <Button>En curso</Button>
        <Button className="disabled-button">Revisión</Button>
        <Button className="disabled-button">Finalizados</Button>
      </div>
      <div className="principal-container">
        <div>
          <CardRequest />
          <br />
          <CardRequest type={RequestType.two} />
          <br />
          <CardRequest type={RequestType.tree} />
        </div>
        <div>
          <ContractDetail />
          <div className="buttons-options_container">
            <Button>Comentarios</Button>
            <Button>Historial</Button>
            <Button>Actividad</Button>
          </div>
          <CommentInput profilePhoto={collaborator.photo} />
          <Comments attachment={null} />
          <Comments />
          <br />
        </div>
        <div>
          <div className="select_container">
            <select>
              <option value="value">Tareas por hacer</option>
            </select>
          </div>
          <DetailContract />
        </div>
      </div>
    </ContractContainer>
  );
}
