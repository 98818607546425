import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';

import { Routes } from '../../../../routes';
// import { Timer } from '../../../A';

import { sendVerificationCode, validateVerificationCode } from '../../../redux/ducks/userRecover';
import PSLoginContainer from '../../../components/PSLoginContainer';
import PSHeaderLogin from '../../../components/PSHeaderLogin';
import PSButton from '../../../components/PSButton';
import PSBox from '../../../components/PSBox';
import PSLabel from '../../../components/PSLabel';
import PSLoginSelectValidation from '../../../components/PSLoginSelectValidation';
import PSLogingMessageValidation from '../../../components/PSLogingMessageValidation';
import PSTimerValidation from '../../../components/PSTimerValidation';
import PSParagraph from '../../../components/PSParagraph';
import PSError from '../../../components/PSError';
import { Timer } from '../../../shared/constants/Constants';
import { Strings } from '../../../shared/constants/String';
import { removeDashInput } from '../../../../scripts/Util';

export default function SignUpValidation({ closeModal }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { email, codeSent, validCode, loading } = useSelector((state) => state.userRecoverPass);

  const [sendValidation, setSendValidation] = useState(false);
  const [confirmValidation, setConfirmValidation] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [codeValidation, setCodeValidation] = useState(null);
  const [error, setError] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [validationCode, setValidationCode] = useState();
  const [exceedAttempts, setExceedAttempts] = useState();
  const [sendCode, setSendCode] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const validationType = 'email';

  const onButtonClick = () => {
    if (confirmValidation) {
      sendCodeValidation();
    } else {
      sendToValidation();
    }
  };

  const sendToValidation = () => {
    if (!sendValidation) {
      dispatch(sendVerificationCode(email));
    } else if (!confirmValidation) {
      setLastPage(true);
      setConfirmValidation(true);
    }
  };

  const sendCodeValidation = () => {
    if (!removeDashInput(codeValidation)) {
      setError('Ingrese el codigo');
    }
    setError(null);
    dispatch(validateVerificationCode(removeDashInput(codeValidation), email));
  };

  const forwardCode = () => {
    setSendCode(true);
    setDisabledButton(false);
    dispatch(sendVerificationCode(email));

    setTimeout(() => {
      setSendCode(false);
    }, 1500);
  };

  const getFooterMenssage = () => {
    if (confirmValidation) {
      return '';
    }

    if (sendValidation) {
      return validationType === 'phoneNumber' ? Strings.INFO_PHONE : Strings.INFO_EMAIL;
    }
  };

  const onClose = () => {
    closeModal();
  };

  const isButtonDisabled = () => {
    return disabledButton ||
      validationType === undefined ||
      (lastPage && codeValidation === null) ||
      codeValidation === ''
      ? 'button-disabled'
      : '';
  };

  useEffect(() => {
    if (codeSent) {
      if (sendCode) {
        setSendCode(false);
      }

      setSendValidation(true);
    }
  }, [codeSent]);

  useEffect(() => {
    console.log('validCode', validCode);
    if (validCode === 'TRIES_EXCEEDED') {
      setExceedAttempts(true);
    }

    if (validCode === 'INVALID_CODE') {
      setInvalidCode(true);
    }

    if (validCode === 'VALID_CODE') {
      setValidationCode(true);
      setTimeout(() => {
        history.push(Routes.FORGOT_PASSWORD_CREATE_PASSWORD);
      }, Timer.waitingApi);
    }
  }, [validCode]);

  return (
    <div className="sign-up-validation">
      {!validationCode && !exceedAttempts && (
        <PSLoginContainer className="user-container" onClose={onClose}>
          <PSHeaderLogin
            className="user-header-login"
            title="Solicitud de cambio de contraseña"
            icon="user"
          />

          <div className="user-body-validation">
            {!sendValidation && (
              <PSLoginSelectValidation className="user-select-validation" email={email} />
            )}

            {sendValidation && !confirmValidation && (
              <PSLogingMessageValidation
                className="user-message-validation"
                type={validationType}
              />
            )}

            {confirmValidation && (
              <PSTimerValidation
                className="user-timer-validation"
                onChange={(e) => {
                  setInvalidCode(false);
                  setCodeValidation(e.target.value);
                }}
                value={codeValidation}
                setDisabledProp={(value) => setDisabledButton(value)}
                refresh={sendCode}
                error={confirmValidation && invalidCode}
              />
            )}

            {error && <PSError>{error}</PSError>}
            {confirmValidation && invalidCode && (
              <PSError>
                *El código de verificación que ingresaste no es correcto vuelve a solicitar el envío
              </PSError>
            )}
          </div>
          <PSBox className="container-footer-buttons">
            <PSButton
              onClick={onButtonClick}
              disabled={
                confirmValidation ? codeValidation == null || codeValidation === '' : disabledButton
              }
              className={isButtonDisabled()}
              loading={!sendCode && loading}
            >
              {!sendValidation ? 'Enviar' : 'Aceptar'}
            </PSButton>
          </PSBox>
          {confirmValidation && (
            <PSBox className="container-footer-buttons">
              <PSLabel>¿No recibiste el código de verificación?</PSLabel>
              <PSButton
                onClick={forwardCode}
                loading={sendCode && loading}
                className="btn-secondary"
              >
                Reenviar
              </PSButton>
            </PSBox>
          )}
          <PSBox className="user-footer-message">
            <PSParagraph>{getFooterMenssage()}</PSParagraph>
          </PSBox>
        </PSLoginContainer>
      )}
    </div>
  );
}
