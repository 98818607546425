import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import PSBox from './PSBox';
import PSLabel from './PSLabel';
import PSTitle from './PSTitle';

const StyledPSLogingMessageValidation = styled.div`
  .message-validation {
    font-family: 'BlissPro-ExtraBold', sans-serif;
    color: #ed6e00 !important;
    padding: 0 10px;
  }
`;
const Message = {
  email: 'Le enviamos un código de activación al correo registrado:',
  phoneNumber: 'Le enviamos un SMS con el código de verificación al celular registrado',
};

const PSLogingMessageValidation = React.forwardRef(({ className, type }, ref) => {
  const { email } = useSelector((state) => state.userRecoverPass);

  const fontSize = email.length < 19 ? 30 : email.length < 34 ? 20 : 14;

  return (
    <StyledPSLogingMessageValidation>
      <PSBox className={className} ref={ref}>
        <PSBox>
          <PSLabel className="message-validation">{Message[type]}</PSLabel>
        </PSBox>
        <PSBox>
          <PSTitle className={`font-z-${fontSize}`}>{email}</PSTitle>
        </PSBox>
      </PSBox>
    </StyledPSLogingMessageValidation>
  );
});

PSLogingMessageValidation.displayName = 'PSLogingMessageValidation';

PSLogingMessageValidation.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['email', 'phoneNumber']),
};

export default PSLogingMessageValidation;
