import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import { AttachedFile } from '..';
import { ItemsListBrowser } from '../../organisms';
import { contractModels } from './Constants';
import PSModal from '../../PSModal';

const ContractModel = React.forwardRef(({ className, hasAddButton, hasFilesSelector }, ref) => {
  const [viewModal, setViewModal] = useState(false);
  const [filter, setFilter] = useState('');
  const onGetCollaborators = () => {
    return contractModels.filter((item) => {
      return filter ? item.name.search(new RegExp(filter, 'i')) !== -1 : item;
    });
  };
  return (
    <div className={`contract-model_container ${className}`} ref={ref}>
      <div className="contract-model-header_container d-flex">
        <h3>{hasAddButton ? 'Seleccionar modelo de contrato' : 'Modelo de contrato'}</h3>
        {hasAddButton ? (
          <PSIcon
            type="orangeAdd"
            onClick={() => {
              setViewModal(true);
            }}
          />
        ) : (
          <div />
        )}
      </div>
      <div className="model_container">
        <AttachedFile hasDownload={false} />
      </div>
      <PSModal
        onClose={() => {
          setViewModal(false);
        }}
        open={viewModal}
        center
        classNames="customModal"
      >
        <ItemsListBrowser
          button="Adjuntar"
          title="Modelo de Contrato"
          subtitle="Seleccionar"
          onSearchByName={(value) => {
            setFilter(value);
          }}
          list={onGetCollaborators()}
          hasFilesSelector={hasFilesSelector}
          className="ilb-psmodal_container"
        />
      </PSModal>
    </div>
  );
});

ContractModel.displayName = 'ContractModel';

ContractModel.defaultProps = {
  className: '',
  hasAddButton: true,
};

ContractModel.propTypes = {
  className: PropTypes.string,
  hasAddButton: PropTypes.bool,
};

export default ContractModel;
