import React from 'react';
import ContractContainer from '../../../components/containers/ContractPage';
import { ContractContracts } from '../../../components/templates';

export default function TemplatePageContractor() {
  const listFiles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <ContractContainer title="Colaborador Asignado">
      <ContractContracts date="22 Mar 2021" files={listFiles} />
    </ContractContainer>
  );
}
