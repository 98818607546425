import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Button } from '../../atoms';
import PSIcon from '../../PSIcon';
import { AttachedFile } from '../../molecules';
import { attachedFiles } from './Constants';

const DetailRequest = React.forwardRef(({ className }, ref) => {
  return (
    <div className={`detail-request_container ${className}`} ref={ref}>
      <div className="header_container d-flex">
        <div className="title_container">
          <h3>Detalles de solicitud</h3>
        </div>
        <div className="revision-button_container">
          <Button>En espera</Button>
        </div>
      </div>
      <div className="general-info_container">
        <div className="title_container">
          <h3>Inf.general</h3>
        </div>
        <div className="contain_container">
          <div className="item1">
            <h4>Responsable</h4>
            <p>Will Brewer Brewer</p>
          </div>
          <div className="item2">
            <h4>Área usuaria</h4>
            <p>Will Brewer Brewer</p>
          </div>
          <div className="item3">
            <h4>Contraparte</h4>
            <p>Lorem ipsum</p>
          </div>
          <div className="item4">
            <h4>Fecha de contrato</h4>
            <div className="d-flex">
              <PSIcon type="calendarContract" />
              <p>23/10/2021</p>
            </div>
          </div>
          <div className="item5">
            <h4>Tipo de contrato</h4>
            <p>Lorem ipsum</p>
          </div>
          <div className="item6">
            <h4>Plazo de vigencia</h4>
            <p>Lorem ipsum</p>
          </div>
        </div>
      </div>
      <div className="description_container">
        <div className="title_container">
          <h3>Descripción</h3>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
          accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
          sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua. At vero eos et accusam.
        </p>
      </div>
      <div className="attached-files_container">
        <div className="title_container">
          <h3>Archivos adjuntos</h3>
        </div>
        <div className="grid-prueba">
          {attachedFiles.map((item, index) => (
            <div key={index}>
              <AttachedFile icon={item.icon} fileName={item.fileName} fileSize={item.fileSize} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

DetailRequest.displayName = 'DetailRequest';

DetailRequest.defaultProps = {
  className: '',
};

DetailRequest.propTypes = {
  className: PropTypes.string,
};

export default DetailRequest;
