import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import PSIcon from '../../PSIcon';

const ContractContainer = ({
  children,
  title,
  hasFilters,
  description,
  hasBack,
  className,
  ...props
}) => {
  const history = useHistory();
  const { customBack, placeholder } = props;
  return (
    <div className={`contract-container ${className}`}>
      <div className="container-header d-flex">
        {hasBack && (
          <PSIcon
            type="arrowBack"
            className="icon-arrow-back"
            onClick={() => {
              if (customBack) customBack();
              else history.goBack();
            }}
          />
        )}
        <h2>{title}</h2>
      </div>
      {description && <p>{description}</p>}

      {hasFilters && (
        <div className="option-filters">
          <input type="text" placeholder={placeholder} onChange={() => {}} />
        </div>
      )}
      <div className="container-body">{children}</div>
    </div>
  );
};

ContractContainer.displayName = 'BoxComment';

ContractContainer.defaultProps = {
  hasFilters: false,
  hasBack: false,
  placeholder: 'Buscar colaborador',
};

ContractContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  hasFilters: PropTypes.bool,
  hasBack: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default ContractContainer;
