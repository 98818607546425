import React from 'react';
import { AttachedFile } from '../../molecules';
import './index.scss';

const index = ({ attachedFiles }) => {
  return (
    <div className="attached-files_container">
      <div className="title_container">
        <h3>Archivos adjuntos</h3>
      </div>
      <div className="grid-prueba">
        {attachedFiles.map((item, index) => (
          <div key={index}>
            <AttachedFile icon={item.icon} fileName={item.fileName} fileSize={item.fileSize} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default index;
