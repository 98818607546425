import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Button } from '../../atoms';

const ContractDetail = React.forwardRef(({ className }, ref) => {
  return (
    <div className={`contract-detail_container ${className}`} ref={ref}>
      <div className="contract-detail-header d-flex">
        <div className="titles-container">
          <span>Tipo de contrato / CON-100</span>
          <h5>Find top 5 customer requests</h5>
        </div>
        <Button>Ver detalles</Button>
      </div>
      <div className="contract-detail-contain">
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
          accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
          sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua. At vero eos et accusam.
        </p>
      </div>
    </div>
  );
});

ContractDetail.displayName = 'ContractDetail';

ContractDetail.defaultProps = {
  className: '',
};

ContractDetail.propTypes = {
  className: PropTypes.string,
};

export default ContractDetail;
