import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { TableContractsUser } from '../../molecules';
import { contractsByUser } from '../../molecules/tableContractsUser/Constants';
import PSIcon from '../../PSIcon';
import { Button } from '../../atoms';

const ContractsByUser = React.forwardRef(({ className, contractsList }, ref) => {
  return (
    <div className={`contracts-by-user_container ${className}`} ref={ref}>
      <div className="cbu_left_container">
        <PSIcon type="user" className="avatar-icon" />
        <h3>Daniel</h3>
        <h3>Saavedra Sagástegui</h3>
        <div className="my-summary_container">
          <h4>Mi resumen</h4>
          {contractsList.map((item, index) => {
            return (
              <div key={index} className="item-list_container d-flex">
                <PSIcon type={item.icon} />
                <div>
                  <span>{item.type}</span>
                </div>
                <div>
                  <p>{item.numberOfFiles}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="cbu_right_container">
        <Button>Agregar contrato</Button>
        <div className="filter-options_container d-flex">
          <div className="year-selector d-flex">
            <div>
              <p>Año</p>
              <span>2021</span>
            </div>
            <PSIcon type="orangeArrowDown" />
          </div>
          <div className="type-selector d-flex">
            <div>
              <p>Tipo</p>
              <span>Tipo 1</span>
            </div>
            <PSIcon type="orangeArrowDown" />
          </div>
          <div className="option-filters">
            <input type="text" placeholder="Buscar solicitud" />
          </div>
        </div>
        <TableContractsUser listItem={contractsByUser} />
      </div>
    </div>
  );
});

ContractsByUser.displayName = 'ContractsByUser';

ContractsByUser.defaultProps = {
  className: '',
};

ContractsByUser.propTypes = {
  className: PropTypes.string,
};

export default ContractsByUser;
