
import {doGetWithEntity, doPostWithEntity, doPutWithEntity} from './Api';

export default class AdvertisingService {
  static async getAdvertising() {

    const response = await doGetWithEntity(`${process.env.REACT_APP_ADVERTISING}/promotions`,'PROMOTION');
    return response;
  }

  static async saveAdvertising(data) {
    const response = await doPostWithEntity(`${process.env.REACT_APP_ADVERTISING}/promotion`,'PROMOTION',data);
    return response;
  }

  static async updateAdvertising(data) {
    const response = await doPutWithEntity(`${process.env.REACT_APP_ADVERTISING}/promotion`,'PROMOTION',data);
    return response;
  }
}


