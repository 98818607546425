import React, { useState } from 'react';
import {
  ContractorCollaborator,
  ContractorCollaboratorDetail,
} from '../../../components/templates/index';

export default function CollaboratorPageContractor() {
  const [collaborator, setCollaborator] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  if (showDetail)
    return (
      <ContractorCollaboratorDetail
        customBack={() => setShowDetail(false)}
        collaborator={collaborator}
      />
    );
  return (
    <ContractorCollaborator
      onShowDetail={() => setShowDetail(true)}
      onSelectCollaborator={(c) => setCollaborator(c)}
    />
  );
}
