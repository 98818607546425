import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { PARAMS_PAGINATE } from '../../../shared/constants/Constants';

import './index.scss';

const RANGE_PER_PAGES = 4;

const Paginator = React.forwardRef(
  ({ className, rangePerPage, onPageChange, pageCount, actualPage, forcePage, ...props }, ref) => {
    return (
      <ReactPaginate
        {...PARAMS_PAGINATE}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        initialPage={actualPage}
        forcePage={forcePage ? actualPage : false}
        onPageChange={(page) => onPageChange(page)}
        pageRangeDisplayed={rangePerPage}
        containerClassName={`pagination ${className}`}
      />
    );
  }
);

Paginator.displayName = 'Paginator';

Paginator.defaultProps = {
  className: '',
  pageCount: 10,
  actualPage: 1,
  rangePerPage: RANGE_PER_PAGES,
  onPageChange: () => {},
};

Paginator.propTypes = {
  className: PropTypes.string,
  onPageChange: PropTypes.func,
  type: PropTypes.string,
  pageCount: PropTypes.number,
  actualPage: PropTypes.number,
  rangePerPage: PropTypes.number,
};

export default Paginator;
