import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { pathServer } from '../../../shared/constants/Constants';

const CommentInput = React.forwardRef(({ className, profilePhoto }, ref) => {
  return (
    <div className={`comment-input_container d-flex ${className}`} ref={ref}>
      <div className="photo_container">
        <img src={`${pathServer.PATH_IMG}${profilePhoto}`} alt="Profile Photo" />
      </div>
      <div className="input-container">
        <input type="textarea" placeholder="Add a comment..." />
      </div>
    </div>
  );
});

CommentInput.displayName = 'CommentInput';

CommentInput.defaultProps = {
  className: '',
  profilePhoto: 'default',
};

CommentInput.propTypes = {
  className: PropTypes.string,
  profilePhoto: PropTypes.string,
};

export default CommentInput;
