import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PSIcon from './PSIcon';

const StyledPSLoginContainer = styled.div`
  background: #2b0d61;
  width: 375px;
  height: 650px;
  padding: 1.5rem 1.2rem 4rem 1.5rem;
  display: block;
  align-items: initial;
`;

const StyledPsHeaderIcon = styled.div`
  padding: 0;
  width: 100%;
  border: none;
  height: initial;
  display: block;
  align-items: initial;
  text-align: right;
  cursor: pointer;
`;

const PSLoginContainer = React.forwardRef(
  ({ children, className, onClose, showCloseIcon }, ref) => (
    <StyledPSLoginContainer className={className} ref={ref}>
      {showCloseIcon && (
        <StyledPsHeaderIcon>
          <PSIcon type="closeHeader" onClick={onClose} enabledClick={true}/>
        </StyledPsHeaderIcon>
      )}
      <div className="container-children">{children}</div>
    </StyledPSLoginContainer>
  )
);

PSLoginContainer.displayName = 'PSLoginContainer';

PSLoginContainer.defaultProps = {
  className: null,
  onClose: () => {},
  showCloseIcon: true,
};

PSLoginContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

export default PSLoginContainer;
