import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FileSaver from 'file-saver';
import { base64toBlob, capitalizeFirstLetter } from '../../scripts/Util';
import PSIcon from './PSIcon';

const StyledPSPreviewFile = styled.div`
  .container-file {
    display: flex;
    cursor: pointer;
    .container-text {
      margin: 0 10px;
    }
  }
`;

const PSPreviewFile = React.forwardRef(
  ({ poliza, fileBase64, descriptionHeader, typeDocument, titleDocument, hasError }, ref) => {
    const downloadFile = () => {
      const blob = base64toBlob(fileBase64, 'application/pdf');
      const name = `${capitalizeFirstLetter(typeDocument)}-${descriptionHeader}-${
        poliza ? poliza.nroPolicy : '7000468446'
      }`;
      FileSaver.saveAs(blob, name);
      setTimeout(() => {
        alert('Su archivo se ha descargado con éxito');
      }, 1000);
    };

    return (
      <StyledPSPreviewFile ref={ref}>
        {!hasError && fileBase64 !== null && (
          <div className="header-wrapper">
            <div className="container-file" onClick={downloadFile}>
              <PSIcon type="pdf" className="icon-pdf" />
              <div className="container-text">
                <div className="name-file">{titleDocument}</div>
              </div>
              <PSIcon type="download" />
            </div>

            {/* <button onClick={downloadFile}> Esto es todo esto es todo , descarga el base 64</button> */}
            {/* <PSIcon type="download"/> */}
          </div>
        )}

        {hasError && (
          <div>
            <p>error al cargar {titleDocument}</p>
          </div>
        )}
      </StyledPSPreviewFile>
    );
  }
);

PSPreviewFile.displayName = 'PSPreviewFile';

PSPreviewFile.defaultProps = {
  descriptionHeader: '',
  titleDocument: '',
  typeDocument: '',
  poliza: null,
  fileBase64: null,
  hasError: false,
};

PSPreviewFile.propTypes = {
  descriptionHeader: PropTypes.string,
  titleDocument: PropTypes.string,
  typeDocument: PropTypes.string,
  poliza: PropTypes.object,
  fileBase64: PropTypes.string,
  hasError: PropTypes.bool,
};

export default PSPreviewFile;
