import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PSHeaderHome from '../components/PSHeaderHome';
import { ModalSuccess } from '../components/organisms';
import { logout } from '../redux/ducks/user-loginDuck';
import { STORAGE_TOKEN_COGNITO } from '../../scripts/Constants';
import { Routes } from '../../routes';
import { FIELD_NEXTTIME_LOGIN } from '../shared/constants/Constants';
import AsideBarContract from '../components/molecules/asideBarContract/AsideBarContract';

const SECONDS_RELOAD = 3600000;
const StyledPSManagerContractor = styled.div`
  display: grid;
  margin-bottom: 0 !important;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #e9edf1;
  grid-template-columns: 0.5fr 3fr;
  .column-left {
    background: #f5f5f5;
    .section-item {
      margin: 25px 0 !important;
      a {
        color: #00265599 !important;
        font-weight: bold;
      }
    }
  }
  .column-right {
    background: #fff;
    padding: 0 1rem;
    .container-children {
      margin: 1rem;
    }
  }

  @media (max-width: 576px) {
    .column-left {
      display: none;
    }
    .column-right {
      width: 100%;
    }
  }
`;

const menuSidebar = () => {
  return [
    {
      id: 1,
      nameMenu: 'ÁREA LEGAL',
      menu: [
        {
          id: 11,
          title: 'Actualizaciones',
          icon: 'construct',
          url: Routes.CONTRACT.UPDATES,
          options: [],
          show: () => true,
        },
        {
          id: 12,
          title: 'Comunidad',
          icon: 'construct',
          url: Routes.CONTRACT.COMMUNITY,
          options: [],
          show: () => true,
        },
        {
          id: 13,
          title: 'Colaboradores',
          icon: 'construct',
          url: Routes.CONTRACT.COLLABORATORS,
          options: [],
          show: () => true,
        },
        {
          id: 14,
          title: 'Contratos',
          icon: 'construct',
          url: Routes.CONTRACT.CONTRACTS,
          options: [],
          show: () => true,
        },
        {
          id: 15,
          title: 'Enviar Solicitud',
          icon: 'construct',
          url: Routes.CONTRACT.REQUEST,
          options: [],
          show: () => true,
        },
        {
          id: 16,
          title: 'Evalución de Desempeño',
          icon: 'construct',
          url: Routes.CONTRACT.SURVEY,
          options: [],
          show: () => true,
        },
        {
          id: 17,
          title: 'Validaciones',
          icon: 'construct',
          url: Routes.CONTRACT.VALIDATIONS,
          options: [],
          show: () => true,
        },
      ],
    },
  ];
};

export default function PSManagerContractor({ children }) {
  return (
    <StyledPSManagerContractor>
      <div className="column-left">
        <AsideBarContract />
      </div>
      <div className="column-right">
        <PSHeaderHome />
        <div className="container-children">{children}</div>
      </div>
    </StyledPSManagerContractor>
  );
}

PSManagerContractor.propTypes = {};

export const withContract = (Component) => (props) => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const redirectToLogin = () => {
    dispatch(logout());
    localStorage.removeItem(STORAGE_TOKEN_COGNITO);
    localStorage.removeItem(FIELD_NEXTTIME_LOGIN);
    localStorage.clear();
    return <Redirect to={Routes.LOGIN} />;
  };

  const loadModal = () => {
    const actualDate = new Date();
    const loginDate =
      localStorage.getItem(FIELD_NEXTTIME_LOGIN) || moment().startOf('day').fromNow();
    if (loginDate) {
      const duration = moment.duration(moment(actualDate).diff(loginDate)).asMinutes();
      if (duration >= 60) {
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      loadModal();
    }, SECONDS_RELOAD);

    if (!showModal) {
      loadModal();
    }
  });
  return (
    <PSManagerContractor>
      {showModal && (
        <ModalSuccess
          className="-"
          title="Tu sesión ha expirado"
          message="Vuelve a iniciar sesión"
          buttonText="Aceptar"
          openModal={showModal}
          onClick={redirectToLogin}
        />
      )}
      <Component {...props} />
    </PSManagerContractor>
  );
};
