import React from 'react';
import './index.scss';
import PSIcon from '../../PSIcon';

const index = () => {
  return (
    <div className="container-comment">
      <PSIcon type="user" />
      <input className="comment" type="text" placeholder="Escribe tu comentario " />
    </div>
  );
};

export default index;
