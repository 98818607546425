import React, {useEffect, useState} from 'react';
import './index.scss';
import { TagBox } from 'react-tag-box';
import { List } from 'immutable';
import PropTypes from "prop-types";

const sampleTags = List(['test','test2'].map((t) => ({ label: t, value: t })));

const TagSearchInput = React.forwardRef(({ className, tags,onChange }, ref) => {
  const [listTags, setListTags] = useState(sampleTags);
  const [count, setCount] = useState(0);
  const onSelect = (tag) => {
    if(count <= 4){
      let iterator = count;
      const newTag = {
        label: tag.label,
        value: tag.value || tag.label
      }
      setListTags(listTags.push(newTag));
      setCount(++iterator);
      const newList = transformKeytoValue(listTags.push(newTag));
      onChange(newList.toString(), 'newsletterKeywords');
    }
  };

  const transformKeytoValue = (list) => {
    return list.toArray().map(l => l.value);
  }

  const remove = (tag) => {
    let iterator = count;
    setListTags(listTags.filter((t) => t.value !== tag.value));
    const newList = transformKeytoValue(listTags.filter((t) => t.value !== tag.value));
    onChange(newList.toString(), 'newsletterKeywords');
    setCount(--iterator);
  };

  useEffect(() => {
    if(tags){
     const arrTags = tags.split(',');
      arrTags.length > 0 && setListTags(List(arrTags.map((t) => ({ label: t, value: t }))));
    }
  }, []);

  useEffect(() => {
    const element = document.getElementsByClassName('tag-box')[0].getElementsByTagName("input");
    if(element){
      element[0].setAttribute("maxlength", 20);
    }
  });

  return (
    <div ref={ref} className={`tag-search-input ${className}`}>
      <TagBox
        tags={sampleTags.toJS()}
        selected={listTags.toJS()}
        onSelect={onSelect}
        removeTag={remove}
      />
    </div>
  )
});

TagSearchInput.displayName = 'TagSearchInput';

TagSearchInput.defaultProps = {
  className: '',
  tags: 'test',
  onChange: () => {},
};

TagSearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tags: PropTypes.string,
};

export default TagSearchInput;
