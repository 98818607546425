import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import ItemFile from '../itemFile/ItemFile';
import PSIcon from '../../PSIcon';

const AttachedFile = React.forwardRef(
  ({ className, icon, fileName, fileSize, hasDownload }, ref) => {
    return (
      <div className={`attached-file_container ${className}`} ref={ref}>
        <div className="contain_container d-flex">
          <div className="item-file_container">
            <ItemFile icon={icon} />
          </div>
          <div className="title-size_container">
            <div className="file-title_container">
              <p>{fileName}</p>
            </div>
            <div className="file-size_container">
              <p>Descargar - {fileSize}</p>
            </div>
          </div>
          {hasDownload ? (
            <div className="download-icon_container">
              <PSIcon type="download2" />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
);

AttachedFile.displayName = 'AttachedFile';

AttachedFile.defaultProps = {
  className: '',
  icon: 'fileAttached',
  fileName: 'Anexo.doc',
  fileSize: '241 KB',
  hasDownload: true,
};

AttachedFile.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  fileName: PropTypes.string,
  fileSize: PropTypes.string,
  hasDownload: PropTypes.bool,
};

export default AttachedFile;
