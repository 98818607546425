import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from "prop-types";
import {Button, SelectCustom} from '../../atoms';
import { InputLabel } from '../../molecules';
import { ButtonColor } from '../../atoms/button/Button';
import { ModalContainer } from '../../organisms';
import {pathServer} from "../../../shared/constants/Constants";

const CreateSurveySatisfaction = React.forwardRef(({ onHandleInput, surveyCreate, onSaveSurvey}, ref) => {
  useEffect(() => {

  }, []);

  return (
    <div className="create-survey" ref={ref}>
      <div className="body__validation">
        <div className="body__row">
          <div className="survey-body-container">
            <div className="left-container">
              <div className="left-title-container">
                <h3 className="lc-title1">Crear</h3>
                <h2 className="lc-title2">Encuesta</h2>
              </div>
              <div className="buttons-container">
                <Button type={ButtonColor.orangeOutlinePurpleText}>Escala</Button>
                <Button type={ButtonColor.orangeOutlinePurpleText}>Múltiple</Button>
              </div>
              <InputLabel
                labelMessage="Nombre de encuesta"
                placeholder="Lorem ipsum sit medium"
                className="paragraph-container"
              />

              <div className="start-date-container">
                <label htmlFor="start">Fecha de Inicio:</label>
                <input type="date" min="2010-01-01" max="2050-12-31"/>
              </div>
              <div className="ending-date-container">
                <label htmlFor="start">Fecha de Fin:</label>
                <input type="date" min="2010-01-01" max="2050-12-31"/>
              </div>


            </div>
            <div className="right-container">
              <h3 className="rc-title1">Descripción de Encuesta</h3>
              <InputLabel
                labelMessage="Pregunta"
                placeholder="Lorem ipsum sit medium"
                className="paragraph-container"
              />
              <h6>Rango</h6>
              <div className="range-container">
                <SelectCustom
                  className="range-dropdown"
                  placeholder={'10'}
                  options={
                    [
                      {label: '1', value: '1'},
                      {label: '2', value: '2'},
                      {label: '3', value: '3'},
                      {label: '4', value: '4'},
                      {label: '5', value: '5'},
                      {label: '6', value: '6'},
                      {label: '7', value: '7'},
                      {label: '8', value: '8'},
                      {label: '9', value: '9'},
                      {label: '10', value: '10'}
                    ]
                  }/>
                <span>1 - 10</span>
              </div>
              <div className="select-icon-container">
                <h6>Ícono</h6>
                <SelectCustom placeholder={'⭐'} className="range-dropdown"/>
              </div>
              <div className="final-buttons-container">
                <Button type={ButtonColor.blue}>Publicar</Button>
                <Button type={ButtonColor.orangeOutline} icon="save">Guardar</Button>
              </div>
            </div>
          </div>
        </div>
        {/*{!showDetail && (
              <Button  loading={loading} type={ButtonColor.green} onClick={() => verifyUser(documentToVerify,typeDocument)}>
                {btnLabel}
              </Button>
            )}*/}
      </div>
    </div>


  );
});

CreateSurveySatisfaction.displayName = 'CreateSurveySatisfaction';

CreateSurveySatisfaction.defaultProps = {
  surveyCreate:{},
  onHandleCreateSurvey: ()=>{},
};

CreateSurveySatisfaction.propTypes = {

  surveyCreate: PropTypes.object,
  onHandleCreateSurvey: PropTypes.func,
};

export default CreateSurveySatisfaction;

