import RecoverPasswordService from '../../shared/services/RecoverPasswordService';

// CONSTANTES

const dataInicial = {};

const RECOVER_LOADING = 'RECOVER_LOADING';
const SET_USER_RECOVER_PASS = 'SET_USER_RECOVER_PASS';
const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
const CLEAR_USER = 'CLEAR_USER';
const VALIDATE_VERIFICATION_CODE = 'VALIDATE_VERIFICATION_CODE';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export default function beneficaryReducer(state = dataInicial, action) {
  switch (action.type) {
    case SET_USER_RECOVER_PASS:
      return { ...state, ...action.payload };
    case RECOVER_LOADING:
      return { ...state, ...action.payload };
    case SEND_VERIFICATION_CODE:
      return { ...state, ...action.payload };
    case VALIDATE_VERIFICATION_CODE:
      return { ...state, ...action.payload };
    case CHANGE_PASSWORD:
      return { ...state, ...action.payload };

    case CLEAR_USER:
      return { ...dataInicial };
    default:
      return state;
  }
}

export const setUserRecoverPass = (user) => ({
  type: SET_USER_RECOVER_PASS,
  payload: user,
});

export const setAuthLoading = (loading) => async (dispatch) => {
  dispatch({
    type: RECOVER_LOADING,
    payload: { loading },
  });
};

export const sendVerificationCode = (email) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      await RecoverPasswordService.sendValidationOtp(email);

      dispatch({
        type: SEND_VERIFICATION_CODE,
        payload: { codeSent: true },
      });
      dispatch(setAuthLoading(false));
    } catch (error) {
      dispatch(setAuthLoading(false));
      dispatch({
        type: SEND_VERIFICATION_CODE,
        payload: { codeSent: false },
      });
    }
  };
};

export const validateVerificationCode = (code, email) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));

      const response = await RecoverPasswordService.validateOTPChallenge({
        code,
        documentId: email,
      });

      dispatch({
        type: VALIDATE_VERIFICATION_CODE,
        payload: { validCode: response, code },
      });

      dispatch(setAuthLoading(false));
    } catch (error) {
      dispatch({
        type: VALIDATE_VERIFICATION_CODE,
        payload: { validCode: null },
      });
      dispatch(setAuthLoading(false));
    }
  };
};

export const updatePassword = (password, email, code) => {
  return async (dispatch) => {
    try {
      dispatch(setAuthLoading(true));
      await RecoverPasswordService.finalizeProcess(
        {
          documentId: email,
          password,
        },
        code
      );
      dispatch({
        type: CHANGE_PASSWORD,
        payload: { updatedPassword: true },
      });

      dispatch(setAuthLoading(false));
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD,
        payload: { updatedPassword: false },
      });
      dispatch(setAuthLoading(false));
    }
  };
};

export const clearRecoverPassword = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER,
  });
};
