export const CommentDefault = {
  user: 'Daniel Montanchez',
  date: '20 de julio de 2021 16:29',
  description:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
};

export const AttachmentDefault = {
  name: 'Specifications.doc',
  size: '120.18 KB',
};
