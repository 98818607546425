import React from 'react';
import PropTypes from 'prop-types';
import { FileBoletaRow } from '../../molecules/index';
import './index.scss';
import { capitalizeSentence, getMes } from '../../../../scripts/Util';
import { pathServer } from '../../../shared/constants/Constants';
import { BoletaRowClass } from '../../molecules/fileBoletaRow/FileBoletaRow';

const FileBoleta = React.forwardRef(({ id, className, boleta }, ref) => {
  let currency, fullNameReceptor, documentReceptor, fullNameBeneficary, fullDirectionBeneficary, documentPolicy, fullNamePolicy,fullDirectionBeneficary2 , mounthDate, dateBoleta,fullDirectionPolicy,fullDirectionPolicy2, pensionType;
  let dataDiscount = [], dataIncrease = [];
  if (boleta) {
    fullNameBeneficary = `${boleta.beneficary.firstName + ' '}${boleta.beneficary.middleName + ' '}${boleta.beneficary.lastName + ' '}${boleta.beneficary.secondLastName}`;
    fullDirectionBeneficary = boleta.address.description;
    fullDirectionBeneficary2 = `${boleta.address.district.description + ' '}  ${boleta.address.province.description  + ' ' } ${boleta.address.region.description}`;

    documentPolicy = `${boleta.policy.titular.document.type === 1 ? 'DNI-' : ''}${boleta.policy.titular.document.number}`;
    fullNamePolicy = `${boleta.policy.titular.firstName  + ' '}${boleta.policy.titular.middleName  + ' '}${boleta.policy.titular.lastName  + ' ' }${boleta.policy.titular.secondLastName}`;
    fullDirectionPolicy = boleta.policy.titular.address.description;
    fullDirectionPolicy2 = boleta.policy.titular.address.district.description + ' ' + boleta.policy.titular.address.province.description + ' ' + boleta.policy.titular.address.region.description;
    fullNameReceptor = `${boleta.receiver.firstName + '  '}${boleta.receiver.middleName + ' '}${boleta.receiver.lastName  + '  '}${boleta.receiver.secondLastName}`;
    documentReceptor = boleta.receiver.document.number;

    mounthDate = getMes(boleta.period.substring(4, 6));
    dateBoleta = mounthDate + ' ' + boleta.period.substr(0, 4);

    dataIncrease = boleta.detail.filter((b) => b.type.category === 'H');
    dataDiscount = boleta.detail.filter((b) => b.type.category === 'D');

    pensionType= boleta.policy && boleta.pensionType ? boleta.pensionType.description.toLowerCase() :'Rentas Vitalicias';
  }
  currency = boleta ? (boleta.currency.shortDescription + ' ') : 'S/.';

  return (
    <div ref={ref} id={id} className={`app-file-boleta hidden-content-force ${className}`}>
      <div className="report-header">
        <div>
          <img src={`${pathServer.PATH_IMG}logo_oficial.jpeg`} />
        </div>
        <div className="report-header-company">
          <h4>Boleta de Pago de Pensión</h4>
          <h3>{boleta ? dateBoleta : 'Mayo 2019'}</h3>
          <div className="report-header-ruc">
            <label>Compañia: Protecta S.A. Compañía de Seguros</label>
            <label>R.U.C.: 20517207331</label>
          </div>
        </div>
      </div>
      <div className="report-body">
        <div className="report-body-title">
          <div className="d-flex">
            <h3>Póliza </h3> <span> : </span> <h3> {boleta ? boleta.policy.number : '0000000322'}</h3>
          </div>
          <div className="d-flex text-capitalize">
            <h3>Tipo de pensión </h3> <span> : </span> <h3> {pensionType}</h3>
          </div>
          <div className="d-flex">
            <h3>Nro Beneficiario </h3> <span> : </span> <h3> {boleta ? boleta.order : '2'}</h3>
          </div>
        </div>
        <div className="report-body-content">
          <div className="report-description">
            <p> Nos comprometimos a pagarte una pensión durante toda la vida para que tu mayor preocupación sea disfrutarla.</p>
          </div>
          <div className="report-content-fields">

            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Ident. Afiliado" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small"  name={boleta? documentPolicy : "DNI - 07659710"}/>
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Nombre" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small"  name={boleta ? fullNamePolicy : "Carmen Rosa Meza Osorio De Osorio"}/>
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Dirección" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small"  name= {boleta? fullDirectionPolicy :  "Av. Las Begonias Mz K Lote 1 Coo. Pablo Patron"}/>
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.hidden} name="" />
              <FileBoletaRow type={BoletaRowClass.white}  className="row-small" name={boleta? fullDirectionBeneficary2 : "Lurigancho-chosica-lima-lima"} />
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Ident. Pensionista" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small"  name={boleta ?`${boleta.beneficary.document.type === 1 ? 'DNI' : '' }-${ boleta.beneficary.document.number}` : 'DNI-08363399'} />
            </div>

            <div className="row mt-2">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Nombre" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small" name= {boleta ? fullNameBeneficary : "Jose Luis Osorio Dominguez" }/>
            </div>

            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Dirección" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small"  name= {boleta? fullDirectionBeneficary :  "Av. Las Begonias Mz K Lote 1 Coo. Pablo Patron"}/>
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.hidden} name="" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small"  name={boleta? fullDirectionPolicy2 : "Lurigancho-chosica-lima-lima"} />
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Inst. de salud" />
              <FileBoletaRow type={BoletaRowClass.white} name= {boleta ? boleta.healthInsurance.description : "ESSALUD" }/>
            </div>
            <div className="row mt-2">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Ident. Receptor" />
              <FileBoletaRow type={BoletaRowClass.white}className="row-small"  name={`DNI - ${documentReceptor || '08363399'}`} />
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Receptor" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small"  name={fullNameReceptor || ''} />
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Vía de pago" />
              <FileBoletaRow type={BoletaRowClass.white} className="row-small"  name={boleta ? boleta.way.description : 'Transferencia AFP - Integra'} />
            </div>
            <div className="row">
              <FileBoletaRow type={BoletaRowClass.blue} isBold name="Vence tutor" />
              <FileBoletaRow type={BoletaRowClass.white} name="" />
            </div>

            <div className="row-2 mt-2">
              <FileBoletaRow className="title-ammount" type={BoletaRowClass.blue} isBold name="Ingresos" />
              <FileBoletaRow className="title-ammount" type={BoletaRowClass.blue} isBold name="Descuentos" />
            </div>
            <div className="row-2 mt-0">
              <div>
                {dataIncrease.map( increase => {
                  return <div className="row-2 mt-0">
                    <FileBoletaRow className="label-left" type={BoletaRowClass.gray1} isBold name={capitalizeSentence(increase.type.description)} />
                    <FileBoletaRow className="label-right" type={BoletaRowClass.gray2} name={`${currency}${increase.amount}`} />
                  </div>
                })}
                <div className="row-2">
                  <FileBoletaRow className="label-left" type={BoletaRowClass.orange} isBold name="Total ingresos" />
                  <FileBoletaRow className="label-right" type={BoletaRowClass.orange2} isBold  name={boleta ? `${currency}${  boleta.assetAmount}` :  "S/.100.72" } />
                </div>
              </div>
              <div>
                {dataDiscount.map( discount => {
                  return <div className="row-2 mt-0">
                    <FileBoletaRow className="label-left" type={BoletaRowClass.gray1} isBold name={capitalizeSentence(discount.type.description)} />
                    <FileBoletaRow className="label-right" type={BoletaRowClass.gray2} name={`${currency}${discount.amount}`} />
                  </div>
                })}
                <div className="row-2">
                  <FileBoletaRow className="label-left" type={BoletaRowClass.gray1} name="Total descuentos" />
                  <FileBoletaRow className="label-right" type={BoletaRowClass.gray2} name={boleta ? `${currency}${  boleta.discountAmount}` :  "S/.100.72" }/>
                </div>
                <div className="row-2">
                  <FileBoletaRow className="label-left" isBold type={BoletaRowClass.orange} name="Total pensión líquida" />
                  <FileBoletaRow className="label-right" isBold type={BoletaRowClass.orange2} name= {boleta ? `${currency} ${  boleta.liquidAmount}` : "S/ 2417.25"}/>
                </div>
              </div>
            </div>
          </div>
          <div className="report-footer">
            <h5 className="footer-description">
              Declaro que este importe lo he recibido a mi entera y completa satisfacción, por tanto, Protecta S.A. Compañía de Seguros, queda relevada de toda responsabilidad futura por la pensión del mes arriba mencionado.
            </h5>
            <div className="row">
              <FileBoletaRow
                isBold
                type={BoletaRowClass.blue}
                name={
                  boleta
                    ? `La suma de: ${boleta.liquidAmountDescription}`
                    : ' La suma de: DOS MIL CUATROCIENTOS DIECISIETE CON 25/100 NUEVOS SOLES'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
);

FileBoleta.displayName = 'FileBoleta';

FileBoleta.defaultProps = {
  className: '',
  boleta: null,
};

FileBoleta.propTypes = {

  className: PropTypes.string,
  boleta: PropTypes.object

};

export default FileBoleta;
