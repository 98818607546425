import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import ReactTooltip from 'react-tooltip';
// import { pathServer, STORAGE_TOKEN_COGNITO } from '../../util/Constant';


// import { cleanAuth } from '../redux/auth';
import {pathServer} from "../shared/constants/Constants";

const StyledPSHeaderPageHome = styled.div`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  .option-selected {
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    border-width: 1.5px;
  }
  .option-selected-left {
    border-left: 1px solid #e4e4e4;
    border-width: 1.5px;
  }
  .container-img {
    text-align: center;
  }
  .header-bar:hover {
    cursor: pointer;
  }
  .section-header {
    padding: 0.8rem;
    width: 100%;
    display: flex;
    img {
      height: auto;
      width: 80%;
      &:hover {
        cursor: pointer;
      }
    }
    span {
      margin: 0 auto;
    }
    label {
      letter-spacing: 0px;
      color: #7a7a7a;
      opacity: 1;
      font-family: 'BlissPro-Bold';
      font-size: 15px;
    }
    .container-nav {
      padding: 0.75rem;
      .container-dropdown {
        background: #ffff;
        top: 60%;
        left: 42px;
        min-width: 75%;
        padding: 0;
        a {
          font-family: 'BlissPro-ExtraBold', sans-serif !important;
          border: 0.5px solid #dbdbdb;
          border-top: none;
          font-weight: 600;
          padding: 15px 0;
          color: #ed6e00;
          font-size: 15px;
        }
        .item-menu:hover {
          background-color: #ed6e00 !important;
          color: #ffff !important;
        }
      }
    }
    .container-button {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      button {
        font-size: 15px;
        width: 55%;
        height: 36px;
      }
    }
  }
`;

const PSHeaderPageHome = React.forwardRef(({ hasTitle, className }, ref) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const signAuth = () => {
  //   dispatch(cleanAuth());
  //   localStorage.removeItem(STORAGE_TOKEN_COGNITO);
  //   history.push(Routes.LOGIN);
  // };
  return (
    <StyledPSHeaderPageHome ref={ref} className={className}>
      <div className="section-header columns">
        <div className="column is-one-quarter container-img">
          <img
            src={`${pathServer.PATH_IMG}logo_oficial.jpeg`}
            onClick={() => {
              // if (hasTitle) history.push(Routes.HOME);
            }}
          />
        </div>
      </div>
    </StyledPSHeaderPageHome>
  );
});

PSHeaderPageHome.defaultProps = {
  className: null,
  hasTitle: false,
};

PSHeaderPageHome.propTypes = {
  className: PropTypes.string,
  hasTitle: PropTypes.bool,
};

export default PSHeaderPageHome;
