import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import exampleDuck from './ducks/exampleDuck';
import userDuck from './ducks/user-loginDuck';
import saveUserBeneficiario from './ducks/user-beneficary';
import userRecoverPass from './ducks/userRecover';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  example: exampleDuck,
  user: userDuck,
  beneficary: saveUserBeneficiario,
  userRecoverPass,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function generateStore() {
  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
  const persistor = persistStore(store);
  return { store, persistor };
}
