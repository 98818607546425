export const listItems = [
  {
    id: 1,
    icon: 'docFile',
    fileName: 'SEO Keyword optimizado',
    fileSize: '2 MB',
    postDate: '23/06/2021',
  },
  {
    id: 2,
    icon: 'docFile',
    fileName: 'SEO Keyword optimizado',
    fileSize: '2 MB',
    postDate: '23/06/2021',
  },
  {
    id: 3,
    icon: 'docFile',
    fileName: 'SEO Keyword optimizado',
    fileSize: '2 MB',
    postDate: '23/06/2021',
  },
  {
    id: 4,
    icon: 'docFile',
    fileName: 'SEO Keyword optimizado',
    fileSize: '2 MB',
    postDate: '23/06/2021',
  },
  {
    id: 5,
    icon: 'docFile',
    fileName: 'SEO Keyword optimizado',
    fileSize: '2 MB',
    postDate: '23/06/2021',
  },
  {
    id: 6,
    icon: 'docFile',
    fileName: 'SEO Keyword optimizado',
    fileSize: '2 MB',
    postDate: '23/06/2021',
  },
];
