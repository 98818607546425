import React, { useState } from 'react';
import './index.scss';
import CardRequest from '../../molecules/CardRequest/CardRequest';
import {
  Comments,
  ContractFile,
  ContractReview,
  AccordionContract,
  ContainerCardContract,
  ContractsTree,
  ItemFile,
  RequestComment,
  CollaboratorsDeck,
} from '../../molecules/index';
import { RequestType } from '../../molecules/CardRequest/Constants';
import {
  DetailRequest,
  ItemsListBrowser,
  MyCollaborators,
  TableContract,
  BoxComment,
  TableTaskHistory,
  DetailContract,
  FormContracts,
  ContractsByUser,
  TableTask,
} from '../../organisms';

import { CollaboratorsInfo } from '../../organisms/myCollaborators/Constants';

import PSModal from '../../PSModal';
import { docItemFile, pdfItemFile } from '../../molecules/itemFile/Constants';
import CardReceivedRequest from '../../molecules/cardReceivedRequest/CardReceivedRequest';
import {
  ReceivedRequestDefault,
  ReceivedRequestWithoutButton,
} from '../../molecules/cardReceivedRequest/Constants';

import { listItems } from '../../organisms/tableTaskHistory/Constants';
import { collaborators } from '../../molecules/collaborators/Constants';
import { contractsList } from '../../organisms/contractsByUser/Constants';

const contractorHome = React.forwardRef(() => {
  const [viewModal, setViewModal] = useState(true);
  const [filter, setFilter] = useState('');
  const listTest = [
    {
      employee: 'Julio',
      absenceType: 'Descanso médico',
      period: '15/11/2021 - 16/11/2021',
      duration: '1 día',
    },
    {
      employee: 'Pepito',
      absenceType: 'Descanso médico',
      period: '15/11/2021 - 16/11/2021',
      duration: '1 día',
    },
    {
      employee: 'Sandra',
      absenceType: 'Descanso médico',
      period: '15/11/2021 - 16/11/2021',
      duration: '1 día',
    },
  ];
  const listResponsible = [
    {
      name: 'Daniel Montanchez',
      job: 'Nombre del cargo',
    },
    {
      name: 'Daniel Montanchez',
      job: 'Nombre del cargo',
    },
    {
      name: 'Daniel Montanchez',
      job: 'Nombre del cargo',
    },
  ];
  const listTasks = [
    {
      priority: 'Alto',
      taskName: 'SEO Keyword optimizado',
      numberOfAttachedFiles: 3,
      numberOfComments: 3,
      profilePhoto: 'profile/profile_photo_1.jpg',
      employee: 'Terry Riley',
      statusProgress: 'En progreso',
      date: '12/06/2021',
    },
    {
      priority: 'Medio',
      taskName: 'SEO Keyword optimizado',
      numberOfAttachedFiles: 3,
      numberOfComments: 3,
      profilePhoto: 'profile/profile_photo_2.jpg',
      employee: 'Joe Brewer',
      statusProgress: 'Terminado',
      date: '12/06/2021',
    },
    {
      priority: 'Bajo',
      taskName: 'SEO Keyword optimizado',
      numberOfAttachedFiles: 3,
      numberOfComments: 3,
      profilePhoto: 'profile/profile_photo_3.jpg',
      employee: 'Bruce Andrews',
      statusProgress: 'En progreso',
      date: '12/06/2021',
    },
    {
      priority: 'Alto',
      taskName: 'SEO Keyword optimizado',
      numberOfAttachedFiles: 3,
      numberOfComments: 3,
      profilePhoto: 'profile/profile_photo_4.jpg',
      employee: 'Justin Collins',
      statusProgress: 'En progreso',
      date: '12/06/2021',
    },
    {
      priority: 'Alto',
      taskName: 'SEO Keyword optimizado',
      numberOfAttachedFiles: 3,
      numberOfComments: 3,
      profilePhoto: 'profile/profile_photo_5.jpg',
      employee: 'Alex Sullivan',
      statusProgress: 'En progreso',
      date: '12/06/2021',
    },
    {
      priority: 'Alto',
      taskName: 'SEO Keyword optimizado',
      numberOfAttachedFiles: 3,
      numberOfComments: 3,
      profilePhoto: 'profile/profile_photo_6.jpg',
      employee: 'Tyler Barnett',
      statusProgress: 'En progreso',
      date: '12/06/2021',
    },
  ];
  const onGetCollaborators = () => {
    return CollaboratorsInfo.filter((item) => {
      return filter ? item.name.search(new RegExp(filter, 'i')) !== -1 : item;
    });
  };
  return (
    <div className="contractor-home">
      <div style={{ width: '30%' }}>
        <CardRequest />
        <br />
        <CardRequest type={RequestType.two} />
        <br />
        <CardRequest type={RequestType.tree} />
      </div>
      <div style={{ width: '40%' }}>
        <Comments />
        <br />
        <Comments />
        <br />
        <Comments attachment={null} />
      </div>
      <ItemsListBrowser
        title="Mis colaboradores"
        subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod"
        hasBrowser
        onSearchByName={(value) => {
          setFilter(value);
        }}
        list={onGetCollaborators()}
        button="Ver todos"
      />
      <div style={{ width: '30%' }}>
        <DetailContract />
      </div>
      <ContractsTree />
      <MyCollaborators />
      <div style={{ with: '100%' }}>
        <FormContracts />
      </div>
      <AccordionContract />
      <ItemFile
        icon={pdfItemFile.icon}
        fileName={pdfItemFile.fileName}
        uploadDate={pdfItemFile.uploadDate}
      />
      <ItemFile
        icon={docItemFile.icon}
        fileName={docItemFile.fileName}
        uploadDate={docItemFile.uploadDate}
      />
      <CardReceivedRequest requestInfo={ReceivedRequestDefault} />
      <CardReceivedRequest requestInfo={ReceivedRequestWithoutButton} />

      <ContractFile icon={pdfItemFile.icon} fileName="Contratov1.pdf" uploadDate="19/03/21" />

      <DetailRequest />

      <PSModal
        onClose={() => {
          setViewModal(false);
        }}
        open={viewModal}
        center
        classNames="psm_container"
      >
        <ItemsListBrowser
          hasBrowser
          button="Entendido"
          title="Asignar a:"
          onSearchByName={(value) => {
            setFilter(value);
          }}
          list={onGetCollaborators()}
          className="ilb-psmodal_container"
        />
      </PSModal>
      <TableContract listItem={listTest} />
      <TableContract listItem={listTest} />
      <BoxComment
        type="Tipo de contrato / CON-100"
        title="Find top 5 customer requests"
        comment="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
      />
      <ContractReview
        listResponsible={listResponsible}
        assigned="16/02/2021"
        delivered="16/02/2021"
      />
      <ContainerCardContract>This is a container to contract</ContainerCardContract>
      <RequestComment />
      <TableTaskHistory listItem={listItems} />
      <CollaboratorsDeck collaborators={collaborators} />
      <ContractsByUser contractsList={contractsList} />
      <TableTask listTasks={listTasks} />
    </div>
  );
});

export default contractorHome;
