import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ContainerCard } from '../../molecules';
import PSIcon from '../../PSIcon';

import './index.scss';

const ListProducts = React.forwardRef(
  ({ className, listProducts, selectProduct, productSelected }, ref) => {
    const onClickSelected = (product) => {
      selectProduct(product);
    };
    return (
      <div ref={ref} className={`container-list-products ${className}`}>
        {listProducts.map((product, index) => (
          <ContainerCard
            key={product.menuId}
            keyItem={`product-${product.menuId?.toString()}`}
            onClick={() => onClickSelected(product)}
            className={
              productSelected && productSelected.menuId === product.menuId ? 'product-selected' : ''
            }
          >
            <div className="title-item">{product.name}</div>
            <PSIcon type={product.icon} className="container-icon" />
          </ContainerCard>
        ))}
      </div>
    );
  }
);

ListProducts.displayName = 'ListProducts';

ListProducts.defaultProps = {
  className: '',
  listProducts: [],
  productSelected: {},
  selectProduct: () => {},
};

ListProducts.propTypes = {
  className: PropTypes.string,
  listProducts: PropTypes.array,
  selectProduct: PropTypes.func,
};

export default ListProducts;
