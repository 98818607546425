export const ReceivedRequestDefault = {
  state: 'Pendiente',
  buttonCard: true,
  type: 'Tipo de contrato',
  name: 'Rodrigo Corante',
  photo: 'profile/profile_photo.jpg',
  date: '16/02/2021',
};
export const ReceivedRequestWithoutButton = {
  state: 'Pendiente',
  buttonCard: false,
  type: 'Tipo de contrato',
  name: 'Rodrigo Corante',
  photo: 'profile/profile_photo.jpg',
  date: '16/02/2021',
};