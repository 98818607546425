export const collaborators = [
  {
    id: 1,
    icon: 'user',
    name: 'Luis Arteaga Arteaga Fuentes',
  },
  {
    id: 2,
    icon: 'user',
    name: 'Luis Arteaga Arteaga Fuentes',
  },
  {
    id: 3,
    icon: 'user',
    name: 'Luis Arteaga Arteaga Fuentes',
  },
  {
    id: 4,
    icon: 'user',
    name: 'Luis Arteaga Arteaga Fuentes',
  },
  {
    id: 5,
    icon: 'user',
    name: 'Luis Arteaga Arteaga Fuentes',
  },
  {
    id: 6,
    icon: 'user',
    name: 'Luis Arteaga Arteaga Fuentes',
  },
];
