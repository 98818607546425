import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PSHeaderHome from './PSHeaderHome';
import PSAsideBar from './PSAsideBar';
import { ModalSuccess } from './organisms';
import { logout } from '../redux/ducks/user-loginDuck';
import { STORAGE_TOKEN_COGNITO } from '../../scripts/Constants';
import { Routes } from '../../routes';
import { FIELD_NEXTTIME_LOGIN } from '../shared/constants/Constants';

const SECONDS_RELOAD = 3600000;
const StyledPSHome = styled.div`
  display: grid;
  margin-bottom: 0 !important;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #e9edf1;
  grid-template-columns: 0.8fr 3fr;
  .column-left {
    background: #f5f5f5;
  }
  .column-right {
    background: #fff;
    padding: 0 1rem;
    .container-children {
      margin: 1rem;
    }
  }

  @media (max-width: 576px) {
    .column-left {
      display: none;
    }
    .column-right {
      width: 100%;
    }
  }
`;

export default function PSHome({ children }) {
  return (
    <StyledPSHome>
      <div className="column-left">
        <PSAsideBar />
      </div>
      <div className="column-right">
        <PSHeaderHome />
        <div className="container-children">{children}</div>
      </div>
    </StyledPSHome>
  );
}

PSHome.propTypes = {};

export const withHome = (Component) => (props) => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const redirectToLogin = () => {
    dispatch(logout());
    localStorage.removeItem(STORAGE_TOKEN_COGNITO);
    localStorage.removeItem(FIELD_NEXTTIME_LOGIN);
    localStorage.clear();
    return <Redirect to={Routes.LOGIN} />;
  };

  const loadModal = () => {
    const actualDate = new Date();
    const loginDate =
      localStorage.getItem(FIELD_NEXTTIME_LOGIN) || moment().startOf('day').fromNow();
    if (loginDate) {
      const duration = moment.duration(moment(actualDate).diff(loginDate)).asMinutes();
      if (duration >= 60) {
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      loadModal();
    }, SECONDS_RELOAD);

    if (!showModal) {
      loadModal();
    }
  });
  return (
    <PSHome>
      {showModal && (
        <ModalSuccess
          title="Tu sesión ha expirado"
          message="Vuelve a iniciar sesión"
          buttonText="Aceptar"
          openModal={showModal}
          onClick={redirectToLogin}
          classContainer="login-expired"
        />
      )}
      <Component {...props} />
    </PSHome>
  );
};
