import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import PSIcon from '../../PSIcon';
import ProgressBar from '../progressBar/progressBar';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: 16,
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '115px',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  marginBottom: 8,
  marginRight: 8,
  width: '100%',
  height: 49,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  flexDirection: 'row',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 82,
  height: '100%',
};

const title = {
  fontFamily: 'Gotham-Book',
  fontSize: '12px',
  color: '#808495',
  paddingLeft: 0,
  margin: 'auto',
};

export default function DropzoneCustom({ onChangeFile, acceptMultiple }) {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState(0);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 3,
    accept: ['.jpg', '.jpeg', '.png'],
    multiple: acceptMultiple,
    onDrop: (acceptedFiles) => {
      onChangeFile(acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
        <ul style={title}>{file.path}</ul>
      </div>
    </div>
  ));

  if (files.length > 0) {
    document.getElementById('title2').style.display = 'none';
  }

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (files.length > 0) {
      const interval = setInterval(() => {
        setValue((oldValue) => {
          const newValue = oldValue + 10;
          if (newValue === 100) {
            clearInterval(interval);
          }
          return newValue;
        });
      }, 40);
    }
  }, [files]);

  return (
    <div className="dropzone-news-letter2 dropzone-constancy2">
      <section className="section-drop-zone2">
        <div className="container-info-drop-zone2" {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="title1">
            <span>*Tamaño óptimo a subir 150x100, peso máximo 5mb</span>
          </div>
          <div className="title2" id="title2">
            <PSIcon type="upload2" />
            <span>Suelta los archivos aquí o haz clic en ...</span>
          </div>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
      <div className="progress-bar-container">
        {files.length > 0 && <ProgressBar color="#DE6D19" width="280px" value={value} max={100} />}
      </div>
    </div>
  );
}

DropzoneCustom.defaultProps = {
  selectFile: () => {},
  acceptMultiple: false,
};

DropzoneCustom.propTypes = {
  selectFile: PropTypes.func,
  acceptMultiple: PropTypes.bool,
};
