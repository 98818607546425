import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { typesDocument, TYPE_DOCUMENT_DNI, TYPE_DOCUMENT_PPT } from '../../../../scripts/Constants';
import { getTypeDocument, maxLengthCheck } from '../../../../scripts/Util';

import { Button } from '../../atoms';
import { InputLabel } from '../../molecules';
import { ButtonColor } from '../../atoms/button/Button';
import { ModalContainer } from '../../organisms';
import DropDown from '../../molecules/dropDown/DropDown';

import './index.scss';

// usuario de prueba : 40608067
const CreateUserNatural = React.forwardRef(
  (
    {
      verifyUser,
      userCreate,
      loading,
      onHandleInput,
      onHandleCreateUser,
      headerTitle,
      btnMessage,
      btnLabel,
      isSearch,
    },
    ref
  ) => {
    const [showDetail, setShowDetail] = useState(false);
    const [documentToVerify, setDocumentToVerify] = useState(null);
    const [typeDocument, setTypeDocument] = useState(TYPE_DOCUMENT_DNI);
    useEffect(() => {
      if (userCreate) {
        setShowDetail(true);
      } else {
        setShowDetail(false);
      }
    }, [userCreate]);
    return (
      <div className="create-user-natural" ref={ref}>
        <ModalContainer
          mainTitle={headerTitle}
          btnMessage={btnMessage}
          showFooterSection={showDetail}
          onAccept={() => onHandleCreateUser(documentToVerify, typeDocument, userCreate)}
          loadingSubmit={loading}
        >
          <div className="body__validation">
            <div className="body__row">
              <DropDown
                value={typeDocument}
                className={`input--select ${showDetail ? 'input--blocked' : ' '}`}
                onClick={(event) => setTypeDocument(event.target.value)}
                optionText="Tipo de Documento"
                options={[...typesDocument]}
              />
              <InputLabel
                value={documentToVerify}
                onChange={(value) => {
                  setDocumentToVerify(value.toUpperCase());
                }}
                maxLength={typeDocument === TYPE_DOCUMENT_DNI ? 8 : 11}
                onKeyPress={(e) => (typeDocument !== TYPE_DOCUMENT_PPT ? maxLengthCheck(e) : null)}
                labelMessage="Documento"
                placeholder={`Ingrese el ${getTypeDocument(typeDocument)} `}
                className={showDetail ? 'input--blocked' : ''}
              />
            </div>
            {!showDetail && (
              <Button
                loading={loading}
                type={ButtonColor.green}
                onClick={() => verifyUser(documentToVerify, typeDocument)}
              >
                {btnLabel}
              </Button>
            )}
          </div>
          {showDetail && (
            <>
              <hr />
              <div className="body__detail">
                <InputLabel
                  onChange={(value) => onHandleInput(value, 'names')}
                  className="input--blocked"
                  labelMessage="Nombres"
                  value={userCreate.names}
                />
                <InputLabel
                  onChange={(value) => onHandleInput(value, 'lastName')}
                  className="input--blocked"
                  labelMessage="Apellidos"
                  value={`${userCreate.lastName} ${userCreate.lastName2}`}
                />
                <InputLabel
                  onChange={(value) => onHandleInput(value, 'birthdate')}
                  className="input--blocked"
                  labelMessage="Nacimiento"
                  value={userCreate.birthdate}
                />
                <InputLabel
                  onChange={(value) => onHandleInput(value, 'lastEmail')}
                  className={isSearch ? 'input--blocked' : ''}
                  placeholder="Ingrese su correo electronico"
                  labelMessage="Correo"
                  value={userCreate.lastEmail}
                />
                <InputLabel
                  onChange={(value) => onHandleInput(value, 'lastMobilePhone')}
                  className={isSearch ? 'input--blocked' : ''}
                  placeholder="Ingrese su celular"
                  labelMessage="Celular"
                  value={userCreate.lastMobilePhone}
                />
                {/* <DropDown
                  value={userCreate.indPDP === 1 ? 'SI' : 'NO'}
                  className="input--select"
                  onClick={(event) => onHandleInput(event.target.value, 'indPDP')}
                  optionText="Promociones"
                  options={[
                    { value: true, label: 'SI' },
                    { value: false, label: 'NO' },
                  ]}
                /> */}
              </div>
            </>
          )}
        </ModalContainer>
      </div>
    );
  }
);

CreateUserNatural.displayName = 'CreateUserNatural';

CreateUserNatural.defaultProps = {
  verifyUser: () => {},
  userCreate: {},
  loading: false,
  onHandleInput: () => {},
  onHandleCreateUser: () => {},
  headerTitle: 'Crear usuario',
  btnMessage: 'Crear Usuario',
  btnLabel: 'Validar',
  isSearch: false,
};

CreateUserNatural.propTypes = {
  verifyUser: PropTypes.func,
  userCreate: PropTypes.object,
  loading: PropTypes.bool,
  onHandleInput: PropTypes.func,
  onHandleCreateUser: PropTypes.func,
  headerTitle: PropTypes.string,
  btnMessage: PropTypes.string,
  btnLabel: PropTypes.string,
  isSearch: PropTypes.bool,
};

export default CreateUserNatural;
