import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { pathServer } from '../../../shared/constants/Constants';

const CollaboratorCard = React.forwardRef(({ className, collaborator, ...props }, ref) => {
  const { photo, name, jobTitle, boards, tasks, overdueTasks } = collaborator;
  const { onShowDetail, onSelectCollaborator } = props;
  return (
    <div
      className={`collaborator-card__container ${className}`}
      ref={ref}
      onClick={() => {
        if (onShowDetail) onShowDetail(true);
        if (onSelectCollaborator) onSelectCollaborator(collaborator);
      }}
    >
      <div className="d-flex collaborator_container">
        <div className="options-container">
          <a href="#">•••</a>
        </div>
        <div className="image-container">
          <img alt="" src={`${pathServer.PATH_IMG + photo}`} />
        </div>
        <div className="info-container">
          <h3 className="name">{name}</h3>
          <div className="job-title-container">
            <p className="jobTitle">{jobTitle}</p>
          </div>
          <div className="boards-tasks-container d-flex">
            <div className="boards-container">
              <p className="boards">{boards}</p>
              <p>Tableros</p>
            </div>
            <div className="tasks-container">
              <p className="tasks">{tasks}</p>
              <p>Tareas</p>
            </div>
          </div>
          <p className="overdueTasks">{overdueTasks} tarea vencida</p>
        </div>
      </div>
    </div>
  );
});

CollaboratorCard.displayName = 'CollaboratorCard';

CollaboratorCard.defaultProps = {
  className: '',
  collaborator: {
    photo: '',
    name: '',
    lastName: '',
    field1: '',
    field2: '',
  },
};

CollaboratorCard.propTypes = {
  className: PropTypes.string,
  collaborator: PropTypes.object,
};

export default CollaboratorCard;
