import React, { useEffect, useState } from 'react';
import {SurveySatisfaction} from "../../components/templates";
import { useHistory } from 'react-router-dom';
import {Routes} from "../../../routes";
import SurveyService from "../../shared/services/SurveyService";
import {SurveyModel} from "../../shared/model/SurveyModel";

const RECENT_SURVEY = [
  { title: 'Encuesta Protecta 1', date: 'Creado: 10.08.2021' },
  // { title: 'Encuesta Protecta 2', date: 'Creado: Mar 8. 2021' },
  // { title: 'Encuesta Protecta 3', date: 'Creado: Mar 8. 2021' },
];

const SURVEY_STATISTICS = {
  title: 'Usuarios',
  total: 1500,
  items: [
    { title: 'Satisfacción', icon: 'satisfaction', total: 600 },
    { title: 'Insatisfacción', icon: 'dissatisfaction', total: 600 },
    { title: 'Ignorados', icon: 'ignore', total: 300 },
  ],
};

const SURVEY = [
  { abbreviation: 'PP', title: 'Encuesta de Satisfacción', date: '10.05.2021', hour: '10.05.2021 - 11.07.2021', state: 'Publicado' },
  // { abbreviation: 'PP', title: 'Protección Premia', date: '10.05.2021', hour: '10.05.2021 - 11.07.2021', state: 'Publicado' },
  // { abbreviation: 'PP', title: 'Protección Premia', date: '10.05.2021', hour: '10.05.2021 - 11.07.2021', state: 'Pendiente' },
  // { abbreviation: 'PP', title: 'Protección Premia', date: '10.05.2021', hour: '10.05.2021 - 11.07.2021', state: 'Rechazado' },
  // { abbreviation: 'PP', title: 'Protección Premia', date: '10.05.2021', hour: '10.05.2021 - 11.07.2021', state: 'Publicado' },
  // { abbreviation: 'PP', title: 'Protección Premia', date: '10.05.2021', hour: '10.05.2021 - 11.07.2021', state: 'Publicado' },
]

export default function SurveySatisfactionPage(props) {
  const history = useHistory();
  const [showModalSurvey,setShowModalSurvey] = useState(false);
  const [loading,setLoading] = useState(true);
  const [summarySurvey, setSummarySurvey] = useState({...SURVEY_STATISTICS});

  const loadInfo = async ()=>{
    try{
      setLoading(true);
      const response = await SurveyService.getSurveyInfo();

      if(response.statusCode===200){
        let survey = response.data.Items.map(item=>{
          return new SurveyModel(item)
        });

        const userSatisfaction = survey.filter(item=>{return item.points && item.points>5}).length;
        const userDissatisfaction = survey.filter(item=>{return item.points && item.points<=5}).length;
        const userIgnored = survey.filter(item=>{return !item.points}).length;
        summarySurvey.total = survey.length;
        summarySurvey.items[0].total = userSatisfaction;
        summarySurvey.items[1].total = userDissatisfaction;
        summarySurvey.items[2].total = userIgnored;
        setSummarySurvey({...summarySurvey});
      }
      setLoading(false);

    }catch (error){
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);



  const onClickView = () => {
    history.push(`${Routes.SURVEY_SATISFACTION_DASHBOARD}`);
  };

  return (
    <SurveySatisfaction
      onClickView={onClickView}
      showModalSurvey={showModalSurvey}
      onCloseModal = {()=>setShowModalSurvey(false)}
      openModal = {()=>setShowModalSurvey(true)}
      survey={SURVEY}
      surveyStatistics={summarySurvey}
      recentSurvey={RECENT_SURVEY}
      loading={loading}
    />
  );
}
