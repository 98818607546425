import React, { useState, useEffect } from 'react';
import { ContainerCard, DropzoneNewsLetter} from "../../molecules";
import { Button} from "../../atoms";
import './index.scss';
import PSIcon from "../../PSIcon";
import PropTypes from "prop-types";
import { ButtonColor } from '../../atoms/button/Button';
import {InputText} from "../../atoms";
import {
  MAGAZINE_BOLETIN_EN_VIVO,
  MAGAZINE_BOLETIN_FINANCIERO,
  MAGAZINE_BOLETIN_QUEDATE_CASA,
  MAGAZINE_BOLETIN_VIVE_SALUD
} from '../../../../scripts/Constants';
import { removeElementFromArray } from '../../../../scripts/Util';

const DescriptionVivesalud = ({ magazine , onChangeContent, onDeleteItem}) =>
  magazine.content.map((b, index) => (
    <div className="mb-1  d-flex container-title">
      <div className="w-90">
        <h6 className="title">Título (máx 100) </h6>
        <InputText maxLength={100} onChange={(event) => onChangeContent(index,event, 'title')} className="subTitle mb-1 w-70" value={b ? b.title : 'Lorem ipsum'} placeholder="Lorem ipsum"/>
        <h6 className="title">Descripción (máx 200) </h6>
        <InputText maxLength={200} onChange={(event) => onChangeContent(index,event, 'description')} className="subTitle w-70 mb-1" value={b? b.description: 'Lorem ipsum'} placeholder="Lorem ipsum"/>
      </div>
      <PSIcon type="delete" className="icon-delete" onClick={() => onDeleteItem(b, index)} />
    </div>
  ));

const DescriptionQuedateCasa = ({ magazine, onChangeParagraphs,onDeleteItem }) =>
  magazine.paragraphs.map((op, index) => (
    <div className="content-casa" key={index}>
      <textarea rows="4" cols="50" onChange={(event) => onChangeParagraphs(index,event.target.value, 'paragraph')} className="subTitle mb-1 w-70" value={op ? op.paragraph : 'Lorem ipsum'} placeholder="Lorem ipsum"/>
      <span onClick={() => onDeleteItem(op,index)} title="Eliminar">X</span>
    </div>
  ));


const NewSletterBoletinCard = React.forwardRef(({ mainTitle, boletin, hasDetail, onHandleBoletin }, ref)  => {
  const [count , setCount ] = useState(0);
  const onChangeContent = (index, value, attribute) => {
      const { content } = boletin;
      content[index][attribute] = value;
      onHandleBoletin(content, 'content');
  };

  const onChangeParagraphs = (index, value, attribute) => {
      const { paragraphs } = boletin;
      paragraphs[index][attribute] = value;
      onHandleBoletin(paragraphs, 'paragraphs');
  };

  const onIncreaseTitle = () => {
      const { content, paragraphs } = boletin;
      let nextCount = count;
      if (boletin.idTipoContenido === MAGAZINE_BOLETIN_QUEDATE_CASA) {
        paragraphs.push({ paragraph: '' });
        // eslint-disable-next-line no-plusplus
        setCount(++nextCount);
        onHandleBoletin(paragraphs, 'paragraphs');
      } else {
        const newArr = { title: '', description: '' };
        content.push(newArr);
        // eslint-disable-next-line no-plusplus
        setCount(++nextCount);
        onHandleBoletin(content, 'content');
      }
  };

  const onDeleteTitle = (item , index) => {
    let nextCount = count;
    if (boletin.idTipoContenido === MAGAZINE_BOLETIN_QUEDATE_CASA) {
        const newContent = removeElementFromArray(index, boletin.paragraphs);
        // eslint-disable-next-line no-plusplus
        setCount(--nextCount);
        onHandleBoletin(newContent, 'paragraphs');
      } else{
        const newContent = removeElementFromArray(index, boletin.content);
        // eslint-disable-next-line no-plusplus
        setCount(--nextCount);
        onHandleBoletin(newContent, 'content');
      }
  };

  return (
    <ContainerCard className="newsletter-boletion-card" ref={ref}>
        <div className="main-title">{mainTitle}</div>
        <div className="description">
          { (hasDetail && boletin) ?
          (
            boletin.idTipoContenido === MAGAZINE_BOLETIN_VIVE_SALUD ? (
              <DescriptionVivesalud magazine={boletin} onChangeContent={onChangeContent} onDeleteItem={onDeleteTitle}/>
            ) : boletin.idTipoContenido === MAGAZINE_BOLETIN_FINANCIERO ? (
              <DescriptionVivesalud magazine={boletin} onChangeContent={onChangeContent} onDeleteItem={onDeleteTitle} />
            ) : boletin.idTipoContenido === MAGAZINE_BOLETIN_QUEDATE_CASA ? (
              <DescriptionQuedateCasa magazine={boletin} onChangeParagraphs={onChangeParagraphs} onDeleteItem={onDeleteTitle} />
            ) : boletin.idTipoContenido === MAGAZINE_BOLETIN_EN_VIVO  ? (
              <DescriptionVivesalud magazine={boletin} onChangeContent={onChangeContent} onDeleteItem={onDeleteTitle}/>
            ) : (
              <DescriptionVivesalud magazine={boletin} onChangeContent={onChangeContent} onDeleteItem={onDeleteTitle}/>
                )
          ) :
            <>
              <h6 className="title">Título Principal (máx 200)</h6>
              <InputText maxLength={200} onChange={(value) => onHandleBoletin(value, 'principalTitle')} className="subTitle w-75 mb-1" value={boletin ? boletin.principalTitle:  'Lorem ipsum'} placeholder="Lorem ipsum"/>
              <h6 className="title">Título Secundario (máx 100)</h6>
              <InputText maxLength={100} onChange={(value) => onHandleBoletin(value, 'shortDescription')} className="subTitle w-75 mb-1" value={boletin ? boletin.shortDescription:  'Lorem ipsum'} placeholder="Lorem ipsum"/>
            </>
        }

    </div>
      {hasDetail  && count <=2 && (
        <div className="mt-1 mb-1">
          <Button className="button" type={ButtonColor.red} onClick={() => onIncreaseTitle()}>
            Agregar título
          </Button>
        </div>
      )}
    </ContainerCard>
  )
});

NewSletterBoletinCard.displayName = 'NewSletterBoletinCard';

NewSletterBoletinCard.defaultProps = {
  mainTitle: 'Título general',
  boletin: null,
  hasDetail: false,
  onHandleBoletin: () => {},
};

NewSletterBoletinCard.propTypes = {
  mainTitle: PropTypes.string,
  boletin: PropTypes.object,
  hasDetail: PropTypes.bool,
  onHandleBoletin: PropTypes.func,
};

export  default NewSletterBoletinCard;
