import React, { useEffect, useState } from 'react';

import './index.scss';
import groupBy from 'lodash/groupBy';

import moment from 'moment-timezone/index';
import { ManagementPromotions } from '../../components/templates';
import AdvertisingService from '../../shared/services/AdvertisingService';
import { PromotionModel } from '../../shared/model/PromotionModel';
import PSLoading from '../../components/PSLoading';
import { getBase64 } from '../../../scripts/Util';
import { showErrorToast, showSuccessToast } from '../../shared/constants/Constants';

const tabsMngPromotions = [
  { id: 1, name: 'Oportunidad del mes', className: '', selected: true },
  { id: 2, name: 'PQP', className: '', selected: false },
  { id: 3, name: 'Promociones', className: '', selected: false },
];

export default function ManagementPromotionsPage(props) {
  const [tabsManagementPromotions, setTabsManagementPromotions] = useState([...tabsMngPromotions]);
  const [showModal, setShowModal] = useState(false);
  const [promotions, setPromotions] = useState(null);
  const [typeSelected, setTypeSelected] = useState(1);
  const [loading, setLoading] = useState(true);
  const [newPromotion, setNewPromotion] = useState({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const onSelectTab = (indexChange) => {
    if (indexChange >= 0) {
      setTypeSelected(indexChange);
      tabsManagementPromotions.forEach((item, index) => {
        if (index === indexChange - 1) item.selected = true;
        else item.selected = false;
      });
      setTabsManagementPromotions([...tabsManagementPromotions]);
    }
  };

  const loadInfo = async () => {
    try {
      setLoading(true);
      const response = await AdvertisingService.getAdvertising();
      if (response?.status === 200) {
        const data = response.data.map((item) => {
          return new PromotionModel(item);
        });
        const dataGroupByType = groupBy(data, 'type', 'month');
        setPromotions({ ...dataGroupByType });
      } else {
        setPromotions([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangePromotion = (property, value) => {
    newPromotion[property] = value;
    setNewPromotion({ ...newPromotion });
  };

  const onSavePromotion = async () => {
    try {
      setLoadingSave(true);
      const date = Date.now();
      const fileBase64 = await getBase64(newPromotion.file[0]);

      let data = {
        StartDate: newPromotion.startDate,
        EndDate: newPromotion.endDate,
        Name: newPromotion.name,
        Link: newPromotion.link,
        Type: typeSelected,
        Date: moment(date).format('YYYY/MM/DD'),
        Default: false,
        ImageBase64: fileBase64,
      };

      if (typeSelected === 3) {
        const products = newPromotion.products.map((item) => item.value);
        data = { ...data, Products: products };
      }

      const response = await AdvertisingService.saveAdvertising(data);
      if (response?.status === 200) {
        setShowModal(false);
        setLoading(true);
        setTimeout(() => {
          loadInfo();
        }, 10000);
      }
      showSuccessToast('Promoción guardada con éxito');
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      setShowModal(false);
      showErrorToast('Hubo un problema, intente luego');
      setLoadingSave(false);
    }
  };

  const onUpdatePromotion = async (id) => {
    try {
      const data = {
        Id: id,
        Status: 'Publicado',
      };
      setLoadingUpdate(true);
      const response = await AdvertisingService.updateAdvertising(data);
      if (response.status === 200) {
        setLoadingUpdate(false);
        loadInfo();
      }
    } catch (error) {
      setLoadingUpdate(false);
      console.log(error);
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <ManagementPromotions
      tabsManagementPromotions={tabsManagementPromotions}
      openModal={() => setShowModal(true)}
      showModalManagementPromotions={showModal}
      onCloseModal={() => setShowModal(false)}
      onSelectTab={onSelectTab}
      dataHistory={promotions}
      typeSelected={typeSelected}
      onSavePromotion={onSavePromotion}
      newPromotion={newPromotion}
      onChangePromotion={onChangePromotion}
      loadingSave={loadingSave}
      onUpdatePromotion={onUpdatePromotion}
      loadingUpdate={loadingUpdate}
      loading={loading}
      setLoading={(status) => setLoading(status)}
    />
  );
}
