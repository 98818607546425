import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { es } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './index.scss';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment-timezone';
import { removeDays } from '../../../../scripts/Util';
import PSIcon from '../../PSIcon';
import PSModal from '../../PSModal';
import { ModalContainer } from '../../organisms';
import { Button } from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';

const formatDatePicker = 'DD MMM';
const date = new Date();
const initialDate = date.setFullYear(date.getFullYear() - 1);

const CalendarRange = React.forwardRef(({ className, onSearch, onRefresh }, ref) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: removeDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [dateDisplay, setDisplayDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const onChangeDate = (item) => {
    setDateRange(item);
    const dateFrom = item[0].startDate;
    const dateTo = item[0].endDate;
    const dateFormattedStart = moment(dateFrom).format(formatDatePicker);
    const dateFormattedEnd = moment(dateTo).format(formatDatePicker);
    setDisplayDate(`${dateFormattedStart} - ${dateFormattedEnd}`);
  };

  const rangeWeek = `${moment(removeDays(new Date(), 7)).format(formatDatePicker)} - ${moment(
    new Date()
  ).format(formatDatePicker)}`;

  return (
    <div ref={ref} className={`calendar-range ${className} d-flex-center-align`}>
      <div className="ranger-picker-icon">
        <PSIcon
          type="calendar"
          className="ml-1 mr-1"
          onClick={() => setShowCalendar(!showCalendar)}
        />
      </div>
      {showCalendar && (
        <PSModal open={showCalendar} onClose={() => setShowCalendar(false)}>
          <ModalContainer
            mainTitle={`Búsqueda por fechas: ${dateDisplay || rangeWeek}`}
            showFooterSection={false}
          >
            <div className="ranger-picker-container">
              <DateRangePicker
                onChange={(item) => onChangeDate([item.selection])}
                showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={dateRange}
                locale={es}
                direction="horizontal"
                className="calendar-range-custom"
                rangeColors={['#de6d19']}
                maxDate={new Date()}
                minDate={new Date(initialDate)}
              />
            </div>
            <div className="align-all-center mt-2">
              <Button
                type={ButtonColor.white}
                className="mr-1"
                onClick={() => {
                  setShowCalendar(false);
                  setDisplayDate(null);
                  onRefresh();
                }}
              >
                {' '}
                Refrescar
              </Button>
              <Button
                onClick={() => {
                  setShowCalendar(false);
                  onSearch(dateRange);
                }}
              >
                {' '}
                Buscar
              </Button>
            </div>
          </ModalContainer>
        </PSModal>
      )}
    </div>
  );
});

CalendarRange.displayName = 'CalendarRange';

CalendarRange.defaultProps = {
  className: '',
  onRefresh: () => {},
  onSearch: () => {},
};

CalendarRange.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default CalendarRange;
