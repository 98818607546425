import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { ContainerCard } from '../index';
import { ReceivedRequestDefault } from './Constants';
import PSIcon from '../../PSIcon';
import { Button } from '../../atoms';

const CardReceivedRequest = React.forwardRef(({ className, requestInfo }, ref) => {
  return (
    <ContainerCard ref={ref} className={`container-card-received-request ${className}`}>
      <div className={`header-container d-flex ${requestInfo.buttonCard ? 'space-between' : 'end'}`}>
        {
          requestInfo.buttonCard &&
          <Button>
            <span>{requestInfo.type}</span>
          </Button>
        }
        <div className="request-state">
          <span>{requestInfo.state}</span>
        </div>
      </div>
      <div className="container-content">
        <div className="content-name">
          <span>{requestInfo.type}</span>
        </div>
        <div className="profile-container">
          <div>
            <PSIcon type="profile" className="content-profile" />
          </div>
          <div>
            <div className="content-info1">
              <span>{requestInfo.name}</span>
            </div>
            <div className="content-info2">
              <PSIcon type="calendarContract" />
              <span>{requestInfo.date}</span>
            </div>
          </div>
        </div>
      </div>
    </ContainerCard>
  );
});

CardReceivedRequest.displayName = 'CardReceivedRequest';

CardReceivedRequest.defaultProps = {
  className: '',
  requestInfo: ReceivedRequestDefault,
};

CardReceivedRequest.propTypes = {
  className: PropTypes.string,
  requestInfo: PropTypes.object,
};

export default CardReceivedRequest;
