import React from 'react';
import { useHistory } from 'react-router-dom';

import { menuCode } from '../../../shared/constants/Constants';
import { Routes } from '../../../../routes';

import { ListProducts } from '../../organisms';
import PSDynamicBasicTable from '../../PSDynamicBasicTable';
import PSLoading from '../../PSLoading';

import PSIcon from '../../PSIcon';

import './index.scss';

const headerTable = [
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
];

const headerSOATTable = [
  { elementProp: 'placa', headerColumn: 'Placa' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
  { elementProp: 'marca', headerColumn: 'Marca' },
  { elementProp: 'modelo', headerColumn: 'Modelo' },
];
const headerVITable = [
  { elementProp: 'producto', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'nroCertificate', headerColumn: 'Número de certificado' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
];

export default function ProductsByUser({
  listProducts,
  selectUserProduct,
  selectDetail,
  listPoliceByProducts,
  loading,
  loadingDetails,
  productSelected,
  beneficary,
}) {
  const history = useHistory();

  const customHeaderTable = () => {
    if (productSelected.branchId === 66) return headerSOATTable;
    const productWithCertificate = [
      menuCode.VIDA_INDIVIDUAL,
      menuCode.DESGRAVAMEN,
      menuCode.ACCIDENTES_PERSONALES,
    ];
    if (productWithCertificate.includes(productSelected.menuId)) return headerVITable;
    return headerTable;
  };

  return !loading ? (
    <div className="container-products-user">
      <div className="general-title">
        <PSIcon
          type="arrowBack"
          className="icon-arrow-back"
          onClick={() => {
            history.push(`${Routes.NET_NATURAL_USER}`);
          }}
        />
        <div className="item-info-basic">
          {/* <div className="info-photo">
                    <PSIcon type="profile"/>
                  </div>   */}
          <div>
            <h2 className="info-name">{beneficary.name}</h2>
            <div className="info-mail">
              {beneficary.tipoDoc} {beneficary.nroDoc}
            </div>
          </div>
        </div>
      </div>

      <div id="containerProductDetails" className="container-product-details">
        <div className="table-title">Productos Contratados</div>
      </div>

      <div className="container-user-products">
        {listProducts.length > 0 ? (
          <ListProducts
            listProducts={listProducts}
            selectProduct={selectUserProduct}
            productSelected={productSelected}
            className="products-by-user"
          />
        ) : (
          <p>El cliente no cuenta con productos vigentes</p>
        )}
      </div>
      {listProducts.length > 0 && productSelected && (
        <div id="containerProductDetails" className="container-product-details">
          <div className="table-title">Detalle del seguro</div>

          <PSDynamicBasicTable
            headers={customHeaderTable()}
            items={listPoliceByProducts}
            onSelect={selectDetail}
            loading={loadingDetails}
            productSelected={productSelected}
          />
        </div>
      )}
    </div>
  ) : (
    <div className="align-all-center">
      <PSLoading color="default" />
    </div>
  );
}
