import React, { useState } from 'react';
import styled from 'styled-components';


import PSHeaderPageHome from './PSHeaderPageHome';
// import PSFooterPageHome from './PSFooterPageHome';
// import PSOptionsLanding from './PSOptionsLanding';
import { Routes } from '../../routes';
import PSFooterPageHome from "./PSFooterPageHome";
// import { cleanAuth } from '../redux/auth';

const BackgroudContainer = styled.div`
  background: url('${process.env.PUBLIC_URL}/images/img/landing/home.png');
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 570px;
  display: flex;
  align-content: center;
  align-items: center !important;
  justify-content: center;

  h1 {
    font-size: 36px;
    letter-spacing: 0px;
    text-align: center;
    color: #FFF;
    display: none;
  }

  @media (min-width: 768px) {
    justify-content: space-around;
    h1 {
      display: block;
      width: 300px;
      text-align: left;
      color: #2b0d61;
      line-height: 35px;
    }
  }
`;

export default function PSLanding({ children }) {
  return (
    <div>
      <PSHeaderPageHome />
      <div>
        <BackgroudContainer className="bg-container">
          <h1 className="f-bold">Seguimos protegiendo lo que más valoras.</h1>
          <div>{children}</div>
        </BackgroudContainer>
      </div>
      <PSFooterPageHome className={"display-none-response"}/>
    </div>
  );
}

// export const  withLanding = (Component) => (props) => {
//   const history = useHistory();
//   const dispatch = useDispatch();
//
//   const [open, setOpen] = useState(true);
//
//   const onClose = () => {
//     setOpen(false);
//     dispatch(cleanAuth());
//     history.push(Routes.LOGIN);
//   };
//
//   // const closeOnOverlay = () => {
//   //   if(props.location.pathname === Routes.SIGN_UP_CREATE_PASSWORD || props.location.pathname === Routes.FORGOT_PASSWORD_CREATE_PASSWORD){
//   //     return false
//   //   }else{
//   //     return true
//   //   }
//   // }
//
//   return (
//     <GAConsumer>
//       {(ga) => (
//         <Modal
//           animationDuration={0}
//           open={open}
//           onClose={onClose}
//           showCloseIcon={false}
//           onOverlayClick={closeOnOverlay()?onClose:()=>{}}
//           closeOnOverlayClick = {false}
//         >
//           <Component {...props} closeModal={onClose} ga={ga} />
//         </Modal>
//       )}
//     </GAConsumer>
//
//   );
// };
