import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const ContainerPreviewBanner = React.forwardRef(({ className, category }, ref) => {
  return (
    <div ref={ref} className={`container-preview-banner ${className}`}>
      <div className="preview-banner-header">
        <div className="header-title-preview">Vista preliminar</div>
        <div className="header-title-newsletter">{category.principalTitle}</div>
      </div>
      <div className="preview-newsletter-body">
        <div className="body-section-img">
          <div className="container-img">
            <img src={category.img} />
          </div>
        </div>
      </div>
    </div>
  );
});

ContainerPreviewBanner.displayName = 'ContainerPreviewBanner';

ContainerPreviewBanner.defaultProps = {
  className: '',
  category:{},
};

ContainerPreviewBanner.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object,
};

export default ContainerPreviewBanner;
