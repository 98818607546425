import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import { DetailDefault } from './Constants';
import { ContainerCardContract, DropzoneContract } from '../../molecules';

const DetailContract = React.forwardRef(({ details }) => {
  return (
    <ContainerCardContract>
      <div className="detailContract-title">
        <h3>Detalles</h3>
      </div>
      <div className="title-section">
        <h4>Fechas</h4>
      </div>
      <div className="container-dates">
        <div className="detail-date">
          <div className="d-flex">
            <PSIcon type="orangeCalendar" />
            <span className="detail-state">Asignada :</span>
          </div>
          <span>{details.dateInitial}</span>
        </div>
        <div className="detail-date">
          <div className="d-flex">
            <PSIcon type="orangeCalendar" />
            <span className="detail-state">Modificada :</span>
          </div>
          <span>{details.dateEnd}</span>
        </div>
      </div>
      <div className="line-separator" />
      <div className="upload-container">
        <div className="title-section">
          <span>Archivos adjuntos</span>
        </div>
        <DropzoneContract />
      </div>
      <div className="container-files">
        <div className="items-file">
          <PSIcon type="fileAttached" />
          <div className="ml-1">
            <div className="file-name">
              <span>Modelo.contrato.doc</span>
            </div>
            <div className="file-size">
              <span>120.18</span>
            </div>
          </div>
          <div className="items-file-options d-flex">
            <PSIcon type="download2" />
            <PSIcon type="blueTrash" />
          </div>
        </div>
      </div>
      <div className="line-separator" />

      <div className="container-details">
        <div className="title-section">
          <span>Detalles</span>
        </div>
        <div className="details-item">
          <span className="item-field">Prioridad</span>
          <span>{details.priority}</span>
        </div>
        <div className="details-item">
          <span className="item-field">Tipo</span>
          <span className="item-value">{details.type}</span>
        </div>
        <div className="details-item">
          <span className="item-field">Etiquetas</span>
          <span>{details.label}</span>
        </div>
        <div className="details-item">
          <span className="item-field">Estado</span>
          <span>{details.resolution}</span>
        </div>
      </div>

      <div className="line-separator" />
      <div className="container-responsible">
        <div className="title-section">
          <span>Personas</span>
        </div>
        <div className="item-responsible">
          <span className="responsible-field">Asignado</span>
          <div className="responsible-value-container d-flex">
            <PSIcon type="user" />
            <span className="responsible-value">{details.assigned}</span>
          </div>
        </div>
        <div className="item-responsible">
          <span className="responsible-field">Responsable</span>
          <div className="responsible-value-container d-flex">
            <PSIcon type="user" />
            <span className="responsible-value">{details.responsible}</span>
          </div>
        </div>
      </div>
    </ContainerCardContract>
  );
});

DetailContract.defaultProps = {
  className: '',
  details: DetailDefault,
};

DetailContract.propTypes = {
  className: PropTypes.string,
  details: PropTypes.object,
};

export default DetailContract;
