import React, { useEffect, useState } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DropzoneNewsLetter, ContainerCard, Loading } from '../../molecules';
import { Button } from '../../atoms';

import PSIcon from '../../PSIcon';
import { Routes } from '../../../../routes';
import { pathServer } from '../../../shared/constants/Constants';
import ManagerNewsLatter from '../../../shared/services/ManagerNewsLatter';

export default function NewSletterCategory({
  onClickModalNewCategory,
  listCategory,
  actionsToCard,
  currentCategory,
  selectCategory,
  listBoletinByCategory,
  listImgBoletinByCategory,
  loading,
  loadingById,
  onClickModalPreview,
}) {
  const history = useHistory();
  const [webImage, setWebImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [loadingImages, setLoadingImages] = useState(false);

  const loadImageFile = async () => {
    setLoadingImages(true);
    const responseImage = await ManagerNewsLatter.responseGetImages(
      currentCategory.webBannerS3UrlImage
    );
    const responseImageMobile = await ManagerNewsLatter.responseGetImages(
      currentCategory.responsiveBannerS3UrlImage
    );
    setWebImage(responseImage);
    setMobileImage(responseImageMobile);
    setLoadingImages(false);
  };

  useEffect(() => {
    if (currentCategory) {
      loadImageFile();
    }
  }, [currentCategory]);

  useEffect(() => {

  },[listBoletinByCategory]);
  
  return (
    <div className="newsletter-category">
      <div className="newsletter-list-category">
        {loading ? (
          <div className="category-loading">
            <Loading />
          </div>
        ) : (
          listCategory.map((item) => {
            return (
              <ContainerCard
                key={item.id}
                withOptions
                actions={actionsToCard}
                className={`category ${
                  currentCategory && item.idContenido === currentCategory.idContenido
                    ? ' category-selected'
                    : ''
                }`}
                itemId={item.idContenido}
                onClick={() => selectCategory(item)}
              >
                <div className="container-card-category">
                  <div className="color-orange title-category">Categoría</div>
                  <div className="color-blue">{item.principalTitle}</div>
                </div>
              </ContainerCard>
            );
          })
        )}
        {/* <div className="container-add-category"> */}
        {/*  <PSIcon */}
        {/*    onClick={() => { */}
        {/*      onClickModalNewCategory(); */}
        {/*    }} */}
        {/*    className="create-category text-center" */}
        {/*    type="addCircle" */}
        {/*  /> */}
        {/*  <div className="create-category-label">Crear nueva categoría</div> */}
        {/* </div> */}
      </div>

      {currentCategory ? (
        <div>
          <div className="newsletter-category-title">
            <div className="category-title">Banner {currentCategory.principalTitle}</div>
            {/*<div className="category-description">*/}
            {/*  */}
            {/*  Selecciona una imagen el cual será banner de la categoría*/}
            {/*</div>*/}
          </div>
          <div className="newsletter-category-dropzone d-flex">
            {webImage || mobileImage ? (
              loadingImages ? (
                <div className="align-all-center">
                  {' '}
                  <Loading />
                </div>
              ) : (
                <>
                  <div>
                    <b>Web</b>
                    <img width="250" src={webImage} alt="" />
                  </div>
                  <div>
                    <b>Mobile</b>
                    <img width="250" src={mobileImage} alt="" />
                  </div>
                </>
              )
            ) : (
              <div>
                <b> Sin imagen</b>
                <img width="250" src={`${pathServer.PATH_IMG}default.png`} alt="" />
              </div>
            )}
          </div>

          {loadingById ? (
            <div className="category-loading">
              <Loading />
            </div>
          ) : (
            <>
              <div className="newsletter-category-last-newsletter mb-2 mt-1">
                <div className="last-newsletter-title">Últimos Boletines</div>
                <Button
                  onClick={() => {
                    history.push(`${Routes.NEWSLETTER_BOLETIN_ALL}/${currentCategory.idContenido}`);
                  }}
                >
                  Ver todos
                </Button>
              </div>
              <div className="newsletter-category-list-newsletter">
                {listBoletinByCategory.slice(0, 3).map((boletin, index) => {
                  const dateCreated = moment.unix(boletin.dateRegister).format('YYYY-MM-DD');
                  return (
                    <ContainerCard
                      withOptions
                      actions={actionsToCard}
                      className="newsletter"
                      onClick={() => onClickModalPreview(index)}
                    >
                      <div className="card-last">
                        <div className="wrapper-img">
                          <img src={listImgBoletinByCategory[index]} width="80px" alt="" />
                        </div>
                        <div>{boletin.principalTitle}</div>
                        <h6>{boletin.shortDescription}</h6>
                        <div>Publicado</div>
                        <div>{dateCreated}</div>
                      </div>
                    </ContainerCard>
                  );
                })}
                <ContainerCard
                  withOptions={false}
                  className="newsletter"
                  onClick={() => {
                    history.push(
                      `${Routes.NEWSLETTER_BOLETIN_CREATE}/${currentCategory.idContenido}`
                    );
                  }}
                >
                  <div className="align-all-center">
                    <div>
                      <div className="mb-1 text-center">Crear boletín</div>
                      <PSIcon className="create-boletin text-center" type="addCircle" />
                    </div>
                  </div>
                </ContainerCard>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="info-select-category">Seleccione una categoría</div>
      )}
    </div>
  );
}
