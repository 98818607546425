import React, { useState, useEffect } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { updatePassword, clearRecoverPassword } from '../../../redux/ducks/userRecover';
import { Routes } from '../../../../routes';

import PSLoginContainer from '../../../components/PSLoginContainer';
import PSHeaderLogin from '../../../components/PSHeaderLogin';
import PSBox from '../../../components/PSBox';
import PSTextInput from '../../../components/PSTextInput';
import PSLabel from '../../../components/PSLabel';
import PSButton from '../../../components/PSButton';
import PSError from '../../../components/PSError';
import PSMessageResponse from '../../../components/PSMessageResponse';
import PSInputIcon from '../../../components/PSInputIcon';
import { Timer, passwordRegExp } from '../../../shared/constants/Constants';
import { isEmpty } from '../../../../scripts/Util';

export default function CreatePassword({ closeModal }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { email, loading, code, updatedPassword } = useSelector((state) => state.userRecoverPass);

  const [successResponse, setSuccessResponse] = useState();
  const [passwordHidden, setPasswordHidden] = useState(false);
  const [passwordRepeatHidden, setPasswordRepeatHidden] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
      pdpNetPrivada: false,
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .matches(passwordRegExp, 'Contraseña no cumple con políticas de seguridad')
        .min(8, 'Necesita mínimo 8 caracteres')
        .required('La contraseña es requerida'),
      repeatPassword: Yup.string()
        .min(8, 'Necesita mínimo 8 caracteres')
        .oneOf([Yup.ref('password'), null], 'Contraseñas deben ser iguales')
        .required('La contraseña es requerida'),
    }),

    onSubmit(val) {
      dispatch(updatePassword(val.password, email, code));
    },
  });

  const onClose = () => {
    closeModal();
  };

  useEffect(() => {
    if (updatedPassword) {
      setSuccessResponse(true);
      dispatch(clearRecoverPassword());
      setTimeout(() => {
        history.push(Routes.LOGIN);
      }, Timer.waitingApi);
    }
  }, [updatedPassword]);

  return (
    <div className="create-password-container">
      {!successResponse && (
        <PSLoginContainer showCloseIcon className="password-container" onClose={onClose}>
          <PSHeaderLogin
            className="create-password-header"
            title="Crea tu nueva contraseña"
            icon="home"
          />
          <form className="baseForm" onSubmit={formik.handleSubmit} noValidate>
            <PSBox className="password-register">
              <div className="password-register-container">
                <PSInputIcon
                  isShow={passwordHidden}
                  showPassword={() => setPasswordHidden(!passwordHidden)}
                >
                  <PSTextInput
                    type={passwordHidden ? 'text' : 'password'}
                    {...formik.getFieldProps('password')}
                    placeholder="********"
                    className={
                      formik.touched.password && formik.errors.password ? 'with-error' : ''
                    }
                  />
                </PSInputIcon>
                <PSError>{formik.touched.password && formik.errors.password}</PSError>
                <PSInputIcon
                  isShow={passwordRepeatHidden}
                  showPassword={() => setPasswordRepeatHidden(!passwordRepeatHidden)}
                >
                  <PSTextInput
                    type={passwordRepeatHidden ? 'text' : 'password'}
                    {...formik.getFieldProps('repeatPassword')}
                    placeholder="********"
                    className={
                      formik.touched.repeatPassword && formik.errors.repeatPassword
                        ? 'with-error'
                        : ''
                    }
                  />
                </PSInputIcon>

                <PSError>{formik.touched.repeatPassword && formik.errors.repeatPassword}</PSError>
              </div>
              <PSLabel>*Al menos 8 caracteres con 1 mayúscula y 1 número.</PSLabel>
            </PSBox>
            <PSBox className="container-button-submit">
              <PSButton
                className={`f-bold ${!isEmpty(formik.errors) && 'button-disabled'}`}
                type="submit"
                loading={loading}
              >
                Enviar
              </PSButton>
            </PSBox>
          </form>
        </PSLoginContainer>
      )}
      {successResponse && (
        <PSMessageResponse title="Contraseña actualizada" icon="successFace">
          Se guardaron los cambios con éxito
        </PSMessageResponse>
      )}
    </div>
  );
}
