export const contractModels = [
  {
    id: 1,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: true,
  },
  {
    id: 2,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: true,
  },
  {
    id: 3,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: false,
  },
  {
    id: 4,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: false,
  },
  {
    id: 5,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: false,
  },
  {
    id: 6,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: false,
  },
  {
    id: 7,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: false,
  },
  {
    id: 8,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: false,
  },
  {
    id: 9,
    icon: 'pdfFile',
    name: 'Contrato no 422',
    isSelected: false,
  },
];
