import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from "prop-types";
import { Button } from '../../atoms';
import { InputLabel } from '../../molecules';
import { ButtonColor } from '../../atoms/button/Button';
import { ModalContainer } from '../../organisms';

const CreateUserCompany = React.forwardRef(({ verifyUser, userCreate, loading, onHandleInput }, ref) => {
  const [showDetail, setShowDetail] = useState(false);
  const [documentToVerify, setDocumentToVerify] = useState(null);
  useEffect(() => {
    if (userCreate) {
        setShowDetail(true);
    }
  }, [userCreate]);

  return (
      <div className="create-user-natural" ref={ref}>
        <ModalContainer mainTitle="Crear empresa" btnMessage="Crear " showFooterSection={showDetail} >
          <div className="body__validation">
            <InputLabel
              value={documentToVerify}
              onChange={(value) => {
                setDocumentToVerify(value);
              }}
              labelMessage="RUC"
              placeholder="Ingrese el RUC del usuario a crear"
            />
            {!showDetail && (
              <Button
                loading={loading}
                type={ButtonColor.green}
                onClick={() => verifyUser(documentToVerify)}
              >
                Validar
              </Button>
            )}
          </div>
          {showDetail && (
            <>
              <hr />
              <div className="body__detail">
                <InputLabel onChange={(value) => onHandleInput(value,'names')} className="input--blocked" labelMessage="Nombres"  value={userCreate.names}/>
                <InputLabel onChange={(value) => onHandleInput(value,'lastName')} className="input--blocked"  labelMessage="Apellidos"  value={`${userCreate.lastName } ${ userCreate.lastName2}`}/>
                <InputLabel onChange={(value) => onHandleInput(value,'birthdate')} className="input--blocked"  labelMessage="Nacimiento" value={userCreate.birthdate}/>
                <InputLabel onChange={(value) => onHandleInput(value,'lastEmail')} className="input--blocked"  labelMessage="Correo" value={userCreate.lastEmail} />
                <InputLabel onChange={(value) => onHandleInput(value,'lastMobilePhone')} className="input--blocked"  labelMessage="Celular" value={userCreate.lastMobilePhone}/>
                <InputLabel onChange={(value) => onHandleInput(value,'indPDP')} className="input--blocked"  labelMessage="PDP" value={userCreate.indPDP ? 'SI' : 'NO'} />
              </div>
            </>
          )}
        </ModalContainer>
    </div>
  );
});

CreateUserCompany.displayName = 'CreateUserCompany';

CreateUserCompany.defaultProps = {
  verifyUser: () => {},
  userCreate: {},
  loading: false,
  onHandleInput: () => {},
};

CreateUserCompany.propTypes = {
  verifyUser : PropTypes.func,
  userCreate: PropTypes.object,
  loading: PropTypes.bool,
  onHandleInput: PropTypes.func,
};

export default CreateUserCompany;

