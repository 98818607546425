import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import { Button } from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';
import { CalendarRange } from '../index';
import PSIcon from '../../PSIcon';

import './index.scss';

const HeaderSection = React.forwardRef(
  (
    {
      className,
      hasBack,
      onHandleBack,
      title,
      hasCalendar,
      hasSearchInput,
      openModalCreateUser,
      onSearchCalendar,
      onSearchRefresh,
      hasCreateUser,
      onSearchByDoc,
      hasFindUser,
      openModalFindUser,
      buttonHeader,
      buttonRefresh,
      hasRefresh,
      loading,
    },
    ref
  ) => {
    const documentFilter = useRef();

    return (
      <div ref={ref} className={`header__section ${className}`}>
        <div className="header__title">
          {hasBack && <PSIcon type="arrowBack" onClick={onHandleBack} />}
          <h2 className="color-blue">{title}</h2>
        </div>
        <div className="option-filters">
          {hasSearchInput && (
            <input
              type="text"
              ref={documentFilter}
              placeholder="Buscar por N° DOC"
              className={`${loading ? 'input-disabled' : ' '}`}
              onChange={(e) => {
                const clearSpace = e.target?.value.replace(/ /g, '');
                e.target.value = clearSpace;
                onSearchByDoc(clearSpace);
              }}
            />
          )}

          {hasCalendar && (
            <CalendarRange
              onRefresh={onSearchRefresh}
              onSearch={(date) => {
                documentFilter.current.value = '';
                onSearchCalendar(date);
              }}
            />
          )}

          {hasCreateUser && (
            <Button
              icon="userCreate"
              className="user-create"
              type={ButtonColor.white}
              onClick={openModalCreateUser}
            >
              Crear
            </Button>
          )}
          {hasFindUser && (
            <Button
              icon="search"
              className="user-create"
              type={ButtonColor.white}
              onClick={openModalFindUser}
            >
              Buscar...
            </Button>
          )}
          {buttonRefresh && buttonRefresh}
          {buttonHeader && buttonHeader}
        </div>
      </div>
    );
  }
);

HeaderSection.displayName = 'HeaderSection';

HeaderSection.defaultProps = {
  loading: false,
  className: '',
  title: 'Usuarios',
  openModalCreateUser: () => {},
  hasCreateUser: false,
  hasCalendar: false,
  hasSearchInput: false,
  onSearchCalendar: () => {},
  onSearchRefresh: () => {},
  onSearchByDoc: () => {},
  openModalFindUser: () => {},
  hasFindUser: false,

  buttonRefresh: null,
  buttonHeader: null,
  hasBack: false,
  onHandleBack: () => {},
};

HeaderSection.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  openModalCreateUser: PropTypes.func,
  hasCreateUser: PropTypes.bool,
  hasSearchInput: PropTypes.bool,

  onSearchCalendar: PropTypes.func,
  hasCalendar: PropTypes.bool,
  onSearchRefresh: PropTypes.func,
  onSearchByDoc: PropTypes.func,
  hasFindUser: PropTypes.bool,
  openModalFindUser: PropTypes.func,
  buttonRefresh: PropTypes.node,
  buttonHeader: PropTypes.node,
  hasBack: PropTypes.bool,
  onHandleBack: PropTypes.func,
};

export default HeaderSection;
