import React from 'react';
import { useHistory } from 'react-router-dom';

import PSIcon from '../../PSIcon';
import './index.scss';

import { MENU_SIDEBAR } from './Constants';

export default function AsideBarContract() {
  const history = useHistory();

  return (
    <div className="aside-menu">
      <div className="logo-menu">
        <PSIcon type="logoProtecta" />
      </div>
      <div className="aside-menu">
        {MENU_SIDEBAR.map(({ id, icon = 'face', title, url }) => {
          return (
            <div key={id} className="container-option hover" onClick={() => history.push(url)}>
              <PSIcon type={icon} />
              <span className="option-title">{title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
