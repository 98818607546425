export const contractsByUser = [
  {
    id: 1,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '23/06/1986',
  },
  {
    id: 2,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '04/02/1973',
  },
  {
    id: 3,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '26/09/1952',
  },
  {
    id: 4,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '01/03/2004',
  },
  {
    id: 5,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '04/10/1998',
  },
  {
    id: 6,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '14/07/2010',
  },
  {
    id: 7,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '30/09/2020',
  },
  {
    id: 8,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '03/02/2014',
  },
  {
    id: 9,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '18/01/2005',
  },
  {
    id: 10,
    icon: 'blueBookmark',
    contractName: 'SEO Keyword optimizado',
    type: 'Tipo 1',
    executedDate: '02/06/1957',
  },
];
