import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/index';

import { isEmpty, numericInputValidation } from '../../../../scripts/Util';

import PSIcon from '../../PSIcon';
import PSLoading from '../../PSLoading';
import { Paginator } from '../../molecules';

import './index.scss';

const MAX_PER_PAGE = 10;

const Table = React.forwardRef(
  ({ title, messageEmptyList, header, data, filter, downloadFile, loadingFile }) => {
    const [listElementsFilter, setListElementsFilter] = useState([]);
    const [actualPage, setActualPage] = useState(1);
    const [valueStartDate, setValueStartDate] = useState(null);
    const [elementSelected, setElementSelected] = useState(null);
    const [typeSelected, setTypeSelected] = useState('pdf');

    const listPaginate = listElementsFilter?.slice(
      (actualPage - 1) * MAX_PER_PAGE,
      actualPage * MAX_PER_PAGE
    );
    const itemsIterable = listElementsFilter
      ? Math.ceil(listElementsFilter.length / MAX_PER_PAGE)
      : 0;
    const onSearch = () => {
      let dataFilter = data;
      setValueStartDate(null);
      filter.map((filterItem) => {
        if (filterItem.type === 'numeric') {
          const currentValue = document.getElementById(filterItem.elementProp).value;
          if (!isEmpty(currentValue)) {
            dataFilter = dataFilter.filter((item) => {
              return item[filterItem.elementProp].search(new RegExp(currentValue, 'i')) !== -1;
            });
          }
        }
        if (filterItem.type === 'range') {
          filterItem.inputs.forEach((filterDate) => {
            const currentValue = document.getElementById(filterDate).value;

            if (filterDate === 'startDate' && !isEmpty(currentValue)) {
              setValueStartDate(currentValue);
              dataFilter = dataFilter.filter((item) => {
                return moment(item[filterItem.elementProp].split('T')[0]) >= moment(currentValue);
              });
            } else if (filterDate === 'endDate' && !isEmpty(currentValue)) {
              dataFilter = dataFilter.filter((item) => {
                return moment(item[filterItem.elementProp].split('T')[0]) <= moment(currentValue);
              });
            }
          });
        }
      });
      setListElementsFilter(dataFilter);
      setActualPage(1);
    };

    const filterInput = (data, index) => {
      let element = '';

      switch (data.type) {
        case 'numeric':
          element = (
            <div className="filter-group" key={index}>
              <label htmlFor={data.elementProp}>{data.label}</label>
              <input
                id={data.elementProp}
                type="number"
                placeholder="Buscar"
                onChange={() => onSearch()}
                onKeyPress={(e) => numericInputValidation(e)}
                maxLength={data.maxLength}
              />
            </div>
          );
          break;
        case 'range':
          element = (
            <div className="filter-group" key={index}>
              <label htmlFor={data.inputs[0]}>{data.label}</label>
              <div className="row-date">
                <input
                  id={data.inputs[0]}
                  type="date"
                  placeholder="Buscar"
                  min="1900-12-31"
                  max="2050-12-31"
                  onChange={() => onSearch()}
                />
                <input
                  id={data.inputs[1]}
                  type="date"
                  placeholder="Buscar"
                  min={valueStartDate || '1900-12-31'}
                  max="2050-12-31"
                  onChange={() => onSearch()}
                />
              </div>
            </div>
          );
          break;
      }
      return element;
    };

    useEffect(() => {
      if (data) {
        setListElementsFilter(data);
      }
    }, [data]);

    useEffect(() => {
      if (elementSelected) {
        downloadFile(elementSelected, typeSelected);
      }
    }, [elementSelected]);

    useEffect(() => {
      if (!loadingFile) {
        setElementSelected(null);
      }
    }, [loadingFile]);

    return (
      <div className="table-component">
        <div className="title">
          <h4>{title}</h4>
        </div>
        {data.length === 0 ? (
          <div className="flex-center empty-list">
            <span>{messageEmptyList} </span>
          </div>
        ) : (
          <>
            {data.length > MAX_PER_PAGE && (
              <div className="table-filters d-flex">
                {filter.map((item, index) => filterInput(item, index))}
              </div>
            )}

            <table className="container-table">
              <thead>
                <tr className="">
                  {header.map((item, index) => (
                    <th key={item.elementProp} className="table-header" colSpan={1}>
                      {item.headerColumn}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listPaginate.length === 0 ? (
                  <tr>
                    <td className="table-item text-center" colSpan={header.length}>
                      No se encontraron registros con el filtro aplicado
                    </td>
                  </tr>
                ) : (
                  listPaginate.map((item, index) => {
                    return (
                      <tr key={index}>
                        {header.map((header, index) =>
                          header.elementProp === 'download' ? (
                            <td key={index} className="table-item table-item-download">
                              {header.icons.map((icon, index) => {
                                return elementSelected?.number === item.number &&
                                  typeSelected === icon.type ? (
                                  <div className="loading margin-icon" key={index}>
                                    <PSLoading width={22} height={22} color="orange" />
                                  </div>
                                ) : (
                                  <PSIcon
                                    key={index}
                                    type={icon.name}
                                    className="icon"
                                    onClick={() => {
                                      setTypeSelected(icon.type);
                                      setElementSelected(item);
                                    }}
                                  />
                                );
                              })}
                            </td>
                          ) : (
                            <td key={index} className="table-item">
                              {item[header.elementProp] || '-'}
                            </td>
                          )
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            {data && data.length > MAX_PER_PAGE && listElementsFilter.length > 0 && (
              <div className="mt-2">
                <Paginator
                  pageCount={itemsIterable}
                  actualPage={actualPage - 1}
                  onPageChange={(page) => setActualPage(page.selected + 1)}
                  forcePage
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

export default Table;

Table.displayName = 'Table';

Table.defaultProps = {
  header: [],
  data: [],
  filter: [],
  downloadFile: () => {},
  loadingFile: false,
};

Table.propTypes = {
  header: PropTypes.array,
  data: PropTypes.array,
  filter: PropTypes.array,
  downloadFile: PropTypes.func,
  loadingFile: PropTypes.bool,
};
