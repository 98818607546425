import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { ContainerCardContract } from '../../molecules';

const BoxComment = ({ type, title, comment }) => {
  return (
    <div>
      <ContainerCardContract className="comment-container">
        <span>{type}</span>
        <button className="bn-button">Ver detalles</button>
        <h3 className="title">{title}</h3>
        <p>{comment}</p>
      </ContainerCardContract>
      <div className="bn-container ">
        <button className="bn-button">Comentarios</button>
        <button className="bn-button margin-10">Historial</button>
        <button className="bn-button">Actividad</button>
      </div>
    </div>
  );
};

export default BoxComment;

BoxComment.displayName = 'BoxComment';

BoxComment.defaultProps = {
  type: '',
  title: '',
  comment: '',
};

BoxComment.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
};
