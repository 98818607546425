export const attachedFiles = [
  {
    id: 1,
    icon: 'docFile',
    fileName: 'Anexo.doc',
    fileSize: '241 KB',
  },
  {
    id: 2,
    icon: 'docFile',
    fileName: 'Anexo2.doc',
    fileSize: '241 KB',
  },
];
