import React from 'react';
import './index.scss';
import { MyCollaborators } from '../../organisms';

export default function contractorCollaborator({ ...props }) {
  return (
    <div className="contractor-collaborator">
      <MyCollaborators {...props} />
    </div>
  );
}
