// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from "prop-types";
import { HeaderSection } from '../../molecules';
import { Button, Switch } from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';
import { PERMISSION_DEFAULT } from '../../../../scripts/Constants';
import PSLoading from "../../PSLoading";
import { includesArray } from '../../../../scripts/Util';

const ID_ROLE_ADMIN = '1628723214';

export default function PermissionList({ className, listRoles, changeView, onHandleCheck }) {
  const [roleSelected, setRoleSelected ] = useState(0);
  const [role, setRole] = useState(listRoles[0]);
  const [loading, setLoading] = useState(false);
  const permission = PERMISSION_DEFAULT;

  const loadPermission = (index, opt) => {
    setLoading(true);
    setRoleSelected(index);
    setRole(opt);
    setTimeout(() => {
      setLoading(false);
    },1000);
  };
  return(
    <div className={`container-permission ${className}`}>
      <HeaderSection
        hasBack
        onHandleBack={changeView}
        title="Configuración de Permisos"
        buttonHeader={<Button type={ButtonColor.blue} onClick={() => changeView(role)}>Guardar</Button>}
      />
      <div className="permission-body">
        <div className="list-group">
          {listRoles.map((opt, index) =>
            <Button key={index} type={index === roleSelected ?  ButtonColor.orange : ButtonColor.white} onClick={() => loadPermission(index,opt)}>
              {opt.label}
            </Button>
          )}
        </div>
        <div className="list-group-permission">
          <table className="w-100">
            <thead>
              <tr>
                <th width="80%">ACCIÓN</th>
                <th width="20%">PERMISO</th>
              </tr>
            </thead>
            <tbody>
              {(loading && role) ?  <div className="loading-container">
                <PSLoading color="orange" />
              </div> :
                permission.map((p, index) => {
                  let isChecked;
                  let isDisabled;
                  if(role) {
                    isChecked = includesArray(p.submenu, role.menuOptions);
                    isDisabled = role.sort === ID_ROLE_ADMIN;
                  }
                  return (
                    <tr key={index}>
                      <td className="description">{p.description}</td>
                      <td className="action">
                        <Switch
                          isDisabled={isDisabled}
                          isChecked={isChecked}
                          onHandle={(checked) =>
                            onHandleCheck({
                              flag: checked,
                              role: listRoles[roleSelected],
                              permission: p.value,
                              submenu: p.submenu,
                            })
                          }
                          className={isDisabled ? 'is-disabled' : ''}
                          id={index}
                        />
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

PermissionList.displayName = 'PermissionList';

PermissionList.defaultProps = {
  className: "",
  changeView : () => {},
  listRoles: [],
  onHandleCheck : () => {},
};

PermissionList.propTypes = {
  className: PropTypes.string,
  changeView: PropTypes.func,
  listRoles: PropTypes.array,
  onHandleCheck: PropTypes.func,
};
