import FileSaver from 'file-saver';
import moment from 'moment-timezone';
import { Api, doGet, doGetWithParams, doPost, doPostWhitParameters, doDelete } from './Api';
import {
  base64toBlob,
  capitalizeSentence,
  fixStructBase64,
  getValueDocument,
} from '../../../scripts/Util';
import { SUCESS_CODE_API } from '../../../scripts/Constants';
import { menuCode, showErrorToast } from '../constants/Constants';
import 'moment/locale/es';
import { Client } from '../model/Client';

const dateNow = moment().tz('America/Lima', true).locale('es').format('DD - MMMM - YYYY');
let dateFormatted = dateNow.replace('-', 'de');
dateFormatted = dateFormatted.replace('-', 'de');

export default class UserNetPrivada {
  static async responseGetUsersNetPrivada(header) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_GET_USERS_NET_PRIVADA}`,
      null,
      header
    );
    response.data.data = response.data.data.sort(
      (a, b) => new Date(b.UserCreateDate) - new Date(a.UserCreateDate)
    );
    return response.data;
  }

  static async doValidateUserNatural(document) {
    const header = {
      'customer-id': document,
    };
    const response = await doGetWithParams(`${process.env.REACT_APP_CLIENT_DATA}`, {}, header);
    const dataResponse = response?.data?.data;
    if (dataResponse) {
      return new Client(dataResponse);
    }
    return null;
  }

  static async validateCognito(documentId, documentType) {
    const response = await Api.get(
      `/user-data-authentications/v0/users/${documentType}${documentId}`
    );
    if (response.data.statusCode === 500) {
      return 'INVALID_USER';
    }
    return 'VALID_USER';
  }

  static async validateUserDigitalStatus(documentId, documentType) {
    const data = {
      identityDocument: {
        documentNumber: documentId,
        documentType: {
          id: getValueDocument(documentType),
        },
      },
    };
    const response = await doPost(
      `${process.env.REACT_APP_ONBORDING}/digital-activation-status`,
      data
    );
    const dataResponse = response.data;

    if (dataResponse && dataResponse.status.id === 204) {
      return 'INVALID_USER';
    }

    return 'VALID_USER';
  }

  static async validateUserConditions(documentId, documentType) {
    const showSMS = process.env.REACT_APP_SHOW_OTP_SMS === 'true';
    const data = {
      identityDocument: {
        documentNumber: documentId,
        documentType: {
          id: getValueDocument(documentType),
        },
      },
    };
    const response = await doPost(
      `${process.env.REACT_APP_ONBORDING}/contact-details-enrolment`,
      data
    );
    const dataResponse = response.data;
    const validation = showSMS
      ? dataResponse?.contactPhone?.phone || dataResponse?.contactEmail?.email
      : dataResponse?.contactEmail?.email;

    if (!dataResponse.status && validation) {
      return 'VALID_USER';
    }

    if (dataResponse.status && dataResponse.status.id === 500) {
      return 'ERROR_USER';
    }

    if (
      dataResponse.status &&
      dataResponse.status.id === 204 &&
      dataResponse.status.name === 'NON CUSTOMER'
    ) {
      return 'INVALID_USER';
    }

    if (
      dataResponse.status.id === 409 &&
      dataResponse.status.name === 'CUSTOMER INVALID POLICIES'
    ) {
      return 'USER_INVALID_POLICIES';
    }

    if (dataResponse.status.id === 409 && dataResponse.status.name === 'INVALID CONTACT DETAILS') {
      return 'NO_DATA_USER';
    }

    return ['NO_DATA_USER'];
  }

  static async createUserNatural(typeDocument, document, user) {
    const structData = {
      id: `${typeDocument}${document}`,
      birthdate: user.birthdate,
      legalName: user.legalName,
      clientID: user.idClient,
      pdp: user.indPDP,
      phone: user.lastMobilePhone,
      secondLastName: user.lastName2,
      lastName: user.lastName,
      name: user.names,
      email: user.lastEmail,
    };
    const response = await doPost(
      `${process.env.REACT_APP_BASE_URL_NET}/nntp-backoffice-authentication-api/nntp-crearUserFromBackofice`,
      structData
    );
    if (response.data.statusCode === 500) {
      return 'INVALID_USER_CREATE';
    }
    return 'VALID_USER_CREATE';
  }

  static async enableUser(header) {
    const response = await doPostWhitParameters(
      `${process.env.REACT_APP_UPDATE_STATUS_USER_NET}/enable`,
      null,
      null,
      header
    );
    return response.data;
  }

  static async disableUser(header) {
    const response = await doPostWhitParameters(
      `${process.env.REACT_APP_UPDATE_STATUS_USER_NET}/disable`,
      null,
      null,
      header
    );
    return response.data;
  }

  static async deleteUser(header) {
    const response = await doDelete(
      `${process.env.REACT_APP_UPDATE_STATUS_USER_NET}`,
      null,
      header
    );
    return response.data;
  }

  static async responseGetProductPolicies(headers) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_GET_PRODUCTS_POLICIES}`,
      null,
      headers
    );
    return response.data;
  }

  static async responseGetProductByUsers(headers) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_POLICIES}/segments-overview/v0/segments-overview`,
      null,
      headers
    );
    return response.data;
  }

  static async responseGetPliciesByProduct(headers) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_POLICIES}/policies/v0/policies`,
      null,
      headers
    );
    return response.data;
  }

  static async responseGetTicketByAnnuity(year, policyId, type) {
    const typeProduct = type === '20000008' ? 'RT' : 'AT';

    const typeUrl =
      type === '20000001'
        ? `/life-annuity/v1/payments?year=${year}&policy=${policyId}`
        : `/private-rent/v1/payments?year=${year}&product=${typeProduct}&policy=${policyId}`;
    const response = await doGet(`${process.env.REACT_APP_GET_TICKETS}${typeUrl}`);
    return response.data;
  }

  static async responseGetDocuments(ticketId, type, nameFile) {
    const data = { data: [{ id: ticketId }] };
    const response = await doPost(
      `${process.env.REACT_APP_DOCUMENTS}/crear${type}`,
      JSON.stringify(data)
    );

    if (response.data.status === 'SUCCEEDED') {
      const base64 = response.data.output;
      const fixBase64 = fixStructBase64(base64);
      const blob = base64toBlob(fixBase64, 'application/pdf');
      FileSaver.saveAs(blob, nameFile);
    } else {
      // todo julio
    }
  }

  static async resposeGetConstancy(
    productSelected,
    policy,
    beneficary,
    sendEmail = false,
    email = '',
    headers
  ) {
    const obj = {
      data: {
        arrayBoletas: [
          {
            documentType: 'L',
            clientName: beneficary.name.toUpperCase(),
            documentNumber: beneficary.nroDoc,
            initValidity: policy.fechaInicioVigencia,
            finishValidity:
              productSelected.menuId === menuCode.RENTA_VITALICIA
                ? 'Vitalicia'
                : policy.fechaFinVigencia,
            policy: policy.nroPolicy.toString().padStart(10, '0'),
            policyType: `Seguro de ${productSelected.name}`,
            ammount: 'Según condiciones de la Póliza',
            dateDescription: `Surquillo, ${dateFormatted}`,
          },
        ],
        enviarEmail: sendEmail,
        receiver: email,
      },
    };

    const response = await doPostWhitParameters(
      `${process.env.REACT_APP_CONSTANCIA}`,
      obj,
      null,
      headers
    );

    if (sendEmail) return response;
    if (response.status === SUCESS_CODE_API) {
      const base64 = response.data.document;
      const fixBase64 = fixStructBase64(base64);
      const blob = base64toBlob(fixBase64, 'application/pdf');
      FileSaver.saveAs(blob, 'ConstaciaAsegurado');
    } else {
      showErrorToast('Error al descargar la Constancia de Asegurado');
    }
    return response.data;
  }

  static async resposeGetPensionerConstancy(headers) {
    const {
      data: { data, status },
    } = await doGetWithParams(`${process.env.REACT_APP_CONSTANCIA_PENSIONER}`, {}, headers);

    if (data && status?.id === SUCESS_CODE_API) {
      const base64 = data[0]?.document;
      const fixBase64 = fixStructBase64(base64);
      const blob = base64toBlob(fixBase64, 'application/pdf');
      FileSaver.saveAs(blob, 'ConstaciaPensionista');
    } else {
      showErrorToast('Error al descargar la Constancia de Pensionista');
    }
  }

  static async responseSendEmailBoletas(ids, email, beneficary, type) {
    const fullName = capitalizeSentence(beneficary.name);
    const data = {
      data: ids,
      name: fullName,
      email,
    };
    const typeUrl = type === '20000001' ? 'nntp-sendRentaVitalicia' : 'nntp-sendRentaTotal';

    const response = await doPost(`${process.env.REACT_APP_SEND_EMAIL_BOLETAS}/${typeUrl}`, data);
    return response.data;
  }

  static async responseGetCertificate() {
    const response = await doGet(`${process.env.REACT_APP_GET_CERTIFICATE}`);
    return response.data;
  }

  static async responseGetSoatConstancia(token, nroPolicy) {
    const response = await doGet(
      `${process.env.REACT_APP_GET_CONSTANCIA_SOAT}/${nroPolicy}`,
      token
    );
    return response.data;
  }

  static async responseGetDigitaPolicy(params) {
    const response = await doPost(`${process.env.REACT_APP_DIGITAL_POLICY}`, params);
    return response?.data;
  }

  static async responseValidateComprobantePago(headerParams) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_PAYMENT_VOUCHERS_VALIDATE}`,
      null,
      headerParams
    );
    return response.data;
  }

  static async responseDocumentComprobantePago(data) {
    const response = await doPost(`${process.env.REACT_APP_PAYMENT_VOUCHERS_DOCUMENT}`, data);
    return response.data;
  }

  static async responseValidateByPolicy(headerParams) {
    const response = await doGetWithParams(
      `${process.env.REACT_APP_VALIDATE_BY_POLICY}`,
      null,
      headerParams
    );
    return response.data;
  }
}
