import React from 'react'
import './index.scss';
import PSIcon from '../../PSIcon';
import PropTypes from "prop-types";
import { ContainerCardContract } from "../index";

const ContractReview = ({ listResponsible, assigned, delivered }) => {
    return (
        <ContainerCardContract className="container-cardReview">
            <h3>Responsables</h3>
            <div className="responsible-container">
                {
                    listResponsible.map((item, index) => {
                        return (
                            <div key={index} className="responsible-item">
                                <span className="responsible-name">{item.name}</span>
                                <span className="responsible-job">{item.job}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className="container-date">
                <h3>Fechas</h3>
            </div>
            <div className="date-item">
                <div className="d-flex">
                    <PSIcon type="contractCalendar" />
                    <span className="tag-date">Asignada</span>
                </div>

                <div className="field-date">
                    <span className="field-date">{assigned}</span>
                </div>
            </div>
            <div className="date-item">
                <div className="d-flex">
                    <PSIcon type="contractCalendar" />
                    <span className="tag-date">Asignada</span>
                </div>
                <div className="field-date">
                    <span className="field-date">{delivered}</span>
                </div>
            </div>
        </ContainerCardContract>
    )
}

export default ContractReview

ContractReview.displayName = 'ContractReview';

ContractReview.defaultProps = {
    listResponsible: [],
    assigned: "",
    delivered: "",

};
ContractReview.propTypes = {
    listResponsible: PropTypes.array,
    assigned: PropTypes.string,
    delivered: PropTypes.string,
};