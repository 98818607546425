export class Client {
  constructor(props) {
    this.birthdate = props.contact?.birthDay;
    this.lastEmail = props.contactEmail?.email;
    this.lastMobilePhone = props.contactPhone?.phone;
    this.lastName = props.contact?.lastName;
    this.lastName2 = props.contact?.lastName2;
    this.legalName = props.contact?.legalName;
    this.names = props.contact?.name;
    this.sexClient = props.contact?.sexClient;
  }
}
