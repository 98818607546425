import React from 'react';
import './index.scss';
import { Task } from '../../organisms/index';
import { Search } from '../../atoms/index';
import PSIcon from '../../PSIcon';

export default function TaskTemplate({ view, tasks, defineView }) {
  return (
    <div>
      <div className="container-tasksOptions">
        <div className="container-options-menu">
          <div
            className={`option-menu ${view === 1 ? 'selected' : ''}`}
            onClick={() => {
              defineView(1);
            }}
          >
            <PSIcon className="color-icon" type="calendarContract" />
            <span className={`options-menu ${view === 1 ? 'text-selected' : ''}`}>
              Lista Tareas
            </span>
          </div>
          <div
            className={`option-menu ${view === 2 ? 'selected' : ''}`}
            onClick={() => {
              defineView(2);
            }}
          >
            <PSIcon type="calendarContract" />
            <span className={`options-menu ${view === 2 ? 'text-selected' : ''}`}>Tablero</span>
          </div>
          <div
            className={`option-menu ${view === 3 ? 'selected' : ''}`}
            onClick={() => {
              defineView(3);
            }}
          >
            <PSIcon type="calendarContract" />
            <span className={`options-menu ${view === 3 ? 'text-selected' : ''}`}>Historial</span>
          </div>
        </div>
        <Search placeholder="Buscar tarea" className="search-task" />
      </div>

      <div className="container-tasksBoard">
        {view === 1 && <span className="headTasks-title">LISTA TAREAS</span>}
        {view === 2 && (
          <>
            <div>
              <div className="container-headTasks">
                <span className="headTasks-title">POR HACER</span>
                <div className="tasks-length">
                  <span>{tasks.filter((task) => task.statusId === 'toDo').length}</span>
                </div>
              </div>
              {tasks
                .filter((task) => task.statusId === 'toDo')
                .map((item) => {
                  return (
                    <Task
                      className="task-card"
                      key={item.task}
                      task={item.task}
                      status={item.status}
                      name={item.name}
                      user={item.user}
                    />
                  );
                })}
            </div>
            <div>
              <div className="container-headTasks">
                <span className="headTasks-title inProgress">EN PROGRESO</span>
                <div className="tasks-length">
                  <span>{tasks.filter((task) => task.statusId === 'inProgress').length}</span>
                </div>
              </div>
              {tasks
                .filter((task) => task.statusId === 'inProgress')
                .map((item) => {
                  return (
                    <Task
                      className="task-card"
                      key={item.task}
                      task={item.task}
                      status={item.status}
                      name={item.name}
                      user={item.user}
                    />
                  );
                })}
            </div>
            <div>
              <div className="container-headTasks">
                <span className="headTasks-title review">REVISIÓN</span>
                <div className="tasks-length">
                  <span>{tasks.filter((task) => task.statusId === 'review').length}</span>
                </div>
              </div>
              {tasks
                .filter((task) => task.statusId === 'review')
                .map((item) => {
                  return (
                    <Task
                      className="task-card"
                      key={item.task}
                      task={item.task}
                      status={item.status}
                      name={item.name}
                      user={item.user}
                    />
                  );
                })}
            </div>
            <div>
              <div className="container-headTasks">
                <span className="headTasks-title finalized">FINALIZADO</span>
                <div className="tasks-length">
                  <span>{tasks.filter((task) => task.statusId === 'finalized').length}</span>
                </div>
              </div>
              {tasks
                .filter((task) => task.statusId === 'finalized')
                .map((item) => {
                  return (
                    <Task
                      className="task-card"
                      key={item.task}
                      task={item.task}
                      status={item.status}
                      name={item.name}
                      user={item.user}
                    />
                  );
                })}
            </div>
          </>
        )}
        {view === 3 && <span>HISTORIAL</span>}
      </div>
    </div>
  );
}
