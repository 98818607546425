import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './index.scss';
import {NewSletterAllBoletin} from '../../components/templates';
import ManagerNewsLatter from "../../shared/services/ManagerNewsLatter";
import {cloneDeep} from "@apollo/client/utilities";

export default function Boletines(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [listBoletines, setListBoletines] = useState([]);
  const [boletinSelected, setBoletinSelected] = useState(null);
  const [boletinOriginal, setBoletinOriginal] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const goBack = () => {
    props.history.goBack();
  };

  const loadBoletines = async () =>{
    try{
      const response = await ManagerNewsLatter.responseGetListBoletinesByCategory(id);
      if (response.statusCode === 200) {
        setListBoletines([...response.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadCategory = async () => {
    try{
      const response = await ManagerNewsLatter.responseGetListCategory();
      if(response.statusCode === 200) {
        const { data } = response;
        const category = data.find(d => d.idContenido === id);
        setCategorySelected(category);
      }

    }catch (error){
      console.error(error);
    }
  };

  const onHandleBoletin = (value, attribute) => {
    boletinSelected[attribute] = value;
    setBoletinSelected({ ...boletinSelected });
  };

  const onSelectBoletin = (boletin) => {
    setBoletinSelected(boletin);
    setBoletinOriginal({ ...cloneDeep(boletin) });
  };

  const onDeleteBoletin = async (boletin) => {
    try{
      const response = await ManagerNewsLatter.responseDeleteBoletin(boletin);
      if(response.statusCode === 200) {
        await loadBoletines();
      }
    }catch (error){
      console.error(error);
    }
  }

  useEffect(() => {
    loadBoletines();
    loadCategory();
  }, []);



  return (
    <NewSletterAllBoletin
      onSelectBoletin={onSelectBoletin}
      listBoletines={listBoletines}
      goBack={goBack}
      categorySelected={categorySelected}
      boletinSelected={boletinSelected}
      onHandleBoletin={onHandleBoletin}
      onDeleteBoletin={onDeleteBoletin}
      boletinOriginal={boletinOriginal}

    />
  );
}
