export const Strings = {
  INFO_PASSWORD:
    'Si no reconoces estos datos de contacto o están desactualizados, contáctate con nuestra Central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  PASSWORD_EXCEEDED_TRY:
    'Puedes volver a intentarlo mañana. Queremos que disfrutes de Net Privada, comunícate con nuestra central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias y te ayudaremos con gusto.',
  PASSWORD_EXCEEDED_TRY_TITlE: 'Excediste los intentos en el día',
  INFO_PHONE:
    'Si no reconoces este número, contáctate con nuestra Central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  INFO_EMAIL:
    'Si no reconoces este e-mail, contáctate con nuestra Central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  USER_NOT_VALID:
    'No te encontramos en nuestra base de datos por favor ponte en contacto con nuestra Central de Servicio al Cliente: (01) 391-3000 Lima y 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  USER_INVALID_POLICIES:
    'Hola,\n' +
    'No te encontramos en nuestra base de clientes activos. Te invitamos a conocer más de nuestros seguros en www.protectasecurity.pe o si lo prefieres comunícate a nuestra Central de Servicio al Cliente al 391-3000 en Lima o 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  USER_NOT_FOUND: 'Lo sentimos, no encontramos un usuario con ese DNI, Te invitamos a registrarte.',
  USER_INCOMPLETE:
    'Necesitamos contar con un correo electrónico o un número de celular registrado en nuestra base de datos para continuar con el proceso. Ponte en contacto con nuestra Central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  USER_COMPLETED: 'Ya se encuentra registrado en la plataforma Net Privada.',
  USER_ERROR_TIME:
    'Lamentablemente este canal es exclusivo para asegurados y contratantes. Si deseas más información puedes contactarte al teléfono 01 3913005 o al correo canalcorporativo@protectasecurity.pe',
  ERROR_MESSAGE_LOGIN:
    'La contraseña ingresada no es válida. Verifícala e intenta de nuevo, o dale click en “Olvidé mi contraseña”.',

  ERROR_REGISTER_BUSINESS: 'Error al realizar el registró de la empresa', // todo mensaje
  ERROR_GET_URL: 'Error al obtener la url para subir el archivo',
  ERROR_UPLOAD_FILE: 'No se pudo subir el archivo adjuntado',
  ERROR_CREATE_BUSINESS: 'Error al crear la empresa',
  USER_INVALID_ACCESS:
    'Estamos trabajando para restablecer nuestros sistemas muy pronto. Por favor ponte en contacto con nuestra Central de Servicio al Cliente: (01) 391-3000 Lima y 0-801-1-1278 Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
};
