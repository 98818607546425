import React,{ useState }  from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from "../../PSIcon";

const ContainerCard = React.forwardRef(({ keyItem, className, withOptions, children, actions, onClick, itemId}, ref) => {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div id={keyItem} key={keyItem} ref={ref} className={`container-card ${className}`}  onClick={onClick}>
      {/*{withOptions && <PSIcon type="optionCard" className="icon-options"  onClick={()=>setShowOptions(!showOptions)} />}*/}
      {/*{ showOptions &&*/}
      {/*  <div className="container-card-options">*/}
      {/*    <div className="card-options">*/}
      {/*      {*/}
      {/*        actions.map(item=> <div key={item.id} onClick={()=>item.action(itemId)}> {item.label}</div>*/}
      {/*        )*/}
      {/*      }*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*}*/}
      {children}
    </div>
    );
  }

);

ContainerCard.displayName = 'ContainerCard';

ContainerCard.defaultProps = {
  className: '',
  withOptions: false,
  actions: [],
  onClick: () => {},
  keyItem: '',
};

ContainerCard.propTypes = {
  className: PropTypes.string,
  withOptions: PropTypes.bool,
  actions: PropTypes.array,
  onClick: PropTypes.func,
  keyItem: PropTypes.string,
};

export default ContainerCard;