import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import { getCurrentYear, getLastThreeYear } from '../../../../scripts/Util';
import { menuCode, productsTitle } from '../../../shared/constants/Constants';

import ContainerShare from '../../molecules/containerShare/ContainerShare';
import DropDown from '../../molecules/dropDown/DropDown';
import DropzoneConstancy from '../../molecules/dropZoneConstancy/DropZoneConstancy';
import MonthCalendar from '../../molecules/monthCalendar/MonthCalendar';
import PSIcon from '../../PSIcon';
import PSButton from '../../PSButton';
import PSDownloadFile from '../../PSDownloadFile';
import PSModal from '../../PSModal';
import PSSelect from '../../PSSelect';
import PSLoading from '../../PSLoading';
import PSPreviewFile from '../../PSPreviewFile';
import Table from '../../organisms/table/Table';

import './index.scss';
import UserNetPrivada from '../../../shared/services/UserNetPrivada';

const getClassName = (currentTab, tabSelected) => {
  if (currentTab === tabSelected) return 'item-selected';
  return '';
};

const getTicketIds = (listTicketSelected) => {
  return listTicketSelected.map((element) => {
    return { id: element.id };
  });
};

const TabButton = ({ tabSelected, setTab, tabId, tabTitle }) => {
  return (
    <PSButton
      type="submit"
      loading={false}
      className={`normal-button ${getClassName(tabId, tabSelected)}`}
      onClick={setTab}
      id={tabId}
    >
      {tabTitle}
    </PSButton>
  );
};

const DetailProductsByUser = React.forwardRef(
  (
    {
      getAnnuityInfo,
      detail,
      downloadTicket,
      sendEmail,
      className,
      generateConstancy,
      generatePolicy,
      getComprobantePoliza,
      loadingConstancia,
      loadingPolicy,
      productSelected,
      goBack,
      loadingTicket,
      soatCertificadoB64,
      soatComprobanteBase64,
      downloadVoucher,
      loadingDownload,
      validatePensioner,
      isPensioner,
    },
    ref
  ) => {
    const [tabSelected, setTab] = useState(null);
    const [listTicketSelected, setListTicketSelected] = useState([]);
    const [showShare, setShowShare] = useState(false);
    const [currentYear, setCurrentYear] = useState(getCurrentYear());
    const [detailUserProduct, setDetailUserProduct] = useState(null);
    const [optionsBeneficiary, setOptionsBeneficiary] = useState([]);
    const [currentBeneficiary, setCurrentBeneficiary] = useState('');
    const beneficary = useSelector((state) => state.beneficary);
    const [temporalElement, setTemporalElement] = useState(null);
    const [isSoat, setIsSoat] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userCreate , setUserCreate] = useState({});
    const isRV = productSelected.menuId === menuCode.RENTA_VITALICIA;
    const isVDP = productSelected.menuId === menuCode.VIDA_DEVOLUCION;
    const isVCF = productSelected.menuId === menuCode.VIDA_CREDITO_FACIL;
    const productsDownloadPolicy = [
      menuCode.AHORRO_TOTAL,
      menuCode.RENTA_TOTAL,
      menuCode.VIDA_DEVOLUCION,
      menuCode.DESGRAVAMEN,
      menuCode.ACCIDENTES_PERSONALES,
    ].includes(productSelected.menuId);
    const nameConstancia = isRV ? 'Constancia de Pensionista' : 'Constancia de Asegurado';
    const files = [
      { id: '00001', name: isRV ? 'Constancia_pensionista' : 'Constancia_asegurado', date: '2022' },
    ];
    const isContractor = detail.rol === 'Contratante';
    const parentTabs = useRef();
    const location = useLocation();
    const pathname = location.pathname;
    const match = pathname.match(/\/products\/([^\/]+)/);
    const contactDocument = match?.[1];

    const verifyUser = async () => {

        if (contactDocument) {
            try {
                const contactUser = await UserNetPrivada.doValidateUserNatural(contactDocument);
                if (contactUser) setUserCreate(contactUser);
            } catch (e) {
                console.error(e);
            }

        }
    };
    const validationsTabOptions = () => {
      const listTabs = parentTabs?.current?.children || [];
      const listTabOptions = Array.from(listTabs).filter((e) =>
        e.classList.contains('normal-button')
      );
      const childrentTabs = listTabOptions?.length || 0;
      const firstTabOption = listTabOptions[0]?.id;
      return { childrentTabs, firstTabOption };
    };
    const { childrentTabs, firstTabOption } = validationsTabOptions();
    const showPaymentVoucher =
      process.env.REACT_APP_ALL_PRODUCTS_SHOW_COMPROBANTE_PAGO === 'true' &&
      ![menuCode.AHORRO_TOTAL, menuCode.RENTA_TOTAL, menuCode.RENTA_VITALICIA].includes(
        productSelected.menuId
      );

    useEffect(() => {
      if (firstTabOption) {
        setTab(firstTabOption);
        if (firstTabOption === 'comprobante') getComprobantePoliza();
      }
    }, [childrentTabs]);

    const setCurrentTab = (item) => {
      setTab(item.currentTarget.id);
      if (item.currentTarget.id === 'comprobante') {
        getComprobantePoliza();
      }
    };

    const addTicketToList = (isSelected, ticket) => {
      if (!isSelected) {
        setListTicketSelected([...listTicketSelected, ticket]);
      } else {
        const filterListTickets = filter(listTicketSelected, (list) => {
          return list.id !== ticket.id;
        });
        setListTicketSelected([...filterListTickets]);
      }
    };

    const getCurrentAnnuityInfo = async () => {
      setLoading(true);
      const list = await getAnnuityInfo(currentYear, detail.nroPolicy);
      setDetailUserProduct([...list]);
      const optionsBeneficiario = map(groupBy(list, 'beneficiaryName'), (item, index) => {
        return { value: index, label: item[0].beneficiaryName };
      });
      optionsBeneficiario.unshift({ value: '', label: 'Seleccione beneficiario' });
      setOptionsBeneficiary([...optionsBeneficiario]);
      setLoading(false);
    };

    const getCurrentListTickets = () => {
      return filter(detailUserProduct, (list) => {
        return optionsBeneficiary.length > 2
          ? list.year === currentYear.toString() &&
              list.beneficiaryName.toString() === currentBeneficiary
          : list.year === currentYear.toString();
      });
    };

    const setCurrentYearAndTicketList = (year) => {
      setCurrentYear(year);
      setListTicketSelected([]);
    };

    const downloadTicketAndSetTemporalItem = (ticket) => {
      downloadTicket(ticket);
      setTemporalElement(ticket);
    };
    useEffect(() => {
       verifyUser();
    }, []);
    useEffect(() => {
      getCurrentAnnuityInfo();
    }, [currentYear]);

    useEffect(() => {
      if (productSelected.menuId === menuCode.SOAT) {
        setIsSoat(true);
      }
    }, [productSelected]);

    useEffect(() => {
      if (isRV) {
        validatePensioner(detail.nroPolicy);
      }
    }, [detail]);

    return (
      <div className={`container-detail-information ${className} `} ref={ref}>
        <div className="container-title">
          <div className="d-flex" onClick={goBack}>
            <PSIcon type="arrowBack" className="icon-arrow-back" />
          </div>
          {/* <div className="title">{productSelected?.name}</div> */}
          <div className="item-info-basic">
            {/* <div className="info-photo">
                      <PSIcon type="profile"/>
                    </div>   */}
            <div>
              <h2 className="info-name">{beneficary.name}</h2>
              <div className="info-mail">
                {beneficary.tipoDoc} {beneficary.nroDoc}
              </div>
            </div>
          </div>
        </div>

        <div id="containerProductDetails" className="container-product-details">
          <div className="table-title">{productSelected?.name}</div>
        </div>

        <div className="container-tabs" ref={parentTabs}>
          {productsTitle[productSelected.menuId] && (
            <TabButton
              tabSelected={tabSelected}
              setTab={setCurrentTab}
              tabId="boletas"
              tabTitle={productsTitle[productSelected.menuId]}
            />
          )}

          {((!isSoat && !isVDP && !isRV && !isContractor) || (isRV && isPensioner) || isVCF) && (
            <TabButton
              tabSelected={tabSelected}
              setTab={setCurrentTab}
              tabId="constancias"
              tabTitle={nameConstancia}
            />
          )}
          {(showPaymentVoucher || isSoat) && (
            <TabButton
              tabSelected={tabSelected}
              setTab={setCurrentTab}
              tabId="comprobante"
              tabTitle="Comprobante de pago"
            />
          )}
          {productsDownloadPolicy && (
            <TabButton
              tabSelected={tabSelected}
              setTab={setCurrentTab}
              tabId="poliza"
              tabTitle="Descarga de póliza"
            />
          )}
          {!isSoat && tabSelected === 'boletas' && (
            <div className="right-element">
              Compartir
              <PSIcon
                type="mail"
                className={listTicketSelected.length <= 0 ? 'icon-disabled' : ''}
                onClick={() => {
                  if (listTicketSelected.length > 0) setShowShare(true);
                }}
              />
            </div>
          )}
        </div>
        <div className="body-tab">
          {childrentTabs === 0 && (
            <div>
              <span>No se encontraron opciones disponibles</span>
            </div>
          )}
          {tabSelected === 'boletas' && (
            <div className="container-boletas">
              <div className="sub-container-left">
                {!isSoat && (
                  <div className="select-box">
                    <DropDown
                      value="2021"
                      className="select-class"
                      optionText="Año"
                      options={getLastThreeYear()}
                      selectedValue={currentYear}
                      onClick={(e) => setCurrentYearAndTicketList(e.target.value)}
                    />
                    <div className="section-title-second-part">
                      {optionsBeneficiary.length > 2 && (
                        <PSSelect
                          className="select-beneficiary"
                          options={optionsBeneficiary}
                          selectedValue={currentBeneficiary}
                          onChange={(e) => setCurrentBeneficiary(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className="container-months">
                  {isSoat &&
                    (!soatCertificadoB64.loading ? (
                      <PSPreviewFile
                        fileBase64={soatCertificadoB64.base64}
                        hasError={soatCertificadoB64.hasError}
                        titleDocument="Certificado Digital"
                        typeDocument="certificado digital"
                      />
                    ) : (
                      <PSLoading color="default" />
                    ))}
                  <div className="list-months">
                    {getCurrentListTickets().map((element) => (
                      <MonthCalendar
                        className={`month-calendar ${
                          listTicketSelected.findIndex((ticket) => ticket.id === element.id) !== -1
                            ? 'month-selected'
                            : ''
                        }`}
                        title={element.description}
                        date={`${element.month}/${element.year}`}
                        ticket={element}
                        listTicketSelected={listTicketSelected}
                        selectCheck={(isSelected) => addTicketToList(isSelected, element)}
                        keyItem={element.id}
                        download={() => downloadTicketAndSetTemporalItem(element)}
                        isLoading={loadingTicket}
                        temporalElement={temporalElement}
                      />
                    ))}
                  </div>
                  {!loading &&
                    detailUserProduct !== null &&
                    getCurrentListTickets().length === 0 &&
                    !isSoat && (
                      <div>
                        <span>
                          No hemos encontrado registros disponibles en el periodo seleccionado
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
          {tabSelected === 'constancias' && (
            <div className="container-constancias">
              <div className="container-upload d-none">
                <DropzoneConstancy />
              </div>
              {loadingConstancia ? (
                <PSLoading color="orange" />
              ) : (
                <div className="constancias-body">
                  {files.map((file, index) => (
                    <div className="container-file">
                      <PSDownloadFile
                        nameFile={file.name}
                        date={file.date}
                        className="downloadFile"
                        onClick={generateConstancy}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {tabSelected === 'comprobante' && (
            <div>
              {!soatComprobanteBase64.loading ? (
                <Table
                  title="Listado de Comprobantes de Pago"
                  messageEmptyList="No se encontraron Comprobantes de Pago relacionados a esta póliza"
                  hasError={soatComprobanteBase64.hasError}
                  titleDocument="Comprobante de Pago"
                  typeDocument="comprobante de pago"
                  header={soatComprobanteBase64.header}
                  data={soatComprobanteBase64.listComprobantes}
                  filter={soatComprobanteBase64.filter}
                  downloadFile={downloadVoucher}
                  loadingFile={loadingDownload}
                />
              ) : (
                <PSLoading color="default" />
              )}
            </div>
          )}
          {tabSelected === 'poliza' && (
            <div className="container-constancias">
              {loadingPolicy ? (
                <PSLoading color="orange" />
              ) : (
                <div className="constancias-body">
                  <div className="container-file">
                    <PSDownloadFile
                      nameFile="Póliza_digital"
                      date="2022"
                      className="downloadFile"
                      onClick={generatePolicy}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {showShare && (
          <PSModal open={showShare} onClose={() => setShowShare(false)}>
            <ContainerShare
              user={userCreate}
              send={sendEmail}
              ticketIds={getTicketIds(listTicketSelected)}
              closeModal={() => setShowShare(false)}
            />
          </PSModal>
        )}
      </div>
    );
  }
);

DetailProductsByUser.displayName = 'DetailProductsByUser';

DetailProductsByUser.defaultProps = {
  className: null,
  user: null,
  getAnnuityInfo: () => {},
  detail: null,
  downloadTicket: () => {},
  sendEmail: () => {},
  generateConstancy: () => {},
  generatePolicy: () => {},
  loadingConstancia: false,
  loadingPolicy: false,
  productSelected: null,
  soatCertificadoB64: {},
};

DetailProductsByUser.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  getAnnuityInfo: PropTypes.func,
  detail: PropTypes.object,
  downloadTicket: PropTypes.func,
  sendEmail: PropTypes.func,
  generateConstancy: PropTypes.func,
  generatePolicy: PropTypes.func,
  loadingConstancia: PropTypes.bool,
  loadingPolicy: PropTypes.bool,
  productSelected: PropTypes.object,
  soatCertificadoB64: PropTypes.object,
  soatComprobanteBase64: PropTypes.object,
};

export default DetailProductsByUser;
