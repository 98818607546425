import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { DetailRequest, ItemsListBrowser, Step } from '../../organisms';
import {
  ContractReview,
  ContractFile,
  ContainerCardContract,
  RequestComment,
} from '../../molecules/index';

import { pdfItemFile } from '../../molecules/itemFile/Constants';
import { Button, Comment, CommentInput } from '../../atoms';
import PSIcon from '../../PSIcon';

const ContractRequest = React.forwardRef(({ listResponsible, files, comments }, ref) => {
  return (
    <div className="container-contractRequest">
      <div className="d-flex">
        <div style={{ width: '30%' }}>
          <span>Contenedor</span>
        </div>
        <div style={{ width: '70%' }}>
          <Step />
        </div>
      </div>
      {/* <div>
        <ContainerCardContract>
          <DetailRequest />
        </ContainerCardContract>
        <ContainerCardContract className="mt-1">
          <h3>Comentarios</h3>
          <CommentInput />
          <div className="previousComments">
            <span>Ver comentarios anteriores</span>
            <PSIcon type="arrowDown" />
          </div>
          <div>
            {comments.map((item) => {
              return <RequestComment key={item} />;
            })}
          </div>
        </ContainerCardContract>
      </div> */}
      {/* <div>
        <ContractReview
          listResponsible={listResponsible}
          assigned="16/02/2021"
          delivered="16/02/2021"
        />
        <ContainerCardContract className="mt-1">
          <h3>Contrato</h3>
          <div>
            {files.map((item) => {
              return (
                <ContractFile
                  key={item}
                  icon={pdfItemFile.icon}
                  fileName="Contratov1.pdf"
                  uploadDate="19/03/21"
                />
              );
            })}
          </div>
          <div className="seeAll">
            <span>Ver todos</span>
          </div>
        </ContainerCardContract>
      </div> */}
    </div>
  );
});

ContractRequest.displayName = 'ContractRequest';

ContractRequest.defaultProps = {
  listResponsible: [],
  files: [],
  comments: [],
};

ContractRequest.propTypes = {
  listResponsible: PropTypes.array,
  files: PropTypes.array,
  comments: PropTypes.array,
};

export default ContractRequest;
