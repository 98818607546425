import React, { useEffect, useState } from 'react';
import './index.scss';
import { NewSletterCategory, NewSletterGeneral } from '../../components/templates';

export default function NewSletter(props) {
  const [page, setPage] = useState('general');
  useEffect(() => {
    const { path } = props.match;
    if (path.includes('category')) {
      setPage('category');
    } else {
      setPage('general');
    }
  }, []);

  switch (page) {
    case 'general':
      return (<NewSletterGeneral/>)
    case 'category':
      return (<NewSletterCategory/>)
    default:
      return (<NewSletterGeneral/>)
  }
}
