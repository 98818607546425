import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import PSIcon from '../../PSIcon';
import { Contracts } from './Constants';

const ContractsTree = React.forwardRef(({ className }, ref) => {
  const [active, setActive] = useState(null);
  return (
    <div className={`contracts-tree_container ${className}`} ref={ref}>
      <div className="contracts-tree_header d-flex">
        <div className="contractor_title">
          <h3>Folders ({Contracts.length - 2})</h3>
        </div>
        <div className="contractor-options d-flex">
          <div className="upload-icon">
            <PSIcon type="fileUpload" />
          </div>
          <div>
            <PSIcon type="newFolder" />
          </div>
        </div>
      </div>
      <div className="contracts-list_container">
        {Contracts.map((item, index) => (
          <div key={item.id} className="contract-item" onClick={() => {}}>
            <div
              className="d-flex w-100"
              onClick={() => {
                setActive(index);
              }}
            >
              <PSIcon type={item.icon} className="icon-container" />
              <p>{item.name}</p>
              {item.treeItems && (
                <PSIcon className="ml-auto" type={item.treeItems ? 'rightArrow' : 'bottomArrow'} />
              )}
            </div>
            {active === index && item.treeItems && (
              <div className="content-children">
                {item.treeItems.map((c) => (
                  <div className="d-flex">
                    <PSIcon type={c.icon} className="icon-container" />
                    <p>{c.name}</p>
                  </div>
                ))}
              </div>
            )}
            <div />
          </div>
        ))}
      </div>
    </div>
  );
});

ContractsTree.displayName = 'ContractsTree';

ContractsTree.defaultProps = {
  className: '',
};

ContractsTree.propTypes = {
  className: PropTypes.string,
};

export default ContractsTree;
