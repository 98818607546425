import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import "./index.scss";

const SelectCustom = React.forwardRef(({ name, size, isMulti, isAsync, isClearable, isSearchable, isDisabled, isLoading, isRtl, minMenuHeight, maxMenuHeight, menuPlacement, placeholder, options, defaultValue, formatGroupLabel ,className , ...props}, ref) => (
  <div ref={ref} className={`select ${className} ${size}`}>
    { isAsync
      ?<AsyncSelect
        cacheOptions
        loadOptions={options}
        defaultOptions
      />
      :<Select
        className="basic-single"
        name={name}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isRtl={isRtl}
        minMenuHeight={minMenuHeight}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        options={options}
        defaultValue={defaultValue}
        formatGroupLabel={formatGroupLabel}
        {...props}
      />
    }
  </div>
));

SelectCustom.displayName = 'Select';

SelectCustom.defaultProps = {
  className: null,
  name: 'select-defauld',
  size: 'medium',
  isMulti: false,
  isAsync: false,
  isSearchable: false,
  isClearable: false,
  isDisabled: false,
  isLoading: false,
  isRtl: false,
  minMenuHeight: '140px',
  maxMenuHeight: '300px',
  menuPlacement: 'bottom',
  placeholder: 'Select...',
  options: [{label: 'item1', value: 'item 1'},{label: 'item2', value: 'item 2'}],
  defaultValue: [],
  formatGroupLabel: null,
};

SelectCustom.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  isMulti: PropTypes.bool,
  isAsync: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRtl: PropTypes.bool,
  minMenuHeight: PropTypes.string,
  maxMenuHeight: PropTypes.string,
  menuPlacement: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.array,
  formatGroupLabel: PropTypes.node,
};

export default SelectCustom;
