import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import PSIcon from './PSIcon';
import { FIELD_NEXTTIME_LOGIN, pathServer } from '../shared/constants/Constants';
import { logout } from '../redux/ducks/user-loginDuck';
import { Routes } from '../../routes';
import { STORAGE_TOKEN_COGNITO } from '../../scripts/Constants';

const userDefault = {
  given_name: '',
  middle_name: '',
};

const StyledPSHeaderHome = styled.div`
  display: flex;
  justify-content: flex-end;
  .container-info {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .info-name {
      color: #002655;
      font-size: 17px;
      text-align: right;
    }
    .info-rol {
      color: #de6d19;
    }
  }
  .container-icons {
    margin: 1rem;
    display: flex;
    .icon {
      margin: 10px;
    }
    .avatar {
      cursor: pointer;
    }
    img {
      width: 40px;
      height: 40px;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
      path {
        fill: #002655;
      }
    }
    .container-dropdown {
      background: white;
      position: absolute;
      box-shadow: 5px 5px 10px #004eaf12;
      right: 3%;
      top: 50px;
      width: 200px;
      ul {
        margin: 10px 0;
        padding: 0;
      }
      li {
        padding: 5px;
        list-style: none;
        &:hover {
          background: #de6d1936;
        }
      }
      a {
        padding: 0 2px;
        color: #002655;
        text-decoration: none;
      }
    }
  }
  .logout {
    margin: 10px;
  }
`;

const PSHeaderHome = (props) => {
  const [showNavBar, setShowNavVar] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user) || userDefault;
  const singOut = () => {
    dispatch(logout());
    localStorage.removeItem(STORAGE_TOKEN_COGNITO);
    localStorage.removeItem(FIELD_NEXTTIME_LOGIN);
    localStorage.clear();
    history.push(Routes.LOGIN);
  };
  const goToMainModal = () => {
    history.push(`${Routes.HOME}`);
  };
  return (
    <StyledPSHeaderHome>
      <div className="container-info">
        <div className="info-name">{`${user.given_name} ${user.middle_name}`}</div>
        <div className="info-rol">Administrador</div>
      </div>
      <div className="container-icons">
        <PSIcon type="dashboard" className="icon" />
        <PSIcon type="notification" className="icon" />
        <PSIcon type="chat" className="icon" />
        <img
          className="avatar"
          alt=""
          src={`${pathServer.PATH_IMG}user.png`}
          onClick={goToMainModal}
        />
        <div className="logout">
          <FontAwesomeIcon
            className="d-pointer"
            icon={showNavBar ? faCaretUp : faCaretDown}
            onClick={() => setShowNavVar(!showNavBar)}
          />
          {showNavBar && (
            <div className="container-dropdown">
              <ul>
                <li>
                  <a href="#">Mi Perfil</a>
                </li>
                <li>
                  <a href="#" onClick={() => singOut()}>
                    {' '}
                    Cerrar sesión
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </StyledPSHeaderHome>
  );
};

PSHeaderHome.propTypes = {};

PSHeaderHome.defaultProps = {};

export default PSHeaderHome;
