//CONSTANTES

const dataInicial = {
  results : []
}
const EXAMPLE_INFO_SUCCESS ='EXAMPLE_INFO_SUCCESS'


//REDUCER

export default function exampleReducer( state = dataInicial , action ) {
  switch (action.type) {
    case EXAMPLE_INFO_SUCCESS:
      return ({...state, ...action.payload})
    default :
      return state
  }
}


//ACCIONES

export const loadInformation = async ( dispatch, getState ) => {
  try {
    const res = {data:'test'};
    dispatch({
      type : EXAMPLE_INFO_SUCCESS,
      payload:res.data
    })
  }catch(error){
    //manejaremos el error
  }
}