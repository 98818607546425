import React,{ useState }  from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import {CardCalendar} from "../../molecules";
import PSIcon from "../../PSIcon";

const ListMonthCardCalendar = React.forwardRef(({ className, listMounts, indexSelect, selectMonth}, ref) => {

  return (
      <div ref={ref} className={`container-list-mount ${className}`}>
        <div className="container-list-cardCalendar" >
          {
            listMounts.map((item, index) =>
              <CardCalendar
                id={index+1}
                key={index} src={item.src}
                month={item.month}
                className={`cardItem ${indexSelect===index?'cardItem__selected':''}`}
                onClick={()=>selectMonth(index)}
              />
            )
          }
        </div>
      </div>
    );
  }
);

ListMonthCardCalendar.displayName = 'ListMonthCardCalendar';

ListMonthCardCalendar.defaultProps = {
  className: '',
  indexSelect:6,
};

ListMonthCardCalendar.propTypes = {
  className: PropTypes.string,
  indexSelect: PropTypes.number,
};

export default ListMonthCardCalendar;