export function verifyPathIncludesOption(props, content) {
  if(content) {
    let flag = false;
    content.options.forEach((item) => {
      if(item){
        const path = props.location.pathname;
        if(path.includes(item.url)){
          flag = true;
          return;
        }
      }
    });
    return flag;
  }
  return null;
}