import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PSModal from '../../PSModal';
import { Button } from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';

import './index.scss';

const ModalStyles = { modal: 'customModalConfirm' };
const ModalConfirm = React.forwardRef(
  (
    {
      className,
      title,
      question,
      openModal,
      onCancel,
      onAccept,
      buttonCancel,
      buttonAccept,
      onClose,
    },
    ref
  ) => {
    return (
      <div ref={ref}>
        <PSModal open={openModal} showCloseIcon={false} classNames={ModalStyles} onClose={onClose}>
          <div className={`container-modal-confirm ${className}`}>
            <div className="container-modal-confirm__info">
              <div className="info-title">{title}</div>
              <div className="info-question">{question}</div>
            </div>
            <div className="container-modal-confirm__container-buttons">
              <Button type={ButtonColor.white} onClick={onCancel}>
                {buttonCancel}
              </Button>
              <Button onClick={onAccept}>{buttonAccept}</Button>
            </div>
          </div>
        </PSModal>
      </div>
    );
  }
);

ModalConfirm.displayName = 'ModalConfirm';

ModalConfirm.defaultProps = {
  className: '',
  title: 'Eliminar Categoría',
  question: '¿Estás seguro que deseas eliminar la categoría?',
  buttonCancel: 'Cancelar',
  buttonAccept: 'Si',
  onCancel: () => {},
  onAccept: () => {},
  onClose: () => {},
  openModal: false,
};

ModalConfirm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  question: PropTypes.string,
  buttonCancel: PropTypes.string,
  buttonAccept: PropTypes.string,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  openModal: PropTypes.bool,
};

export default ModalConfirm;
