import React from 'react';
import './index.scss';
import PSIcon from '../../PSIcon';

export default function Task({ className, task, status, name, user }) {
  return (
    <div className={`container-taskComponent ${className}`}>
      <div className="container-taskTitle">
        <div>
          <span className="task-title">Tarea {task}</span>
          <div>
            <span className="label-status">Estado</span>
            <span className="label-status task-status">{status}</span>
          </div>
        </div>
        <div>
          <PSIcon type="arrowsDown" />
        </div>
      </div>
      <p className="task-name">{name}</p>
      <div className="container-taskUser">
        <PSIcon type="taskUser" />
        <span className="task-user">{user}</span>
      </div>
      <div className="container-taskFooter">
        <PSIcon type="attachFile" />
        <span className="task-footer">3</span>
        <PSIcon type="comments" />
        <span className="task-footer">23</span>
        <PSIcon type="comments" />
        <span className="task-footer">6 días</span>
      </div>
    </div>
  );
}
