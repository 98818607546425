import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSLoading from '../../PSLoading';
import PSIcon from '../../PSIcon';

export const ButtonColor = {
  blue: 'button-blue',
  orange: 'button-orange',
  white: 'button-white',
  red: 'button-red',
  green: 'button-green',
  orangeOutline: 'button-orange-outline',
  redOutline: 'button-red-outline',
  blackPurple: 'button-blackPurple',
  orangeOutlinePurpleText: 'button-orange-outline-purple-text',
};

const Button = React.forwardRef(
  ({ type, className, onClick, children, icon, disabled, loading, ...props }, ref) => (
    <div
      ref={ref}
      className={`container-button  ${type} ${className} ${loading && 'loading'} ${
        icon && 'button-icon'
      } `}
    >
      {icon && <PSIcon type={icon} onClick={onClick} />}
      <button onClick={onClick} disabled={loading || disabled} {...props}>
        {loading ? <PSLoading width={25} height={25} /> : children}
      </button>
    </div>
  )
);

Button.displayName = 'Button';

Button.defaultProps = {
  className: '',
  onClick: () => {},
  type: ButtonColor.blue,
  loading: false,
  disabled: false,
  icon: null,
};

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
};

export default Button;
