import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import PSLoading from '../../PSLoading';

const MonthCalendar = React.forwardRef(
  (
    {
      ticket,
      className,
      title,
      date,
      selectCheck,
      download,
      productType = 'rrvv',
      isLoading,
      listTicketSelected,
      temporalElement,
    },
    ref
  ) => {
    const ticketFound = listTicketSelected.findIndex((element) => element.id === ticket.id);
    return (
      <div
        id={ticket.id}
        ref={ref}
        className={`container-month-calendar ${className}`}
        onClick={() => selectCheck(ticketFound !== -1, ticket)}
      >
        <div className="container-header">
          <div
            className={`check-select ${ticketFound !== -1 ? 'check-selected' : ''}`}
            onClick={() => selectCheck(ticketFound !== -1, ticket)}
          />
          {ticket && ticket.type === 'M' ? (
            <span className="regularizacion"> Regularización</span>
          ) : (
            <span className="mensual"> Mensual</span>
          )}
        </div>
        <div className="title-month">{title}</div>
        <div className="container-date">
          <div className="label-date">Fecha</div>
          <div className="d-flex">
          <div className="date">{date}</div>
          {
            (isLoading && temporalElement.id === ticket.id) &&
            <div className="align-center-end"><PSLoading color="default" width={15} height={15}/></div>
          }
        </div>
        {
            (!isLoading || temporalElement.id !== ticket.id) &&
            <PSIcon type="download" onClick={(e)=>{e.stopPropagation(); download()}} />
        }
      </div>
    </div>
    );
  }
);

MonthCalendar.displayName = 'MonthCalendar';

MonthCalendar.defaultProps = {
  className: '',
  title: '',
  date: '',
  selectCheck: () => {},
};

MonthCalendar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  selectCheck: PropTypes.func,
};

export default MonthCalendar;
