import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import PSDynamicTable from '../../../components/PSDynamicTable';
import UserNetPrivada from '../../../shared/services/UserNetPrivada';
import {
  findByAtribute,
  formatDate,
  getorigin,
  getPdp,
  getTypeDocument,
  isEmptyArray,
  swapMonthsDay,
  capitalize,
} from '../../../../scripts/Util';
import { Routes } from '../../../../routes';
import { ContainerCard, HeaderSection } from '../../../components/molecules';
import PSModal from '../../../components/PSModal';
import { CreateUserNatural } from '../../../components/templates';
import {
  HEADERS_FIELDS_CSV,
  NAME_FILE,
  showErrorToast,
  showSuccessToast,
} from '../../../shared/constants/Constants';
import {
  SUCESS_CODE_API,
  TYPE_DOCUMENT_CARNE,
  TYPE_DOCUMENT_DNI,
} from '../../../../scripts/Constants';
import { saveUserBeneficiario } from '../../../redux/ducks/user-beneficary';
import { ModalConfirm } from '../../../components/organisms';
import { Button } from '../../../components/atoms';
import { ButtonColor } from '../../../components/atoms/button/Button';
import PSIcon from '../../../components/PSIcon';

const headerTable = [
  { elementProp: 'tipoDoc', headerColumn: 'TIPO DOC', width: '5%' },
  { elementProp: 'nroDoc', headerColumn: 'N° DOC', width: '8%' },
  { elementProp: 'phone', headerColumn: 'TELÉFONO', width: '8%' },
  { elementProp: 'pdp', headerColumn: 'PROMOCIONES', width: '5%' },
  { elementProp: 'origin', headerColumn: 'ORIGEN', width: '8%' },
];

const MAX_PER_PAGE = 60;
const defaultFilters = {
  value: null,
  attribute: null,
};
export default function NaturalUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const beneficary = useSelector((state) => state.beneficary);

  const [userOperation, setUserOperation] = useState(false);
  const [listUser, setListUser] = useState([]);
  const [listUserOriginal, setListUserOriginal] = useState([]);
  const [listToExport, setListToExport] = useState([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [showModalForm, setShowModalForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [userCreate, setUserCreate] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showModalFindUser, setShowModalFindUser] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const itemsIterable = Math.ceil(listUser.length / MAX_PER_PAGE);
  const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;
  const isTablet = window.matchMedia('only screen and (max-width: 920px)').matches;
  const listPaginate = listUser.slice(
    (pageSelected - 1) * MAX_PER_PAGE,
    pageSelected * MAX_PER_PAGE
  );

  const textConfirmModal = {
    messageBlock: {
      text: '¿Estás seguro que deseas bloquear a este usuario?',
      title: 'Bloquear usuario',
    },
    messageUnblock: {
      text: '¿Estás seguro que deseas desbloquear a este usuario?',
      title: 'Desbloquear usuario',
    },
    messageDelete: {
      text: '¿Estás seguro que deseas eliminar a este usuario?',
      title: 'Eliminar usuario',
    },
  };

  const verifyUser = async (document, typeDocument) => {
    if (document) {
      const fullDocument = typeDocument + document;
      try {
        setLoading(true);
        const data = await UserNetPrivada.doValidateUserNatural(fullDocument);
        if (data) {
          const responseCognito = await UserNetPrivada.validateUserDigitalStatus(
            document,
            typeDocument
          );
          if (responseCognito === 'INVALID_USER') {
            const responseConditions = await UserNetPrivada.validateUserConditions(
              document,
              typeDocument
            );

            switch (responseConditions) {
              case 'VALID_USER':
                setUserCreate(data);
                break;
              case 'NO_DATA_USER':
                showErrorToast('Usuario no cuenta con correo o email');
                break;
              case 'USER_INVALID_POLICIES':
                showErrorToast('Usuario no cuenta con pólizas vigentes');
                break;
              default:
                showErrorToast('Problemas al validar usuario');
            }
          } else {
            showErrorToast('Usuario ya registrado en Net Privada');
          }
        } else {
          showErrorToast('Usuario no encontrado');
        }
        setLoading(false);
      } catch (e) {
        showErrorToast('Error, intente luego');
        setLoading(false);
      }
    } else {
      showErrorToast(`Ingrese el ${getTypeDocument(typeDocument?.toLowerCase())}`);
    }
  };

  const searchUser = async (document, typeDocument) => {
    if (document) {
      const fullDocument = typeDocument + document;
      try {
        setLoading(true);
        const data = await UserNetPrivada.doValidateUserNatural(fullDocument);
        if (data) {
          const responseCognito = await UserNetPrivada.validateUserDigitalStatus(
            document,
            typeDocument.toUpperCase()
          );
          if (responseCognito === 'INVALID_USER') {
            const fullName = `${data.lastName}  ${data.lastName2} ${data.names}`;
            setUserCreate({
              ...data,
              legalName: data.legalName ? data.legalName : fullName.toUpperCase(),
            });
          } else {
            showErrorToast('Usuario ya registrado en Net Privada');
          }
        } else {
          showErrorToast('Usuario no encontrado');
        }
        setLoading(false);
      } catch (e) {
        showErrorToast('Error, intente luego');
        setLoading(false);
      }
    } else {
      showErrorToast(`Ingrese el ${getTypeDocument(typeDocument?.toLowerCase())}`);
    }
  };

  const verifyUserCognito = async (document, typeDocument, user) => {
    try {
      setLoading(true);
      const responseCreate = await UserNetPrivada.createUserNatural(typeDocument, document, user);
      if (responseCreate === 'VALID_USER_CREATE') {
        showSuccessToast('Usuario creado');
        await getListUser();
        setShowModalForm(false);
        setUserCreate(null);
      } else {
        showErrorToast('No se pudo crear el usuario');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onHandleInput = (value, name) => {
    if (name) {
      userCreate[name] = value;
      setUserCreate({ ...userCreate });
    }
  };

  const mapResponseUserToTable = (listUsers) => {
    return listUsers.map((item) => {
      const fullName = `${findByAtribute(
        item.Attributes,
        'given_name'
      ).toLowerCase()} ${findByAtribute(
        item.Attributes,
        'middle_name',
        ' '
      ).toLowerCase()} ${findByAtribute(item.Attributes, 'family_name', ' ').toLowerCase()}`;

      return {
        mail: findByAtribute(item.Attributes, 'email'),
        createDate: formatDate(item.UserCreateDate),
        updateDate: formatDate(item.UserLastModifiedDate),
        name: capitalize(fullName.trim()),
        phone: findByAtribute(item.Attributes, 'phone_number'),
        userName: item.Username,
        nroDoc: item.Username ? item.Username.substring(1, item.Username.length).toUpperCase() : '',
        tipoDoc: getTypeDocument(item.Username ? item.Username.substring(0, 1) : ''),
        status: item.UserStatus === 'CONFIRMED' ? 'Activo' : 'Pendiente de Confirmar',
        enabled: item.Enabled,
        enabledText: item.Enabled ? 'Habilitado' : 'Bloqueado',
        pdp: getPdp(findByAtribute(item.Attributes, 'custom:pdp_net_privada', 'false')),
        origin: getorigin(findByAtribute(item.Attributes, 'custom:aap_origin')),
      };
    });
  };

  const getListUser = async (resetFilter = false) => {
    try {
      setLoadingUsers(true);
      const headers = { 'customer-type': 'natural' };
      const response = await UserNetPrivada.responseGetUsersNetPrivada(headers);
      const { value, attribute } = filters;
      if (response.status.id === 200) {
        const users = mapResponseUserToTable(response.data);
        const listUsersFormat = users.map((user) => {
          return { ...user, nroDoc: `'${user.nroDoc}`, name: user.name };
        });
        setListToExport(listUsersFormat);
        setListUserOriginal(users);
        if (resetFilter || attribute == null) {
          setListUser(users);
        } else if (attribute !== null) {
          attribute === 'document' ? onSearchByDoc(value, users) : onSearchCalendar(value, users);
        }
      }
      setLoadingUsers(false);
    } catch (error) {
      console.log('error', error);
      setLoadingUsers(false);
    }
  };

  const onSearchCalendar = (date, newList) => {
    setFilters({ attribute: 'date', value: date });
    const dateFrom = date[0].startDate;
    const dateTo = date[0].endDate;
    const listUsers = newList || listUserOriginal;
    const users = listUsers.filter((l) => {
      const actualDate = swapMonthsDay(l.createDate);
      return (
        actualDate >= swapMonthsDay(formatDate(dateFrom)) &&
        actualDate <= swapMonthsDay(formatDate(dateTo))
      );
    });
    if (isEmptyArray(users)) {
      showErrorToast('Registros no encontrados');
    }
    setListUser(users);
  };
  const selectUser = async (user) => {
    history.push(`${Routes.NET_NATURAL_USER_PRODUCTS}/${user?.userName}`);
    const data = await UserNetPrivada.doValidateUserNatural(user?.userName);
    if (data) user.name = data.legalName;

    dispatch(saveUserBeneficiario({ ...user, pageSelected }));
  };

  const selectUserFind = async (document, typeDocument, user) => {
    const payload = {
      createDate: '-',
      mail: user.lastEmail,
      name: user && user.legalName ? user.legalName : '',
      nroDoc: document,
      status: 'No registrado',
      updateDate: '',
      tipoDoc: getTypeDocument(typeDocument),
      userName: `${typeDocument}${document}`,
    };
    history.push(`${Routes.NET_NATURAL_USER_PRODUCTS}/${typeDocument}${document}`);
    dispatch(saveUserBeneficiario({ ...payload, pageSelected }));
  };

  const onSearchByDoc = (valueDoc, newList) => {
    const value = valueDoc || '';
    let filter = newList || listUserOriginal;
    if (value) {
      filter = filter.filter((item) => {
        return item.nroDoc.search(new RegExp(value, 'i')) !== -1;
      });
      setPageSelected(1);
    }
    setListUser(filter);
    setFilters({ attribute: 'document', value: valueDoc });
  };

  const blockUser = async (user) => {
    const { name, userName } = user;
    const header = { 'customer-id': userName };

    try {
      const { status, data } = await UserNetPrivada.disableUser(header);

      if (data && status.id === 201) {
        showSuccessToast(`El usuario ${name} fue bloqueado con éxito`);
        getListUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };
  const unblockUser = async (user) => {
    const { name, userName } = user;
    const header = { 'customer-id': userName };

    try {
      const { status, data } = await UserNetPrivada.enableUser(header);
      if (data && status.id === 201) {
        showSuccessToast(`El usuario ${name} fue desbloqueado con éxito`);
        getListUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };
  const deleteUser = async (user) => {
    const { name, userName } = user;
    const header = { 'customer-id': userName };
    try {
      const { status, data } = await UserNetPrivada.deleteUser(header);
      if (data && status.id === 201) {
        showSuccessToast(`El usuario ${name} fue eliminado con éxito`);
        getListUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };

  useEffect(() => {
    getListUser();
  }, []);

  useEffect(() => {
    if (beneficary && beneficary.pageSelected) {
      setPageSelected(beneficary.pageSelected);
    }
  }, [beneficary]);

  return (
    <div className="natural-user">
      <HeaderSection
        loading={loadingUsers}
        hasSearchInput
        hasCalendar
        title="Usuarios"
        onSearchRefresh={getListUser}
        listToExport={listToExport}
        hasCreateUser
        isNatural
        openModalCreateUser={() => setShowModalForm(true)}
        onSearchCalendar={onSearchCalendar}
        onSearchByDoc={onSearchByDoc}
        hasFindUser
        openModalFindUser={() => setShowModalFindUser(true)}
        buttonRefresh={
          <div className="refresh d-flex-center-align ml-1">
            <PSIcon type="refresh" className="icon-refresh" onClick={getListUser} />
          </div>
        }
        buttonHeader={
          <Button className={ButtonColor.blue}>
            <CSVLink
              onClick={() =>
                setTimeout(() => {
                  alert('Su archivo se ha descargado con éxito');
                }, 1000)
              }
              headers={HEADERS_FIELDS_CSV}
              data={listToExport}
              filename={NAME_FILE}
            >
              Exportar CSV
            </CSVLink>
          </Button>
        }
      />

      <ContainerCard>
        <div className="natural-user__body">
          <PSDynamicTable
            hasActions
            headers={headerTable}
            items={listPaginate}
            selectUser={selectUser}
            loading={loadingUsers}
            blockUser={blockUser}
            unblockUser={unblockUser}
            deleteUser={deleteUser}
            textConfirmModal={textConfirmModal}
          />
        </div>
      </ContainerCard>
      {!loadingUsers && listUser.length > 0 && (
        <div className="natural-user__footer">
          <ReactPaginate
            pageCount={itemsIterable}
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakClassName="break-me"
            marginPagesDisplayed={2}
            initialPage={pageSelected - 1}
            onPageChange={(pageSel) => setPageSelected(pageSel.selected + 1)}
            pageRangeDisplayed={isTablet ? (isMobile ? 1 : 2) : 5}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      )}
      {showModalForm && (
        <PSModal
          open={showModalForm}
          onClose={() => {
            // setShowModalForm(false);
            setShowConfirmModal(true);
            // setUserCreate(null);
          }}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModalPreview',
          }}
        >
          <CreateUserNatural
            verifyUser={verifyUser}
            userCreate={userCreate}
            loading={loading}
            onHandleInput={onHandleInput}
            onHandleCreateUser={verifyUserCognito}
          />
        </PSModal>
      )}

      {showModalFindUser && (
        <PSModal
          open={showModalFindUser}
          onClose={() => {
            setUserCreate(null);
            setShowModalFindUser(false);
          }}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModalPreview',
          }}
        >
          <CreateUserNatural
            headerTitle="Buscar Usuario"
            btnMessage="Ver productos"
            btnLabel="Buscar"
            verifyUser={searchUser}
            userCreate={userCreate}
            loading={loading}
            onHandleInput={onHandleInput}
            onHandleCreateUser={(document, typeDocument, user) =>
              selectUserFind(document, typeDocument, user)
            }
            isSearch
          />
        </PSModal>
      )}
      {showConfirmModal && (
        <ModalConfirm
          openModal={showConfirmModal}
          onCancel={() => setShowConfirmModal(false)}
          title="Crear usuario"
          onAccept={() => {
            setShowConfirmModal(false);
            setShowModalForm(false);
            setUserCreate(null);
          }}
          question="¿Seguro que desea abandonar esta operación?"
          onClick={() => {}}
        />
      )}
    </div>
  );
}
