import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PSIcon from './PSIcon';

const StyledPSDownloadFile = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9ecef;
  border-radius: 7px;
  opacity: 1;
  min-height: 28px;
  font-size: 10px;
  color: #002655;
  opacity: 1;
  .container-download-file {
    display: flex;
    justify-content: space-between;
    cursor:pointer;
    margin: 7px 12px;
    .info-name-file {
      display: flex;
      .name-file {
        margin: 4px 7px;
      }
    }
  }
`;

const PSDownloadFile = React.forwardRef(({ className, nameFile, date, ...props }, ref) => {
  return (
    <StyledPSDownloadFile className={className} {...props} ref={ref}>
      <div className="container-download-file">
        <div className="info-name-file">
          <PSIcon type="pdf" className="icon-pdf" />
          <div className="container-text">
            <div className="name-file">{nameFile}</div>
            {date && <div className="name-date">{date}</div>}
          </div>
        </div>
        <PSIcon type="download" className="icon-download" />
      </div>
    </StyledPSDownloadFile>
  );
});

PSDownloadFile.displayName = 'PSDownloadFile';

PSDownloadFile.defaultProps = {
  className: null,
  nameFile: 'testFile',
};

PSDownloadFile.propTypes = {
  className: PropTypes.string,
  nameFile: PropTypes.string,
};

export default PSDownloadFile;
