import React from 'react'
import PropTypes from "prop-types";

import PSIcon from '../../PSIcon';
import './index.scss';

const TableContract = ({ listItem }) => {
    return (
        <table className="container-table">
            <thead>
                <tr>
                    <th className="table-header-field table-item-employee"><input type="checkbox" className="checkbox" />Empleado</th>
                    <th className="table-header-field">Tipo de ausencia</th>
                    <th className="table-header-field">Periodo</th>
                    <th className="table-header-field">Duración</th>
                    <th className="table-header-field">Acciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    listItem.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className="table-item table-item-employee">
                                    <input type="checkbox" className="checkbox" />
                                    <img width={30} className="image" src="https://pbs.twimg.com/profile_images/1432183725924798464/cjgFNBax_400x400.jpg" />
                                    {item.employee}
                                </td>
                                <td className="table-item">{item.absenceType}</td>
                                <td className="table-item">{item.period}</td>
                                <td className="table-item">{item.duration}</td>
                                <td className="table-item">
                                    <PSIcon type="options2"/>
                                </td>
                            </tr>
                        )

                    })
                }
            </tbody>
        </table>
    )
};

export default TableContract

TableContract.displayName = 'TableContract';

TableContract.defaultProps = {
  className: "",
  listItem: [],

};

TableContract.propTypes = {
  className: PropTypes.string,
  listItem : PropTypes.array,
};
