import React, { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import { Button } from '../../atoms';
import { Collaborators, ContractModel } from '../../molecules';
import { AttachedFiles } from '..';
import { attachedFiles } from '../attachedFiles/Constants';
import { collaborators } from '../../molecules/collaborators/Constants';
import PSModal from '../../PSModal';

// eslint-disable-next-line consistent-return
const FirstCircle = ({ step }) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case 1:
      return (
        <div className="circle active-circle d-flex">
          <span className="step-number">1</span>
        </div>
      );
    default:
      return <PSIcon type="check" className="step-icon" />;
  }
};
const FirstLineSeparator = ({ step }) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case 1:
      return <div className="inactive-line-separator" />;
    default:
      return <div className="active-line-separator" />;
  }
};
const SecondCircle = ({ step }) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case 1:
      return (
        <div className="circle inactive-circle d-flex">
          <span className="step-number">2</span>
        </div>
      );
    case 2:
      return (
        <div className="circle active-circle d-flex">
          <span className="step-number">2</span>
        </div>
      );
    default:
      return <PSIcon type="check" className="step-icon" />;
  }
};
// eslint-disable-next-line consistent-return
const SecondText = ({ step }) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case 1:
      return (
        <div className="inactive-text">
          <p>En revisión</p>
        </div>
      );
    default:
      return (
        <div className="active-text">
          <p>En revisión</p>
        </div>
      );
  }
};
const SecondLineSeparator = ({ step }) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case 1:
    case 2:
      return <div className="inactive-line-separator" />;
    default:
      return <div className="active-line-separator" />;
  }
};
// eslint-disable-next-line consistent-return
const ThirdCircle = ({ step }) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case 1:
    case 2:
      return (
        <div className="circle inactive-circle d-flex">
          <span className="step-number">3</span>
        </div>
      );
    case 3:
      return (
        <div className="circle active-circle d-flex">
          <span className="step-number">3</span>
        </div>
      );
    default:
      return <PSIcon type="check" className="step-icon" />;
  }
};
// eslint-disable-next-line consistent-return
const ThirdText = ({ step }) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case 1:
    case 2:
      return (
        <div className="inactive-text">
          <p>En proceso</p>
        </div>
      );
    default:
      return (
        <div className="active-text">
          <p>En proceso</p>
        </div>
      );
  }
};
const Tab = ({ step }) => {
  return (
    <div className="step_container d-flex">
      <div className="item_container d-flex">
        <FirstCircle step={step} />
        <div className="active-text">
          <p>Pendiente</p>
        </div>
      </div>
      <FirstLineSeparator step={step} />
      <div className="item_container d-flex">
        <SecondCircle step={step} />
        <SecondText step={step} />
      </div>
      <SecondLineSeparator step={step} />
      <div className="item_container d-flex">
        <ThirdCircle step={step} />
        <ThirdText step={step} />
      </div>
    </div>
  );
};
const Footer = ({ step, onChange, activateModal }) => {
  switch (step) {
    case 1:
      return (
        <div className="footer_container_1">
          <Button
            className="next_button"
            onClick={() => {
              onChange(++step);
            }}
          >
            Siguiente
          </Button>
        </div>
      );
    case 2:
      return (
        <div className="footer_container_2 d-flex">
          <Button
            className="observe_button"
            onClick={() => {
              activateModal();
            }}
          >
            Observar
          </Button>
          <Button
            className="next_button"
            onClick={() => {
              onChange(++step);
            }}
          >
            Siguiente
          </Button>
        </div>
      );
    case 3:
      return (
        <div className="footer_container_3">
          <Button
            className="next_button"
            onClick={() => {
              onChange(++step);
            }}
          >
            Siguiente
          </Button>
        </div>
      );
    case 4:
      return (
        <div className="footer_container_4 d-flex">
          <Button
            className="back_button"
            onClick={() => {
              onChange(--step);
            }}
          >
            Regresar
          </Button>
          <Button className="save_button">Guardar</Button>
        </div>
      );
    default:
      return <div />;
  }
};
const BodyStep = () => {
  return (
    <div className="body_container">
      <div className="header_container d-flex">
        <PSIcon type="blueBookmark" />
        <h2>Contrato de ventas</h2>
      </div>
      <div className="general-info_container">
        <h3>Inf. General</h3>
        <h3>Descripción</h3>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
          invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
          accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
          sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
          elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua. At vero eos et accusam.
        </p>
      </div>
      <AttachedFiles attachedFiles={attachedFiles} />
    </div>
  );
};
// eslint-disable-next-line consistent-return
const Body = ({ step }) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case 1:
      return <BodyStep />;
    case 2:
      return <BodyStep />;
    case 3:
      return (
        <div>
          <Collaborators collaborators={collaborators} />
          <ContractModel hasFilesSelector />
        </div>
      );
    case 4:
      return (
        <div>
          <BodyStep />
          <Collaborators collaborators={collaborators} hasAddButton={false} />
          <ContractModel hasAddButton={false} />
        </div>
      );
  }
};

const Step = () => {
  const [stepActual, setActual] = useState(1);
  const [viewModalObservation, setViewModalObservation] = useState(false);
  const [viewModalMessage, setViewModalMessage] = useState(false);
  const openModalObservation = () => {
    setViewModalObservation(true);
  };
  return (
    <div className="contract-steps_container">
      <Tab step={stepActual} />
      <Body step={stepActual} />
      <Footer step={stepActual} onChange={setActual} activateModal={openModalObservation} />
      <PSModal
        onClose={() => {
          setViewModalObservation(false);
        }}
        open={viewModalObservation}
        center
        classNames={{
          overlay: 'observationModalOverlay',
          modal: 'observationModal',
        }}
      >
        <div className="observation_container">
          <div className="subtitle-container">
            <h4>Ingresar</h4>
          </div>
          <div className="title-container">
            <h3>Observación</h3>
          </div>
          <div className="input-container">
            <textarea placeholder="Escriba sus observaciones…" rows={14} />
          </div>
          <Button
            onClick={() => {
              setViewModalObservation(false);
              setViewModalMessage(true);
            }}
          >
            Aceptar
          </Button>
        </div>
      </PSModal>
      <PSModal
        onClose={() => {
          setViewModalMessage(false);
        }}
        open={viewModalMessage}
        center
        classNames={{
          overlay: 'confirmMessageModalOverlay',
          modal: 'confirmMessageModal',
        }}
      >
        <div className="message-container">
          <PSIcon type="sentMessage" />
          <div className="contain-modal-title">
            <h2>Observación enviada</h2>
          </div>
        </div>
      </PSModal>
    </div>
  );
};

Step.displayName = 'Step';

Step.defaultProps = {
  className: '',
};

Step.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  className: PropTypes.string,
};

export default Step;
