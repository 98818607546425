import React,{ useState }  from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import {ContainerCard} from "../../molecules";
import PSIcon from "../../PSIcon";

const ListFAQ = React.forwardRef(({ className, listFaq }, ref) => {
    return (
      <div ref={ref} className={`container-list-faq ${className}`}>
        <div>Agregar pregunta</div>
        <div>
          {
            listFaq.map((item=>{
              return(
                <ContainerCard className="container-card-faq">
                  <div>{item.question}</div>
                  <PSIcon type="arrowOrange"/>
                </ContainerCard>
              )
            }))
          }
        </div>
      </div>
    );
  }
);

ListFAQ.displayName = 'ListFAQ';

ListFAQ.defaultProps = {
  className: '',
};

ListFAQ.propTypes = {
  className: PropTypes.string,
};

export default ListFAQ;