import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import PSIcon from '../../PSIcon';
import { pathServer } from '../../../shared/constants/Constants';

const getPriorityTypeColor = (priority) => {
  switch (priority) {
    case 'Bajo':
      return 'greenBackColor';
    case 'Medio':
      return 'orangeBackColor';
    default:
      return 'redBackColor';
  }
};

const TableTask = ({ listTasks }) => {
  return (
    <table className="container-table-task">
      <thead>
        <tr>
          <th className="table-header-field">Prioridad</th>
          <th className="table-header-field">Tareas</th>
          <th className="table-header-field">Miembro</th>
          <th className="table-header-field">Progreso</th>
          <th className="table-header-field">Fecha</th>
        </tr>
      </thead>
      <tbody>
        {listTasks.map((item, index) => {
          return (
            <tr key={index} className="table-row">
              <td className="table-item table-item-priority">
                <div className={`${getPriorityTypeColor(item.priority)} d-flex`}>
                  <p>{item.priority}</p>
                </div>
              </td>
              <td className="table-item">
                <div className="table-item-task d-flex">
                  <div className="d-flex">
                    <PSIcon type="task" />
                    <p className="taskName">{item.taskName}</p>
                  </div>
                  <div className="d-flex">
                    <div className="group">
                      <PSIcon type="attachment" />
                      <p>{item.numberOfAttachedFiles}</p>
                    </div>
                    <div className="group">
                      <PSIcon type="message" />
                      <p>{item.numberOfComments}</p>
                    </div>
                  </div>
                </div>
              </td>
              <td className="table-item">
                <div className="table-item-member d-flex">
                  <div>
                    <img
                      width={30}
                      className="image"
                      src={`${pathServer.PATH_IMG}${item.profilePhoto}`}
                    />
                  </div>
                  <div>
                    <p>{item.employee}</p>
                  </div>
                </div>
              </td>
              <td className="table-item table-item-progress">
                <div
                  className={`${item.statusProgress === 'En progreso' ? 'inProgress' : 'finished'}`}
                >
                  <p>{item.statusProgress}</p>
                </div>
              </td>
              <td className="table-item table-item-date">
                <p>{item.date}</p>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableTask;

TableTask.displayName = 'TableTask';

TableTask.defaultProps = {
  className: '',
  listTasks: [],
};

TableTask.propTypes = {
  className: PropTypes.string,
  listTasks: PropTypes.array,
};
