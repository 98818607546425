import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const InputLabel = React.forwardRef(({ className, onChange, value,labelMessage,readOnly, placeholder, ...props}, ref) =>{
    const onChangeValue = (event) => {
      event.preventDefault();
      if(onChange){
        onChange(event.target.value);
      }
    };
  return (
    <div ref={ref} className={`container-InputLabel ${className}`}>
        <label htmlFor="name">{labelMessage}</label>
        <input required {...props} placeholder={placeholder} value={value}  onChange={onChangeValue}/>
    </div>
  )

});

InputLabel.displayName = 'InputLabel';

InputLabel.defaultProps = {
  className: '',
  placeholder: 'Ingrese su nombre',
  value: '',
  onChange: () => {},
  labelMessage : 'Nombres',
};

InputLabel.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  labelMessage: PropTypes.string,
};

export default InputLabel;
