
//CONSTANTES

const dataInicial = {};

const USER_SELECT_BENEFICIARIO = 'USER_SELECT_BENEFICIARIO';
const USER_CLEAR_BENEFICIARIO = 'USER_CLEAR_BENEFICIARIO';

export const setLoginUser = (beneficary) => ({
  type: USER_SELECT_BENEFICIARIO,
  payload: beneficary,
});

export default function beneficaryReducer(state = dataInicial, action) {
  switch (action.type) {
    case USER_SELECT_BENEFICIARIO:
      return ({...state, ...action.payload})
    case USER_CLEAR_BENEFICIARIO:
      return ({...dataInicial});
    default :
      return state;
  }
};

export const saveUserBeneficiario = (userBeneficiario) => (dispatch) => {
  dispatch({
    type : USER_SELECT_BENEFICIARIO,
    payload: { ...userBeneficiario },
  })
};

export const clearUserBeneficiario = () => (dispatch) => {
  dispatch({
    type : USER_CLEAR_BENEFICIARIO
  })
};

