import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';

import {
  HEADERS_FIELDS_COMPANY_CSV,
  NAME_FILE_COMPANY,
  showErrorToast,
  showSuccessToast,
} from '../../../shared/constants/Constants';
import { findByAtribute, formatDate, getPdp } from '../../../../scripts/Util';
import { SUCESS_CODE_API } from '../../../../scripts/Constants';
import UserNetPrivada from '../../../shared/services/UserNetPrivada';

import { Button } from '../../../components/atoms';
import { ButtonColor } from '../../../components/atoms/button/Button';
import { HeaderSection } from '../../../components/molecules';
import PSDynamicTable from '../../../components/PSDynamicTable';
import PSModal from '../../../components/PSModal';
import { CreateUserCompany } from '../../../components/templates';

import './index.scss';

const headerTable = [
  { elementProp: 'empresa', headerColumn: 'EMPRESA' },
  { elementProp: 'ruc', headerColumn: 'RUC' },
  { elementProp: 'pdp', headerColumn: 'PROMOCIONES' },
];

const MAX_PER_PAGE = 60;

export default function CompanyUser() {
  const [listUser, setListUser] = useState([]);
  const [listToExport, setListToExport] = useState([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [showModalForm, setShowModalForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userCreate, setUserCreate] = useState(null);
  const listPaginate = listUser.slice(
    (pageSelected - 1) * MAX_PER_PAGE,
    pageSelected * MAX_PER_PAGE
  );

  const itemsIterable = Math.ceil(listUser.length / MAX_PER_PAGE);
  const textConfirmModal = {
    messageBlock: {
      text: '¿Estás seguro que deseas bloquear a esta empresa?',
      title: 'Bloquear empresa',
    },
    messageUnblock: {
      text: '¿Estás seguro que deseas desbloquear a esta empresa?',
      title: 'Desbloquear empresa',
    },
    messageDelete: {
      text: '¿Estás seguro que deseas eliminar a esta empresa?',
      title: 'Eliminar empresa',
    },
  };
  const verifyUser = async (document) => {
    if (isNaN(document)) {
      try {
        setLoading(true);
        const data = await UserNetPrivada.doValidateUserNatural(document);
        if (data) {
          setUserCreate(data);
        } else {
          showErrorToast('Usuario no encontrado');
        }
      } catch (e) {
        showErrorToast('Error, intente luego');
      } finally {
        setLoading(false);
      }
    } else {
      showErrorToast('Ingrese el documento');
    }
  };

  const onHandleInput = (value, name) => {
    if (name) {
      userCreate[name] = value;
      setUserCreate({ ...userCreate });
    }
  };

  const mapResponseUserToTable = (listUsers) => {
    return listUsers.map((item) => {
      return {
        mail: findByAtribute(item.Attributes, 'email'),
        createDate: formatDate(item.UserCreateDate),
        updateDate: formatDate(item.UserLastModifiedDate),
        name: findByAtribute(item.Attributes, 'custom:legal_name').toLowerCase(),
        empresa: findByAtribute(item.Attributes, 'custom:legal_name'),
        ruc: item.Username ? item.Username.substring(1, item.Username.length) : '',
        status: item.UserStatus === 'CONFIRMED' ? 'Activo' : 'Pendiente de Confirmar',
        enabled: item.Enabled,
        enabledText: item.Enabled ? 'Habilitado' : 'Bloqueado',
        pdp: getPdp(findByAtribute(item.Attributes, 'custom:pdp_net_privada', 'false')),
        userName: item.Username,
      };
    });
  };

  const getListBusinessUser = async () => {
    try {
      setLoading(true);
      const headers = { 'customer-type': 'company' };
      const response = await UserNetPrivada.responseGetUsersNetPrivada(headers);
      if (response.status.id === 200) {
        const users = mapResponseUserToTable(response.data);
        const listUsersFormat = users.map((user) => {
          return { ...user, nroDoc: `'${user.nroDoc}` };
        });
        setListToExport(listUsersFormat);
        setListUser(users);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const blockUser = async (user) => {
    const { empresa, userName } = user;
    const header = { 'customer-id': userName };

    try {
      const { status, data } = await UserNetPrivada.disableUser(header);
      if (data && status.id === 201) {
        showSuccessToast(`La empresa ${empresa} fue bloqueada con éxito`);
        getListBusinessUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };
  const unblockUser = async (user) => {
    const { empresa, userName } = user;
    const header = { 'customer-id': userName };

    try {
      const { status, data } = await UserNetPrivada.enableUser(header);
      if (data && status.id === 201) {
        showSuccessToast(`La empresa ${empresa} fue desbloqueada con éxito`);
        getListBusinessUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };
  const deleteUser = async (user) => {
    const { empresa, userName } = user;
    const header = { 'customer-id': userName };
    try {
      const { status, data } = await UserNetPrivada.deleteUser(header);
      if (data && status.id === 201) {
        showSuccessToast(`La empresa ${empresa} fue eliminada con éxito`);
        getListBusinessUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };

  useEffect(() => {
    getListBusinessUser();
  }, []);

  return (
    <div className="company-user">
      <HeaderSection
        title="Personas Jurídicas"
        listToExport={listToExport}
        isNatural={false}
        openModalCreateUser={() => setShowModalForm(true)}
        buttonHeader={
          <Button className={ButtonColor.blue}>
            <CSVLink
              onClick={() =>
                setTimeout(() => {
                  alert('Su archivo se ha descargado con éxito');
                }, 1000)
              }
              headers={HEADERS_FIELDS_COMPANY_CSV}
              data={listToExport}
              filename={NAME_FILE_COMPANY}
            >    
              Exportar CSV
            </CSVLink>
          </Button>
        }
      />
      <div className="company-user__body">
        <PSDynamicTable
          hasActions
          headers={headerTable}
          items={listPaginate}
          loading={loading}
          blockUser={blockUser}
          unblockUser={unblockUser}
          deleteUser={deleteUser}
          textConfirmModal={textConfirmModal}
        />
      </div>
      {!loading && listUser.length > 0 && (
        <div className="company-user__footer">
          <ReactPaginate
            pageCount={itemsIterable}
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakClassName="break-me"
            marginPagesDisplayed={2}
            initialPage={pageSelected - 1}
            onPageChange={(pageSel) => setPageSelected(pageSel.selected + 1)}
            pageRangeDisplayed={MAX_PER_PAGE}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      )}

      {showModalForm && (
        <PSModal
          open={showModalForm}
          onClose={() => setShowModalForm(false)}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModalPreview',
          }}
        >
          <CreateUserCompany
            verifyUser={verifyUser}
            userCreate={userCreate}
            loading={loading}
            onHandleInput={onHandleInput}
          />
        </PSModal>
      )}
    </div>
  );
}
