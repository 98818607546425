import React,{ useState }  from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import {Button} from "../../atoms";
import {InputText} from "../../atoms";

const ContainerNewCategory = React.forwardRef(({ className, createCategory, onChange, nameCategory}, ref) => {

    return (
      <div  ref={ref} className={`container-new-category ${className}`} >
        <div className="container-title">
          <div className="container-title__title">Crear nueva <b>Categoría</b></div>
        </div>
        <div className="container-body">
          <div className="container-body__name-category">Nombre de categoría</div>
          <InputText onChange={onChange} value={nameCategory}/>
        </div>
        <div className="container-button-modal">
          <Button onClick={createCategory}>Crear</Button>
        </div>
      </div>
    );
  }

);

ContainerNewCategory.displayName = 'ContainerNewCategory';

ContainerNewCategory.defaultProps = {
  className: '',
  createCategory: ()=>{},
};

ContainerNewCategory.propTypes = {
  className: PropTypes.string,
  createCategory: PropTypes.func,
};

export default ContainerNewCategory;