import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const InputText = React.forwardRef(({ className, onChange, value, placeholder, ...props}, ref) =>{
  return (
    <div ref={ref} className={`container-InputText ${className}`}>
      <input
        {...props}
        type="text"
        onChange={(event) => onChange(event.target.value)}
        value={value}
        placeholder={placeholder}
      />
    </div>
  )

});

InputText.displayName = 'InputText';

InputText.defaultProps = {
  className: '',
  placeholder: '',
  onChange: () => {},
};

InputText.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputText;
