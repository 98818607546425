import React,{ useState }  from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import {ContainerCard, InputLabelContract, TextAreaLabelContract} from "../../molecules";
import PSIcon from "../../PSIcon";

const FormContracts = React.forwardRef(({ className, listFaq }, ref) => {
    return (
      <div ref={ref} className={`container-formContracts ${className}`}>
        <ContainerCard>
          <div><h4>Información</h4></div>
          <div className="container-inputs">
            <InputLabelContract labelMessage="Correo"/>
            <InputLabelContract labelMessage="Responsable de elaborar el contrato"/>
            <TextAreaLabelContract placeholder="Escriba aquí...." labelMessage="Breve descripción del servicio (Objetivo del contrato)"/>
            <InputLabelContract labelMessage="Contraparte"/>
            <InputLabelContract labelMessage="Tipo de contrato"/>
            <InputLabelContract labelMessage="Plazo de vigencia del contrato/adenda"/>
            <InputLabelContract labelMessage="Area usuaria"/>
            <InputLabelContract labelMessage="Fecha de contrato"/>
          </div>
        </ContainerCard>


      </div>
    );
  }
);

FormContracts.displayName = 'FormContracts';

FormContracts.defaultProps = {
  className: '',
};

FormContracts.propTypes = {
  className: PropTypes.string,
};

export default FormContracts;