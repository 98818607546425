import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';

const Stats = React.forwardRef(({ className, stats }, ref) => {
  return (
    <div ref={ref} className={`stats-component ${className}`}>
      {stats.map((item, index) => 
        <div key={index} className="stats-card">
          <PSIcon type={item.img} className="stats-icon"/>
          <div className="stats-content">
            <div className="stats-title">{item.title}</div>
            <div className="stats-description">{item.description}</div>
          </div>
        </div>
      )}
    </div>
  );
});

Stats.displayName = 'Stats';
Stats.defaultProps = {
  className: '',
  stats: [],
};

Stats.propTypes = {
  className: PropTypes.string,
  stats: PropTypes.array,
};

export default Stats;
