
export const DetailDefault = {
  dateInitial: '16/02/2021',
  dateEnd: '19/02/2021',
  type: 'Tipo de contrato',
  priority: 'Lorem ipsum',
  label: 'Lorem ipsum',
  state: 'Lorem ipsum',
  resolution: 'Lorem ipsum',
  assigned: 'Harry Garrett',
  responsible: 'Daniel Montanchez'
};