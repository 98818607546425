import React, { useState, useEffect } from 'react';
import { ContainerCard } from "../../molecules";
import './index.scss';
import PropTypes from "prop-types";
import moment from "moment";

export default function NewSletterSummaryCard({ className, showBoletin, boletin , onDeleteBoletin}) {
  const dateCreated = moment.unix(boletin.dateRegister).format('DD/MM/YYYY');
  return(
    <ContainerCard className={`newsletter-boletion-card ${className}`} >
      <div className="container-header">
        <input type="checkbox" onClick={ () => showBoletin(boletin)}/>
        <div> <span onClick={() => onDeleteBoletin(boletin)} title="Eliminar">X</span></div>
      </div>
      <div className="container-title">
        <div>{boletin.principalTitle || '# Cada dia Cuenta'}</div>
        <div className="mt-0">{boletin.shortDescription || ''}</div>
      </div>
      <div className="container-publication-date">
        <div className="container-publication-date__title">Publicado</div>
        <div className="container-publication-date__date">{boletin.publicationDate || dateCreated}</div>
      </div>
    </ContainerCard>
  )
}

NewSletterSummaryCard.displayName = 'NewSletterSummaryCard';

NewSletterSummaryCard.defaultProps = {
  className: "",
  boletin: null,
  showBoletin: () => {},
  onDeleteBoletin: () => {},
};

NewSletterSummaryCard.propTypes = {
  className: PropTypes.string,
  showBoletin: PropTypes.func.isRequired,
  boletin: PropTypes.object.isRequired,
  onDeleteBoletin : PropTypes.func,
};
