import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import PSIcon from '../../PSIcon';
import { NewSletterBoletinCard } from '../../organisms';
import {
  ButtonFile,
  ContainerCard,
  ContainerPreviewNewsLetter,
  DropzoneNewsLetter,
  TagSearchInput,
} from '../../molecules';
import { ButtonColor } from '../../atoms/button/Button';
import ManagerNewsLatter from '../../../shared/services/ManagerNewsLatter';
import { Button, InputText } from '../../atoms';

import {
  getStructToGetUrlFile,
  inspectParagraph,
  isEmpty,
  isEmptyArray,
  isRequired,
  transformDatetoUnix,
} from '../../../../scripts/Util';
import { showErrorToast, showSuccessToast } from '../../../shared/constants/Constants';

import PSModal from '../../PSModal';
import {
  BASE_URL_PATH_FILE_NEWSLETTER,
  BOLETIN_OBJ,
  MAGAZINE_BOLETIN_EN_VIVO,
} from '../../../../scripts/Constants';
import { Routes } from '../../../../routes';

let dateExpired = '07/01/20';
let dateCreated = '07/01/20';
const PATH_NEWSLETTER = '/newsletter/get/';
const NewSletterBoletin = React.forwardRef(
  (
    {
      boletin,
      boletinOriginal,
      goBack,
      isEdit,
      category,
      hiddenBoletin,
      onHandleBoletin,
      categoryId,
    },
    ref
  ) => {
    const [imageBoletin, setImageBoletin] = useState(null);
    const [boletinSelected, setBoletinSelected] = useState(boletin);
    const [currentImg, setCurrentImg] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [fileUploaded, setFileUploaded] = useState({ s3UrlImage: '', s3UrlPdf: '' });
    const [structFile, setStructFile] = useState({ structToGetUrlImg: '', structToGetUrlFile: '' });
    const [showPreview, setShowPreview] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(true);
    const [loadingform, setLoadingForm] = useState(false);
    const [buttonMagazine, setButtonMagazine] = useState({ title: '', link: '' });
    const enableEdit = isEdit && boletin;
    const history = useHistory();
    const hasChange = JSON.stringify(boletinOriginal) !== JSON.stringify(boletin);
    const hasButtons = boletinSelected.buttons ? boletinSelected.buttons.length > 0 : false; // dont touch
    dateCreated = moment.unix(boletinSelected.dateRegister).format('YYYY-MM-DD');
    dateExpired = moment.unix(boletinSelected.dateExpiration).format('YYYY-MM-DD');

    const loadImageFile = async () => {
      if (boletin.s3UrlImage !== '') {
        const responseImage = await ManagerNewsLatter.responseGetImages(boletin.s3UrlImage);
        setImageBoletin(responseImage);
      }
    };

    const onDownloadFile = async () => {
      try {
        const { buttons } = boletin;
        if (hasButtons && buttons[0].link.includes(PATH_NEWSLETTER)) {
          const data = await ManagerNewsLatter.getFileBoletin(buttons[0].link);
          const link = document.createElement('a');
          link.href = data;
          link.download = 'boletin.pdf';
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          showSuccessToast('Archivo descargado');
        } else {
          showErrorToast('Archivo no subido');
        }
      } catch (error) {
        showErrorToast('Error al obtener el archivo');
      }
    };

    const onUploadFileFirstStep = async (file, type) => {
      if (file) {
        setLoadingForm(true);
        const structToGetUrl = getStructToGetUrlFile(
          category ? category.idContenido : categoryId,
          file.name
        );
        try {
          const responseUrl = await ManagerNewsLatter.getUrlDocuments(structToGetUrl);
          if (responseUrl.code === 200) {
            if (type === 'img') {
              setFileUploaded({ ...fileUploaded, s3UrlImage: responseUrl.data });
              setStructFile({
                ...structFile,
                structToGetUrlImg: BASE_URL_PATH_FILE_NEWSLETTER + structToGetUrl,
              });
            } else {
              setFileUploaded({ ...fileUploaded, s3UrlPdf: responseUrl.data });
              setStructFile({
                ...structFile,
                structToGetUrlFile: BASE_URL_PATH_FILE_NEWSLETTER + structToGetUrl,
              });
            }
            setTimeout(() => {
              setLoadingForm(false);
            }, 1000);
          } else {
            showErrorToast('Error al subir el archivo , intentelo mas tarde');
          }
        } catch (e) {
          showErrorToast('Hubo un problema al subir el archivo');
        }
      } else {
        showErrorToast('Sube un archivo válido porfavor');
      }
    };

    const onUploadFileLastStep = async (urlFromSecondStep, file) => {
      await ManagerNewsLatter.uploadFile(urlFromSecondStep, file, file.type);
    };

    const strucDataPayload = () => {
      const dataBoletin = cloneDeep(boletinSelected);
      const { structToGetUrlImg, structToGetUrlFile } = structFile;
      if (hasButtons) {
        if (
          isEmpty(structToGetUrlFile) &&
          dataBoletin.buttons[0].link.includes(PATH_NEWSLETTER) &&
          showDownloadButton
        ) {
          buttonMagazine.link = dataBoletin.buttons[0].link;
        }
        if (structToGetUrlFile && showDownloadButton) {
          buttonMagazine.link = structToGetUrlFile;
        }
      }
      dataBoletin.paragraphs = inspectParagraph(dataBoletin.paragraphs);
      dataBoletin.buttons = [{ ...buttonMagazine }];
      dataBoletin.idTipoContenido = isEdit ? category.idContenido : categoryId;
      dataBoletin.s3UrlImage =
        structToGetUrlImg === '' ? dataBoletin.s3UrlImage : structToGetUrlImg; // newsletter/img/xxxx
      dataBoletin.s3UrlPdf = structToGetUrlFile === '' ? dataBoletin.s3UrlPdf : structToGetUrlFile; // newsletter/img/xxxxx
      return dataBoletin;
    };

    const onSubmit = async () => {
      const dataBoletin = strucDataPayload();
      const validLink = dataBoletin.idTipoContenido === MAGAZINE_BOLETIN_EN_VIVO ? 'urlLink' : null;
      const FIELDS_REQUIRED = ['principalTitle', 's3UrlImage', validLink];
      const validatePayload = isRequired(FIELDS_REQUIRED, dataBoletin);
      if (isEmptyArray(validatePayload)) {
        const hasFilePDF = dataBoletin.s3UrlPdf !== '' && currentFile;
        const hasFileIMG = dataBoletin.s3UrlImage !== '' && currentImg;
        setLoadingForm(true);
        try {
          const responseCreate = await ManagerNewsLatter.responseUpdateorCreateBoletin(
            dataBoletin,
            isEdit
          );
          if (responseCreate.statusCode === 200) {
            if (hasFileIMG) {
              await onUploadFileLastStep(fileUploaded.s3UrlImage, currentImg);
            }
            if (hasFilePDF) {
              await onUploadFileLastStep(fileUploaded.s3UrlPdf, currentFile);
            }
            setLoadingForm(false);
            showSuccessToast(isEdit ? 'Boletín Actualizado' : 'Boletin Creado');
            if (isEdit) {
              window.location.reload();
            } else {
              const cleanData = cloneDeep({ ...BOLETIN_OBJ, dateRegister: moment().format('X') });
              setBoletinSelected({ ...cleanData });
              history.push(`${Routes.NEWSLETTER_BOLETIN_ALL}/${categoryId}`);
            }
          }
        } catch (e) {
          showErrorToast('Hubo un problema al subir los archivos');
        }
      } else {
        const customMessage = validatePayload.join('   -    ');
        showErrorToast(customMessage);
      }
    };

    useEffect(() => {
      if (enableEdit) {
        loadImageFile();
        const { buttons } = boletin;
        if (buttons.length > 0) {
          const { link, title } = buttons[0];
          setShowDownloadButton(link.includes(PATH_NEWSLETTER));
          setButtonMagazine({
            ...buttonMagazine,
            title,
            link: link.includes(PATH_NEWSLETTER) ? '' : link,
          });
        }
      }
    }, []);

    useEffect(() => {
      setBoletinSelected(boletin);
    }, [boletin]);

    return (
      <div className="newsletter-boletin" ref={ref}>
        <div className="newsletter-boletin__header d-flex">
          <div className="d-flex">
            <PSIcon hasHover type="arrowBack" onClick={isEdit ? hiddenBoletin : goBack} />
            <div className="ml-1">
              <h2 className="color-blue lighter">{isEdit ? 'Editar' : 'Crear'} Boletín</h2>
              <h2 className="color-blue bold mt-0">
                {category ? category.principalTitle : boletinSelected.principalTitle}
              </h2>
            </div>
          </div>
          <div className="container-actions">
            <div className="newsletter-boletin__footer">
              <Button onClick={() => setShowPreview(true)}> Vista preliminar</Button>
            </div>
            <Button loading={loadingform} onClick={onSubmit} disabled={!hasChange}>
              {' '}
              Publicar cambios
            </Button>
          </div>
        </div>

        <div className="newsletter-boletin__body">
          <div>
            <DropzoneNewsLetter
              isEdit={isEdit}
              imgEdit={imageBoletin}
              selectFile={(file) => {
                setCurrentImg(file);
                onUploadFileFirstStep(file, 'img');
              }}
            />
            <div className="container-button-upload mt-2">
              {isEdit && showDownloadButton && (
                <div className="d-flex" onClick={onDownloadFile}>
                  <PSIcon type="pdf" className="icon-pdf" />
                  <div className="ml-0">
                    <h6 className="color-blue">Documento</h6>
                    <h6 className="color-gray">Descargar 143 KB</h6>
                  </div>
                </div>
              )}
              {showDownloadButton && (
                <ButtonFile
                  type={ButtonColor.white}
                  icon="upload"
                  name="Subir documento"
                  onClick={() => {}}
                  selectFile={(file) => {
                    setCurrentFile(file);
                    onUploadFileFirstStep(file, 'file');
                  }}
                />
              )}
            </div>
          </div>
          <div>
            <div className="d-flex-separate mb-1">
              <div className="d-flex">
                <PSIcon type="calendar" />
                <h5 className="color-blue ml-0 mt-0">Vigencia: </h5>
                <input
                  className="ml-0"
                  type="date"
                  id="start"
                  value={dateExpired}
                  min="2021-01-01"
                  max="2023-01-01"
                  onChange={(event) => {
                    const dateUnix = transformDatetoUnix(event.target.value);
                    onHandleBoletin(dateUnix, 'dateExpiration');
                  }}
                />
              </div>

              {isEdit && (
                <div>
                  <h5 className="color-gray"> Publicado {dateCreated} </h5>
                </div>
              )}
            </div>
            <NewSletterBoletinCard
              mainTitle="Título general"
              boletin={boletinSelected}
              onHandleBoletin={onHandleBoletin}
            />
            <NewSletterBoletinCard
              mainTitle="Detalle"
              hasDetail
              boletin={boletinSelected}
              onHandleBoletin={onHandleBoletin}
            />
            <ContainerCard className="container-card-keyword ">
              <div className="main-title">Palabras clave</div>
              <h6 className="subTitle mb-1">Agregar palabra clave</h6>
              <TagSearchInput
                onChange={onHandleBoletin}
                tags={boletinSelected.newsletterKeywords}
              />
            </ContainerCard>

            <ContainerCard className="container-card-keyword ">
              <div className="main-title mb-1">Botones</div>
              <div className="d-flex w-100 mb-1 ">
                <div
                  className={!showDownloadButton ? 'not-selected' : 'selected'}
                  onClick={() => {
                    setShowDownloadButton(true);
                  }}
                >
                  Descarga
                </div>
                <div
                  className={!showDownloadButton ? 'selected' : 'not-selected'}
                  onClick={() => setShowDownloadButton(false)}
                >
                  Enlace
                </div>
              </div>
              <h6 className="color-light-blue">Nombre del botón</h6>
              <InputText
                onChange={(value) => setButtonMagazine({ ...buttonMagazine, title: value })}
                className="color-light-blue w-50"
                value={buttonMagazine.title}
                maxLength={50}
              />

              {!showDownloadButton && (
                <>
                  <h6 className="color-light-blue mt-1">Enlace</h6>
                  <InputText
                    onChange={(value) => setButtonMagazine({ ...buttonMagazine, link: value })}
                    className="color-light-blue w-100"
                    value={buttonMagazine.link}
                  />
                </>
              )}
            </ContainerCard>
          </div>
        </div>
        {showPreview && (
          <PSModal
            open={showPreview}
            onClose={() => setShowPreview(false)}
            classNames={{
              overlay: 'customOverlay',
              modal: 'customModalPreview',
            }}
          >
            <ContainerPreviewNewsLetter
              buttonMagazine={buttonMagazine}
              onClickDownload={onDownloadFile}
              boletin={boletinSelected}
              imageUrlBoletin={
                imageBoletin || (currentImg ? URL.createObjectURL(currentImg) : null)
              }
            />
          </PSModal>
        )}
      </div>
    );
  }
);

NewSletterBoletin.displayName = 'NewSletterBoletin';

NewSletterBoletin.defaultProps = {
  isEdit: false,
  boletin: null,
  boletinOriginal: null,
  category: null,
  hiddenBoletin: () => {},
  onHandleBoletin: () => {},
};

NewSletterBoletin.propTypes = {
  goBack: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  boletin: PropTypes.object,
  boletinOriginal: PropTypes.object,
  hiddenBoletin: PropTypes.func,
  category: PropTypes.object,
  onHandleBoletin: PropTypes.func,
};

export default NewSletterBoletin;
