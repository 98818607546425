import React, { useState } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Modal from 'react-responsive-modal';

import { clearRecoverPassword } from '../redux/ducks/userRecover';

import { Routes } from '../../routes';

import Login from '../pages/Login';

export default function PSHomeLogin({ children }) {
  return <Login />;
}

export const withLogin = (Component) => (props) => {
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const onClose = () => {
    setShowModal(false);
    dispatch(clearRecoverPassword());
    history.push(Routes.LOGIN);
  };
  return (
    <div>
      <PSHomeLogin />
      {showModal && (
        <Modal showCloseIcon={false} className="" open onClick={() => {}} onClose={() => {}}>
          <Component {...props} closeModal={onClose} />
        </Modal>
      )}
    </div>
  );
};
