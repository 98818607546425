import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../routes';

import './index.scss';

import { setUserRecoverPass, setAuthLoading } from '../../../redux/ducks/userRecover';

import RecoverPasswordService from '../../../shared/services/RecoverPasswordService';
import { STORAGE_TOKEN_APP } from '../../../shared/constants/Constants';
import PSLoading from '../../../components/PSLoading';
import PSError from '../../../components/PSError';
import PSTextInput from '../../../components/PSTextInput';
import PSButton from '../../../components/PSButton';
import PSBox from '../../../components/PSBox';
import PSLoginContainer from '../../../components/PSLoginContainer';
import PSHeaderLogin from '../../../components/PSHeaderLogin';
import PSMessageResponse from '../../../components/PSMessageResponse';

const ForgotPasswordForm = ({ formik, onClose, loading, loadingToken }) => (
  <PSLoginContainer
    className={`user-container  ${loadingToken ? 'container-loading2' : ''}`}
    onClose={onClose}
  >
    {loadingToken ? (
      <PSLoading color="orange" />
    ) : (
      <>
        <PSHeaderLogin className="user-header-login" title="Solicitud de validación" icon="user" />
        <form className="baseForm" onSubmit={formik.handleSubmit} noValidate>
          <PSBox className="user-box-login">
            <PSTextInput
              type="text"
              placeholder="ingrese correo"
              {...formik.getFieldProps('documentId')}
            />
            <PSError>{formik.touched.documentId && formik.errors.documentId}</PSError>
          </PSBox>
          <PSBox className="user-footer">
            <PSButton className="btn-submit" type="submit" loading={loading}>
              Validar
            </PSButton>
          </PSBox>
        </form>
      </>
    )}
  </PSLoginContainer>
);

const ErrorMessage = ({ statusValid, onClose }) => {
  const getErrorMessage = () => {
    if (statusValid === 204) {
      return 'El usuario no existe. Por favor solicitar la creación de usuario por Help desk.';
    }

    if (statusValid === 423) {
      return 'Lo sentimos, el usuario se encuentra bloqueado.';
    }

    if (statusValid === 500) {
      return 'Error al consultar';
    }

    return 'no found';
  };

  return (
    <PSMessageResponse icon="face" onClose={onClose}>
      {getErrorMessage()}
    </PSMessageResponse>
  );
};

export default function SingUpPassword({ closeModal }) {
  const history = useHistory();
  const { isUserValid, statusValid, loading } = useSelector((state) => state.userRecoverPass);
  const [showError, setError] = useState(false);
  const [loadingToken, setLoadingToken] = useState(false);
  const dispatch = useDispatch();

  const onValidStatus = async (email) => {
    try {
      dispatch(setAuthLoading(true));
      const response = await RecoverPasswordService.validateStatus(email);
      if (response.status && response.status.id === 200) {
        dispatch(setUserRecoverPass({ email, isUserValid: true }));
        setError(null);
        setTimeout(() => {
          history.push(Routes.FORGOT_PASSWORD_VALIDATION);
        }, 1500);
      } else {
        dispatch(
          setUserRecoverPass({
            email,
            statusValid: response.status.id,
          })
        );
        setError(true);
      }
      dispatch(setAuthLoading(false));
    } catch (error) {
      dispatch(setAuthLoading(false));
      dispatch(setUserRecoverPass({ email, statusValid: 500 }));
      setError(true);
    }

    return false;
  };

  const formik = useFormik({
    initialValues: {
      documentId: '',
    },
    validationSchema: Yup.object().shape({
      documentId: Yup.string().email('Usuario invalido').required('Usuario es requerido'),
    }),

    onSubmit(form) {
      onValidStatus(form.documentId);
    },
  });

  const onClose = () => {
    closeModal();
  };

  const getToken = async () => {
    try {
      setLoadingToken(true);
      const response = await RecoverPasswordService.getTokenApp();
      if (
        response.authenticationResponse &&
        response.authenticationResponse.authenticationState === 200
      ) {
        const token = response.authenticationResponse.authenticationData.securityToken;
        localStorage.setItem(STORAGE_TOKEN_APP, token);
      }
      setLoadingToken(false);
    } catch (error) {
      setLoadingToken(false);
    }
  };
  useEffect(() => {
    getToken();
  }, []);

  return (
    <div className="forgot-password-validation-container">
      {!showError && (
        <ForgotPasswordForm
          formik={formik}
          onClose={onClose}
          loading={loading}
          loadingToken={loadingToken}
        />
      )}
      {showError && <ErrorMessage statusValid={statusValid} onClose={onClose} history={history} />}
    </div>
  );
}
