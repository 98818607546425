import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { generateArrayIterable } from '../../scripts/Util';

const StyledPaginator = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  .number {
    margin: 0 0.5em;
    padding: 5px;
    font-weight: 500;
  }
  .selected {
    padding: 5px 10px;
    border-radius: 50%;
    color: white;
    background: #de6d19;
  }
  li {
    list-style-type: none;
    &:hover {
      cursor: pointer;
    }
  }
`;

const PSPaginator = React.forwardRef(
  ({ countList, maxPerPage, pageSelected, onSelectPage }, ref) => {
    const itemsIterable = generateArrayIterable(Math.ceil(countList / maxPerPage));

    return (
      <StyledPaginator ref={ref}>
        {itemsIterable.map((p, index) => (
          <ul
            key={`pg_${index}`}
            onClick={() => onSelectPage(index + 1)}
            className={`color-blue number ${index === pageSelected - 1 ? 'selected' : ''}`}
          >
            <li>{p}</li>
          </ul>
        ))}
      </StyledPaginator>
    );
  }
);

PSPaginator.displayName = 'PSPaginator';

PSPaginator.defaultProps = {
  countList: 20,
  maxPerPage: 8,
  pageSelected: 1,
  onSelectPage: () => {},
};

PSPaginator.propTypes = {
  countList: PropTypes.number,
  maxPerPage: PropTypes.number,
  pageSelected: PropTypes.number,
  onSelectPage: PropTypes.func,
};

export default PSPaginator;
