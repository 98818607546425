import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  formatRoleToSelect,
  isEmptyArray,
  isRequired,
  removeArrayFromArray,
  validateEmail,
  formatDate,
} from '../../../../scripts/Util';
import { showErrorToast, showSuccessToast } from '../../../shared/constants/Constants';
import RolesAndPermissions from '../../../shared/services/RolesAndPermissions';
import { RolModel } from '../../../shared/model/RolModel';
import { UserBackOfficeModel } from '../../../shared/model/UserBackOfficeModel';

import { ConfigurationRole } from '../../../components/templates';
import { SelectCustom } from '../../../components/atoms';

const headerTable = [
  { elementProp: 'username', headerColumn: 'Usuario' },
  { elementProp: 'rol', headerColumn: 'Rol' },
];
const userBackOffice = {
  name: '',
  lastName: '',
  secondLastName: '',
  phone: '',
  email: '',
  roles: null,
};
export default function RolesPage() {
  const [listUser, setListUser] = useState([]);
  const [listRoles, setListRoles] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showModalCreateUser, setShowModalCreateUser] = useState(false);
  const [permissionSelected, setPermissionSelected] = useState([]);
  const [userCreate, setUserCreate] = useState({ ...userBackOffice });
  const userAdmin = useSelector((state) => state.user);
  const textConfirmModal = {
    messageBlock: {
      text: '¿Estás seguro que deseas bloquear a este usuario?',
      title: 'Bloquear usuario',
    },
    messageUnblock: {
      text: '¿Estás seguro que deseas desbloquear a este usuario?',
      title: 'Desbloquear usuario',
    },
    messageDelete: {
      text: '¿Estás seguro que deseas eliminar a este usuario?',
      title: 'Eliminar usuario',
    },
  };
  const getListUser = async (useLoading = true) => {
    try {
      if (useLoading) setLoadingUsers(true);
      const response = await RolesAndPermissions.getListUsers();
      const { employess } = await RolesAndPermissions.listEmployees();
      if (response.status === 200) {
        const users = response.data
          ? response.data.map((item) => {
              item.CreationDate = formatDate(item.CreationDate);
              item.UpdateDate = formatDate(item.UpdateDate);
              return new UserBackOfficeModel(item);
            })
          : [];
        const listUsers = employess
          .filter((e) =>
            users.some((user) => user.username.toUpperCase() === e.Username.toUpperCase())
          )
          .map((u) => {
            const user = users.find(
              (user) => user.username.toUpperCase() === u.Username.toUpperCase()
            );
            const optionsRoles = listRoles.filter((item) => user.roles.includes(item.sort));
            return {
              ...u,
              ...user,
              enabled: u.Enabled,
              rol: (
                <SelectCustom
                  closeMenuOnSelect={false}
                  value={optionsRoles}
                  isMulti
                  options={listRoles}
                  onChange={(evt) => onUpdateRoleUser(evt, u, users)}
                  isDisabled={u?.Enabled === false}
                />
              ),
            };
          });
        setListUser(listUsers);

        if (useLoading) setLoadingUsers(false);
      }
    } catch (error) {
      setLoadingUsers(false);
      console.log(error);
    }
  };

  const getListRoles = async () => {
    try {
      setLoadingRoles(true); // todo for the moment
      const response = await RolesAndPermissions.getListRoles();
      if (response.status === 200) {
        let data = response.data
          ? response.data.map((item) => {
              return new RolModel(item);
            })
          : [];
        data = data.filter((d) => d.sort !== '1628723210');
        setListRoles([...data]);
      }
      setLoadingRoles(false);
    } catch (error) {
      setLoadingRoles(false);
      console.log(error);
    }
  };

  const onUpdateRoleUser = async (roles, user, users) => {
    try {
      setLoadingSubmit(true);
      const rolesToSave = roles.map((r) => r.sort);
      const response = await updateRoleByUser([...rolesToSave], user.Username, users);
      if (response) {
        getListUser(false);
        showSuccessToast('Rol actualizado con éxito');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const updateRoleByUser = async (roles, email, users) => {
    const adminUser = users.find((u) => u.username === userAdmin.email);
    const payload = {
      ItemUserRole: {
        UpdateUser: adminUser.UpdateUser,
        Username: email,
        Roles: roles,
      },
    };
    return await RolesAndPermissions.updateRoleByUser(payload);
  };
  const onHandleValuesUser = (value, property) => {
    userCreate[property] = value;
    setUserCreate({ ...userCreate });
  };
  const onSaveUser = async () => {
    const roles = userCreate.roles && [userCreate.roles];
    const payload = { employeeEntity: userCreate };
    const validateFields = isRequired(
      ['name', 'lastName', 'secondLastName', 'email', 'phone'],
      userCreate
    );
    if (isEmptyArray(validateFields) && userCreate.roles) {
      if (validateEmail(userCreate.email)) {
        setLoadingCreateUser(true);
        try {
          const {
            status: { id },
          } = await RolesAndPermissions.createAdministrator(payload);
          if (id && id !== 204) {
            const responseCognito = await updateRoleByUser(roles, userCreate.email, listUser);
            if (responseCognito) {
              getListUser(false);
              setUserCreate({ ...userBackOffice });
            }
            showSuccessToast('Usuario creado con exito');
          } else {
            showErrorToast('Usuario ya existe');
          }
          setLoadingCreateUser(false);
          setShowModalCreateUser(false);
        } catch (e) {
          showErrorToast('Hubo un error, intente luego');
          setLoadingCreateUser(false);
          setShowModalCreateUser(false);
        }
      } else {
        showErrorToast('Ingrese un email válido');
      }
    } else {
      showErrorToast('Todos los campos son obligatorios');
    }
  };

  const onUpdatePermission = (data) => {
    setPermissionSelected([...permissionSelected, data]);
  };

  const onSubmitRolePermission = async (role = null) => {
    if (role.menuOptions) {
      setLoadingSubmit(true);
      setLoadingUsers(true);
      const menuEnabled = permissionSelected.filter((p) => p.flag).map((p) => p.submenu);
      let menuDisabled = permissionSelected.filter((p) => !p.flag).map((p) => p.submenu);
      menuDisabled = [...menuDisabled.flat()];
      const menuFilter =
        menuDisabled.length === 0
          ? role.menuOptions
          : removeArrayFromArray(role.menuOptions, menuDisabled);
      const adminUser = listUser.find((u) => u.username === userAdmin.email);
      const menuOptions = [...menuEnabled.flat(), menuFilter];
      const payload = {
        ItemRole: {
          Id: role.sort,
          UpdateUser: adminUser.UpdateUser,
          Name: role.name,
          MenuOptions: [...new Set([...menuOptions.flat()])],
        },
      };
      try {
        await RolesAndPermissions.updateRolePermission(payload);
        getListRoles();
        setPermissionSelected([]);
        showSuccessToast('Permisos actualizados con éxito');
      } catch (e) {
        showErrorToast('Hubo un error intentelo luego');
      } finally {
        setLoadingSubmit(false);
        setLoadingUsers(false);
      }
    }
  };

  const blockUser = async ({ sort: userId, username }) => {
    const header = { entity: 'USUARIO' };
    try {
      const { status, data } = await RolesAndPermissions.disableUser(userId, header);
      if (data && status.id === 200) {
        showSuccessToast(`El usuario ${username} fue bloqueado con éxito`);
        getListUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };
  const unblockUser = async ({ sort: userId, username }) => {
    const header = { entity: 'USUARIO' };
    try {
      const { status, data } = await RolesAndPermissions.enableUser(userId, header);
      if (data && status.id === 200) {
        showSuccessToast(`El usuario ${username} fue desbloqueado con éxito`);
        getListUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };
  const deleteUser = async ({ sort: userId, username }) => {
    const header = { entity: 'USUARIO' };
    try {
      const { status, data } = await RolesAndPermissions.deleteUser(userId, header);
      if (data && status.id === 200) {
        showSuccessToast(`El usuario ${username} fue eliminado con éxito`);
        getListUser();
      } else {
        showErrorToast('Hubo un error, intente luego');
      }
    } catch (e) {
      showErrorToast('Hubo un error, intente luego');
    }
  };

  useEffect(() => {
    getListRoles();
  }, []);

  useEffect(() => {
    if (listRoles.length > 0) {
      getListUser();
    }
  }, [listRoles]);

  return (
    <ConfigurationRole
      headerTable={headerTable}
      loadingUsers={loadingRoles || loadingUsers}
      listUser={listUser}
      listRoles={listRoles}
      userCreate={userCreate}
      onHandleValuesUser={onHandleValuesUser}
      onSaveUser={onSaveUser}
      onUpdatePermission={onUpdatePermission}
      setShowModalCreateUser={setShowModalCreateUser}
      showModalCreateUser={showModalCreateUser}
      loadingCreateUser={loadingCreateUser}
      onSubmitPermission={(role) => onSubmitRolePermission(role)}
      loadingSubmit={loadingSubmit}
      blockUser={blockUser}
      unblockUser={unblockUser}
      deleteUser={deleteUser}
      textConfirmModal={textConfirmModal}
    />
  );
}
