import Axios from 'axios';

import {doGet, doPost, doDelete, doGetImagesorFiles, doPut} from './Api';

export default class ManagerNewsLatter {

  static async responseGetListCategory() {
    const response = await doGet(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter-contents/contenidos`);
    return response.data;
  }

  static async responseCreateCategory(data) {
    const response = await doPost(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter-contents`,data);
    return response.data;
  }

  static async responseUpdateCategory(data) {
    const response = await doPut(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter-contents`,data);
    return response.data;
  }

  static async responseDeleteCategory(data) {
    const response = await doDelete(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter-contents`,data);
    return response.data;
  }

  static async responseGetListBoletinesByCategory(categoryId) {
    const response = await doGet(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter/${categoryId}`);
    const { data } = response;
    data.data = data.data.sort((a, b) => new Date(b.dateRegister) - new Date(a.dateRegister));
    return data || response.data;
  }

  static async responseGetImages(url) {
    const responseImage = await doGetImagesorFiles(
      process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER + url
    );
    if(responseImage){
      return responseImage.data.data;
    }

    return null;

  }

  static async getUrlDocuments(structToGetUrlFile) {
    const response = await doGet(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter/put/${structToGetUrlFile}`);
    return response.data;
  }

  static async uploadFile(url, data,type) {
    const config = {
      method: 'put',
      url,
      headers: {
        'content-type': `${type}`,
      },
      data,
    };

    const response = await Axios(config);
    return response;
  }


  static async responseUpdateorCreateBoletin(data, isEdit) {
    let response = {};
    if(isEdit){
      try{
        response = await doPut(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter`, data);
      } catch (e) {

      }
    } else {
      try{
        response = await doPost(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter`,data);
      } catch(e){
      }
    }
    return response.data;
  };


  static async responseDeleteBoletin(data) {
    let response = {};
    try{
      response = await doDelete(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}/newsletter`,data);
    } catch(e){

    }
    return response.data;
  };



  static async getFileBoletin(urlPath) {
    const response = await doGet(`${process.env.REACT_APP_BASE_URL_ADMIN_NEWSLETTER}${urlPath}`);
    return response.data.data;
  }

}
