import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pathServer } from '../shared/constants/Constants';
import PSIcon from './PSIcon';
import { Routes } from '../../routes';
import { verifyPathIncludesOption } from '../../scripts/Helper';
import { clearUserBeneficiario } from '../redux/ducks/user-beneficary';
import { PERMISSION_DEFAULT, PERMISSION_LIST } from '../../scripts/Constants';
import { filterOptionsMenu, includesArray } from '../../scripts/Util';

const StyledPSASideBar = styled.div`
  width: 100%;
  height: 100%;
  .container-img {
    display: flex;
    background: #fff;
    img {
      margin: 1rem auto;
      width: 70%;
    }
  }
  .container-menu {
    margin: 1rem;
    .menu {
      margin: 1rem 1rem 2.5rem 1rem;
      .section-title {
        padding-bottom: 1rem;
        color: #de6d19;
        text-align: left;
        font-size: 13px;
      }
      .section-item {
        font-family: 'Gotham-Book', sans-serif;
        margin: 1rem 0;
        color: #002655;
        cursor: pointer;
        font-size: 14px;
        .item-title {
          display: flex;
          .icon-title {
            padding-right: 10px;
          }
          .icon-arrow {
            padding-left: 20px;
          }
        }

        .hide {
          display: none;
        }
        .section-item-option {
          padding: 0.5rem;
          cursor: pointer;
          li {
            list-style: none;
          }
          li:before {
            content: '• ';
            color: #de6d19;
            font-size: 22px;
          }
        }
        .selected {
          color: #de6d19;
          background: #fae9dd;
          border-radius: 17px;
        }
      }
    }
  }
`;

const menuSidebar = (permission_list) => {
  return [
    {
      id: 1,
      nameMenu: 'CONFIGURACIÓN GENERAL',
      menu: [
        {
          id: 11,
          title: 'Administradores',
          icon: 'construct',
          url: '#',
          options: [],
          show: () =>
            permission_list && includesArray(permission_list, PERMISSION_LIST.Administradores),
        },
        {
          id: 12,
          title: 'Roles y Permisos',
          url: Routes.GENERAL_ROLES,
          icon: 'construct',
          options: [],
          show: () =>
            permission_list && includesArray(permission_list, PERMISSION_LIST.Roles_Permisos),
        },
        {
          id: 13,
          title: 'Grupos',
          url: '#',
          icon: 'construct',
          options: [],
          show: () => permission_list && includesArray(permission_list, PERMISSION_LIST.Grupos),
        },
        {
          id: 14,
          title: 'Encuesta de Satisfacción',
          url: '/survey',
          icon: 'construct',
          options: [],
          show: () =>
            permission_list &&
            includesArray(permission_list, PERMISSION_LIST.Encuesta_Satisfaccion),
        },
      ],
    },
    {
      nameMenu: 'CONFIGURACIÓN NET PRIVADA',
      id: 2,
      menu: [
        {
          id: 21,
          title: 'Configuración General',
          icon: 'construct',
          show: () =>
            permission_list && includesArray(permission_list, PERMISSION_LIST.Net_ConfigGeneral),
          options: filterOptionsMenu(),
        },
        {
          id: 22,
          title: 'Naturales',
          icon: 'people',
          show: () =>
            permission_list && includesArray(permission_list, PERMISSION_LIST.Net_Naturales),
          options: [{ id: 221, description: 'Usuarios', url: '/net/natural/user' }],
        },
        {
          id: 23,
          title: 'Empresas',
          icon: 'company',
          show: () =>
            permission_list && includesArray(permission_list, PERMISSION_LIST.Net_Empresas),
          options: [
            { id: 231, description: 'Solicitudes', url: '/net/company/request' },
            { id: 232, description: 'Usuarios', url: '/net/company/user' },
          ],
        },
      ],
    },
    {
      id: 3,
      nameMenu: 'CONFIGURACIÓN NEWSLETTER',
      menu: [
        {
          id: 31,
          title: 'Configuración General',
          icon: 'construct',
          show: () =>
            permission_list && includesArray(permission_list, PERMISSION_LIST.Newsletter_General),
          options: [
            { id: 311, description: 'Banners y Categorías', url: Routes.NEWSLETTER_GENERAL },
            // { id: 312, description: 'Gestionar Banner y Categorias  ', url: Routes.NEWSLETTER_CATEGORY },
          ],
        },
      ],
    },
  ];
};
const PSAsideBar = ({ optionsMenu }) => {
  const [currentOption, setCurrentOption] = useState({});
  const { administrator } = useSelector((state) => state.user);
  let permissionAdministrator = administrator
    ? administrator.roles.filter((r) => administrator.info.roles.includes(r.sort))
    : null;
  if (permissionAdministrator) {
    permissionAdministrator = permissionAdministrator.map((p) => p.menuOptions);
  }
  // permissionAdministrator = PERMISSION_DEFAULT.map((p) => p.value);
  const optionsSidebar = optionsMenu || menuSidebar;
  const menuState = permissionAdministrator
    ? optionsSidebar(...permissionAdministrator)
    : optionsSidebar(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const selectMenu = (firstIndex, SecondIndex, url) => {
    if (!url) {
      if (SecondIndex === currentOption[firstIndex]) {
        setCurrentOption({});
      } else {
        currentOption[firstIndex] = SecondIndex;
        setCurrentOption({ ...currentOption });
      }
    }
    history.push(url);
  };
  const goToPage = (item) => {
    if (item.id === 221) {
      dispatch(clearUserBeneficiario());
    }
    history.push(item.url);
  };
  return (
    <StyledPSASideBar>
      <div className="container-img">
        <img src={`${pathServer.PATH_IMG}logo_oficial.jpeg`} />
      </div>
      <div className="container-menu">
        {menuState.map((menu, fIndex) => (
          <div key={menu.id} className="menu">
            <div className="section-title">
              <a>{menu.nameMenu}</a>
            </div>
            {menu.menu.map((opt, SIndex) => {
              const hasOpenOption = verifyPathIncludesOption(history, opt);
              const showOpenOption = SIndex === currentOption[fIndex] || hasOpenOption;
              return (
                opt.show() && (
                  <div key={opt.id} className="section-item">
                    <div className="item-title" onClick={() => selectMenu(fIndex, SIndex, opt.url)}>
                      {opt.icon && <PSIcon type={opt.icon} className="icon-title" />}
                      <a>{opt.title}</a>
                      {opt.options.length > 0 && (
                        <PSIcon
                          type={showOpenOption ? 'arrowDown' : 'arrowLeft'}
                          className="icon-arrow"
                        />
                      )}
                    </div>
                    <div className={showOpenOption ? ' ' : 'hide'}>
                      {opt.options.map((item) => (
                        <div
                          key={item.id}
                          className="section-item-option"
                          onClick={() => goToPage(item)}
                        >
                          <li>{item.description}</li>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        ))}
        <div />
      </div>
    </StyledPSASideBar>
  );
};

PSAsideBar.propTypes = {
  optionsMenu: PropTypes.array,
};

export default PSAsideBar;
