import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import PSIcon from '../../PSIcon';

const TableTaskHistory = ({ listItem }) => {
  return (
    <table className="container-table-task-history">
      <thead>
        <tr>
          <th className="table-task-history-header-field">Nombre del archivo</th>
          <th className="table-task-history-header-field">Peso</th>
          <th className="table-task-history-header-field">Fecha de envío</th>
          <th className="table-task-history-header-field">Acción</th>
        </tr>
      </thead>
      <tbody>
        {listItem.map((item, index) => {
          return (
            <tr key={index}>
              <td className="table-task-history-item d-flex">
                <PSIcon type={item.icon} />
                <div>
                  <span>{item.fileName}</span>
                </div>
              </td>
              <td className="table-task-history-item">{item.fileSize}</td>
              <td className="table-task-history-item">{item.postDate}</td>
              <td className="table-task-history-item action-item">
                <PSIcon type="download2" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableTaskHistory;

TableTaskHistory.displayName = 'TableTaskHistory';

TableTaskHistory.defaultProps = {
  className: '',
  listItem: [],
};

TableTaskHistory.propTypes = {
  className: PropTypes.string,
  listItem: PropTypes.array,
};
