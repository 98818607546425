import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import PSModal from '../../PSModal';
import { Button } from '../../atoms';

const ModalSuccess = React.forwardRef(
  ({ className, title, message, buttonText, openModal, onClick, classContainer }, ref) => {
    return (
      <div ref={ref}>
        <PSModal open={openModal} showCloseIcon={false} classNames={className}>
          <div className={`container-modal-success ${className} ${classContainer}`}>
            <PSIcon type="success" className="modal-success-icon" />
            <div className="modal-success-message">
              <div className="modal-title">{title}</div>
              <div className="modal-message">{message}</div>
            </div>
            <Button onClick={onClick} className="modal-success-button">
              {buttonText}
            </Button>
          </div>
        </PSModal>
      </div>
    );
  }
);

ModalSuccess.displayName = 'ModalSuccess';

ModalSuccess.defaultProps = {
  className: '',
  title: 'Exitoso',
  message: 'Se publicaron los cambios exitosamente',
  buttonText: 'OK',
  onClick: () => {},
  openModal: false,
};

ModalSuccess.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.message,
  onClick: PropTypes.func,
  openModal: PropTypes.bool,
  buttonText: PropTypes.string,
  classContainer: PropTypes.string,
};

export default ModalSuccess;
