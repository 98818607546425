import React, { useState, useEffect } from 'react';
import './index.scss';
import moment from "moment";
import { Routes } from '../../../../routes';
import { useHistory , useParams } from 'react-router-dom';

import { ModalConfirm, ModalSuccess, NewSletterCategory } from '../../organisms';
import PSModal from "../../PSModal";
import PSTabs from '../../PSTabs';
import { ContainerNewCategory, ContainerPreviewNewsLetter } from '../../molecules';
import ManagerNewsLatter from "../../../shared/services/ManagerNewsLatter";

const tabsNewSletter = [
  { id: 0, name: 'Banner', className: '', selected: false },
  { id: 1, name: 'Categoría', className: '', selected: true },
];

export default function NewSletterGeneralCategory() {

  const history = useHistory();
  const { id } = useParams();
  const [showPreview, setShowPreview] = useState(false);
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [listBoletinByCategory, setListBoletinByCategory] = useState([]);
  const [listImgBoletinByCategory, setListImgBoletinByCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingById, setLoadingById] = useState(false);
  const [currentIndexBoletin, setCurrentIndexBoletin] = useState(0);
  const [nameCategory, setNameCategory] = useState('');

  const actionsToCard = [
    { id: 1, label: 'Ocultar', action: (id) => console.log('Ocultar', id) },
    { id: 2, label: 'Editar', action: (id) => console.log('Editar', id) },
    { id: 3, label: 'Eliminar', action: (id) => {setShowModalConfirm(true); setCurrentCategoryId(id)} },
  ];

  const loadCategory = async () => {
    try{
      setLoading(true);
      const response = await ManagerNewsLatter.responseGetListCategory();
      if(response.statusCode === 200) {
        const  data = response.data;
        setListCategory(data);
        if(id){
          setCurrentCategory(data.find(d=> d.idContenido === id));
        }
      }
      setLoading(false);

    }catch (error){
      setLoading(false);
      console.error(error);
    }
  }

  const createCategory = async () => {

    if(nameCategory !==''){
      const data= {
        contentOrder:listCategory.length,
        enabled: true,
        responsiveBannerS3UrlImage:"img1",
        webBannerS3UrlImage: "img2",
        idContentRelated: "00000002",
        dateRegister: moment(new Date()).format('DD/MM/YYYY'),
        principalTitle: nameCategory,

      }
      try{
        const response = await ManagerNewsLatter.responseCreateCategory(data);
        setShowNewCategory(false);
        if(response.statusCode === 200) {
          await loadCategory();
        }

      }catch (error){
        console.error(error);
      }
    }
  }

  const deleteCategory = async () => {

    const data = {idContenido: currentCategoryId}
    try{
      const response = await ManagerNewsLatter.responseDeleteCategory(data);
      if (response.statusCode === 200) {
        setShowModalConfirm(false);
        await loadCategory();
      }
    }catch (error){
      console.error(error);
    }
  }

  const loadBoletinByCategory = async () =>{

    try{
      setLoadingById(true);
      const response = await ManagerNewsLatter.responseGetListBoletinesByCategory(currentCategory.idContenido);
      if (response.statusCode === 200) {
        const { data } = response;
        if (data) {
          setListBoletinByCategory([...data.slice(0, 3)]);

          setLoadingById(false);
        }
      }
    }catch (error){
      setLoadingById(false);
      console.error(error);
    }
  }

  const loadImageFile = async () => {
    const listImg = [];
    for(const obj in  listBoletinByCategory){
      const responseImage = await ManagerNewsLatter.responseGetImages(listBoletinByCategory[obj].s3UrlImage);
      listImg.push(responseImage);
      setListImgBoletinByCategory([...listImg]);
    }
  };

  useEffect(()=>{
    loadCategory();
  },[]);


  useEffect(() => {
   if(currentCategory!==null){
      loadBoletinByCategory();
   }
  }, [currentCategory]);

  useEffect(() => {
    if(!loadingById){
      loadImageFile();
    }
  },[loadingById])

  return (
    <div className="newsletter-general-category">
      <div className="newsletter-general__header">
        <h2 className="color-blue extra-bold">Newsletter</h2>
        <PSTabs className="tabs-newsletter" options={tabsNewSletter} onSelecTab={(index) => {
          index === 0  ?  history.push(Routes.NEWSLETTER_GENERAL) :  history.push(Routes.NEWSLETTER_CATEGORY);
        }} hasFilters={false} hasButtonSubmit={false}
        />
      </div>
      <div className="newsletter-general__body">
        <NewSletterCategory
          onClickModalPreview = {(index)=>{setShowPreview(true); setCurrentIndexBoletin(index);}}
          onClickModalNewCategory = {()=>setShowNewCategory(true)}
          onClickModalSuccess = {()=>setShowModalSuccess(true)}
          listCategory={listCategory}
          actionsToCard={actionsToCard}
          currentCategory={currentCategory}
          selectCategory = {(category)=>setCurrentCategory(category)}
          listBoletinByCategory={listBoletinByCategory}
          loading ={loading}
          loadingById={loadingById}
          listImgBoletinByCategory={listImgBoletinByCategory}
        />

        {showPreview && (
          <PSModal
            open={showPreview}
            onClose={() => setShowPreview(false)}
            classNames={{
              overlay: 'customOverlay',
              modal: 'customModalPreview',
            }}
          >
            <ContainerPreviewNewsLetter boletin={listBoletinByCategory[currentIndexBoletin]} imageUrlBoletin={listImgBoletinByCategory[currentIndexBoletin]} />
          </PSModal>
        )}

        {showNewCategory &&
        <PSModal
          open={showNewCategory}
          onClose={() => setShowNewCategory(false)}
        >
          <ContainerNewCategory
              createCategory={createCategory}
              nameCategory={nameCategory}
              onChange={(value) => {
                setNameCategory(value);
              }}
            />
        </PSModal>
        }
        {
          showModalSuccess &&
          <ModalSuccess openModal={showModalSuccess} onClick={()=>setShowModalSuccess(false)}/>
        }
        {
          showModalConfirm &&
          <ModalConfirm
            openModal={showModalConfirm}
            onClick={()=>setShowModalConfirm(false)}
            onCancel = {()=>{setCurrentCategoryId(null); setShowModalConfirm(false)}}
            onAccept={()=>{deleteCategory()}}
          />
        }

      </div>
    </div>
    )

}
