export const Contracts = [
  {
    icon: 'emptyFolder',
    name: 'Nuevo Contrato',
    treeItems: null,
  },
  {
    icon: 'folder',
    name: 'Contratos 1',
    treeItems: null,
  },
  {
    icon: 'folder',
    name: 'Contratos 2',
    treeItems: [
      {
        icon: 'emptyFolder',
        name: 'Item 1',
        treeItems: null,
      },
      {
        icon: 'emptyFolder',
        name: 'Item 2',
        treeItems: null,
      },
      {
        icon: 'emptyFolder',
        name: 'Item 3',
        treeItems: null,
      },
    ],
  },
  {
    icon: 'recent',
    name: 'Archivos recientes',
    treeItems: null,
  },
  {
    icon: 'blueStar',
    name: 'Favoritos',
    treeItems: null,
  },
];
