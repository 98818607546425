import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import PSIcon from '../../PSIcon';

const ItemFile = React.forwardRef(({ className, icon, fileName, uploadDate }, ref) => {
  return (
    <div className={`item-file_container ${className}`} ref={ref}>
      <div className="file-icon_container">
        <PSIcon type={icon} />
      </div>
      <div className="file-title_container">
        <p>{fileName}</p>
      </div>
      <div className="upload-date_container">
        <p>{uploadDate}</p>
      </div>
    </div>
  );
});

ItemFile.displayName = 'ItemFile';

ItemFile.defaultProps = {
  className: '',
  icon: 'default',
  fileName: '',
  uploadDate: '',
};

ItemFile.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  fileName: PropTypes.string,
  uploadDate: PropTypes.string,
};

export default ItemFile;
