import { doGetWithEntity, doPatch, doPost, doPostWhitParameters, doDelete, doGet } from './Api';
import { UserBackOfficeModel } from '../model/UserBackOfficeModel';
import { RolModel } from '../model/RolModel';

export default class RolesAndPermissions {
  static async getListRoles() {
    const response = await doGetWithEntity(
      `${process.env.REACT_APP_ROLES_AND_PERMISSION}/roles`,
      'ROLE'
    );
    return response;
  }

  static async getListUsers() {
    const response = await doGetWithEntity(
      `${process.env.REACT_APP_ROLES_AND_PERMISSION}/roles`,
      'USUARIO'
    );
    return response;
  }

  static async updateRoleByUser(payload) {
    const response = await doPatch(
      `${process.env.REACT_APP_ROLES_AND_PERMISSION}/userrole`,
      'USUARIO',
      payload
    );
    return response;
  }

  static async updateRolePermission(payload) {
    const response = await doPatch(
      `${process.env.REACT_APP_ROLES_AND_PERMISSION}/role`,
      'ROLE',
      payload
    );
    return response;
  }

  static async createAdministrator(payload) {
    const response = await doPost(`${process.env.REACT_APP_HELPDESK}/employees`, payload);
    return response.data;
  }
  static async listEmployees() {
    const response = await doGet(`${process.env.REACT_APP_HELPDESK}/employees`);
    return response.data;
  }

  static async loadAdministrator(email) {
    try {
      const response = await RolesAndPermissions.getListUsers();
      const responsePermission = await RolesAndPermissions.getListRoles();
      if (response.status === 200) {
        let { data } = response;
        let roles = responsePermission.data.map((item) => new RolModel(item));
        roles = roles.filter((d) => d.sort !== '1628723210');
        data = data.map((item) => new UserBackOfficeModel(item));
        const info = data.find((d) => d.username === email);
        return { info, roles };
      }
    } catch (e) {
      console.log('error', e);
      return null;
    }
  }

  static async enableUser(userId, header) {
    const response = await doPostWhitParameters(
      `${process.env.REACT_APP_UPDATE_STATUS_USER}/enable/${userId}`,
      {},
      null,
      header
    );
    return response.data;
  }

  static async disableUser(userId, header) {
    const response = await doPostWhitParameters(
      `${process.env.REACT_APP_UPDATE_STATUS_USER}/disable/${userId}`,
      {},
      null,
      header
    );
    return response.data;
  }

  static async deleteUser(userId, header) {
    const response = await doDelete(
      `${process.env.REACT_APP_UPDATE_STATUS_USER}/${userId}`,
      null,
      header
    );
    return response.data;
  }
}
