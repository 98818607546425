import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import PSIcon from './PSIcon';
import PSLoading from './PSLoading';
import { ModalConfirm } from './organisms';

const StyledPSDynamicTable = styled.div`
  background: #fff;
  width: 100%;

  .parent-table {
    overflow-x: scroll;
    min-width: 100%;
    width: 50px;

    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #cbcbcb;
      margin-left: 0.8rem;
    }

    ::-webkit-scrollbar-thumb {
      background: #ed6e00;
      border-radius: 10px;
    }
  }

  .container-table {
    border-spacing: 0px 10px;
    width: 100%;
    text-align: left;
    .table-header {
      color: #002655;
      font-size: 12px;
      padding-left: 10px;
      .table-header-field {
        padding: 0.5rem 10px;
      }
      .header-info-basic {
        padding-left: 10px;
      }
      .th-check {
        width: 15px;
      }
    }
    .tr-body {
      cursor: pointer;
      &:hover {
        background: #f5f5f5;
      }
    }
    .tr-disabled {
      cursor: default;
      background: #80808012;
      .select.medium {
        cursor: default;
      }
    }
    .table-item {
      font-family: 'Gotham-Book', sans-serif;
      color: #002655;
      padding: 0.4rem 10px;
      .item-actions {
        filter: opacity(0.6);
        display: flex;
        justify-content: left;
        gap: 20px;
        margin-left: 12px;
      }
      .item-info-basic {
        font-family: 'Gotham-Medium', sans-serif;
        display: flex;
        .info-photo {
          width: 32px;
          height: 32px;
          border-radius: 50px;
          background: red;
          margin-right: 10px;
          svg {
            border-radius: 15px;
          }
        }
        .info-name {
          font-size: 12px;
          text-transform: capitalize;
        }
        .info-mail {
          font-size: 10px;
          opacity: 0.5;
        }
      }
    }
    // .table-item-first {
    //   border-left: 1px solid #e9ecef;
    // }a
    // .table-item-last {
    //   border-right: 1px solid #e9ecef;
    // }
  }
`;

export const PSDynamicTable = React.forwardRef(
  (
    {
      headers,
      items,
      onSelect,
      withCheck,
      selectUser,
      loading,
      hasActions,
      hasDates,
      labelPrincipalHeader,
      showCheck,
      blockUser,
      unblockUser,
      deleteUser,
      textConfirmModal,
      showMainHeader,
    },
    ref
  ) => {
    const userBackground = '';
    const [listSelect, setListSelect] = useState([]);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [contentModalConfirmation, setContentModalConfirmation] = useState({
      text: '',
      title: '',
    });
    const onCheck = (id, value) => {
      if (value) {
        listSelect.push(id);
        setListSelect([...listSelect]);
      } else {
        const listSelectFiler = listSelect.filter((item) => {
          return item !== id;
        });
        setListSelect([...listSelectFiler]);
      }
    };

    return (
      <StyledPSDynamicTable
        ref={ref}
        userBackground={userBackground}
        className={`${loading ? 'align-all-center' : ' '}`}
      >
        {loading ? (
          <PSLoading color="orange" />
        ) : (
          <div className={`${items.length > 0 ? 'parent-table' : ''} `}>
            <table className="container-table">
              <thead>
                <tr className="table-header">
                  {withCheck && <th className="th-check" />}
                  {showMainHeader && (
                    <th className="table-header-field header-info-basic" width="10%">
                      {labelPrincipalHeader}
                    </th>
                  )}

                  {headers.map(({ elementProp, headerColumn, width = '5%' }) => (
                    <th key={elementProp} className="table-header-field" width={width}>
                      {headerColumn}
                    </th>
                  ))}

                  {hasDates && (
                    <th className="table-header-field" width="6%">
                      CREADO
                    </th>
                  )}
                  {hasDates && (
                    <th className="table-header-field" width="6%">
                      ACTUALIZADO
                    </th>
                  )}
                  {hasActions && (
                    <th className="table-header-field" width="6%">
                      ACCIONES
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => {
                  return (
                    <tr
                      key={`user-${index}`}
                      onClick={() => selectUser(item)}
                      className={`tr-body ${item.enabled === false ? 'tr-disabled ' : ''}`}
                    >
                      {withCheck && (
                        <td className="table-item">
                          <input
                            type="checkbox"
                            onChange={(event) => onCheck(item.id, event.target.checked)}
                          />
                        </td>
                      )}
                      {showMainHeader && (
                        <td className="table-item">
                          <div className="item-info-basic">
                            <div className="info-photo">
                              <PSIcon type="profile" />
                            </div>
                            <div>
                              <div className="info-name">{item.name}</div>
                              <div className="info-mail">{item.mail}</div>
                            </div>
                          </div>
                        </td>
                      )}
                      {headers.map((header, i) => (
                        <td
                          className={`table-item${
                            i === 0 && !withCheck ? ' table-item-first' : ' '
                          }`}
                          key={item.id + header.elementProp}
                        >
                          {item[header.elementProp] || '-'}
                        </td>
                      ))}
                      {hasDates && <td className="table-item">{item.createDate}</td>}
                      {hasDates && (
                        <td className="table-item table-item-last">{item.updateDate}</td>
                      )}
                      {hasActions && (
                        <td className="table-item">
                          <div className="item-actions">
                            {item.enabled ? (
                              <PSIcon
                                className="show-modal"
                                data-tip="Bloquear usuario"
                                type="block"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setContentModalConfirmation({
                                    ...contentModalConfirmation,
                                    text: textConfirmModal?.messageBlock?.text,
                                    title: textConfirmModal?.messageBlock?.title,
                                    function: () => blockUser(item),
                                  });
                                  setShowModalConfirmation(true);
                                }}
                              />
                            ) : (
                              <PSIcon
                                data-tip="Desbloquear usuario"
                                className="show-modal"
                                type="unlock"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setContentModalConfirmation({
                                    ...contentModalConfirmation,
                                    text: textConfirmModal?.messageUnblock?.text,
                                    title: textConfirmModal?.messageUnblock?.title,
                                    function: () => unblockUser(item),
                                  });
                                  setShowModalConfirmation(true);
                                }}
                              />
                            )}
                            <PSIcon
                              data-tip="Eliminar usuario"
                              className="show-modal"
                              type="delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                setContentModalConfirmation({
                                  ...contentModalConfirmation,
                                  text: textConfirmModal?.messageDelete?.text,
                                  title: textConfirmModal?.messageDelete?.title,
                                  function: () => deleteUser(item),
                                });
                                setShowModalConfirmation(true);
                              }}
                            />
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {showModalConfirmation && (
              <ModalConfirm
                openModal={showModalConfirmation}
                onCancel={() => setShowModalConfirmation(false)}
                onClose={() => setShowModalConfirmation(false)}
                title={contentModalConfirmation.title}
                onAccept={() => {
                  contentModalConfirmation.function();
                  setShowModalConfirmation(false);
                }}
                question={contentModalConfirmation.text}
              />
            )}

            <ReactTooltip place="top" type="dark" />
          </div>
        )}
      </StyledPSDynamicTable>
    );
  }
);

PSDynamicTable.displayName = 'PSDynamicTable';

PSDynamicTable.defaultProps = {
  showCheck: false,
  headers: [],
  items: [],
  withCheck: false,
  loading: false,
  onSelect: () => {},
  selectUser: () => {},
  blockUser: () => {},
  unblockUser: () => {},
  deleteUser: () => {},
  hasActions: true,
  hasDates: true,
  labelPrincipalHeader: 'INFORMACIÓN BÁSICA',
  textConfirmModal: {},
  showMainHeader: true,
};

PSDynamicTable.propTypes = {
  showCheck: PropTypes.bool,
  items: PropTypes.array,
  headers: PropTypes.array,
  onSelect: PropTypes.func,
  withCheck: PropTypes.bool,
  loading: PropTypes.bool,
  selectUser: PropTypes.func,
  blockUser: PropTypes.func,
  unblockUser: PropTypes.func,
  deleteUser: PropTypes.func,
  hasActions: PropTypes.bool,
  hasDates: PropTypes.bool,
  labelPrincipalHeader: PropTypes.string,
  textConfirmModal: PropTypes.object,
  showMainHeader: PropTypes.bool,
};

export default PSDynamicTable;
