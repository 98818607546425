import React, { useState } from 'react';
import './index.scss';
import PSTabs from '../../components/PSTabs';
import PSCard from '../../components/PSCard';
import { PSDynamicTable } from '../../components/PSDynamicTable';
import PSModal from '../../components/PSModal';
import PSButton from '../../components/PSButton';
import PSSolicitud from '../../components/PSSolicitud';
import { Button } from '../../components/atoms';
import PSIcon from '../../components/PSIcon';
import DropDown from '../../components/molecules/dropDown/DropDown';
import { FileBoleta, Task } from '../../components/organisms';
import TaskTemplate from '../../components/templates/taskTemplate/TaskTemplate';

export default function TestPage() {
  const [view, setView] = useState(1);
  const defineView = (page) => {
    setView(page);
  };
  const tabsSolicitudes = [
    { id: 0, name: 'Solicitudes nuevas', action: () => {}, className: '', selected: true },
    { id: 1, name: 'Solicitudes aceptadas', action: () => {}, className: '', selected: false },
    { id: 2, name: 'Solicitudes rechazadas', action: () => {}, className: '', selected: false },
  ];

  const headerTable = [
    { elementProp: 'empresa', headerColumn: 'EMPRESA' },
    { elementProp: 'ruc', headerColumn: 'RUC' },
    { elementProp: 'razonSocial', headerColumn: 'RAZON SOCIAL' },
  ];

  const items = [
    {
      id: '001',
      empresa: 'empresa',
      ruc: '000001',
      razonSocial: 'razonSocial',
      name: 'Sta. Jorge Luis Garibay',
      mail: 'julio@gmail.com',
    },
    {
      id: '002',
      empresa: 'empresa2',
      ruc: '000002',
      razonSocial: 'razonSocial2',
      name: 'cesar',
      mail: 'julcr@gmail.com',
    },
    {
      id: '003',
      empresa: 'empresa3',
      ruc: '000003',
      razonSocial: 'razonSocial3',
      name: 'julio2',
      mail: 'julio3@gmail.com',
    },
  ];
  const tasks = [
    {
      statusId: 'toDo',
      task: '10',
      status: 'Por hacer',
      name: 'Make Money Online Through',
      user: 'Terry Riley',
    },
    {
      statusId: 'inProgress',
      task: '10',
      status: 'En progreso',
      name: 'Make Money Online Through',
      user: 'Terry Riley',
    },
    {
      statusId: 'review',
      task: '10',
      status: 'Revisión',
      name: 'Make Money Online Through',
      user: 'Terry Riley',
    },
    {
      statusId: 'toDo',
      task: '10',
      status: 'Por hacer',
      name: 'Make Money Online Through',
      user: 'Terry Riley',
    },
    {
      statusId: 'inProgress',
      task: '10',
      status: 'En progreso',
      name: 'Make Money Online Through',
      user: 'Terry Riley',
    },
    {
      statusId: 'inProgress',
      task: '10',
      status: 'En progreso',
      name: 'Make Money Online Through',
      user: 'Terry Riley',
    },
    {
      statusId: 'review',
      task: '10',
      status: 'Revisión',
      name: 'Make Money Online Through',
      user: 'Terry Riley',
    },
    {
      statusId: 'finalized',
      task: '10',
      status: 'Finalizado',
      name: 'Make Money Online Through',
      user: 'Terry Riley',
    },
  ];
  return (
    // <div className="container-test">
    //   pagina de test
    //   <p>Hola mundo</p>
    //   <PSTabs options={tabsSolicitudes} />
    //   <PSCard />
    //   <PSDynamicTable headers={headerTable} items={items} withCheck />
    //   <Button loading />
    //   <FileBoleta />
    //   <Task task="10" status="En progreso" name="Make Money Online Through" user="Terry Riley" />
    // </div>
    <div>
      <TaskTemplate view={view} tasks={tasks} defineView={defineView} />
    </div>
  );
}
