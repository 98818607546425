import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { pathServer } from '../../../shared/constants/Constants';
import PSIcon from '../../PSIcon';

const SmallCollaboratorCard = React.forwardRef(
  ({ className, photo, name, lastName, isSelected }, ref) => {
    return (
      <div className={`small-collaborator-card__container ${className}`} ref={ref}>
        <div className="collaborator_container d-flex">
          {isSelected ? <PSIcon type="orangeCheck" /> : <PSIcon type="uncheck" />}
          {photo ? (
            <div className="image-container d-flex">
              <img alt="" src={`${pathServer.PATH_IMG + photo}`} />
            </div>
          ) : (
            <PSIcon type="pdfFile" />
          )}
          <div className="info-container">
            <h3 className="name">{name}</h3>
            <h3 className="lastName">{lastName}</h3>
          </div>
        </div>
      </div>
    );
  }
);

SmallCollaboratorCard.displayName = 'SmallCollaboratorCard';

SmallCollaboratorCard.defaultProps = {
  className: '',
  collaborator: {
    photo: '',
    name: '',
    lastName: '',
  },
};

SmallCollaboratorCard.propTypes = {
  className: PropTypes.string,
  collaborator: PropTypes.object,
};

export default SmallCollaboratorCard;
