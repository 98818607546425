import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const CheckBox = React.forwardRef(({ className, onClick , message}, ref) => (
  <div ref={ref} className={`container-CheckBox  ${className}`}>
    <input name="checkBox" type="checkbox" onClick={onClick} />
    <label htmlFor="checkbox"> {message}</label>
  </div>
));

CheckBox.displayName = 'CheckBox';

CheckBox.defaultProps = {
  className: '',
  onClick: () => {},
  message: '',
};

CheckBox.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  message: PropTypes.string,
};

export default CheckBox;
