import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from "../../PSIcon";

export const TypeState = {
  earring: 'pendiente',
  published: 'publicado',
  rejected: 'rechazado',
};

const IconState = React.forwardRef(({ type, className, onClick }, ref) => {
  const op = type.toLowerCase();
  return <div ref={ref} className={`container-IconState ${className}`}>
    <PSIcon type={ op===TypeState.published ?'checkCircle' :(op===TypeState.earring ?'timesCircle' :'xCircle' )} onClick={onClick}/>
  </div>
});

IconState.displayName = 'IconState';

IconState.defaultProps = {
  className: '',
  onClick: () => {},
  type: TypeState.rejected,
};

IconState.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default IconState;
