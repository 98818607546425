export const listRequestsDefault =[
  {
   id:'1',
    name:'Pendientes: 3',
    options:[
      {
        id:'11',
        img :'',
        name:'Valeria Saavedra',
        date:'12/12/21'
      },
      {
        id:'11',
        img :'',
        name:'Valeria Saavedra',
        date:'12/12/21'
      }
    ]
  },
  {
    id:'2',
    name:'Pendientes: 3',
    options:[
      {
        id:'21',
        img :'',
        name:'Valeria Saavedra',
        date:'12/12/21'
      },
      {
        id:'21',
        img :'',
        name:'Valeria Saavedra',
        date:'12/12/21'
      }
    ]
  }
];