export class UserBackOfficeModel {
  constructor(props) {
    this.createDate = props.CreationDate;
    this.creationUser = props.CreationUser;
    this.id = props.Id;
    this.menuOptions = props.MenuOptions;
    this.name = props.Name;
    this.document = '312312321'; // for the moment don't have
    this.username = props.Name; // for the moment
    this.email = 'email@test.com'; // for the mement
    this.sort = props.Sort;
    this.roles = props.Roles ? props.Roles : [];
    this.updateDate = props.UpdateDate;
    this.UpdateUser = props.UpdateUser;
  }
}