import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import { IconState } from '../../atoms/index';

const ItemListSurvey = React.forwardRef(({ className, data, ...props }, ref) => {
  return (
    <div ref={ref} className={`container-itemListSurvey d-flex ${className}`}>
      <div className="container-itemListSurvey__item title d-flex">
        <div className="title__icon sub">
          <a style={{ backgroundColor: `#FF8036` }}>{data.abbreviation}</a>
        </div>
        <div className="title__text sub">
          <span>{data.title}</span>
        </div>
      </div>
      <div className="container-itemListSurvey__item date d-flex">
        <PSIcon type="calendar" className="date__icon sub" />
        <div className="date__text sub">
          <span>{data.date}</span>
        </div>
      </div>
      <div className="container-itemListSurvey__item hour d-flex">
        <PSIcon type="time" className="hour__icon sub" />
        <div className="hour__text sub">
          <span>{data.hour}</span>
        </div>
      </div>
      <div className="container-itemListSurvey__item state d-flex">
        <IconState type={data.state} className="state__icon sub" />
        <div className="state__text sub">
          <span>{data.state}</span>
        </div>
      </div>
    </div>
  );
});

ItemListSurvey.displayName = 'ItemListSurvey';

ItemListSurvey.defaultProps = {
  className: '',
};

ItemListSurvey.propTypes = {
  className: PropTypes.string,
};

export default ItemListSurvey;
