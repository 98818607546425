import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';
import PSModal from '../../PSModal';
import { attachedFiles } from './Constants';
import { Button } from '../../atoms';
import { ActivityComment, AttachedFile } from '../../molecules';
import { ButtonColor } from '../../atoms/button/Button';

const ModalTask = React.forwardRef(({ className, openModal }, ref) => {
  return (
    <div ref={ref}>
      <PSModal open={openModal} showCloseIcon>
        <div className={`task-modal_container ${className}`}>
          <div className="description-task-header d-flex">
            <div className="contract-name d-flex">
              <h4>Nombre del contrato</h4>
            </div>
            <div className="contract-detail d-flex">
              <p>Queda 05 dias</p>
              <select>
                <option>En progreso</option>
              </select>
            </div>
          </div>
          <div className="title-task">
            <h2>Nombre de la tarea</h2>
          </div>
          <div className="description-contain">
            <div className="title-container">
              <h3>Descripción</h3>
            </div>
            <div className="paragraph-container">
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut
              </p>
            </div>
          </div>
          <div className="details-container">
            <div className="informer">
              <div>
                <span>
                  <h5>Informador</h5>
                </span>
              </div>
              <div className="d-flex">
                <PSIcon type="user" />
                <p>Adam Oliver</p>
              </div>
            </div>
            <div className="responsable">
              <div>
                <span>
                  <h5>Responsable</h5>
                </span>
              </div>
              <div className="d-flex">
                <PSIcon type="user" />
                <p>Adam Oliver</p>
              </div>
            </div>
            <div className="date">
              <div>
                <span>
                  <h5>Fecha</h5>
                </span>
              </div>
              <div className="d-flex">
                <PSIcon type="contractCalendar" />
                <p>12/12/2021</p>
              </div>
            </div>
            <div className="priority">
              <div>
                <span>
                  <h5>Prioridad</h5>
                </span>
              </div>
              <div className="d-flex">
                <PSIcon type="calendarOrange" />
                <p>Medio</p>
              </div>
            </div>
          </div>
          <div className="attached-files-title d-flex">
            <PSIcon type="attachment" />
            <p>Archivos adjuntos (2)</p>
          </div>
          <div className="files_container">
            {attachedFiles.map((item, index) => (
              <div key={index}>
                <AttachedFile icon={item.icon} fileName={item.fileName} fileSize={item.fileSize} />
              </div>
            ))}
          </div>
          <div className="activity-title">
            <h3>Actividad</h3>
          </div>
          <div className="activity-buttons_container">
            <Button className="comments-button">Comentarios</Button>
            <Button className="history-button">Historia</Button>
          </div>
          <ActivityComment />
          <ActivityComment />
          <div className="comment-box_container d-flex">
            <PSIcon type="user2" />
            <div className="message-box">
              <textarea placeholder="Escribe tu comentario..." />
            </div>
          </div>
          <div className="action-buttons d-flex">
            <Button className="cancel-button" type={ButtonColor.white}>
              Cancelar
            </Button>
            <Button className="save-button">Guardar</Button>
          </div>
        </div>
      </PSModal>
    </div>
  );
});

ModalTask.displayName = 'ModalTask';

ModalTask.defaultProps = {
  className: '',
  openModal: true,
};

ModalTask.propTypes = {
  className: PropTypes.string,
  openModal: PropTypes.bool,
};

export default ModalTask;
