import React, { useState,useEffect} from 'react';
import './index.scss';
import { ContainerCard, HeaderSection, ItemListSurvey } from '../../molecules';
import { Button} from '../../atoms';
import { ButtonColor } from '../../atoms/button/Button';
import PSModal from '../../PSModal';
import PSIcon from '../../PSIcon';
import { CreateSurveySatisfaction } from '../index';
import PSLoading from "../../PSLoading";

export default function SurveySatisfaction({
  showModalSurvey,
  openModal,
  onCloseModal,
  onClickView,
  survey,
  surveyStatistics,
    recentSurvey, loading,
}) {

  useEffect(()=>{
  },[]);

  return (
    <div className="container-survey-satisfaction">
      <HeaderSection title="Encuestas" />
      <div className="survey-satisfaction__body">
        <section className="secction-left">
          <div className="secction-left__subtitle-1">
            <h3>Últimas publicaciones</h3>
          </div>
          <div className="d-flex">
            {recentSurvey.map((item, index) => (
              <ContainerCard key={index}>
                <PSIcon type="form" className="card__icon" />
                <div className="card__description">
                  <h5 className="card__title">{item.title}</h5>
                  <p className="card__date">{item.date}</p>
                </div>
                {/*<PSIcon type="options2" className="card__option" />*/}
              </ContainerCard>
            ))}
          </div>
          <div className="d-flex secction-left__subtitle-2">
            <h3>Encuestas recientes</h3>
            {/*todo comment for the moment */}
            {/*<Button type={ButtonColor.orange} onClick={openModal}>*/}
              {/*Agregar*/}
            {/*</Button>*/}
          </div>
          {survey.map((item,index) =>
            <div key={index}>
              <ItemListSurvey data={item} />
            </div>
          )}
        </section>

        <section className="secction-right">
          <ContainerCard>
            {
              loading ?
                <PSLoading color={'orange'}/>:
                <>
                  <div className="card-header">
                    <h3 className="card__title">Encuesta Satisfacción</h3>
                    <p>Resumen</p>
                  </div>
                  <div className="card__graphic">
                    <div className="graphic__total">
                      <div />
                      <div />
                      <div />
                      <div>{surveyStatistics.total}</div>
                    </div>
                    <div className="graphic__description d-flex">
                      <PSIcon type="users2" />
                      <span>{surveyStatistics.title}</span>
                    </div>
                  </div>
                  <ul className="card__description">
                    {surveyStatistics.items.map((data, index) => (
                      <li key={index} className="d-flex description__item">
                        <PSIcon type={data.icon} />
                        <span>{data.title}</span>
                        <span>{data.total}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="d-flex card__action">
                    <Button onClick={onClickView} icon="show" type='button-blackPurple'>
                      Ver
                    </Button>
                    {/*todo comment for the moment */}
                    {/*<a><span>Modificar</span></a>*/}
                  </div>
                </>
            }
          </ContainerCard>
        </section>
      </div>
      <PSModal
        open={showModalSurvey}
        onClose={onCloseModal}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModalPreview',
        }}
      >
        <CreateSurveySatisfaction />
      </PSModal>
    </div>
  );
}
