import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import PSIcon from '../../PSIcon';
import PSModal from '../../PSModal';
import { ItemsListBrowser } from '../../organisms';
import { SmallCollaboratorsInfo } from '../../organisms/myCollaborators/Constants';

const CollaboratorsDeck = React.forwardRef(({ className, collaborators }, ref) => {
  const [selected, setSelected] = useState(0);
  const [showCollaboratorsModal, setShowCollaboratorsModal] = useState(false);
  const [filter, setFilter] = useState('');
  const onGetCollaborators = () => {
    return SmallCollaboratorsInfo.filter((item) => {
      return filter ? item.name.search(new RegExp(filter, 'i')) !== -1 : item;
    });
  };
  return (
    <div className={`collaborators-deck__container ${className}`} ref={ref}>
      <div className="collaborator_container d-flex">
        {collaborators.map((item, index) => (
          <div
            className={`${
              showCollaboratorsModal ? '' : index === selected ? 'selected' : ''
            } moveToLeft`}
            onClick={() => {
              setSelected(index);
            }}
          >
            <PSIcon type={item.icon} />
          </div>
        ))}
        <div
          className={`${showCollaboratorsModal ? 'selected' : ''} moveToLeft`}
          onClick={() => {
            setShowCollaboratorsModal(true);
          }}
        >
          <PSIcon type="whiteCircle" />
        </div>
      </div>
      <PSModal
        onClose={() => {
          setShowCollaboratorsModal(false);
        }}
        open={showCollaboratorsModal}
        center
        classNames="customModal"
      >
        <ItemsListBrowser
          hasBrowser
          button="Agregar"
          title="Colaboradores"
          subtitle="Lista de"
          onSearchByName={(value) => {
            setFilter(value);
          }}
          list={onGetCollaborators()}
          className="ilb-psmodal_container"
        />
      </PSModal>
    </div>
  );
});

CollaboratorsDeck.displayName = 'CollaboratorsDeck';

CollaboratorsDeck.defaultProps = {
  className: '',
};

CollaboratorsDeck.propTypes = {
  className: PropTypes.string,
};

export default CollaboratorsDeck;
