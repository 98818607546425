export const contractsList = [
  {
    id: 1,
    icon: 'blueBookmark',
    type: 'Tipo 1',
    numberOfFiles: 100,
  },
  {
    id: 2,
    icon: 'blueBookmark',
    type: 'Tipo 1',
    numberOfFiles: 100,
  },
  {
    id: 3,
    icon: 'blueBookmark',
    type: 'Tipo 1',
    numberOfFiles: 100,
  },
  {
    id: 4,
    icon: 'blueBookmark',
    type: 'Tipo 1',
    numberOfFiles: 100,
  },
];
