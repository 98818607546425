import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import PSIcon from '../../PSIcon';

const RequestComment = ({ username, date, comment }) => {
  return (
    <div className="container-comment2">
      <div className="comment-header">
        <div className="container-user-date">
          <PSIcon type="user" />
          <div className="margin-user-date">
            <span className="comment-user">{username}</span>
            <br />
            <span className="comment-date">{date}</span>
          </div>
        </div>
        <PSIcon type="orangeTrash" />
      </div>
      <div className="comment-body d-flex">
        <div className="space-separator" />
        <div className="comment-container2">
          <p>{comment}</p>
        </div>
      </div>
    </div>
  );
};
export default RequestComment;

RequestComment.displayName = 'RequestComment';

RequestComment.defaultProps = {
  username: 'Daniel Montanchez',
  date: '20/04/2021',
  comment:
    'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen.',
};

RequestComment.propTypes = {
  username: PropTypes.string,
  date: PropTypes.string,
  comment: PropTypes.string,
};
