import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Button, SelectCustom } from '../../atoms';
import { InputLabel } from '../../molecules';
import { ModalContainer } from '../../organisms';
import { pathServer } from '../../../shared/constants/Constants';
import { formatRoleToSelect, maxLengthCheck } from '../../../../scripts/Util';

const CreateUserBackoffice = React.forwardRef(
  ({ onHandleInput, listRoles, userCreate, onSaveUser, loadingCreateUser }, ref) => {
    const roles = formatRoleToSelect(listRoles);
    useEffect(() => {}, []);

    return (
      <div className="create-user-backOffice" ref={ref}>
        <ModalContainer
          mainTitle="Crear Usuario"
          btnMessage="Agregar "
          showFooterSection
          onAccept={onSaveUser}
          loadingSubmit={loadingCreateUser}
        >
          <div className="user-backOffice__body">
            <div>
              <div className="text-center">
                <img
                  src={`${pathServer.PATH_IMG}userBackOffice.png`}
                  className="img-user-backOffice"
                />
                {/* <div> */}
                {/*  <label>Rol</label> */}
                {/* </div> */}
              </div>
              <SelectCustom
                placeholder="Ver todos"
                options={roles}
                className="select-role"
                onChange={(itemSelected) => {
                  onHandleInput(itemSelected.value, 'roles');
                }}
              />
            </div>
            <div className="user-backOffice-container__inputs">
              <form action="">
                <div className="d-flex m-y">
                  <InputLabel
                    onChange={(value) => onHandleInput(value, 'name')}
                    className="mr-1"
                    labelMessage="Nombres"
                    value={userCreate.name}
                  />
                </div>
                <div className="d-flex">
                  <InputLabel
                    onChange={(value) => onHandleInput(value, 'lastName')}
                    className=""
                    labelMessage="Apellido Paterno"
                    value={userCreate.lastName}
                    placeholder="Ingrese su apellido"
                  />
                  <InputLabel
                    onChange={(value) => onHandleInput(value, 'secondLastName')}
                    className=""
                    labelMessage="Apellido Materno"
                    value={userCreate.secondLastName}
                    placeholder="Ingrese su apellido"
                  />
                </div>
                <div className="d-flex m-y">
                  <InputLabel
                    onKeyPress={(e) => maxLengthCheck(e)}
                    maxLength={9}
                    onChange={(value) => onHandleInput(value, 'phone')}
                    className="test"
                    labelMessage="Telefono"
                    value={userCreate.phone}
                    placeholder="Ingrese su telefono"
                  />
                </div>
                <InputLabel
                  onChange={(value) => onHandleInput(value, 'email')}
                  className="m-y"
                  labelMessage="Email"
                  value={userCreate.email}
                  placeholder="Ingrese su email"
                />
              </form>
            </div>
          </div>
        </ModalContainer>
      </div>
    );
  }
);

CreateUserBackoffice.displayName = 'CreateUserBackoffice';

CreateUserBackoffice.defaultProps = {
  userCreate: {},
  onHandleCreateUser: () => {},
  loadingCreateUser: false,
  listRoles: [],
};

CreateUserBackoffice.propTypes = {
  userCreate: PropTypes.object,
  onHandleCreateUser: PropTypes.func,
  loadingCreateUser: PropTypes.bool,
  listRoles: PropTypes.array,
};

export default CreateUserBackoffice;
